// Ensures that time options before current time are not created
// Ensures that time options are created in 5 minute intervals

import {
  addMinutes,
  isSameDay,
  setHours,
  setMinutes,
  startOfHour,
} from 'date-fns';

// Ensures that time options are created between startHour and endHour (00:00 - 23:55 by default)
export const generateTimeOptions = (
  currentDate?: string,
  interval = 5,
  startHour = 0,
  endHour = 23
) => {
  const times: string[] = [];
  const now = new Date();
  const isCurrentDate = currentDate
    ? isSameDay(new Date(currentDate), now)
    : false;

  const startTime = isCurrentDate
    ? nextInterval(now, interval)
    : startOfHour(new Date(currentDate || now));

  for (let hour = startHour; hour <= endHour; hour++) {
    for (let minute = 0; minute < 60; minute += interval) {
      const currentHour = hour % 24;
      const timeToCheck = setMinutes(setHours(startTime, currentHour), minute);
      if (!isCurrentDate || timeToCheck >= now) {
        const formattedHour = currentHour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        const formattedTime = `${formattedHour}:${formattedMinute}`;
        times.push(formattedTime);
      }
    }
  }
  return times;
};

export const nextInterval = (date: Date, interval: number) => {
  let nextAvailableTime = new Date(date.getTime());
  nextAvailableTime.setMilliseconds(0);
  nextAvailableTime.setSeconds(0);
  const minutes = nextAvailableTime.getMinutes();
  const addOneInterval = minutes % interval !== 0;
  const extraMinutes = addOneInterval ? interval - (minutes % interval) : 0;
  nextAvailableTime = addMinutes(nextAvailableTime, extraMinutes);

  return nextAvailableTime;
};

export const parseTime = (timeValue: string): [number, number] => {
  const parts = timeValue.split(':').map(Number);
  if (parts.length !== 2 || parts.some(isNaN)) {
    throw new Error('Invalid time format.');
  }
  return parts as [number, number];
};
