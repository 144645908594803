import { useQuery, UseQueryResult } from '@tanstack/react-query';

import * as api from '../../Api';
import { TBooking } from '../useBookings';

const getBookingsForEventQueryKey = (eventId?: string) => [
  'getBookingsForEvent',
  eventId,
];

interface UseGetBookingsForEventResult {
  bookingsForEvent: UseQueryResult<TBooking[]>;
}

export const useGetBookingsForEvent = (
  eventId?: string
): UseGetBookingsForEventResult => {
  const queryKey = getBookingsForEventQueryKey(eventId);

  const bookingsForEvent = useQuery({
    queryKey: queryKey,
    queryFn: () => api.getBookings(eventId),
    enabled: Boolean(eventId),
  });

  return { bookingsForEvent };
};
