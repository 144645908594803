import { Stack } from '@mui/material';
import { Controller, Path, useFormContext } from 'react-hook-form';

import ControlledPhoneInput from '../../../../../Components/PhoneInput/ControlledPhoneInput';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { StyledTextField } from '../../../shared/StyledTextField';
import { ProfileFormData } from '../../domain/use-profile-form-validation';

export const ProfileFormDetails = () => {
  const { t } = useTranslate('settings.profile');
  const {
    formState: { errors },
    control,
  } = useFormContext<ProfileFormData>();

  return (
    <Stack gap={2}>
      <Controller
        name={'name'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('fullName')}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        )}
      />
      <Controller
        name={'email'}
        render={({ field }) => (
          <StyledTextField {...field} label={t('email')} disabled={true} />
        )}
      />
      <ControlledPhoneInput
        name={'phone' as Path<ProfileFormData>}
        control={control}
        sx={{ maxWidth: 320, minWidth: 320 }}
      />
    </Stack>
  );
};
