import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import * as api from '../../Api';

type TNotification = {
  key: string;
  items: {
    key: string;
    enabled: boolean;
  }[];
};

export const useNotifications = () => {
  const queryClient = useQueryClient();

  const NotificationsQueryKey = ['notifications'];

  const notifications = useQuery({
    queryKey: NotificationsQueryKey,

    queryFn: async () => {
      const templates = await api.getTemplates();
      const settings = await api.getSettings();
      const grouped = templates.reduce<{ [k: string]: string[] }>(
        (acc, { groupKey, templateId }) => {
          if (
            groupKey !== 'requests' &&
            templateId !== 'b-events-request-email'
          ) {
            return {
              ...acc,
              [groupKey]: [...(acc[groupKey] ?? []), templateId],
            };
          }
          return acc;
        },
        {}
      );
      return Object.entries(grouped).map(([gKey, keys]) => {
        return {
          key: gKey,
          items: keys.map((k) => ({
            key: k,
            enabled: settings[k] ?? true,
          })),
        };
      });
    },

    enabled: true,
  });

  const toggleNotification = useMutation({
    mutationFn: ({
      key,
      checked,
    }: {
      groupKey: string;
      key: string;
      checked: boolean;
    }) => {
      const settings = queryClient.getQueryData<TNotification[]>(
        NotificationsQueryKey
      );
      const updated = settings
        ?.reduce<{ key: string; enabled: boolean }[]>(
          (templates, { items }) => [...templates, ...items],
          []
        )
        ?.reduce<{ [k: string]: boolean }>((obj, el) => {
          return {
            ...obj,
            [el.key]: el.key === key ? checked : el.enabled,
          };
        }, {});
      return api.updateSettings(updated);
    },

    onMutate: async ({ groupKey, key, checked }) => {
      await queryClient.cancelQueries({
        queryKey: NotificationsQueryKey,
      });

      const previous = queryClient.getQueryData(NotificationsQueryKey);

      queryClient.setQueryData<TNotification[]>(
        NotificationsQueryKey,
        (prev) => {
          return (
            prev?.map((el) => {
              return el.key === groupKey
                ? {
                    ...el,
                    items: el.items.map((it) => {
                      return it.key === key
                        ? {
                            ...it,
                            enabled: checked,
                          }
                        : it;
                    }),
                  }
                : el;
            }) ?? []
          );
        }
      );

      return { previous };
    },

    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(NotificationsQueryKey, context.previous);
      }
    },

    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: NotificationsQueryKey,
      });
    },
  });

  return {
    notifications,
    toggleNotification,
  };
};
