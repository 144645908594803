import 'react-toastify/dist/ReactToastify.min.css';
import './i18n/config';

import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RouterProvider } from 'react-router';
import { ToastContainer } from 'react-toastify';

import { ampli } from './Ampli';
import { useReturnUrls } from './features/auth/use-return-urls';
import { useIntercom } from './Hooks/use-intercom';
import { useAmplitude } from './Hooks/useAmplitude';
import { useGlobalShortcuts } from './Hooks/useGlobalShortcuts';
import { useLaunchDarkly } from './Hooks/useLaunchDarkly';
import { useLocale } from './Hooks/useLocale';
import { useToastStyling } from './Hooks/useToastStyling';
import { useUsers } from './Hooks/useUsers';
import { createRouter } from './router';
import { environment } from './Utils/environment';

ampli.load({
  environment: environment,
});
ampli.client?.add(
  sessionReplayPlugin({
    sampleRate: 1,
  })
);

const router = createRouter();

export default function App() {
  useReturnUrls();
  useGlobalShortcuts();
  useToastStyling();

  // Plugins
  useIntercom();
  useAmplitude();
  useLaunchDarkly();

  // Prefetch users
  useUsers();

  const { _locale: locale } = useLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <ToastContainer />
      <RouterProvider
        router={router}
        future={{
          v7_startTransition: true,
        }}
      />
    </LocalizationProvider>
  );
}
