import { lightTheme, LinkButton, Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useHref,
  useLinkClickHandler,
  useNavigate,
  useRouteError,
} from 'react-router-dom';

import useResponsive from '../../../Hooks/layout/useResponsive';
import { DialogWrapper } from '../../connect/components/dialog-wrapper';

export const ErrorDialog = () => {
  const error = useRouteError();

  const { t } = useTranslation();

  const { isSm } = useResponsive();

  const closeHref = useHref(`..`);
  const closeLinkClick = useLinkClickHandler(closeHref);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(closeHref);
  };

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('giftCard.errorDialog.title')}
    >
      <Stack sx={{ gap: 4 }}>
        <Stack sx={{ gap: 2 }}>
          <Text color={lightTheme.palette.neutral.n400}>
            {t('giftCard.errorDialog.description')}
          </Text>
          <Text color={lightTheme.palette.error.e300}>
            {(error as Error)?.message}
          </Text>
        </Stack>
        <LinkButton
          href={closeHref}
          onClick={closeLinkClick}
          variant="secondary"
          size="large"
          fullWidth
          style={{
            flexShrink: 'unset',
          }}
        >
          {t('giftCard.errorDialog.closeLabel')}
        </LinkButton>
      </Stack>
    </DialogWrapper>
  );
};
