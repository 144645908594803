export const languageOptions = (key: string) => {
  if (key === 'se') {
    return languagesOptionValues['sv'];
  }
  return languagesOptionValues[key];
};

type LanguageOptionValue = {
  label: string;
  emoji: string;
  icon: string;
  locale: string;
};

export const languagesOptionValues: { [key: string]: LanguageOptionValue } = {
  da: {
    label: 'Dansk',
    emoji: '🇩🇰',
    icon: '/flags/svg/da.svg',
    locale: 'da',
  },
  en: {
    label: 'English',
    emoji: '🇬🇧',
    icon: '/flags/svg/en.svg',
    locale: 'enGB',
  },
  no: {
    label: 'Norsk',
    emoji: '🇳🇴',
    icon: '/flags/svg/no.svg',
    locale: 'nn',
  },
  sv: {
    label: 'Svenska',
    emoji: '🇸🇪',
    icon: '/flags/svg/sv.svg',
    locale: 'sv',
  },
  de: {
    label: 'Deutsch',
    emoji: '🇩🇪',
    icon: '/flags/svg/de.svg',
    locale: 'de',
  },
};

export const currencyOptions: { [key: string]: any } = {
  dkk: {
    emoji: '🇩🇰',
    icon: '/flags/svg/da.svg',
    label: 'DKK',
  },
  gbp: {
    emoji: '🇬🇧',
    icon: '/flags/svg/en.svg',
    label: 'GBP (£)',
  },
  usd: {
    emoji: '🇺🇸',
    icon: '/flags/svg/us.svg',
    label: 'USD ($)',
  },
  nok: {
    emoji: '🇳🇴',
    icon: '/flags/svg/no.svg',
    label: 'NOK',
  },
  sek: {
    emoji: '🇸🇪',
    icon: '/flags/svg/sv.svg',
    label: 'SEK',
  },
  eur: {
    emoji: '🇪🇺',
    icon: '/flags/svg/eu.svg',
    label: 'EUR (€)',
  },
};
