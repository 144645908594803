import { Button } from '@holdbar-com/pixel';
import { CheckRounded } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  InputAdornment,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import * as api from '../../../../Api';
import { useTranslate } from '../../../../Hooks/useTranslate';

export const DomainPopover = ({
  anchorEl,
  domain,
  onClose,
  onSubmit,
}: {
  anchorEl: HTMLElement | null;
  domain?: string;
  onClose: () => void;
  onSubmit: (domain: string) => void;
}) => {
  const { t } = useTranslate('utils.generic');

  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const [hasChanged, setHasChanged] = useState(false);

  const [input, setInput] = useState<string | undefined>(domain);
  const [loading, setLoading] = useState(false);

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (input) {
      clearTimeout(timeout.current);
      setLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          setLoading(true);
          const { available } = await api.domainAvailable(input);
          setValid(available);
        } catch (err) {
          setValid(false);
        } finally {
          setLoading(false);
          if (input !== domain) {
            setHasChanged(true);
          }
        }
      }, 500);
    }
    setValid(false);
  }, [input]);

  const handleSave = () => {
    if (input) {
      onSubmit(input);
    }
  };

  return (
    <Popover
      open={true}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box p={2} maxWidth={320}>
        <TextField
          fullWidth
          onChange={(e) => setInput(e.target.value)}
          label={'Indtast domæne'}
          defaultValue={domain}
          InputProps={{
            endAdornment: (
              <InputAdornment sx={{ opacity: 0.5 }} position="end">
                .understory.io
              </InputAdornment>
            ),
          }}
          sx={{
            ...(valid && {
              '& fieldset': {
                borderWidth: 2,
                borderColor: 'success.main',
              },
            }),
          }}
        />
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          mt={1}
        >
          <Typography
            variant={'body2'}
            color={valid ? 'success' : 'error'}
            maxWidth={70}
          >
            {input &&
              hasChanged &&
              !loading &&
              (valid
                ? t('domainAvailable', 'utils.generic')
                : t('domainUnavailable', 'utils.errors'))}
          </Typography>
          {input && (hasChanged || loading) && (
            <Button
              onClick={handleSave}
              disabled={!valid}
              variant="primary"
              size={'medium'}
              leftIcon={
                loading ? (
                  <CircularProgress size={'1em'} />
                ) : valid ? (
                  <CheckRounded fontSize="small" />
                ) : null
              }
            >
              {loading
                ? t('checkingDomainAvailability')
                : t('saveAndClose', 'buttons')}
            </Button>
          )}
        </Stack>
      </Box>
    </Popover>
  );
};
