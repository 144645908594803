import { lightTheme, Text } from '@holdbar-com/pixel';
import { Link, Stack } from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import { useTranslate } from '../../../Hooks/useTranslate';
import { CloseIcon } from '../../../Icons';
import { delayedFadeInGrowAnimation } from '../animations';

type DashboardRebrandBannerProps = {
  showCloseButton?: boolean;
};

export const DashboardRebrandBanner = ({
  showCloseButton = true,
}: DashboardRebrandBannerProps) => {
  const { t, i18n } = useTranslate('dashboard.rebrandBanner');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const bannerState = localStorage.getItem('dashboardRebrandBannerVisible');
    setIsVisible(bannerState !== 'false');
  }, []);

  const hideBanner = () => {
    setIsVisible(false);
    localStorage.setItem('dashboardRebrandBannerVisible', 'false');
  };

  if (!isVisible) {
    return null;
  }

  return (
    <motion.div {...delayedFadeInGrowAnimation}>
      <Stack
        sx={{
          paddingY: 4,
          paddingX: { xs: 3, md: 5 },
          backgroundColor: '#cfe8ff',
          borderRadius: 2,
          boxShadow: lightTheme.shadows.large,
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack gap={0.5}>
            <Text
              variant="medium"
              style={{
                fontFamily: 'National2Condensed',
                fontSize: '1.875rem',
                lineHeight: '100%',
              }}
            >
              {t('title').toUpperCase()}
            </Text>
            <Link
              href={getBannerURL(i18n.language)}
              target="_blank"
              underline="always"
            >
              {t('readmore', 'utils.generic').toUpperCase()}
            </Link>
          </Stack>
          {showCloseButton && (
            <CloseIcon
              fontSize="large"
              style={{ cursor: 'pointer', color: '#000' }}
              onClick={hideBanner}
            />
          )}
        </Stack>
      </Stack>
    </motion.div>
  );
};

const getBannerURL = (language: string) => {
  const baseURL = 'https://understory.io/';
  return baseURL + getLocalizedPath(language);
};

const getLocalizedPath = (language: string) => {
  switch (language.split('-')[0]) {
    case 'da':
      return `da/holdbar-bliver-til-understory`;
    case 'sv':
      return `sv/holdbar-blir-understory`;
    default: // English (and rest) path
      return 'holdbar-becomes-understory';
  }
};
