import { CircularProgress, Stack } from '@mui/material';
import { useState } from 'react';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { TimePeriod } from '../../../types';
import { useDashboardPageContext } from '../../../use-dashboard-page-context';
import { DashboardGraphsFilterPeriodButtons } from './dashboard-graphs-filter-period-buttons';
import { DashboardGraphsFilterPeriodCustom } from './dashboard-graphs-filter-period-custom';
import { DashboardGraphsFilterPeriodInterval } from './dashboard-graphs-filter-period-interval';
import { DashboardGraphsFilterPeriodContextProvider } from './use-dashboard-graphs-filter-period-context';

export const DashboardGraphsFilterPeriod = () => {
  const { isSm } = useResponsive();

  const { isLoadingMetrics } = useDashboardPageContext();

  const [interval, setInterval] = useState<TimePeriod | 'period'>('thisWeek');
  const isCustom = interval === 'custom';

  return (
    <DashboardGraphsFilterPeriodContextProvider
      interval={interval}
      setInterval={setInterval}
    >
      <Stack
        gap={isCustom ? 3 : 2}
        direction={'row'}
        alignItems={'center'}
        flex={1}
      >
        <DashboardGraphsFilterPeriodInterval />
        {!isSm &&
          (isCustom ? (
            <DashboardGraphsFilterPeriodCustom />
          ) : (
            <DashboardGraphsFilterPeriodButtons />
          ))}
        {isLoadingMetrics && !isSm && <CircularProgress size={'1.5em'} />}
      </Stack>
    </DashboardGraphsFilterPeriodContextProvider>
  );
};
