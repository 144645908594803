import { ampli } from '../../Ampli';

export const trackStorefrontLanguagesViewed = () =>
  ampli.storefrontLanguagesViewed();

export const trackStorefrontLanguagesAddLanguage = (language: string) =>
  ampli.storefrontLanguagesAddLanguage({ language });

export const trackStorefrontLanguagesRemoveLanguage = (language: string) =>
  ampli.storefrontLanguagesRemoveLanguage({ language });

export const trackStorefrontLanguagesSetPrimaryLanguage = (
  language: string
) => {
  ampli.storefrontLanguagesSetPrimaryLanguage({ language });
};
