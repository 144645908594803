import { differenceInCalendarDays, parseISO } from 'date-fns';

import { ampli } from '../../../Ampli';
import { TEvent } from '../../../Utils/eventHelpers';

export const trackExperienceFlowStockPhotoSelected = (
  image_url: string,
  number_of_stock_photos: number
) => {
  ampli.experienceFlowStockPhotoSelected({ image_url, number_of_stock_photos });
};

export const trackExperienceEditFlowStarted = (experience_id: string) => {
  ampli.experienceEditFlowStarted({ experience_id });
};

export const trackExperienceEditFlowSaved = (
  experience_id: string,
  fields: { [k: string]: boolean } | undefined,
  first_upcoming_event:
    | {
        totalCount: number;
        events: TEvent[];
      }
    | undefined
) => {
  const days_until_first_event_starts = first_upcoming_event?.events?.length
    ? differenceInCalendarDays(
        parseISO(first_upcoming_event.events[0].startDateTime),
        new Date()
      )
    : undefined;

  ampli.experienceEditFlowSaved({
    experience_id,
    edit_types: Object.keys(fields ?? {}),
    days_until_first_event_starts,
  });
};

export const trackExperienceCreated = (userId?: string) => {
  ampli.experienceCreated();
  ampli.identify(userId, {
    'Experience Created': true,
  });
};
