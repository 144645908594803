import { Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { useMemo } from 'react';

import { PaymentBadge } from '../../../../Components/badge/badge-config';
import { StatusBadge } from '../../../../Components/badge/status-badge';
import { TBooking } from '../../../../Hooks/useBookings';
import { Variant } from '../../../../Hooks/useExperience';
import { useReceipt } from '../../../../Hooks/useReceipt';
import { renderGuestTypes } from '../../../../Utils/eventHelpers';

export const EventDetailsListColumns = [
  {
    key: 'name',
    props: {},
    value: (el: TBooking) => el.customer?.name,
  },
  {
    key: 'email',
    props: {},
    value: (el: TBooking) => el.customer?.email,
  },
  // {
  //   key: 'phone',
  //   props: {},
  //   value: (el: TBooking) => el.customer?.phone,
  // },
  {
    key: 'type',
    props: {},
    value: (booking: TBooking, language?: string, variants?: Variant[]) => (
      <Stack key={`participants-type-${booking.id}`}>
        {renderGuestTypes(booking, 'variant', language, variants).map(
          (guestType, ix) => (
            <Text
              key={`participants-type-${booking.id}-${ix}`}
              fontSize="small"
            >
              {guestType}
            </Text>
          )
        )}
      </Stack>
    ),
  },
  {
    key: 'count',
    props: {},
    value: (el: TBooking) =>
      parseInt(
        String(
          Object.entries(el.items ?? {}).reduce(
            (acc, [id, count]) =>
              acc + (id.startsWith('variant') ? parseInt(`${count}`) : 0),
            0
          )
        )
      ),
  },
  /*{
    key: 'created',
    props: {},
    value: (el: TBooking) => renderDate(el.created, 'dd.MM.yyyy'),
  },*/
  {
    key: 'paymentStatus',
    props: {},
    value: (el: TBooking) =>
      el.receiptId && <PaymentStatus receiptId={el.receiptId} />,
  },
  {
    key: 'status',
    props: {},
    value: (el: TBooking) => <BookingStatusBadge status={el.status} />,
  },
];

type PaymentState = {
  isUnpaid: boolean;
  isFullyPaid: boolean;
  isPartiallyPaid: boolean;
  isFree: boolean;

  isPartiallyRefunded: boolean;
  isFullyRefunded: boolean;

  isIntegration: boolean;
  isNone: boolean;
};

export const PaymentStatus = ({ receiptId }: { receiptId: string }) => {
  const {
    receipt: { data: receipt },
  } = useReceipt(receiptId);

  const paymentState = useMemo(() => {
    const state: PaymentState = {
      isUnpaid: false,
      isFullyPaid: false,
      isPartiallyPaid: false,
      isFree: false,
      isPartiallyRefunded: false,
      isFullyRefunded: false,
      isIntegration: false,
      isNone: false,
    };

    if (!receipt) {
      state.isNone = true;
      return state;
    }

    const total = receipt.financials.grandTotalCents;

    if (total === 0) {
      state.isFree = total === 0;
      return state;
    }

    if (receipt.transactions.length === 0) {
      state.isUnpaid = true;
      return state;
    }

    if (
      receipt.transactions.every(
        (transaction) => transaction.transactionMethod === 'integration'
      )
    ) {
      state.isIntegration = true;
      return state;
    }

    const paidTotal = receipt.transactions.reduce(
      (acc, transaction) => (acc += transaction.totalAmountChargedCents),
      0
    );
    const refundedTotal = receipt.refunds.reduce(
      (acc, refund) => (acc += refund.amountRefundedCents),
      0
    );

    state.isFullyPaid = total <= paidTotal - refundedTotal;
    state.isPartiallyPaid = paidTotal < total;

    state.isPartiallyRefunded = refundedTotal > 0 && refundedTotal < total;
    state.isFullyRefunded = refundedTotal === total;

    return state;
  }, [receipt]);

  if (paymentState === null) {
    return null;
  }

  return (
    <StatusBadge
      type="payment"
      size="small"
      state={getPaymentState(paymentState)}
    />
  );
};

function getPaymentState(paymentState: PaymentState): PaymentBadge['state'] {
  if (paymentState.isNone) {
    return 'none';
  }
  if (paymentState.isIntegration) {
    return 'external';
  }
  if (paymentState.isFullyRefunded) {
    return 'refunded';
  }
  if (paymentState.isFree) {
    return 'free';
  }
  if (paymentState.isFullyPaid) {
    return 'paid';
  }
  if (paymentState.isPartiallyRefunded) {
    return 'refunded-partially';
  }
  if (paymentState.isPartiallyPaid) {
    return 'paid-partially';
  }
  return 'unpaid';
}

interface BookingStatusBadgeProps extends Pick<TBooking, 'status'> {}

export function BookingStatusBadge({ status }: BookingStatusBadgeProps) {
  return (
    <StatusBadge
      type="booking"
      size="small"
      state={status === 'unpaid' ? 'active' : status}
    />
  );
}

export const EventDetailsListColumnWidths = {
  name: '10%',
  email: '15%',
  // phone: '9%',
  type: '25%',
  count: '10%',
  created: '10%',
  status: '10%',
  paymentStatus: '10%',
} as const;
