import { Divider } from '@mui/material';

import { Page } from '../../Components/Page/Page';
import useResponsive from '../../Hooks/layout/useResponsive';
import { DashboardGraphs } from './dashboard-graphs/dashboard-graphs';
import { DashboardHeader } from './dashboard-header/dashboard-header';
import { DashboardPageContextProvider } from './use-dashboard-page-context';

export const DashboardPage = () => {
  const { isSm } = useResponsive();

  return (
    <DashboardPageContextProvider>
      <Page gap={4} pb={4} maxWidth={1400} divider={!isSm && <Divider />}>
        <DashboardHeader />
        <DashboardGraphs />
      </Page>
    </DashboardPageContextProvider>
  );
};
