import { Text } from '@holdbar-com/pixel';
import { Box, BoxProps } from '@mui/material';
import { forwardRef } from 'react';

import { IExperience } from '../../../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../../../Hooks/useLocalizedStringFormatter';
import { StyledLink } from '../../shared';

type Props = {
  experience?: IExperience;
} & BoxProps;

export const GridExperienceCell = forwardRef<typeof Box, Props>(
  ({ experience, children, ...rest }, ref) => {
    const localized = useLocalizedStringFormatter();

    return (
      <Box
        padding={2}
        overflow="hidden"
        textOverflow="ellipsis"
        {...rest}
        ref={ref}
      >
        {experience ? (
          <StyledLink to={experience.id ? `/experience/${experience.id}` : '#'}>
            <Text variant="medium" fontSize="small">
              {localized(experience.headline)}
            </Text>
          </StyledLink>
        ) : (
          children
        )}
      </Box>
    );
  }
);

GridExperienceCell.displayName = 'GridExperienceCell';
