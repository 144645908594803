import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

import { CloseButton } from '../Components/CloseButton/CloseButton';
import useResponsive from '../Hooks/layout/useResponsive';

interface IOptionsDialogProps {
  onClose?: () => void;
  buttons?: typeof defaultButtons;
  title: string;
  description?: string;
  headline: string | ReactNode;
}

const defaultButtons: { key: string; label: string; props: ButtonProps }[] = [
  {
    key: 'goToRule',
    label: 'For alle begivenheder i serien',
    props: {
      variant: 'outlined',
      color: 'secondary',
    },
  },
  {
    key: 'editThis',
    label: 'Kun for denne begivenhed',
    props: {
      variant: 'contained',
    },
  },
];

export const OptionsDialog = NiceModal.create(
  ({
    onClose,
    title = '',
    description = '',
    headline = '',
    buttons = defaultButtons,
  }: IOptionsDialogProps) => {
    const modal = useModal();
    const { isMd } = useResponsive();
    const handleClose = () => {
      modal.reject('OptionsDialog');
      modal.hide();
      onClose?.();
    };

    const handleClick = (key: string) => () => {
      modal.resolve(key);
      modal.hide();
    };

    return (
      <Dialog open={modal.visible} onClose={handleClose}>
        <CloseButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: 24, right: 24 }}
        />
        <Box p={8} textAlign={'center'}>
          <Typography display={'inline-flex'} alignItems={'center'} mb={2}>
            {headline}
          </Typography>
          <Typography variant={'h4'}>{title}</Typography>
          <Typography>{description}</Typography>
          <Stack
            direction={isMd ? 'column' : 'row'}
            spacing={2}
            justifyContent={'center'}
            mt={4}
          >
            {buttons.map((el) => (
              <Button
                key={el.key}
                onClick={handleClick(el.key)}
                size="medium"
                sx={{ whiteSpace: 'nowrap', borderRadius: 1 }}
                {...el.props}
              >
                {el.label}
              </Button>
            ))}
          </Stack>
        </Box>
      </Dialog>
    );
  }
);
