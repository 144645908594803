import { lightTheme, Text } from '@holdbar-com/pixel';
import { Box, Stack } from '@mui/material';
import { isSameDay } from 'date-fns';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { TEventWithTitle } from '../../../../Utils/eventHelpers';
import { useEventPopover } from '../event-popover/event-popover-context';
import { useCalendarLink } from '../hooks/useCalendarLink';

type Props = {
  events: Array<TEventWithTitle>;
  date: Date;
};

export const GroupedEventList: FC<Props> = ({ events, date }) => {
  const getCalendarLink = useCalendarLink();

  const { handleSelectEvent } = useEventPopover();

  const eventsForDate = useMemo(() => {
    return events.filter((event) =>
      isSameDay(new Date(event.startDateTime), date)
    );
  }, [date, events]);

  return (
    <Link key={date.toISOString()} to={getCalendarLink(date, 'day')}>
      <Stack p="1px" gap="1px">
        {eventsForDate.map((event) => {
          const fullyBooked = event.slots.total <= (event.slots.booked ?? 0);

          return (
            <Box
              key={event.id}
              style={{
                aspectRatio: '1 / 1',
                background: fullyBooked
                  ? lightTheme.palette.error.e200
                  : lightTheme.palette.success.s200,
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={(e) => {
                e.preventDefault();
                handleSelectEvent(event, e);
              }}
            >
              <Text fontSize="xsmall" variant="medium" color="white">
                {event.slots.booked}
              </Text>
            </Box>
          );
        })}
      </Stack>
    </Link>
  );
};
