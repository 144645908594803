import NiceModal from '@ebay/nice-modal-react';
import { useMemo } from 'react';

import { useGetBookingsForEvent } from '../../../../Hooks/data/useBookings';
import { useExperience } from '../../../../Hooks/useExperience';
import { useProfile } from '../../../../Hooks/useProfile';
import { WriteParticipantsDialog } from '../../../../Modals/WriteParticipantsDialog';
import { EventSourceType } from '../../../../tracking/events/eventProperties';
import { trackEventMessageAllStarted } from '../../../../tracking/events/trackEventMessageAllStarted';
import { TEventWithTitle } from '../../../../Utils/eventHelpers';

export const useMessageParticipantsAction = (
  source: EventSourceType,
  event?: TEventWithTitle
) => {
  const { me } = useProfile();

  const {
    bookingsForEvent: { data: bookings },
  } = useGetBookingsForEvent(event?.id);

  const {
    experience: { data: experience },
  } = useExperience(event?.experienceId);

  const showMessageParticipantsDialog = () => {
    trackEventMessageAllStarted(event!, experience, source);

    NiceModal.show(WriteParticipantsDialog, {
      eventId: event?.id,
      headline: event?.title,
      startDateTime: event?.startDateTime,
      participantCount: event?.slots.booked,
      id: me.data.id,
    });
  };

  const isDisabled = useMemo(() => {
    if (!event || !bookings) return true;

    if (
      event?.status.startsWith('cancel') &&
      bookings.some((x) => x.cancellationReason === 'event-cancellation')
    ) {
      return false;
    }

    if (event.status.startsWith('cancelled')) {
      return true;
    }

    const activeBookings = bookings.some(
      (x) => x.status !== 'cancelled' && x.status !== 'moved'
    );

    return !activeBookings;
  }, [bookings, event]);

  return { showMessageParticipantsDialog, isDisabled };
};
