import { Receipt } from '@holdbar-com/utils-types';
import { UseQueryResult } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { createContext, useContext } from 'react';

import { useGetEvent } from '../../../../Hooks/events/useGetEvent';
import { TBooking } from '../../../../Hooks/useBookings';
import { useReceipt } from '../../../../Hooks/useReceipt';
import { isSameDayAsEvent, TEvent } from '../../../../Utils/eventHelpers';

type BookingDetailsState = {
  booking: TBooking | undefined;
  eventMovedFrom?: TEvent;
  eventMovedTo?: TEvent;
  receipt: UseQueryResult<Receipt, unknown>;
  checkInAvailable: boolean;
  cancelAvailable: boolean;
  moveAvailable: boolean;
  event?: TEvent;
};

export const BookingDetailsContext = createContext<BookingDetailsState | null>(
  null
);

export const useBookingDetailsContext = () => {
  const context = useContext(BookingDetailsContext);
  if (!context) {
    throw new Error(
      'useBookingDetailsContext must be used within a BookingDetailsContextProvider'
    );
  }
  return context;
};

interface BookingDetailsContextProviderProps {
  children: React.ReactNode;
  booking?: TBooking;
}

export const BookingDetailsContextProvider = ({
  children,
  booking,
}: BookingDetailsContextProviderProps) => {
  const { featureTicketing } = useFlags();

  const { receipt } = useReceipt(booking?.receiptId ?? '');

  const {
    event: { data: event },
  } = useGetEvent(booking?.eventId ?? '');

  const {
    event: { data: eventMovedFrom },
  } = useGetEvent(booking?.movedFromEvent ?? '');
  const {
    event: { data: eventMovedTo },
  } = useGetEvent(booking?.movedToEvent ?? '');

  const checkInAvailable =
    featureTicketing &&
    booking !== undefined &&
    isSameDayAsEvent(booking.startDateTime, booking.endDateTime) &&
    !['cancelled', 'moved', 'checked-in'].includes(booking.status);

  return (
    <BookingDetailsContext.Provider
      value={{
        booking,
        eventMovedFrom,
        eventMovedTo,
        receipt,
        checkInAvailable,
        cancelAvailable: booking?.status !== 'checked-in',
        moveAvailable: booking?.status !== 'checked-in',
        event,
      }}
    >
      {children}
    </BookingDetailsContext.Provider>
  );
};
