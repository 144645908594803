import { useMemo } from 'react';

import { useGetEvent } from '../../../../../../../../Hooks/events/useGetEvent';
import { useProfile } from '../../../../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../../../../domain/use_booking_details_context';
import { LinkItem } from './shared';

/**
 * show payment link if allowed for booking
 * @returns
 */
export const BookingDetailsSummaryPaymentOpenLink = () => {
  const { t } = useTranslate('dialogs.booking');
  const { companyProfileUrl } = useProfile();
  const {
    booking,
    receipt: { data: order },
  } = useBookingDetailsContext();
  const {
    event: { data: event },
  } = useGetEvent(booking?.eventId ?? '');

  const showPaymentLink = useMemo(() => {
    if (!event || !booking?.receiptId || !order) {
      return false;
    }

    if (
      booking.status === 'cancelled' ||
      booking.status === 'moved' ||
      !order
    ) {
      return false;
    }

    // Already paid in full
    if (order.amountDueCents <= 0) {
      return false;
    }

    // If it's a booking through an integration, we don't want to show the payment link
    if (booking.channel === 'integration') {
      return false;
    }

    return true;
  }, [order, booking, event]);

  if (!showPaymentLink) {
    return null;
  }

  return (
    <LinkItem
      link={`${companyProfileUrl()}/payment/${booking?.receiptId}`}
      linkText={t('paymentLink')}
      target={'_blank'}
    />
  );
};
