import { useEffect } from 'react';

export const usePreventWindowUnload = (shouldConfirm: boolean) => {
  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (shouldConfirm) {
        e.preventDefault();
        e.returnValue = '';
        return;
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [shouldConfirm]);
};
