import { Stack } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useOnboardingWidgetContext } from '../use-onboarding-widget-context';

type OnboardingWidgetItemAnimationProps = {
  children: React.ReactNode;
};

export const OnboardingWidgetItemAnimation = ({
  children,
}: OnboardingWidgetItemAnimationProps) => {
  const { isSm } = useResponsive();
  const { step, direction } = useOnboardingWidgetContext();

  return (
    <AnimatePresence custom={direction}>
      <motion.div
        style={{
          position: isSm ? 'relative' : 'absolute',
          maxWidth: '100%',
          height: '100%',
          display: 'flex',
        }}
        key={step}
        custom={direction}
        variants={isSm ? {} : animationVariants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: 'spring', stiffness: 400, damping: 40 },
          opacity: { duration: 0.3 },
        }}
      >
        <Stack
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          width={'100%'}
          gap={isSm ? 4 : 0}
          sx={{
            paddingTop: { xs: 2, md: 4 },
            paddingBottom: { xs: 2, md: 3 },
            paddingX: { xs: 3, md: 5 },
          }}
        >
          {children}
        </Stack>
      </motion.div>
    </AnimatePresence>
  );
};

const animationVariants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 400 : -400,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: () => {
    return {
      zIndex: 0,
      opacity: 0,
    };
  },
};
