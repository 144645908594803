import { useEconomicAccounts } from '../data/use-economic-accounts';
import { EconomicDetailsTableRow } from './economic-details-table-row';

export const EconomicDetailsTableRowAccount = ({
  title,
  accountNumber,
}: {
  title: string;
  accountNumber: number | undefined;
}) => {
  const { data: accounts } = useEconomicAccounts();

  const value =
    accountNumber === undefined
      ? '-'
      : accounts &&
        `${accountNumber} - ${
          accounts.find((account) => account.accountNumber === accountNumber)
            ?.name ??
          'Unknown' /* It should maybe be treated as an error if the account isn't found */
        }`;

  return <EconomicDetailsTableRow title={title} value={value} />;
};
