import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { updateExperience } from '../../../../Api';
import { getLocalized } from '../../../../Hooks/useBookings';
import { IExperience } from '../../../../Hooks/useExperience';
import { trackStorefrontTagManagementTagAddToCompleted } from '../../../../tracking/storefront/tags-events';
import { TagDialogWrapper } from './tag-dialog-wrapper';

type AddTagToExperiencesDialogProps = {
  tagId: string;
  tagName: string;
  experiences: IExperience[];
};

export const AddTagToExperiencesDialog = NiceModal.create(
  ({ tagId, tagName, experiences }: AddTagToExperiencesDialogProps) => {
    const { t } = useTranslation();

    const initialExperiences = experiences.reduce((selected, experience) => {
      if (experience.tagIds.includes(tagId)) {
        return [...selected, experience.id];
      }
      return selected;
    }, [] as string[]);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedExperiences, setSelectedExperiences] =
      useState(initialExperiences);

    const queryClient = useQueryClient();

    const handleSubmit = async (experienceIds: string[]) => {
      setIsSubmitting(true);
      await Promise.all(
        experiences
          // We only want to update the experience if:
          // 1. The experience already has the tag, but it should be removed
          // 2. The experience does not have the tag, but it should be added
          .filter(
            (experience) =>
              (experience.tagIds.includes(tagId) &&
                !experienceIds.includes(experience.id)) ||
              (experienceIds.includes(experience.id) &&
                !experience.tagIds.includes(tagId))
          )
          .map((experience) => {
            const newTagIds = experienceIds.includes(experience.id)
              ? Array.from(new Set([...experience.tagIds, tagId]))
              : [
                  ...experience.tagIds.filter(
                    (existingTagId) => existingTagId !== tagId
                  ),
                ];

            return updateExperience(experience.id, {
              ...experience,
              tagIds: newTagIds,
            });
          })
      )
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['experiences'],
          });
          queryClient.invalidateQueries({
            queryKey: ['events', 'bookings'],
          });
          handleClose();
          trackStorefrontTagManagementTagAddToCompleted(
            selectedExperiences.length
          );
        })
        .catch((error) => {
          toast.error(t('utils.errors.generic'));
          console.error(error);
        })
        .finally(() => setIsSubmitting(false));
    };

    const modal = useModal();

    const handleClose = () => {
      modal.resolve();
      modal.remove();
    };

    return (
      <TagDialogWrapper
        open={modal.visible}
        handleClose={handleClose}
        title={t('tags.dialog.addToExperiences.title', { tagName })}
        description={t('tags.dialog.addToExperiences.description')}
      >
        <ExperienceSelect
          experiences={experiences}
          selected={selectedExperiences}
          setSelected={setSelectedExperiences}
        />
        <Stack
          sx={{
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            gap: { xs: 1, sm: 2 },
            '& > button': {
              flexGrow: 1,
            },
          }}
        >
          <Button
            type="button"
            variant="secondary"
            size="large"
            onClick={handleClose}
          >
            {t('tags.dialog.action.cancel')}
          </Button>
          <Button
            type="button"
            variant="primary"
            size="large"
            onClick={() => handleSubmit(selectedExperiences)}
            disabled={isSubmitting || !experiences.length}
          >
            {t('tags.dialog.action.save')}
          </Button>
        </Stack>
      </TagDialogWrapper>
    );
  }
);

const ExperienceSelect = ({
  experiences,
  selected,
  setSelected,
}: {
  experiences: IExperience[];
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
}) => {
  const { t, i18n } = useTranslation();

  if (!experiences.length) {
    return (
      <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
        {t('storefront.tags.experienceListEmpty')}
      </Text>
    );
  }

  return (
    <Stack>
      {experiences.map((experience) => {
        const isChecked = selected.includes(experience.id);
        const onChange = isChecked
          ? () =>
              setSelected((prev) => prev.filter((id) => id !== experience.id))
          : () => setSelected((prev) => [...prev, experience.id]);

        return (
          <FormControlLabel
            key={experience.id}
            label={getLocalized(experience.headline, i18n.language)}
            control={
              <Checkbox
                value={isChecked}
                onChange={onChange}
                defaultChecked={isChecked}
              />
            }
          />
        );
      })}
    </Stack>
  );
};
