import NiceModal from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { ArrowBackRounded } from '@mui/icons-material';
import { Skeleton, Stack, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { AppShell } from '../../Components/AppShell/AppShell';
import { StatusBadge } from '../../Components/badge/status-badge';
import { PageBreadcrumb } from '../../Components/Page/page_breadcrumb';
import { PageBreadcrumbItem } from '../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/page-breadcrumb-item';
import { PageBreadcrumbBreadcrumbs } from '../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/PageBreadcrumbBreadcrumbs';
import { BookingDetailsActionsMobile } from '../../features/bookings/booking_details/ui/booking_details_actions/ui/BookingDetailsActionsMobile';
import { QuickpayIntegrationLoader } from '../../features/quickpay/quickpay-integration-loader';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useTranslate } from '../../Hooks/useTranslate';
import { useQuickpayIntegration } from '../../Sections/SettingsSections/Integrations/domain/integrations/use-quickpay-integration';
import { ConfirmDialog } from '../../Sections/SettingsSections/Integrations/ui/shared/confirm-dialog';

export const QuickpayDetailsPage = () => {
  const { t } = useTranslate('utils.generic');
  const navigate = useNavigate();
  const { isMd } = useResponsive();

  const handleBack = () => {
    navigate('/settings/integrations');
  };

  const integration = useQuickpayIntegration();
  const quickpay = integration?.onboarding;

  return (
    <AppShell hideContainerPadding>
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbItem title="Settings" href="/settings" />
        <PageBreadcrumbItem
          title="Integrations"
          href="/settings/integrations"
        />
        <PageBreadcrumbItem
          title={t('payments.quickpay.title', 'settings.integrations')}
          href="/settings/integrations/quickpay"
        />
      </PageBreadcrumbBreadcrumbs>
      <PageBreadcrumb>
        <Stack gap={4} width={'100%'}>
          <Stack gap={{ xs: 2, lg: 0 }} pb={2}>
            <Stack direction="row" justifyContent="space-between">
              <Stack gap={isMd ? 1 : 2} direction={isMd ? 'column' : 'row'}>
                <StyledBackArrowRounded onClick={handleBack} />
                <Stack direction="row" gap={2} alignItems={'center'}>
                  <Text fontSize="large" variant="medium">
                    {t('payments.quickpay.title', 'settings.integrations')}
                  </Text>
                  {integration ? (
                    <StatusBadge
                      type="integration"
                      state={
                        quickpay?.metadata?.status === 'connected'
                          ? 'integrated'
                          : 'pending'
                      }
                    />
                  ) : (
                    <Skeleton variant="rounded" height={32} width={110} />
                  )}
                </Stack>
              </Stack>
              <QuickpayDetailsPageHeaderActions />
            </Stack>
          </Stack>
          <QuickpayIntegrationLoader />
        </Stack>
      </PageBreadcrumb>
    </AppShell>
  );
};

const QuickpayDetailsPageHeaderActions = () => {
  const { t } = useTranslate('utils.generic');
  const { isSm } = useResponsive();

  const integration = useQuickpayIntegration();

  const handleRemoveIntegration = () => {
    NiceModal.show(ConfirmDialog, {
      title: t('title', 'settings.integrations.removeIntegrationDialog'),
      description: t(
        'description',
        'settings.integrations.removeIntegrationDialog'
      ),
      cta: t('action', 'settings.integrations.removeIntegrationDialog'),
      onConfirm: integration?.onUninstall,
    });
  };

  if (isSm && integration?.onInstall) {
    return (
      <BookingDetailsActionsMobile
        options={[
          {
            label: t('action', 'settings.integrations.removeIntegrationDialog'),
            onClick: handleRemoveIntegration,
            textColor: lightTheme.palette.error.e300,
          },
        ]}
      />
    );
  }

  return (
    <Stack direction={'row'} gap={1}>
      {integration?.onUninstall && (
        <Button
          variant="secondary"
          size="medium"
          type={'button'}
          style={{ color: lightTheme.palette.error.e400 }}
          onClick={handleRemoveIntegration}
        >
          {t('action', 'settings.integrations.removeIntegrationDialog')}
        </Button>
      )}
    </Stack>
  );
};

const StyledBackArrowRounded = styled(ArrowBackRounded)({
  height: '33px',
  width: '33px',
  padding: '4px',
  cursor: 'pointer',
  borderRadius: '50%',
  marginTop: '3.5px',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n200,
  },
});
