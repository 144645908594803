import { GiftCardStatus } from '@holdbar-com/utils-types';

import { ampli } from '../../Ampli';

export async function giftCardOverviewViewed({
  activeGiftCards,
  totalGiftCards,
}: {
  activeGiftCards: number;
  totalGiftCards: number;
}) {
  return ampli.giftCardOverviewViewed({
    num_active_gf: activeGiftCards,
    num_total_gf: totalGiftCards,
  });
}
export async function giftCardSearchClicked() {
  return ampli.giftCardSearchClicked();
}
export async function giftCardSearchInputted() {
  return ampli.giftCardSearchInputted();
}
export async function giftCardDetailsOpened({
  status,
}: {
  status: GiftCardStatus;
}) {
  return ampli.giftCardDetailsOpened({
    status: status,
  });
}
export async function giftCardModifyFlowStarted() {
  return ampli.giftCardModifyFlowStarted();
}
export async function giftCardModifyFlowCompleted({
  hasNote,
  modifyType,
  valueAfterModification,
}: {
  hasNote: boolean;
  modifyType: 'reduce' | 'increase';
  valueAfterModification: number;
}) {
  return ampli.giftCardModifyFlowCompleted({
    has_note: hasNote,
    modify_type: modifyType,
    value_after_modification: valueAfterModification,
  });
}
export async function giftCardDisableFlowStarted() {
  return ampli.giftCardDisableFlowStarted();
}
export async function giftCardDisableFlowCompleted({
  isRefunded,
}: {
  isRefunded: boolean;
}) {
  return ampli.giftCardDisableFlowCompleted({
    is_refunded: isRefunded,
  });
}
export async function giftCardRefundFlowStarted() {
  return ampli.giftCardRefundFlowStarted();
}
export async function giftCardRefundFlowCompleted() {
  return ampli.giftCardRefundFlowCompleted();
}
