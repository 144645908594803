import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';

import { useStripeIntegration } from '../../../../Sections/SettingsSections/Integrations/domain/integrations/use-stripe-integration';
import { useOnboardingWidgetContext } from './use-onboarding-widget-context';

export const useOnboardingWidgetStepper = () => {
  const flags = useFlags();
  const navigate = useNavigate();

  const { trackEvent, startTour } = useIntercom();
  const stripeIntegration = useStripeIntegration();

  const { items } = useOnboardingWidgetContext();

  const getProgress = (steps?: { progress: number }[]) => {
    if (steps) {
      const summed = steps.reduce((total, { progress }) => progress + total, 0);
      return Math.round(summed / steps.length);
    }
    return 0;
  };

  const getLastStep = useCallback(
    (index: number) => {
      return [...(items[index]?.steps ?? [])].pop();
    },
    [items]
  );

  const getNextStep = useCallback(
    (index: number) => {
      return items[index]?.steps?.filter((el) => el.progress < 100)[0];
    },
    [items]
  );

  const handleClick = useCallback(
    (index: number) => () => {
      const { onClick } = getNextStep(index) ?? getLastStep(index) ?? {};

      const stepKey = items[index]?.key;

      if (!flags.featureSetupGuideV2) {
        const navigation = Object.entries(onBoardingStepNavigationMap).find(
          ([k]) => k === stepKey
        )?.[1];

        navigate(navigation ?? '/');
        return;
      }

      if (stepKey === 'payment') {
        stripeIntegration?.onInstall?.(stripeIntegration.status)();
      }

      onClick?.(trackEvent, startTour);
    },
    [
      flags,
      items,
      stripeIntegration,
      trackEvent,
      startTour,
      navigate,
      getNextStep,
      getLastStep,
    ]
  );

  const isStepActive = useCallback(
    (index: number) => {
      const { depends } = getNextStep(index) ?? getLastStep(index) ?? {};
      if (depends) {
        return depends.some(
          (key) => items.find((el) => el.key === key)?.completed === true
        );
      }
      return true;
    },
    [items, getNextStep, getLastStep]
  );

  return {
    handleClick,
    isStepActive,
    getProgress,
  };
};

const onBoardingStepNavigationMap = {
  experience: '/experiences',
  event: '/event/create',
  payment: '/settings/integrations',
  implementation: '/storefront/bookingflow',
};
