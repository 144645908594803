import { useModal } from '@ebay/nice-modal-react';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { sendSlackNotification } from '../../../Api/Notifications';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { trackOverviewRequestReportCompleted } from '../../../tracking/overview/request-report';
import { RequestReportFormDates } from './inputs/dates';
import { RequestReportFormType } from './inputs/type';
import { RequestReportFormAction } from './request-report-form-action';
import { RequestReportDialogFormType } from './use-request-report-form-validation';

export const RequestReportForm = () => {
  const { t } = useTranslate('dialogs.requestReport');

  const {
    me: { data: me },
    company: { data: company },
  } = useProfile();

  const modal = useModal();
  const { reset, handleSubmit } = useFormContext<RequestReportDialogFormType>();

  const handleSendRequest = async ({
    type,
    startDate,
    endDate,
  }: RequestReportDialogFormType) => {
    modal.resolve();
    modal.hide();

    try {
      toast.loading(t('requesting'), { toastId: 'requesting' });

      const { start, end } = {
        start: formatDate(startDate),
        end: formatDate(endDate),
      };

      const message = `Report requested:\n>Type: ${type}\n>Start date: ${start}\n>End date: ${end}\n>Email recipient: ${me?.email}\n\nUse this command: \`/generate_report reportType=${type}, companyName=${company?.name}, startDate=${start}, endDate=${end}\``;
      await sendSlackNotification(message, '#customer-report-requests');
      reset();

      trackOverviewRequestReportCompleted(type, me?.id);

      toast.dismiss('requesting');
      toast.success(t('success'));
    } catch {
      toast.dismiss('requesting');
      toast.error(t('error'));
    }
  };

  return (
    <form>
      <Stack gap={3}>
        <RequestReportFormType />
        <RequestReportFormDates />
        <RequestReportFormAction
          handleSubmit={handleSubmit(handleSendRequest)}
        />
      </Stack>
    </form>
  );
};

const formatDate = (date: Date) => {
  const { year, month, day } = {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};
