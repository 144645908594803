import { Order, Refund } from '@holdbar-com/utils-types';

import { OrderRefund } from '../Api/Receipt';

/**
 * Checks if an order can be refunded or not
 * @param order
 * @returns
 */
export const canRefundOrder = (order: Order | undefined) => {
  if (!order) {
    return false;
  }

  if (order.transactions.length === 0) {
    return false;
  }

  if (
    order.transactions.every(
      (x) => x.status === 'refunded-fully' || x.status === 'unpaid'
    )
  ) {
    return false;
  }

  const totalAmountChargedCents = order.transactions
    .filter((x) => x.status !== 'unpaid')
    .reduce(
      (total, transaction) => (total += transaction.totalAmountChargedCents),
      0
    );

  const totalAmountRefundedCents = order.refunds
    .filter((x) => x.status === 'completed' || x.status === 'initiated')
    .reduce((total, refund) => (total += refund.amountRefundedCents), 0);

  const refundableAmountCents =
    totalAmountChargedCents - totalAmountRefundedCents;

  // We want to support refunds of orders that have already been refunded, since
  // we support adjusting ticket counts, which means an order can be paid for and refunded
  // multiple times across it's lifetime. Therefore, an order is refundable if we there
  // has been charged more than what has been refunded.
  return refundableAmountCents > 0;
};

/**
 * Gets the transactions that can be refunded and the amount that can be refunded
 * @param order
 * @returns
 */
export const getTransactionToRefund = (order: Order): OrderRefund[] => {
  return order.transactions
    .filter((x) => ['captured', 'refunded-partially'].includes(x.status))
    .map((transaction) => {
      const refundedAmountCents = order.refunds
        .filter(
          (refund) =>
            refund.transactionId === transaction.transactionId &&
            ['completed', 'initiated'].includes(refund.status ?? '')
        )
        .reduce((acc, refund) => acc + refund.amountRefundedCents, 0);

      return {
        transactionId: transaction.transactionId,
        amountCents: transaction.totalAmountChargedCents - refundedAmountCents,
      };
    });
};

/**
 * Get refunds that are either initiated or completed or don't have a status set
 * @param refunds
 * @returns
 */
export const getActiveRefunds = (refunds: Refund[]) => {
  return refunds.filter(
    (x) => !x.status || x.status === 'initiated' || x.status === 'completed'
  );
};
