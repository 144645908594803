import { ampli } from '../../Ampli';
import {
  onboardingSteps,
  Step,
} from '../../features/onboarding/onboarding-context';
import {
  DateWithTime,
  OnboardingFormInputs,
} from '../../features/onboarding/onboarding-flow-form';
import { getFormattedDate } from '../helpers';

export const trackQuickSetupOpened = (source?: string) => {
  ampli.quickSetupOpened({ source });
};

/*
 * Tracking step navigation
 */
export const trackQuickSetupNextStepClicked = (
  current_step: string,
  next_step: string
) => {
  ampli.quickSetupNextStepClicked({
    current_step,
    next_step,
  });
};

export const trackQuickSetupPreviousStepClicked = (
  current_step: string,
  previous_step: string
) => {
  ampli.quickSetupPreviousStepClicked({
    current_step,
    previous_step,
  });
};

export const trackQuickSetupSkipStepClicked = (
  current_step: string,
  next_step: string
) => {
  ampli.quickSetupSkipStepClicked({
    current_step,
    next_step,
  });
};

/*
 * Track step completed
 * This function is called when the user completes a step in the onboarding flow.
 */
export const trackQuickSetupStepCompleted = (
  currentStepKey: Step,
  onboardingFormInputs: OnboardingFormInputs
) => {
  const stepTrackingFn = onboardingSteps[currentStepKey].trackingFn;
  const properties = getStepCompletedProperties(onboardingFormInputs);
  stepTrackingFn?.(properties);
};

const getStepCompletedProperties = (
  onboardingFormInputs: OnboardingFormInputs
) => {
  const {
    domain,
    experienceId: experience_id,
    experienceName: experience_name,
    experienceDescription: experience_description,
    experienceLocation: experience_location,
    tickets,
    experienceMedia,
    eventStartDate,
    eventStartTime,
  } = onboardingFormInputs;

  const event_scheduled_date =
    eventStartDate && eventStartTime
      ? getFormattedDate(DateWithTime(eventStartTime, new Date(eventStartDate)))
      : undefined;

  return {
    domain,
    event_scheduled_date,
    experience_id,
    experience_name,
    experience_description,
    experience_location,
    media_length: experienceMedia?.length ?? 0,
    ticket_name: tickets?.[0].name,
    price: tickets?.[0].price,
  };
};

export type StepCompletedProperties = ReturnType<
  typeof getStepCompletedProperties
>;

/* Close dialog*/
export const trackQuickSetupCloseDialogOpened = (current_step: string) => {
  ampli.quickSetupCloseDialogOpened({ current_step });
};

export const trackQuickSetupCloseDialogContinueClicked = () => {
  ampli.quickSetupCloseDialogContinueClicked();
};

export const trackQuickSetupCloseDialogExitClicked = () => {
  ampli.quickSetupCloseDialogExitClicked();
};

// Close dialog for AI generation in progress
export const trackQuickSetupCloseInProgressDialogOpened = () => {
  ampli.quickSetupCloseInProgressDialogOpened();
};

export const trackQuickSetupCloseInProgressDialogContinueClicked = () => {
  ampli.quickSetupCloseInProgressDialogContinueClicked();
};

export const trackQuickSetupCloseInProgressDialogExitClicked = () => {
  ampli.quickSetupCloseInProgressDialogExitClicked();
};

// AI generation error
export const trackQuickSetupAiGenerationFailed = (url: string) => {
  ampli.quickSetupAiGenerationFailed({ url });
};

export const trackQuickSetupAiGenerationFailedStartManualFlowClicked = () => {
  ampli.quickSetupAiGenerationFailedStartManualFlowClicked();
};

export const trackQuickSetupAiGenerationFailedTryAgainClicked = () => {
  ampli.quickSetupAiGenerationFailedTryAgainClicked();
};
