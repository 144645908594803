import { PageBreadcrumbItem } from '../page-breadcrumb-item';

export const GiftCardsItemBreadCrumb = ({
  id,
  code,
}: {
  id: string;
  code: string;
}) => {
  return <PageBreadcrumbItem title={code} href={`/giftcard/${id}`} />;
};
