import { StorefrontFeatures } from '@holdbar-com/utils-types';
import isEqual from 'lodash.isequal';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';
import { Control, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ampli } from '../../../../Ampli';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';

type ContextProps = {
  save: () => Promise<unknown>;
  control: Control;
  feature: keyof StorefrontFeatures;
};

export const FeatureSettingContext = createContext<ContextProps | null>(null);

type ProviderProps = PropsWithChildren<{
  feature: keyof StorefrontFeatures;
}>;

export const useFeatureSetting = () =>
  useContext(FeatureSettingContext) as ContextProps;

export const FeatureSettingContextProvider = ({
  children,
  feature,
}: ProviderProps) => {
  const { t } = useTranslate('settings.features');

  const formMethods = useForm();
  const { handleSubmit, reset, control } = formMethods;

  const {
    updateCompanyFeature,
    company: { data: company },
  } = useProfile();

  const handleSave = useCallback(() => {
    return new Promise((res, rej) => {
      handleSubmit(
        async ({ params, enabled }) => {
          if (!company) return;

          const featureBefore = company.features?.[feature];

          const before = {
            enabled: featureBefore?.enabled ?? false,
            params: featureBefore?.params ?? {},
          };

          const after = {
            enabled: enabled ?? false,
            params: params ?? {},
          };

          // Don't save if there is no changes
          if (isEqual(before, after)) {
            return;
          }

          await updateCompanyFeature.mutateAsync({
            feature,
            payload: after,
          });

          if (enabled) {
            const enabledFeatures = Object.keys(company.features ?? {}).filter(
              (key) =>
                company.features?.[key as keyof StorefrontFeatures]?.enabled
            );
            enabledFeatures.push(feature);

            ampli.featuresEnabledEvent({
              feature_list: enabledFeatures,
              feature_number: enabledFeatures.length,
            });
          }

          setTimeout(() => reset(after), 1500);
          toast.success(t('toastSuccess'));
          res('Success');
        },
        (err) => {
          toast.error(t('toastError'));
          rej(err);
        }
      )();
    });
  }, [company, feature, handleSubmit, reset, t, updateCompanyFeature]);

  return (
    <FeatureSettingContext.Provider
      value={{ feature, save: handleSave, control }}
    >
      <FormProvider {...formMethods}>{children}</FormProvider>
    </FeatureSettingContext.Provider>
  );
};
