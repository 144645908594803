import { apiServices } from './apiServices';
import api from './index';

export type MetricsSeries = {
  [key: string]: {
    sales: { key: string; value: number }[];
    orders: { key: string; value: number }[];
    giftCards: { key: string; value: number }[];
    participants: { key: string; value: number }[];
  };
};

export type CategoryTotals = {
  sales: { [key: string]: number };
  orders: { [key: string]: number };
  giftCards: { [key: string]: number };
  participants: { [key: string]: number };
};

export interface MetricsData {
  series: MetricsSeries;
  categoryTotals: CategoryTotals;
  metadata: {
    dataUpdateTimeUtc: string;
    nextDataUpdateTimeUtc: string;
  };
}

export const getMetrics = async (
  from: string,
  to: string,
  experienceIds: string[]
) => {
  if (typeof apiServices.tracking === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_TRACKING_URL is not defined'
    );
  }

  const params = new URLSearchParams({
    from,
    to,
    experienceIds: experienceIds.join(','),
  });

  const { data } = await api.get<MetricsData>(
    `${apiServices.tracking}/metrics`,
    { params }
  );

  return data;
};
