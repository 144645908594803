import NiceModal from '@ebay/nice-modal-react';
import { lightTheme } from '@holdbar-com/pixel';
import { Link } from '@mui/material';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { trackStorefrontImplementationExamplesClicked } from '../../../../../tracking/storefront/implementation-events';
import { useBookingFlowSectionContext } from '../../domain/use_booking_flow_section_context';
import { FlowTypePreviewsDialog } from './FlowTypePreviewsDialog';

export const FlowTypePreviewsLink = () => {
  const { t } = useTranslate('storefront.bookingflow');
  const { selectedFlowType } = useBookingFlowSectionContext();

  return (
    <Link
      onClick={() => {
        trackStorefrontImplementationExamplesClicked();
        NiceModal.show(FlowTypePreviewsDialog, {
          initialType: selectedFlowType,
        });
      }}
      sx={{
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: lightTheme.typography.small.normal.fontSize,
      }}
    >
      {t('flowType.previewButton')}
    </Link>
  );
};
