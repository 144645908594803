import create from 'zustand';
import { persist } from 'zustand/middleware';

interface IntegrationRequestStore {
  integrationRequests: string[];
  addIntegrationRequest: (value: string) => void;
  isRequestInStore: (value: string) => boolean;
}

export const useIntegrationRequestsStore = create(
  persist<IntegrationRequestStore>(
    (set, get) => ({
      integrationRequests: [],
      addIntegrationRequest: (value) =>
        set((state) => ({
          integrationRequests: [...state.integrationRequests, value],
        })),
      isRequestInStore: (value: string) =>
        get().integrationRequests.includes(value),
    }),
    {
      name: 'holdbar-admin',
      getStorage: () => sessionStorage,
    }
  )
);
