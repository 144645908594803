import { lightTheme, Text } from '@holdbar-com/pixel';
import { Divider, Stack, styled } from '@mui/material';
import { useContext, useMemo } from 'react';

import { EmptyScreen } from '../../../../Components/EmptyScreen/EmptyScreen';
import { ListSkeleton } from '../../../../Components/ListSkeleton/ListSkeleton';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { TBooking } from '../../../../Hooks/useBookings';
import { Variant } from '../../../../Hooks/useExperience';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { BookingListActions } from '../actions';
import { EventDetailsContext } from '../EventDetailsView';
import { StyledInfoBox } from '../shared';
import { BookingListHeader } from './BookingListHeader';
import { BookingListItem } from './BookingListItem';

export const BookingList = () => {
  const { event, experience, bookingsForEvent } =
    useContext(EventDetailsContext);

  const { isMd } = useResponsive();

  const bookingsInView = useMemo(() => {
    const nonCancelled: TBooking[] = [];
    const cancelled: TBooking[] = [];
    bookingsForEvent?.forEach((booking) => {
      if (booking.status === 'cancelled') {
        cancelled.push(booking);
      } else {
        nonCancelled.push(booking);
      }
    });
    return [...nonCancelled, ...cancelled];
  }, [bookingsForEvent]);

  if (!bookingsForEvent) {
    return <ListSkeleton />;
  }

  if (!event || !experience) return null;

  if (isMd)
    return (
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          paddingTop={3}
          paddingBottom={2}
        >
          <Header
            bookedCount={event.slots.booked ?? 0}
            totalCount={event.slots.total}
          />
        </Stack>
        <Divider />
        <List
          bookingsInView={bookingsInView}
          variants={experience.price?.variants}
        />
      </Stack>
    );

  return (
    <StyledInfoBox>
      <StyledCardHeader
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Header
          bookedCount={event.slots.booked ?? 0}
          totalCount={event.slots.total}
        />
      </StyledCardHeader>
      <Divider />
      <Stack fontSize={lightTheme.typography.small.normal.fontSize}>
        <Stack>
          <BookingListHeader />
          <Divider />
          <List
            bookingsInView={bookingsInView}
            variants={experience.price?.variants}
          />
        </Stack>
      </Stack>
    </StyledInfoBox>
  );
};

const Header = ({
  bookedCount,
  totalCount,
}: {
  bookedCount: number;
  totalCount: number;
}) => {
  const { t } = useTranslate('events.detailsPage.bookingList');

  return (
    <>
      <StyledText variant="medium">
        {t('title', {
          count: bookedCount,
          total: totalCount,
        })}
      </StyledText>
      <BookingListActions />
    </>
  );
};

const List = ({
  bookingsInView,
  variants,
}: {
  bookingsInView: TBooking[];
  variants?: Variant[];
}) => {
  const { t } = useTranslate('utils.tables.header');

  return (
    <Stack divider={<Divider />}>
      {bookingsInView.length === 0 ? (
        <EmptyScreen
          mt={6}
          imageSrc={'/empty-state-nature.svg'}
          title={t('emptyState.title', 'bookings')}
          description={t('emptyState.description', 'bookings')}
        />
      ) : (
        bookingsInView?.map((booking) => (
          <BookingListItem
            key={booking.id}
            booking={booking}
            variants={variants}
          />
        ))
      )}
    </Stack>
  );
};

const StyledText = styled(Text)({
  display: 'block',
  userSelect: 'none',
});

const StyledCardHeader = styled(Stack)({
  paddingLeft: 32,
  paddingRight: 16,
  paddingBlock: 8,
});
