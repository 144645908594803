import { Box, type BoxProps, Grid, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import type { IExperience } from '../../../../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { EconomicConfigurationSectionTitle } from '../../economic-configuration-section-title';
import { EconomicAccountAutocomplete } from '../economic-account-autocomplete';
import { EconomicConfigurationAddonsVariant } from './economic-configuration-addons-variant';

interface ExperienceAccountsProps {
  experience: IExperience;
}

const containerProps: BoxProps = {
  bgcolor: '#fafafb',
  borderRadius: 3,
  p: 3,
  position: 'relative',
  maxWidth: 750,
};

export const EconomicConfigurationAddonsAccount: FC<
  ExperienceAccountsProps
> = ({ experience }) => {
  const localizer = useLocalizedStringFormatter();
  const { watch } = useFormContext();
  const { t } = useTranslate('settings.integrations.economic.configuration');

  const shouldIncludeHeader = useMemo(() => {
    return () =>
      experience.price.variants?.length >= 2 &&
      experience.price.variants?.some(
        (variant) => (variant.addons ?? []).length > 0
      );
  }, [experience]);

  return (
    <Box {...containerProps} key={experience.id}>
      <Stack flexDirection="column" gap={2}>
        <Typography variant={'body1'}>
          {localizer(experience.headline)}
        </Typography>
        <div>
          <EconomicAccountAutocomplete
            label={t('accounts.salesWithVat')}
            path={`experienceSales.${experience.id}`}
            defaultValue={watch(`accounts.salesWithVat`)}
          />
        </div>
        <Grid container spacing={3}>
          {experience.price.variants?.some(
            (variant) => (variant.addons ?? []).length > 0
          ) && (
            <Grid item xs={12}>
              <EconomicConfigurationSectionTitle
                title={t('addons.title')}
                description={t('addons.description')}
              />
            </Grid>
          )}
          {experience.price.variants?.map((variant) => (
            <EconomicConfigurationAddonsVariant
              includeHeader={shouldIncludeHeader()}
              key={variant.id}
              variant={variant}
            />
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};
