import i18n, { TOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'da',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export function t(text: string, options?: TOptions): string {
  return i18n.t(text, options);
}
