import { BaseTextFieldProps } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { forwardRef, useMemo, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { CalendarIcon } from '../../Icons';

export const dateRegEx = /\d{2}-\d{2}-\d{4}/;

/**
 * Takes `value` and returns a date string in the format of `yyyy-MM-dd` in `onChange`.
 * Passing an `undefined` date will result in an empty input
 */
export const DateInput = forwardRef(
  (
    {
      label,
      value,
      onChange,
      required = false,
      error,
      helperText,
      minDate = '2022-01-01',
      maxDate,
      disabled,
      size = 'medium',
      onClick,
      ref,
      sx,
    }: Pick<ControllerRenderProps, 'value' | 'onChange'> &
      Partial<ControllerRenderProps> &
      BaseTextFieldProps & {
        onClick?: () => void;
        minDate?: string | Date;
        maxDate?: string;
      },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _ref
  ) => {
    const [open, setOpen] = useState(false);

    const _value = useMemo(() => new Date(value), [value]);

    const handleChange = (date: Date | null) => {
      if (!date || isNaN(date.valueOf())) return onChange(undefined);

      const newValue = format(date, 'yyyy-MM-dd');
      onChange(newValue);
    };

    return (
      <DesktopDatePicker
        ref={ref}
        value={_value}
        onChange={handleChange}
        open={open}
        onOpen={() => {
          setOpen(true);
          onClick?.();
        }}
        onClose={() => setOpen(false)}
        format="dd-MM-yyyy"
        minDate={new Date(minDate)}
        maxDate={maxDate ? new Date(maxDate) : undefined}
        label={label}
        disabled={disabled}
        slotProps={{
          textField: {
            error,
            helperText,
            required,
            InputLabelProps: { shrink: true },
            size,
            sx: {
              flex: 3,
              '.MuiInputBase-input':
                size === 'small' ? { height: 40, paddingBlock: 0 } : undefined,
              ...sx,
            },
          },
          openPickerIcon: CalendarIcon,
          popper: { placement: 'bottom-start' },
        }}
      />
    );
  }
);

DateInput.displayName = 'DateInput';
