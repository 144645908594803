import { formatISO } from 'date-fns';

export const isEmptyObject = (obj: object) =>
  typeof obj === 'object' &&
  obj !== null &&
  !Array.isArray(obj) &&
  Object.keys(obj).length === 0;

export const toISODate = (date: Date) => {
  return formatISO(date).split('T')[0];
};

export const validUrl = (url: string) => {
  const input = document.createElement('input');
  input.type = 'url';
  if (url && !url.startsWith('https://') && !url.startsWith('http://')) {
    url = `https://${url}`;
  }
  input.value = url;
  return input.checkValidity();
};

export const validEmail = (email: string) => {
  const input = document.createElement('input');
  input.type = 'email';
  input.value = email;
  return input.checkValidity();
};

export const getFormattedPrice = (
  price: number,
  currency: string,
  locale?: string,
  digits?: number
) => {
  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: digits ?? 0,
  }).format(price);

  return formattedPrice;
};

export const sum = (arr: number[]): number => arr.reduce((a, b) => a + b, 0);

export const emailRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
