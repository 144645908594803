import NiceModal from '@ebay/nice-modal-react';
import { useEffect, useState } from 'react';

import { Banner } from '../../../../Components/Banner/Banner';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { ConfirmDialog } from '../../../../Modals/confirm-dialog';
import { useGrowthOpportunityRequestStore } from '../../../../Store/useGrowthOpportunityRequestsStore';
import { trackGrowthBusinessReviewRequestCompleted } from '../../../../tracking/growth/growth-events';
import { useGrowthOpportunities } from '../domain/use-growth-opportunities';

const ReviewOpportunity = {
  id: 'review',
  title: 'Review',
};

export const RequestReviewBanner = () => {
  const { t } = useTranslate('opportunities.review');
  const { handleInterest } = useGrowthOpportunities();
  const {
    me: { data: me },
  } = useProfile();

  const [isRequested, setIsRequested] = useState(false);

  useEffect(() => {
    const checkRequestStatus = () => {
      setIsRequested(
        useGrowthOpportunityRequestStore
          .getState()
          .isRequestInStore(ReviewOpportunity.id)
      );
    };

    const unsubscribe =
      useGrowthOpportunityRequestStore.subscribe(checkRequestStatus);
    checkRequestStatus();

    return () => unsubscribe();
  }, []);

  const handleRequestReview = () => {
    NiceModal.show(ConfirmDialog, {
      title: t('cta2'),
      description: t('description'),
      cta: t('cta'),
      hideCancel: true,
      showLoading: true,
      closeOnConfirm: false,
      onConfirm: async () =>
        await handleInterest(
          ReviewOpportunity.id,
          ReviewOpportunity.title,
          () => trackGrowthBusinessReviewRequestCompleted(me?.id)
        ),
    });
  };

  return (
    <Banner
      title={t('title')}
      subtitle={t('subtitle')}
      cta={isRequested ? t('successTitle', 'opportunities.request') : t('cta')}
      icon={'/graphics/growth-graph.svg'}
      highlight={'growth'}
      disabled={isRequested}
      onClick={handleRequestReview}
    />
  );
};
