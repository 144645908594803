import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

import { useProfile } from './useProfile';

export function useLaunchDarkly() {
  const ldClient = useLDClient();
  const { me, company } = useProfile();

  useEffect(() => {
    if (!ldClient) {
      return;
    }

    let ldCtx = {} as
      | {
          kind: string;
          organization?: { key: string | undefined; name: string | undefined };
          user?: {
            key: string | undefined;
            name: string | undefined;
            [key: string]: string | undefined;
          };
        }
      | {
          kind: 'user' | 'organization';
          name: string | undefined;
          key: string | undefined;
          email: string | undefined;
        };

    if (me.isSuccess && company.isSuccess) {
      ldCtx = {
        kind: 'multi',
        organization: {
          key: company?.data?.id,
          name: company?.data?.name,
        },
        user: {
          key: me?.data?.id,
          name: me?.data?.name,
          email: me?.data?.email,
          organization: company?.data?.id,
        },
      };
    }

    if (me.isSuccess && !company.isSuccess) {
      ldCtx = {
        kind: 'user',
        key: me?.data?.id,
        name: me?.data?.name,
        email: me?.data?.email,
      };
    }

    if (!me.isSuccess && company.isSuccess) {
      ldCtx = {
        kind: 'organization',
        key: company?.data?.id,
        name: company?.data?.name,
      };
    }

    // Identify with LaunchDarkly
    if (ldCtx.kind) {
      ldClient?.identify(ldCtx);
    }
  }, [
    company?.data?.id,
    company?.data?.name,
    company.isSuccess,
    ldClient,
    me?.data?.email,
    me?.data?.id,
    me?.data?.name,
    me.isSuccess,
  ]);
}
