import { StockPhotosSearchResult } from '@holdbar-com/utils-types';
import { Box } from '@mui/material';
import { FC, useEffect, useRef } from 'react';

const CANVAS_WIDTH = 130;
const CANVAS_HEIGHT = 130;

type Props = {
  thumbnail: StockPhotosSearchResult['thumbnail'];
};

/**
 * Shutterstock search results has a whitestrip that we dont want to show.
 *
 * This component is used to preview images from Shutterstock, removing the whitestrip.
 *
 * @see https://www.shutterstock.com/developers/documentation/searching
 */
export const StockPhotoSearchResultThumbnail: FC<Props> = ({ thumbnail }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx) {
      return;
    }

    canvas.width = CANVAS_WIDTH;
    canvas.height = CANVAS_HEIGHT;

    const img = new Image();
    img.src = thumbnail.url;

    img.onload = () => {
      // Clear the canvas before drawing
      ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

      // Instead of stretching we want to crop the image to the center
      const canvasAspectRatio = CANVAS_WIDTH / CANVAS_HEIGHT;
      const imageAspectRatio = thumbnail.width / thumbnail.height;

      let sourceX = 0,
        sourceY = 0,
        sourceWidth = thumbnail.width,
        sourceHeight = thumbnail.height;

      if (imageAspectRatio > canvasAspectRatio) {
        // Image is wider than the canvas
        sourceWidth = thumbnail.height;
        sourceX = (thumbnail.width - sourceWidth) / 2;
      } else {
        // Image is taller than the canvas
        sourceHeight = thumbnail.width;
        sourceY = (thumbnail.height - sourceHeight) / 2;
      }

      // Draw the cropped portion of the image onto the canvas
      ctx.drawImage(
        img,
        sourceX,
        sourceY,
        sourceWidth,
        sourceHeight,
        0,
        0,
        CANVAS_WIDTH,
        CANVAS_HEIGHT
      );
    };
  }, [thumbnail.height, thumbnail.url, thumbnail.width]);

  return (
    <Box
      ref={canvasRef}
      component="canvas"
      width={CANVAS_WIDTH}
      height={CANVAS_HEIGHT}
      sx={{
        display: 'block',
        width: CANVAS_WIDTH,
        height: CANVAS_HEIGHT,
        borderRadius: '12px',
      }}
    />
  );
};
