import NiceModal from '@ebay/nice-modal-react';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useTranslate } from '../../../Hooks/useTranslate';
import { ConfirmDialog } from '../../../Sections/SettingsSections/Integrations/ui/shared/confirm-dialog';
import { useEconomicConfiguration } from '../data/use-economic-configuration';

export const useEconomicConfigurationCancel = () => {
  const { t } = useTranslate('settings.integrations.cancelDialog');
  const navigate = useNavigate();

  const { formState } = useFormContext();

  const { configuration } = useEconomicConfiguration();

  const handleBack = useCallback(() => {
    if (configuration.data === null) {
      navigate('/settings/integrations');
      return;
    }

    navigate('/settings/integrations/economic');
  }, [configuration.data, navigate]);

  const handleCancel = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault();

      if (formState.isDirty) {
        NiceModal.show(ConfirmDialog, {
          title: t('title'),
          description: t('description'),
          cta: t('cta'),
          onConfirm: handleBack,
        });
      } else {
        handleBack();
      }
    },
    [formState.isDirty, handleBack, t]
  );

  return {
    handleCancel,
  };
};
