import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { CloseRounded } from '@mui/icons-material';
import { Dialog, Stack } from '@mui/material';

import useResponsive from '../../../../../../../../../../Hooks/layout/useResponsive';

type NotificationsPreviewModalProps = {
  email: string;
};

export const NotificationsPreviewModal =
  NiceModal.create<NotificationsPreviewModalProps>(({ email }) => {
    const { isSm } = useResponsive();

    const modal = useModal();

    const handleClose = () => {
      modal.resolve();
      modal.hide();
    };

    return (
      <Dialog
        open={modal.visible}
        onClose={handleClose}
        fullWidth
        maxWidth={'lg'}
        fullScreen={isSm}
        sx={{ '& .MuiDialog-paper': { height: '100%' } }}
      >
        <Stack gap={3} px={isSm ? 1 : 4} py={4} height={'100%'}>
          <CloseRounded
            onClick={handleClose}
            sx={{ cursor: 'pointer', ml: isSm ? 3 : 0 }}
          />
          <StyledIframe srcDoc={email} title="Notification email preview" />
        </Stack>
      </Dialog>
    );
  });

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;
