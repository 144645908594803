import { lightTheme, Text } from '@holdbar-com/pixel';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Box, Card, IconButton, Stack, styled } from '@mui/material';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { EventType } from '../domain/types';
import { EventUpsertBooking } from './booking';
import { EventUpsertDetails } from './details';
import { EventUpsertStepper } from './EventUpsertStepper';
import { EventUpsertTime } from './time';

type EventUpsertProps = {
  title: string;
  subtitle?: string;
  event: EventType;
  onBack?: () => void;
  onSubmit: () => void;
};

export const EventUpsert = ({
  title,
  subtitle,
  event,
  onBack,
  onSubmit,
}: EventUpsertProps) => {
  const { isSm, isMd } = useResponsive();

  const displaySubtitle = subtitle && isMd;

  const currentStep = event?.step;

  const stepComponents = {
    details: EventUpsertDetails,
    booking: EventUpsertBooking,
    time: EventUpsertTime,
  };

  const CurrentStep = stepComponents[currentStep];

  return (
    <StyledEventUpsert gap={4}>
      <Stack position="relative" direction="row" alignItems="flex-start">
        {!isMd && (
          <StyledBackButton onClick={onBack}>
            <ArrowBackRoundedIcon
              sx={{
                fontSize: '2rem',
                color: lightTheme.palette.contrast.black,
              }}
            />
          </StyledBackButton>
        )}
        <Stack>
          <Text fontSize="xlarge" variant="medium">
            {title}
          </Text>
          {displaySubtitle && <Text fontSize="small">{subtitle}</Text>}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="center" gap={6}>
        <EventUpsertStepper step={currentStep} />
        <Box
          component={isSm ? Stack : Card}
          flexGrow={1}
          maxWidth="100%"
          data-intercom-target="event-upsert-content"
        >
          <CurrentStep onSubmit={onSubmit} />
        </Box>
      </Stack>
    </StyledEventUpsert>
  );
};

const StyledEventUpsert = styled(Stack)`
  width: 100%;
  max-width: var(--event-upsert-max-width);
`;

const StyledBackButton = styled(IconButton)(
  ({ theme }) => `
  position: absolute;
  padding: 0;
  left: -${theme.spacing(6)};

  &:hover {
    background-color: ${lightTheme.palette.neutral.n100};
  }
`
);
