import { Box, Stack, styled } from '@mui/material';
import { ComponentPropsWithoutRef } from 'react';

const StyledPage = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  margin: '40px 0 40px 0',
  [theme.breakpoints.down('md')]: {
    margin: '16px 0 16px 0',
  },
  maxWidth: theme.breakpoints.up('xl'),
  width: '100%',
  position: 'relative',
})) as typeof Box;

export const Page = ({
  children,
  ...props
}: ComponentPropsWithoutRef<typeof Stack>) => {
  return <StyledPage {...props}>{children}</StyledPage>;
};
