import NiceModal from '@ebay/nice-modal-react';
import { captureException } from '@sentry/react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { refundOrder } from '../../../../../../Api/Receipt';
import { useGetBookingsForEvent } from '../../../../../../Hooks/data/useBookings';
import { useBookings } from '../../../../../../Hooks/useBookings';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { ConfirmDialog } from '../../../../../../Modals/ConfirmDialog';
import { MoveBookingDialog } from '../../../../../../Modals/MoveBookingDialog';
import { OptionsDialog } from '../../../../../../Modals/OptionsDialog';
import {
  trackBookingCheckInCancelled,
  trackBookingCheckInCompleted,
  trackBookingCheckInStarted,
  trackBookingMoveCompleted,
  trackBookingMoveStarted,
  trackBookingRefundCompleted,
  trackBookingRefundExited,
  trackBookingRefundStarted,
} from '../../../../../../tracking/bookings/details';
import {
  canRefundOrder,
  getTransactionToRefund,
} from '../../../../../../Utils/orders';
import { useBookingDetailsContext } from '../../../domain/use_booking_details_context';

export const useBookingDetailsActions = () => {
  const { t } = useTranslate('dialogs.booking');

  const {
    booking,
    receipt: { data: receipt, refetch: refetchReceipt },
    event,
  } = useBookingDetailsContext();

  const { cancelBooking, checkInBooking } = useBookings(undefined, booking?.id);

  const { bookingsForEvent } = useGetBookingsForEvent(
    booking?.eventId as string
  );

  const handleCancel = useCallback(async () => {
    let choice: string | undefined;
    if (!canRefundOrder(receipt)) {
      await NiceModal.show(ConfirmDialog, {
        title: t('title', 'dialogs.cancelBooking'),
        headline: t('headline', 'dialogs.cancelBooking', {
          customerName: booking?.customer.name,
        }),
        confirmLabel: t('actions.primary', 'dialogs.cancelBooking'),
      });
    } else {
      choice = await NiceModal.show(OptionsDialog, {
        title: t('title', 'dialogs.refund'),
        headline: t('headline', 'dialogs.cancelBooking', {
          customerName: booking?.customer.name,
        }),
        buttons: [
          {
            key: 'cancelOnly',
            label: t('cancelOnly', 'dialogs.cancelBooking.actions'),
            props: {
              variant: 'outlined',
              color: 'secondary',
            },
          },
          {
            key: 'shouldRefund',
            label: t('shouldRefund', 'dialogs.cancelBooking.actions'),
            props: {
              variant: 'contained',
            },
          },
        ],
      });
    }

    toast.loading(t('loadingTitle', 'dialogs.cancelBooking.toasts'), {
      toastId: 'cancelBooking',
    });

    if (choice === 'shouldRefund') {
      if (!receipt?.id) return;

      try {
        await refundOrder(receipt.id, getTransactionToRefund(receipt));
        refetchReceipt();
      } catch (error) {
        toast.error(t('errorTitle', 'dialogs.errorDialog'));
        return;
      }
    }

    cancelBooking
      .mutateAsync(false) // shouldRefund = false - refund is handled separately
      .then(() => {
        toast.success(t('successTitle', 'dialogs.cancelBooking.toasts'));
      })
      .catch(() => {
        toast.error(t('errorTitle', 'dialogs.errorDialog'));
      })
      .finally(() => {
        toast.dismiss('cancelBooking');
        bookingsForEvent.refetch();
      });
  }, [booking, bookingsForEvent]);

  const handleRefund = useCallback(
    async (source: 'popover' | 'button') => {
      if (
        !receipt?.id ||
        !receipt?.transactions.some(
          (x) => x.status === 'captured' || x.status === 'refunded-partially'
        ) ||
        !booking
      )
        return;
      trackBookingRefundStarted(booking, source, receipt);

      const headline = t('headline', 'dialogs.refund', {
        refundAmount: `${
          receipt.financials.grandTotalCents / 100
        } ${receipt.financials?.currency.toUpperCase()}`,
        customerName: booking?.customer.name,
      });

      let choice: 'refundOnly' | 'cancelAndRefund' | undefined;
      try {
        if (
          booking.status === 'cancelled' ||
          event?.states.isUpcoming === false
        ) {
          await NiceModal.show(ConfirmDialog, {
            title: t('title', 'dialogs.refund'),
            headline,
            confirmLabel: t('actions.primary', 'dialogs.refund'),
          });

          // if booking is already cancelled, we can only refund
          choice = 'refundOnly';
        } else {
          choice = await NiceModal.show<typeof choice>(OptionsDialog, {
            title: headline,
            description: t('description', 'dialogs.refundBooking'),
            buttons: [
              {
                key: 'refundOnly' as typeof choice,
                label: t('refundOnly', 'utils.generic'),
                props: {
                  variant: 'outlined',
                  color: 'secondary',
                },
              },
              {
                key: 'cancelAndRefund' as typeof choice,
                label: t('cancelAndRefund', 'utils.generic'),
                props: {
                  variant: 'contained',
                  color: 'error',
                },
              },
            ],
          });
        }
      } catch {
        trackBookingRefundExited(booking, source, receipt);
        return;
      }

      const transactionsToRefund = getTransactionToRefund(receipt);

      if (choice === 'refundOnly') {
        try {
          await refundOrder(receipt.id, transactionsToRefund);
          refetchReceipt();
          trackBookingRefundCompleted(booking, source, receipt);
          toast.success(t('refunded', 'utils.generic'));
        } catch (error) {
          toast.error(t('errorTitle', 'dialogs.errorDialog'));
        }

        return;
      }

      if (choice === 'cancelAndRefund') {
        try {
          await refundOrder(receipt.id, transactionsToRefund);
          refetchReceipt();
        } catch (error) {
          toast.error(t('errorTitle', 'dialogs.errorDialog'));
          return;
        }

        trackBookingRefundCompleted(booking, source, receipt);

        cancelBooking
          .mutateAsync(false) // shouldRefund = false - refund is handled separately
          .then(() => {
            toast.success(t('successTitle', 'dialogs.cancelBooking.toasts'));
          })
          .catch(() => {
            toast.error(t('errorTitle', 'dialogs.errorDialog'));
          })
          .finally(() => {
            toast.dismiss('cancelBooking');
            bookingsForEvent.refetch();
          });
        return;
      }
    },
    [booking, receipt]
  );

  const handleMoveBooking = useCallback(
    async (source: 'popover' | 'button') => {
      if (booking?.experienceId) {
        trackBookingMoveStarted(booking, source, receipt);

        await NiceModal.show<() => Promise<void>>(MoveBookingDialog, {
          eventId: booking.eventId,
          bookingId: booking.id,
          experienceId: booking?.experienceId,
        });

        trackBookingMoveCompleted(booking, source, receipt);
      }
    },
    [booking, receipt]
  );

  const handleCheckInBooking = useCallback(async () => {
    if (!booking?.id) {
      return;
    }
    try {
      trackBookingCheckInStarted('manual', booking, receipt);
      await NiceModal.show(ConfirmDialog, {
        description: t('title', 'dialogs.booking.checkIn'),
        headline: t('headline', 'dialogs.booking.checkIn'),
        confirmLabel: t('confirm', 'dialogs.booking.checkIn'),
      });
    } catch {
      trackBookingCheckInCancelled('manual', booking, receipt);
      return;
    }

    try {
      await toast.promise(
        checkInBooking.mutateAsync({
          id: booking.id,
          method: 'backoffice-manual',
        }),
        {
          pending: t('pending', 'dialogs.booking.checkIn'),
          success: t('success', 'dialogs.booking.checkIn'),
          error: t('errorTitle', 'dialogs.errorDialog'),
        }
      );
      trackBookingCheckInCompleted('manual', booking, receipt);
    } catch (error) {
      captureException(error);
      toast.error(t('errorTitle', 'dialogs.errorDialog'));
    }
  }, [booking?.id, checkInBooking, t]);

  return {
    handleCancel,
    handleRefund,
    handleMoveBooking,
    handleCheckInBooking,
  };
};
