import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { FixedActionsBar } from '../../../../../Components/FixedActionsBar/FixedActionsBar';
import { usePreventWindowUnload } from '../../../../../Hooks/usePreventWindowUnload';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { ProfileFormData } from '../../domain/use-profile-form-validation';

export const ProfileFormActions = () => {
  const { t } = useTranslate('toast.saving');

  const { updateMe } = useProfile();

  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = useFormContext<ProfileFormData>();

  usePreventWindowUnload(isDirty);

  const onSubmit = async (profileFormData: ProfileFormData) => {
    try {
      await updateMe.mutateAsync(profileFormData);
      toast.success(t('success'));
      reset(profileFormData);
    } catch {
      toast.error(t('error'));
    }
  };

  return (
    <FixedActionsBar
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => reset()}
      isSubmitting={isSubmitting}
      unsavedChanges={isDirty}
    />
  );
};
