import { Text } from '@holdbar-com/pixel';
import { CancelRounded, CheckRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import isEmpty from 'lodash.isempty';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { createFromTemplate } from '../../../../Api';
import { ProgressButton } from '../../../../Components/ProgressButton/ProgressButton';
import { SimpleSkeleton } from '../../../../Components/SimpleSkeleton/SimpleSkeleton';
import { TranslatableGroup } from '../../../../Components/TranslatableGroup/translatable-group';
import { useStorefrontLanguages } from '../../../../Hooks/translatable/use-storefront-languages';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTerms } from '../../../../Hooks/useTerms';
import { useTranslate } from '../../../../Hooks/useTranslate';

type TermsType = 'general' | 'privacy' | 'cancellation';
type Terms = Record<TermsType, { [lang: string]: string }>;

function useShowActions() {
  const [showActions, _setShowActions] = useState<Record<TermsType, boolean>>({
    privacy: false,
    cancellation: false,
    general: false,
  });

  return {
    showGeneralActions: showActions.general,
    showCancellationActions: showActions.cancellation,
    showPrivacyActions: showActions.privacy,
    setShowActions: (type: TermsType, value: boolean) => {
      _setShowActions((previousState) => ({
        ...previousState,
        [type]: value,
      }));
    },
  };
}

export const TermsForm = () => {
  const { t } = useTranslate('settings.terms');
  const storefrontLanguages = useStorefrontLanguages() ?? [];

  const { setValue, getValues } = useFormContext();
  const [defaultValues, setDefaultValues] = useState<Terms | null>(null);
  const {
    showGeneralActions,
    showCancellationActions,
    showPrivacyActions,
    setShowActions,
  } = useShowActions();

  const [isFormReady, setIsFormReady] = useState(false);

  const {
    terms: { data: terms, isLoading: termsLoading },
    updateTerms,
  } = useTerms();

  useEffect(() => {
    if (!termsLoading && !defaultValues) {
      const next: Terms = {
        privacy: storefrontLanguages.reduce(
          (previous, language) => ({
            ...previous,
            [language.key]:
              terms?.privacy?.versions?.[language.key]?.content ?? '',
          }),
          {}
        ),
        cancellation: storefrontLanguages.reduce(
          (previous, language) => ({
            ...previous,
            [language.key]:
              terms?.cancellation?.versions?.[language.key]?.content ?? '',
          }),
          {}
        ),
        general: storefrontLanguages.reduce(
          (previous, language) => ({
            ...previous,
            [language.key]:
              terms?.general?.versions?.[language.key]?.content ?? '',
          }),
          {}
        ),
      };

      setDefaultValues(next);
      setIsFormReady(true);
    }
  }, [defaultValues, storefrontLanguages, terms]);

  const {
    company: { data: companyProfile },
  } = useProfile();

  const updateField = (termsType: string, value: string, language: string) => {
    setValue(`terms.${termsType}.${language}`, value, { shouldTouch: true });
  };

  const saveChanges = (termsType: string) => {
    const { terms: _terms } = getValues();
    const changedContent = Object.entries(
      _terms as { [k: string]: string }
    ).reduce((acc, [type, el]) => {
      return termsType === type
        ? {
            ...acc,
            ...Object.entries(el).reduce((changed, [lang, content]) => {
              return content !== terms?.[type]?.versions?.[lang]?.content
                ? { ...changed, [lang]: { content } }
                : changed;
            }, {}),
          }
        : acc;
    }, {});

    if (!isEmpty(changedContent)) {
      updateTerms.mutate({ type: termsType, payload: changedContent });
    }
  };

  async function handleCreateFromTemplate(type: string, lang: string) {
    try {
      const { content } = await createFromTemplate<{
        content: string;
        version: number;
      }>(type, lang, { companyName: companyProfile?.name ?? '' });

      return content;
    } catch (err) {
      console.error('Could not get template');
      return '';
    }
  }

  return (
    <>
      <Stack gap={0.5}>
        <Text fontSize={'large'} variant={'medium'}>
          {t('title')}
        </Text>
        <Text
          fontSize={'small'}
          style={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {t('description')}
        </Text>
      </Stack>

      <Divider sx={{ mt: 4, mb: 4 }} />

      <Stack mt={5} spacing={5}>
        {!isFormReady ? (
          <SimpleSkeleton />
        ) : (
          <>
            <Box>
              <Typography variant={'h4'} fontSize={'1.2em'}>
                {t('types.general')}
              </Typography>
              <TranslatableGroup
                sx={{ maxWidth: 680 }}
                langs={storefrontLanguages}
                id={`terms.general`}
                onUpdate={(value, language) => {
                  updateField('general', value, language);
                  if (value !== defaultValues?.general[language])
                    setShowActions('general', true);
                }}
                input={{
                  type: 'editor',
                  placeholder: t('inputPlaceholder'),
                }}
                defaultValue={defaultValues?.general}
                renderControls={[
                  {
                    position: 'below',
                    render: (selectedLang, onUpdate) =>
                      showGeneralActions ? (
                        <ConfirmButtons
                          onSave={() => {
                            saveChanges('general');
                            setShowActions('general', false);
                          }}
                          onCancel={() => {
                            onUpdate(
                              defaultValues?.general[selectedLang] ?? '',
                              true
                            );
                            setShowActions('general', false);
                          }}
                        />
                      ) : (
                        <ProgressButton
                          label={t('insertFromTemplate')}
                          sx={{ mt: 2 }}
                          onClick={async () => {
                            const content = await handleCreateFromTemplate(
                              'general',
                              selectedLang
                            );

                            onUpdate(content, true);
                            setShowActions('general', true);
                          }}
                          variant={'outlined'}
                          color={'secondary'}
                        />
                      ),
                  },
                ]}
              />
            </Box>
            <Box>
              <Typography variant={'h4'} fontSize={'1.2em'}>
                {t('types.cancellation')}
              </Typography>
              <TranslatableGroup
                sx={{ maxWidth: 680 }}
                langs={storefrontLanguages}
                id={`terms.cancellation`}
                onUpdate={(value, language) => {
                  updateField('cancellation', value, language);
                  setShowActions('cancellation', true);
                }}
                input={{
                  type: 'editor',
                  placeholder: t('inputPlaceholder'),
                }}
                defaultValue={defaultValues?.cancellation}
                renderControls={[
                  {
                    position: 'below',
                    render: (selectedLang, onUpdate) =>
                      showCancellationActions ? (
                        <ConfirmButtons
                          onSave={() => {
                            saveChanges('cancellation');
                            setShowActions('cancellation', false);
                          }}
                          onCancel={() => {
                            onUpdate(
                              defaultValues?.cancellation[selectedLang] ?? '',
                              true
                            );
                            setShowActions('cancellation', false);
                          }}
                        />
                      ) : (
                        <ProgressButton
                          label={t('insertFromTemplate')}
                          sx={{ mt: 2 }}
                          onClick={async () => {
                            const content = await handleCreateFromTemplate(
                              'cancellation',
                              selectedLang
                            );

                            onUpdate(content, true);
                            setShowActions('cancellation', true);
                          }}
                          variant={'outlined'}
                          color={'secondary'}
                        />
                      ),
                  },
                ]}
              />
            </Box>

            <Box>
              <Typography variant={'h4'} fontSize={'1.2em'}>
                {t('types.privacy')}
              </Typography>
              <TranslatableGroup
                sx={{ maxWidth: 680 }}
                langs={storefrontLanguages}
                id={`terms.privacy`}
                onUpdate={(value, language) => {
                  updateField('privacy', value, language);
                  if (value !== defaultValues?.privacy[language])
                    setShowActions('privacy', true);
                }}
                input={{
                  type: 'editor',
                  placeholder: t('inputPlaceholder'),
                }}
                defaultValue={defaultValues?.privacy}
                renderControls={[
                  {
                    position: 'below',
                    render: (selectedLang, onUpdate) =>
                      showPrivacyActions ? (
                        <ConfirmButtons
                          onSave={() => {
                            saveChanges('privacy');
                            setShowActions('privacy', false);
                          }}
                          onCancel={() => {
                            onUpdate(
                              defaultValues?.privacy[selectedLang] ?? '',
                              true
                            );
                            setShowActions('privacy', false);
                          }}
                        />
                      ) : (
                        <ProgressButton
                          label={t('insertFromTemplate')}
                          sx={{ mt: 2 }}
                          onClick={async () => {
                            const content = await handleCreateFromTemplate(
                              'privacy',
                              selectedLang
                            );

                            onUpdate(content, true);
                            setShowActions('privacy', true);
                          }}
                          variant={'outlined'}
                          color={'secondary'}
                        />
                      ),
                  },
                ]}
              />
            </Box>
          </>
        )}
      </Stack>
    </>
  );
};

const ConfirmButtons = ({
  onSave,
  onCancel,
  ...props
}: StackProps & { onSave: () => void; onCancel: () => void }) => {
  const { t } = useTranslate('buttons');

  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'} {...props}>
      <Button startIcon={<CheckRounded />} color={'success'} onClick={onSave}>
        {t('save')}
      </Button>
      <Button startIcon={<CancelRounded />} color={'error'} onClick={onCancel}>
        {t('undo')}
      </Button>
    </Stack>
  );
};
