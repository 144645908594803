import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { disconnectPayment } from '../../../../../Api/Api';
import { OnBoardingData } from '../../../../../Api/OnBoarding';
import { useOnBoarding } from '../../../../../Hooks/useOnBoarding';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { IntegrationObject } from '../use-integrations';
import { isQuickpayAllowed } from './payment-integration-permissions';

type QuickpayData = {
  onboarding: OnBoardingData;
};

export type UseQuickpayIntegrationReturn = ReturnType<
  typeof useQuickpayIntegration
>;

export const useQuickpayIntegration = ():
  | (IntegrationObject & Partial<QuickpayData>)
  | null => {
  const { t } = useTranslate('settings.integrations');
  const flags = useFlags();

  const { company } = useProfile();

  const { onBoarding } = useOnBoarding();
  const navigate = useNavigate();

  const quickpay = useMemo(
    () =>
      onBoarding.data?.response['payment-quickpay']?.find(
        (el) => el.key === 'setup'
      ),
    [onBoarding.data]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleConnectQuickpay = (_status: string) => async () => {
    navigate('/settings/integrations/quickpay');
  };

  const handleDisconnectQuickpay = useCallback(async () => {
    await disconnectPayment('quickpay');
    await onBoarding.refetch();
  }, [onBoarding]);

  if (
    !flags?.featureQuickpayIntegration ||
    !isQuickpayAllowed(company.data?.location?.country) ||
    onBoarding.isLoading
  )
    return null;

  const isConnected = quickpay?.metadata?.status === 'connected';

  return {
    id: 'quickpay',
    title: t('payments.quickpay.title'),
    description: t('payments.quickpay.description'),
    extendedDescription: t('payments.quickpay.extendedDescription'),
    onboarding: quickpay,
    status: quickpay?.metadata?.status ?? 'connect',
    icon: '/integrations/quickpay.png',
    buttonLabel: (status: string) => t(`economic.${status}`),
    onInstall: handleConnectQuickpay,
    onUninstall: isConnected ? handleDisconnectQuickpay : undefined,
  };
};
