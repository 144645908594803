import { Location } from '@holdbar-com/utils-types';
import { FieldError, useFormContext } from 'react-hook-form';

import { LocationSelect } from '../../../../../../../Components/LocationSelect';
import { trackEventFlowLocationSelected } from '../../../../../../../tracking/events/flow/details/form/trackEventFlowLocationSelected';
import { trackEventFlowLocationSelectionOpened } from '../../../../../../../tracking/events/flow/details/form/trackEventFlowLocationSelectionOpened';
import { useEventUpsert } from '../../../../domain/event_upsert_context';

export const EventUpsertDetailsFormLocationSelect = () => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const selectedExperienceId = watch('experienceId');
  const selectedValue = watch('locationId');
  const error = errors['locationId'] as FieldError | undefined;

  const { updateMultipleEventFields } = useEventUpsert();

  const handleChange = (location: Location) => {
    const formattedLocation = createFormattedAddress(location);

    setValue('locationId', location.locationId, { shouldValidate: true });
    setValue('addresses', formattedLocation, {
      shouldValidate: true,
    });
    updateMultipleEventFields({
      locationId: location.locationId,
      addresses: formattedLocation,
    });
    trackEventFlowLocationSelected(formattedLocation.value);
  };

  return (
    <LocationSelect
      experienceId={selectedExperienceId}
      locationId={selectedValue}
      errorMessage={error?.message}
      isRequired
      onChange={handleChange}
      onOpen={trackEventFlowLocationSelectionOpened}
    />
  );
};

const createFormattedAddress = (location: Location) => {
  const parts = [
    location.address.line1,
    location.address.postalCode,
    location.address.city,
  ]
    .filter(Boolean)
    .map((part) => part?.trim());

  return {
    value: parts.length > 0 ? parts.join(', ') : location.locationName,
    selectedOptionKey: 'custom',
  };
};
