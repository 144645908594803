import languages from '@cospired/i18n-iso-languages';
import da from '@cospired/i18n-iso-languages/langs/da.json';
import de from '@cospired/i18n-iso-languages/langs/de.json';
import en from '@cospired/i18n-iso-languages/langs/en.json';
import no from '@cospired/i18n-iso-languages/langs/no.json';
import sv from '@cospired/i18n-iso-languages/langs/sv.json';
import { Text } from '@holdbar-com/pixel';
import {
  capitalize,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Stack,
  styled,
} from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useExperience } from '../../../../../../../Hooks/useExperience';
import { trackEventFlowLanguageSelected } from '../../../../../../../tracking/events/flow/details/form/trackEventFlowLanguageSelected';
import { trackEventFlowLanguageSelectionOpened } from '../../../../../../../tracking/events/flow/details/form/trackEventFlowLanguageSelectionOpened';
import { useEventUpsert } from '../../../../domain/event_upsert_context';

export const EventUpsertDetailsFormLanguageSelect = () => {
  // Manually register languages using @cospired/i18n-iso-languages
  // This is a workaround for the fact that the library does not support
  // tree-shaking, and thus imports all languages by default
  // See: https://www.npmjs.com/package/@cospired/i18n-iso-languages?activeTab=readme
  useEffect(() => {
    try {
      languages.registerLocale(da);
      languages.registerLocale(en);
      languages.registerLocale(sv);
      languages.registerLocale(no);
      languages.registerLocale(de);
    } catch (error) {
      console.error('Failed to register language locales:', error);
    }
  }, []);

  const { t, i18n } = useTranslation();
  const { watch, register, setValue } = useFormContext();

  const selectedExperienceId: string = watch('experienceId');
  const selectedLanguages = watch('languages') || [];

  const { currentEvent, updateEventField } = useEventUpsert();

  const {
    experience: { data: experience, isLoading, isError },
  } = useExperience(selectedExperienceId);

  const languageNames = experience?.languages
    ? getLanguageNames(experience.languages, i18n.language)
    : {};

  const label =
    selectedLanguages?.length > 1
      ? t('eventUpsert.flow.details.form.language.label.plural')
      : t('eventUpsert.flow.details.form.language.label.singular');

  useEffect(() => {
    if (!currentEvent.languages.length && experience?.languages?.length) {
      setValue('languages', experience.languages);
      updateEventField('languages', experience.languages);
    }
  }, [experience]);

  if (isLoading && !experience) {
    return <Skeleton variant="rounded" height={56} width="100%" />;
  }

  if (isError || !experience) {
    return null;
  }

  if (experience.languages.length === 0) {
    return null;
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="details-form-language-select-label" shrink>
        {label}
      </InputLabel>
      <Select
        {...register('languages')}
        labelId="details-form-language-select-label"
        multiple
        value={Array.isArray(selectedLanguages) ? selectedLanguages : []}
        input={<OutlinedInput notched label={label} />}
        onChange={(e) => {
          setValue('languages', e.target.value);
          updateEventField('languages', e.target.value as string[]);
          trackEventFlowLanguageSelected(e.target.value as string[]);
        }}
        onOpen={trackEventFlowLanguageSelectionOpened}
        displayEmpty
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 600,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        renderValue={(selected) =>
          !selected?.length
            ? t('eventUpsert.flow.details.form.language.noLanguage')
            : selected
                .map((language: string) =>
                  languageNames[language]
                    ? capitalize(languageNames[language])
                    : ''
                )
                .join(', ')
        }
      >
        <MenuItem
          onClick={() => {
            setValue('languages', []);
            updateEventField('languages', []);
          }}
        >
          <StyledText fontSize="small">
            {t('eventUpsert.flow.details.form.language.noLanguage')}
          </StyledText>
        </MenuItem>
        <Divider variant="middle" />
        {experience?.languages?.map((language: string) => (
          <MenuItem key={language} value={language}>
            <Stack direction="row" alignItems="center" gap={1}>
              <StyledCheckbox checked={selectedLanguages?.includes(language)} />
              <StyledText fontSize="small" textTransform="capitalize">
                {languageNames[language]}
              </StyledText>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const getLanguageNames = (
  languagesList: string[] = [],
  currentLang: string
): Record<string, string> => {
  return languagesList.reduce(
    (acc: Record<string, string>, language: string) => {
      acc[language] = languages.getName(language, currentLang) || language;
      return acc;
    },
    {} as Record<string, string>
  );
};

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  margin: 0;
`;

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
`;
