import { Box, BoxProps } from '@mui/material';

import { LOGO_DEFAULT, LOGO_WHITE } from '../../Utils/constants';

export const Logo = ({
  height = 24,
  appearance = 'default',
  ...props
}: BoxProps & {
  appearance?: 'default' | 'white';
}) => {
  return (
    <Box
      component={'img'}
      src={appearance === 'white' ? LOGO_WHITE : LOGO_DEFAULT}
      height={height}
      alt={''}
      {...props}
    />
  );
};
