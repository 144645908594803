import { IRole } from '../Hooks/data/useRoles';
import { apiServices } from './apiServices';
import api, { IShallowSuccessResponse } from './index';
import { HoldbarApiError } from './Invitation';

export interface IUser {
  username?: string;
  password?: string;
  accessToTracks: [string];
  _id: string;
}

export const updatePassword = async (id: string, password: string) => {
  if (typeof apiServices['update-password'] === 'undefined') {
    throw new Error('Environment variable REACT_APP_API_UPDATE is not defined');
  }

  const { data } = await api.put<IShallowSuccessResponse>(
    `${apiServices['update-password']}/user/${id}/password`,
    { password }
  );

  return data;
};

export const update = async (id: string, role: string) => {
  try {
    if (typeof apiServices.accounts === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
      );
    }

    const { data } = await api.put<{ claims: { role: string } }>(
      `${apiServices.accounts}/company/users/${id}`,
      { claims: { role } }
    );

    return data;
  } catch (error) {
    const apiError = error as HoldbarApiError;
    throw new Error(apiError.response?.data.error);
  }
};

export const getRoles = async (language: string) => {
  if (typeof apiServices.accounts === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { data } = await api.get<{
    items: IRole[];
  }>(`${apiServices.accounts}/roles`, {
    params: {
      lang: language,
    },
  });

  return data.items;
};
