import { Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';

import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { BookingDetailsSummaryNotesForm } from './BookingDetailsSummaryNotesForm';

export const BookingDetailsSummaryNotesMobile = () => {
  const { t } = useTranslate('dialogs.booking');

  return (
    <Stack gap={2}>
      <Text fontSize="small" variant="medium">
        {t('sections.notes')}
      </Text>
      <BookingDetailsSummaryNotesForm />
    </Stack>
  );
};
