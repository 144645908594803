import { lightTheme, Text } from '@holdbar-com/pixel';
import { ChevronRightRounded } from '@mui/icons-material';
import { Card, Grid, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { TBoughtVoucher } from '../../../../Hooks/useVoucher';
import routes from '../../../../Utils/routes';
import { useGiftCardsList } from './domain/use_giftcards_list';

export const GiftCardsListItemDesktop = ({
  giftCard,
}: {
  giftCard: TBoughtVoucher;
}) => {
  const { t } = useTranslate('giftcards');
  const { listColumns } = useGiftCardsList();

  return (
    <Link to={`/giftcard/${giftCard.id}`}>
      <Grid
        container
        key={giftCard.id}
        component={Card}
        p={1.5}
        alignItems={'center'}
        sx={{
          cursor: 'pointer',
          '&:hover': { backgroundColor: lightTheme.palette.neutral.n100 },
        }}
      >
        {listColumns.map((el) => (
          <Grid
            key={el.key}
            item
            {...el.props}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Text fontSize="small">{el.value?.(giftCard, t)}</Text>
          </Grid>
        ))}
      </Grid>
    </Link>
  );
};

export const GiftCardsListItemMobile = ({
  giftCard,
}: {
  giftCard: TBoughtVoucher;
}) => {
  const { t } = useTranslate('giftcards');
  const { listColumns } = useGiftCardsList();

  return (
    <Link to={routes.giftCard.details(giftCard.id).index}>
      <Stack
        key={giftCard.id}
        direction={'row'}
        justifyContent={'space-between'}
        py={3}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: lightTheme.palette.neutral.n100,
          },
        }}
      >
        <Text>
          {listColumns.find((el) => el.key === 'created')?.value?.(giftCard, t)}
        </Text>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Text>
            {listColumns.find((el) => el.key === 'code')?.value?.(giftCard, t)}
          </Text>
          <ChevronRightRounded htmlColor={lightTheme.palette.neutral.n300} />
        </Stack>
      </Stack>
    </Link>
  );
};
