import { Text } from '@holdbar-com/pixel';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { SERIALIZED_PREFIX, SERIALIZED_SYMBOL } from '../../../../Api';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useCountryVatRates } from '../../../../Hooks/useCountryVatRates';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { useEconomicVatAccounts } from '../../data/use-economic-vat-accounts';
import { EconomicConfigurationSectionTitle } from '../economic-configuration-section-title';

export const EconomicConfigurationVatCodes = () => {
  const { t } = useTranslate(
    'settings.integrations.economic.configuration.vatCodes'
  );
  const { isSm } = useResponsive();

  const { data: economicVatAccounts } = useEconomicVatAccounts();

  const { data: vatRatesByCountry, isLoading, isError } = useCountryVatRates();
  const { company } = useProfile();

  const { control } = useFormContext();

  if (isError || company.isError) {
    return null;
  }

  if (isLoading || company.isLoading || !vatRatesByCountry || !company.data) {
    return <Skeleton width="100%" height={38} variant="rounded" />;
  }

  // Assume not VAT registered if no VAT registrations
  if (company.data.vatCompliance.vatRegistrations.length === 0) {
    return null;
  }

  const countryVatCodes = Array.from(
    new Set(
      company.data.vatCompliance.vatRegistrations
        .flatMap(
          (vatRegistration) =>
            vatRatesByCountry[vatRegistration.country]?.rates ?? []
        )
        .flatMap(({ reduced, superReduced, standard }) =>
          [standard, superReduced ?? []]
            .concat(
              // The VAT response type is pretty hard to work with,
              // because we would have to check the type value before being able to use `rate`
              // so we just cast it to any or fallback to nothing
              reduced.flatMap(
                (reduce) => (Object.values(reduce)[0] as any)?.rate ?? []
              )
            )
            .flat()
        )
    )
  ).sort();

  return (
    <Stack gap={2.5}>
      <EconomicConfigurationSectionTitle
        title={t('title')}
        description={t('description')}
      />
      <Grid container spacing={2}>
        {countryVatCodes.map((vatRate) => (
          <Controller
            key={vatRate}
            render={({ field, fieldState }) => {
              if (!economicVatAccounts) {
                return (
                  <Grid item>
                    <Skeleton
                      variant="rounded"
                      width={isSm ? '100%' : 300}
                      height={48}
                    />
                  </Grid>
                );
              }

              const vatRateInPercentage = vatRate * 100;
              const relevantVatAccounts = economicVatAccounts.items.filter(
                (account) => account.ratePercentage === vatRate * 100
              );

              return (
                <Grid item xs={isSm ? 12 : 'auto'}>
                  <FormControl
                    sx={{ width: isSm ? '100%' : 300 }}
                    error={!!fieldState.error}
                    size="small"
                    disabled={relevantVatAccounts.length === 0}
                  >
                    <InputLabel id={`vat-code-${vatRateInPercentage}`}>
                      {vatRateInPercentage}%
                    </InputLabel>
                    <Select
                      onChange={field.onChange}
                      value={field.value ?? null}
                      size="small"
                      labelId={`vat-code-${vatRateInPercentage}`}
                      label={`${vatRateInPercentage}%`}
                      error={!!fieldState.error}
                      sx={{ height: 48 }}
                    >
                      {relevantVatAccounts.map((vatAccount) => (
                        <MenuItem
                          value={vatAccount.vatCode}
                          key={vatAccount.vatCode}
                        >
                          <Stack
                            gap={1}
                            flexDirection="row"
                            alignItems="center"
                          >
                            <Text fontSize="small" variant="medium">
                              {vatAccount.vatCode}
                            </Text>
                            <Text fontSize="small">{vatAccount.name}</Text>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                    {relevantVatAccounts.length === 0 && (
                      <FormHelperText>
                        {t('noAccountsFound', {
                          vatRateInPercentage,
                        })}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              );
            }}
            control={control}
            name={`vatCodes.${SERIALIZED_PREFIX}${vatRate.toString().replaceAll('.', SERIALIZED_SYMBOL)}`}
          />
        ))}
      </Grid>
    </Stack>
  );
};
