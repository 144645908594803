import { Button, lightTheme } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';

export const CancelEventActions = ({
  booked,
  isBookingsLoading,
  prevCancellationRefundChoice,
}: {
  booked: boolean;
  isBookingsLoading: boolean;
  prevCancellationRefundChoice?: boolean;
}) => {
  const { t } = useTranslate('dialogs.cancelEvent');
  const { isMd } = useResponsive();

  const prevChoiceExists = prevCancellationRefundChoice !== undefined;

  return (
    <Stack direction={isMd ? 'column' : 'row'} gap={2} justifyContent={'right'}>
      {!prevChoiceExists && booked && (
        <Button
          size="medium"
          variant="secondary"
          value="false"
          type="submit"
          disabled={isBookingsLoading}
          style={{
            color: lightTheme.palette.error.e300,
            border: `1px solid ${lightTheme.palette.error.e300}`,
          }}
        >
          {t('cancelWithoutRefund')}
        </Button>
      )}
      {!prevChoiceExists && (
        <Button
          size="medium"
          variant="danger"
          value={booked ? 'true' : 'false'}
          type="submit"
          disabled={isBookingsLoading}
        >
          {booked ? t('cancelWithRefund') : t('cancel')}
        </Button>
      )}
      {prevChoiceExists && (
        <Button
          size="medium"
          variant="danger"
          value={String(prevCancellationRefundChoice)}
          type="submit"
          disabled={isBookingsLoading}
        >
          {prevCancellationRefundChoice
            ? t('cancelWithRefund')
            : t('cancelWithoutRefund')}
        </Button>
      )}
    </Stack>
  );
};
