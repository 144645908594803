import { Stack } from '@mui/material';

import { OnboardingWidgetItem } from './onboarding-widget-item/onboarding-widget-item';
import { OnboardingWidgetStepper } from './onboarding-widget-stepper/onboarding-widget-stepper';
import { useOnboardingWidgetContext } from './use-onboarding-widget-context';

export const OnBoardingWidget = () => {
  const { items } = useOnboardingWidgetContext();
  if (!items?.length) return null;

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} gap={6}>
      <OnboardingWidgetItem />
      <OnboardingWidgetStepper />
    </Stack>
  );
};
