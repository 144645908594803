import { useCallback } from 'react';

import { ampli } from '../../../../Ampli';
import FeatureItem from '../../../../Components/FeatureItem/FeatureItem';
import { useProfile } from '../../../../Hooks/useProfile';

const FEATURE_ID = 'giftCards';

export const GiftCardsFeature = () => {
  const { companyProfileUrl } = useProfile();

  const link = companyProfileUrl() + '/giftcards';

  const trackToggling = useCallback((enabled: boolean) => {
    if (enabled) {
      ampli.giftCardPurchaseToggledOn({ feature_enabled: 'giftCards' });
    } else {
      ampli.giftCardPurchaseToggledOff();
    }
  }, []);

  return (
    <FeatureItem feature={FEATURE_ID} link={link} onToggle={trackToggling} />
  );
};
