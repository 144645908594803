import NiceModal from '@ebay/nice-modal-react';
import { MediaItem } from '@holdbar-com/utils-types';
import { Box, Stack } from '@mui/material';
import { isSameDay, setHours, setMinutes } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import randomBytes from 'randombytes';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { generateStorefront } from '../../Api/ai-onboarding';
import { useCreateLocation } from '../../Hooks/data/useLocations';
import { useUpdateEvent } from '../../Hooks/events/useUpdateEvent';
import { useExperience } from '../../Hooks/useExperience';
import { useProfile } from '../../Hooks/useProfile';
import { trackExperienceCreated } from '../../tracking/experiences/flow/step-events';
import { trackQuickSetupStepCompleted } from '../../tracking/quick-setup/global-events';
import { ILocationView } from '../../types/api/location';
import { validUrl } from '../../Utils/helpers';
import { GeneratingExperienceDialog } from './ai-flow/generating-experience-dialog';
import { useOnboardingFlow } from './onboarding-context';
import { OnboardingFormActions } from './onboarding-form-actions';
import {
  SetupProgress,
  SubmitSetupProgress,
} from './onboarding-submit-progress';
import { StartManualStep } from './steps/start-manual';

export type OnboardingFormInputs = {
  domain?: string;
  experienceId: string;
  experienceName?: string;
  experienceDescription?: string;
  experienceMedia?: MediaItem[];
  eventStartDate: string;
  eventStartTime: string;
  eventEndDate: string;
  eventEndTime: string;
  experienceLocation?: string;
  tickets?: { name: string; price: number }[];
};

export const OnboardingFlow = () => {
  const {
    step,
    stepCount,
    nextStep,
    currentStepKey,
    StepComponent,
    setStep,
    closeSetupFlow,
  } = useOnboardingFlow();

  const formMethods = useForm<OnboardingFormInputs>({
    defaultValues: {
      experienceId: randomBytes(16).toString('hex'),
    },
  });

  const { handleSubmit, watch } = formMethods;

  const { i18n } = useTranslation();
  const {
    me: { data: me },
    company,
  } = useProfile();
  const experienceId = watch('experienceId');
  const eventId = useMemo(() => randomBytes(16).toString('hex'), []);
  const locationId = useMemo(() => randomBytes(16).toString('hex'), []);

  const { updateExperience } = useExperience(experienceId);
  const { updateEvent } = useUpdateEvent(eventId);
  const { mutate: createLocation } = useCreateLocation();

  const handleCreateLocation = async ({
    companyId,
    locationName,
    country,
  }: {
    companyId: string;
    locationName: string;
    country: string;
  }) => {
    const locationPayload: ILocationView = {
      locationId,
      companyId,
      address: {
        country,
        city: '',
        line1: '',
        line2: '',
        postalCode: '',
        state: '',
      },
      locationName,
    };

    createLocation(locationPayload);
  };

  const handleCreateExperience = async ({
    companyId,
    name,
    description,
    ticketName,
    ticketPrice,
    media,
    country,
    currency,
  }: {
    companyId: string;
    name: string;
    description: string;
    ticketName: string;
    ticketPrice: number;
    media: MediaItem[];
    country: string;
    currency: string;
  }) => {
    const experiencePayload = {
      id: experienceId,
      tagIds: [],
      visibility: 'public',
      companyId,
      status: 'active',
      deactivateBeforeDays: { selectedOptionKey: 'no' },
      cutoffTimeSeconds: 0,
      headline: { [i18n.language]: name ?? '' },
      practicalInfo: { [i18n.language]: description ?? '' },
      locationIds: [locationId],
      dates: {
        created: new Date().toISOString(),
        modified: new Date().toISOString(),
      },
      languages: [i18n.language],
      pictures: {
        cover: media,
      },
      media: {
        cover: media,
      },
      seats: {
        type: 'single',
        minParticipants: 1,
        maxParticipants: 20,
        seatCount: 20,
      },
      price: {
        // This can be removed when we don't have any checks on that property anymore
        hasVariants: true,
        priceBreakdown: {
          currency,
          vatAmountCents: 0,
          vatExclusivePriceCents: ticketPrice * 100,
          vatInclusivePriceCents: ticketPrice * 100,
          vatSetting: {
            country,
            exempt: true,
            rate: 0,
            vatCategory: 'passenger-transport',
          },
        },
        value: ticketPrice,
        variants: [
          {
            hasMin: false,
            id: 'c237af59e009d66da6417cbc5ac3303b',
            name: {
              [i18n.language]: ticketName,
            },
            price: ticketPrice,
            priceBreakdown: {
              currency,
              vatAmountCents: 0,
              vatExclusivePriceCents: ticketPrice * 100,
              vatInclusivePriceCents: ticketPrice * 100,
              vatSetting: {
                country,
                exempt: true,
                rate: 0,
                vatCategory: 'passenger-transport',
              },
            },
            vatRate: 0,
          },
        ],
        vatRate: 0,
      },
      customData: {
        selectedOptionKey: 'no',
      },
      infoForGuests: {
        selectedOptionKey: 'no',
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    await updateExperience.mutateAsync(experiencePayload);
    trackExperienceCreated(me?.id);
  };

  const handleCreateEvent = async ({
    startDateTime,
    endDateTime,
    seats,
  }: {
    startDateTime: Date;
    endDateTime: Date;
    seats: number;
  }) => {
    const eventId = randomBytes(16).toString('hex');

    const eventPayload = {
      id: eventId,
      experienceId,
      locationId,
      startDateTime: startDateTime.toISOString(),
      endDateTime: endDateTime.toISOString(),
      isOneDay: isSameDay(startDateTime, endDateTime),
      languages: [i18n.language],
      status: 'active',
      assignedGuides: [],
      seatCount: { selectedOptionKey: 'experienceDefault', value: seats },
      visibility: 'public',
    };

    await updateEvent.mutateAsync(eventPayload);
  };

  const [isCompletingSetup, setisCompletingSetup] = useState(false);
  const [progress, setProgress] = useState<SetupProgress>({
    hasCreatedLocation: false,
    hasUploadedMedia: false,
    hasCreatedExperience: false,
    hasCreatedEvent: false,
  });

  const handleSetupCompleted = async (data: OnboardingFormInputs) => {
    try {
      setisCompletingSetup(true);

      const {
        experienceMedia,
        experienceName,
        experienceDescription,
        tickets,
        eventStartDate,
        eventStartTime,
        eventEndDate,
        eventEndTime,
        experienceLocation,
      } = data;

      if (!company.data) return;

      // Get country based on IP
      const countryCode = await getCountryCodeFromIP();

      await handleCreateLocation({
        companyId: company.data.id,
        locationName: experienceLocation ?? '',
        country: countryCode,
      });

      await new Promise<void>((resolve) =>
        setTimeout(() => {
          setProgress((prev) => ({ ...prev, hasCreatedLocation: true }));
          resolve();
        }, 1000)
      );

      const media = await processMedia(experienceMedia ?? []);

      await new Promise<void>((resolve) =>
        setTimeout(() => {
          setProgress((prev) => ({ ...prev, hasUploadedMedia: true }));
          resolve();
        }, 1000)
      );

      await handleCreateExperience({
        companyId: company.data.id,
        name: experienceName ?? '',
        description: experienceDescription ?? '',
        ticketName: tickets?.[0].name ?? 'Standard',
        ticketPrice: tickets?.[0].price ?? 349,
        media: media as unknown as MediaItem[],
        country: countryCode,
        currency: company.data.defaultCurrency,
      });

      await new Promise<void>((resolve) =>
        setTimeout(() => {
          setProgress((prev) => ({ ...prev, hasCreatedExperience: true }));
          resolve();
        }, 1000)
      );

      const startDateTime = DateWithTime(
        eventStartTime,
        new Date(eventStartDate)
      );
      const endDateTime = DateWithTime(eventEndTime, new Date(eventEndDate));

      await handleCreateEvent({
        startDateTime,
        endDateTime,
        seats: 20,
      });

      await new Promise<void>((resolve) =>
        setTimeout(() => {
          setProgress((prev) => ({ ...prev, hasCreatedEvent: true }));
          resolve();
        }, 1000)
      );
    } catch (error) {
      console.error(error);
    } finally {
      setisCompletingSetup(false);
    }
  };

  const flags = useFlags();

  const onSubmit = async (data: OnboardingFormInputs) => {
    try {
      if (flags.featureAiSetupFlow && currentStepKey === 'start') {
        if (!data.domain || !company.data) {
          throw new Error('Missing domain or company data');
        }

        const companyUrl = data.domain.startsWith('http')
          ? data.domain
          : `https://${data.domain}`;
        if (!validUrl(companyUrl)) {
          throw new Error('Invalid url');
        }

        const response = await generateStorefront({
          companyUrl,
          locale: i18n.resolvedLanguage,
          generate: {
            company: false,
            events: true,
            experience: true,
          },
          ids: {
            companyId: company.data.id,
          },
        });

        return NiceModal.show(GeneratingExperienceDialog, {
          previewId: response.id,
          startManualFlow: () => setStep(1),
          closeSetup: closeSetupFlow,
          url: companyUrl,
        });
      }

      if (currentStepKey === 'price') {
        await handleSetupCompleted(data);
      }

      nextStep();
      trackQuickSetupStepCompleted(currentStepKey, data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        style={{ height: '100%' }}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack
          overflow="hidden"
          sx={{
            paddingX: { xs: 2, md: 7 },
            paddingTop: { xs: 9, md: 7 },
            paddingBottom: { xs: 2, md: 6 },
            position: { md: 'relative' },
            height: '100%',
          }}
        >
          {!['start', 'end'].includes(currentStepKey) && (
            <Stack
              direction="row"
              gap={1}
              marginBottom={5}
              sx={{ display: { xs: 'none', md: 'flex' } }}
              maxWidth="80%"
            >
              {Array(stepCount - 2) // We subtract 2 to not create progress bars for start and end steps
                .fill(undefined)
                .map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      background: index + 1 <= step ? '#1841E9' : '#EDF0FD',
                      opacity: index + 1 === step ? 0.5 : 1,
                      height: '5px',
                      flexGrow: 1,
                    }}
                  />
                ))}
            </Stack>
          )}
          {currentStepKey === 'start' ? (
            flags.featureAiSetupFlow ? (
              <StepComponent />
            ) : (
              <StartManualStep />
            )
          ) : (
            <StepComponent />
          )}
          <SubmitSetupProgress
            isCompletingSetup={isCompletingSetup}
            progress={progress}
          />
          <OnboardingFormActions />
        </Stack>
      </form>
    </FormProvider>
  );
};

const processMedia = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  media: { [key: string]: any }[]
) => {
  return media.map((mediaItem) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { localUrl, promise, stockPhotoIndex, ...props } = mediaItem ?? {};
    return { ...props };
  });
};

/**
 * Gets country code based on IP, uses `DK` as fallback
 * @returns {string} countryCode, Example: `'DK'`.
 */
export const getCountryCodeFromIP = async () => {
  try {
    const ipResponse = await fetch('https://ipapi.co/json/');
    const ipData = (await ipResponse.json()) as {
      country_code: string;
    };
    return ipData.country_code ?? 'DK';
  } catch (error) {
    return 'DK';
  }
};

export const DateWithTime = (timestamp: string, date = new Date()) => {
  const [hour, minute] = timestamp.split(':');
  const hourParsed = parseInt(hour, 10);
  const minuteParsed = parseInt(minute, 10);
  const hourNumber = !isNaN(hourParsed) ? hourParsed : 12;
  const minuteNumber = !isNaN(minuteParsed) ? minuteParsed : 0;

  return setMinutes(setHours(date, hourNumber), minuteNumber);
};
