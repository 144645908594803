import { lightTheme, Text } from '@holdbar-com/pixel';
import { Skeleton, Stack, styled } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { getCompanyDomain } from '../../../../../Api/Domains';
import { CopyLink } from '../../../../../Components/CopyLink/CopyLink';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import {
  trackStorefrontImplementationLinkCopyButtonClicked,
  trackStorefrontImplementationLinkKeyboardCopied,
} from '../../../../../tracking/storefront/implementation-events';
import { environment, isProduction } from '../../../../../Utils/environment';
import { FlowTypeEnum } from '../../domain/types';
import { useBookingFlowSectionContext } from '../../domain/use_booking_flow_section_context';
import { IntegrationLinksDropdowns } from './IntegrationLinksDropdowns';

export const IntegrationLinks = () => {
  const { t } = useTranslate('storefront.bookingflow');

  const {
    selectedFlowType,
    selectedExperience,
    selectedLanguage,
    selectedTags,
  } = useBookingFlowSectionContext();

  const {
    company: { data: company },
    companyProfileUrl,
  } = useProfile();

  const [storefrontURL, setStorefrontURL] = useState<undefined | string>(
    undefined
  );
  const [isFetchingDomain, setIsFetchingDomain] = useState(false);

  useEffect(() => {
    if (!company) return;

    setIsFetchingDomain(true);
    getCompanyDomain(
      typeof selectedExperience === 'string' ||
        selectedFlowType === 'storefront'
        ? company.id
        : selectedExperience.companyId
    )
      .then((domain) => setStorefrontURL('https://' + domain))
      .catch(() => setStorefrontURL(companyProfileUrl()))
      .finally(() => setIsFetchingDomain(false));
    // companyProfileUrl is unstable, so left out of the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, selectedExperience, selectedFlowType]);

  const integrationLink = useMemo(() => {
    if (selectedFlowType === FlowTypeEnum.Storefront) {
      return `${storefrontURL}/${selectedLanguage}${typeof selectedExperience === 'string' ? '' : `/experience/${selectedExperience.experienceId}`}`;
    } else if (selectedFlowType === FlowTypeEnum.Checkout) {
      if (typeof selectedExperience === 'string') return;
      // If there is an ownerExperienceId, the experience is shared and we should use the
      // ownerExperienceId. If not, we use the experienceId
      const experienceId =
        selectedExperience.ownerExperienceId ?? selectedExperience.experienceId;
      const distributorParam = selectedExperience.distributorId
        ? `?distributorId=${selectedExperience.distributorId}`
        : '';
      return `${storefrontURL}/${selectedLanguage}/booking/${experienceId}${distributorParam}`;
    } else if (selectedFlowType === FlowTypeEnum.Widget) {
      const { companyId, selectedExperienceAttribute, distributorAttribute } =
        typeof selectedExperience === 'string'
          ? {
              companyId: company?.id,
              selectedExperienceAttribute: '',
              distributorAttribute: '',
            }
          : {
              // For a shared expeerience we create a widget that matches the
              // original widget, but add a `distributor-company-id` attribute
              companyId: selectedExperience.companyId,
              // We need to use the `ownerExperienceId` instead of the shared experience id, if it is present
              selectedExperienceAttribute: `data-experience-id="${selectedExperience.ownerExperienceId ?? selectedExperience.experienceId}"`,
              distributorAttribute: selectedExperience.distributorId
                ? `distributor-company-id="${selectedExperience.distributorId}"`
                : '',
            };

      const selectedTagsAttribute =
        selectedExperience === 'all' && selectedTags && selectedTags.length > 0
          ? `data-tags="${selectedTags.join(',')}"`
          : '';

      return `<!-- Understory Widget Code -->
          <script src="https://widgets.understory.io/widgets/understory-booking-widget.js${isProduction ? '' : '?environment=' + environment}"></script>
          <div class="understory-booking-widget" data-company-id="${companyId}" ${selectedExperienceAttribute} ${distributorAttribute} ${selectedTagsAttribute} data-language="${selectedLanguage}"></div>
          <!-- End Understory Widget Code -->`;
    }
  }, [
    selectedFlowType,
    storefrontURL,
    selectedLanguage,
    selectedExperience,
    company?.id,
    selectedTags,
  ]);

  return (
    <Stack gap={4} data-intercom-target={'integration-links-section'}>
      <Stack gap={1}>
        <Text variant={'medium'}>{t('links.title')}</Text>
        <Text fontSize={'small'}>{t('links.description')}</Text>
      </Stack>
      <IntegrationLinksDropdowns
        languages={company?.languages}
        selectedExperience={selectedExperience !== 'all'}
        flowType={selectedFlowType}
      />
      <Stack gap={1}>
        {selectedFlowType === FlowTypeEnum.Widget && (
          <Text fontSize={'small'}>{t('links.scriptHelperText')}</Text>
        )}
        <StyledLinkContainer
          data-intercom-target={'integration-link'}
          onCopy={() => {
            trackStorefrontImplementationLinkKeyboardCopied(integrationLink);
          }}
        >
          {!isFetchingDomain ? (
            <>
              <Text style={{ whiteSpace: 'pre-line' }}>{integrationLink}</Text>
              <CopyLink
                href={integrationLink ?? ''}
                label=""
                color={lightTheme.palette.action.a300}
                size={'xlarge'}
                onClickCopy={() =>
                  trackStorefrontImplementationLinkCopyButtonClicked(
                    integrationLink
                  )
                }
              />
            </>
          ) : (
            <Skeleton width="100%" />
          )}
        </StyledLinkContainer>
      </Stack>
    </Stack>
  );
};

const StyledLinkContainer = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: ${lightTheme.palette.contrast.white};
  border: 1px solid ${lightTheme.palette.neutral.n200};
  border-radius: 20px;
  padding: 24px;
  word-break: break-all;
`;
