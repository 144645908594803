import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { ampli } from '../Ampli';
import { PageTabs } from '../Components/Page/PageTabs';
import { SimpleSkeleton } from '../Components/SimpleSkeleton/SimpleSkeleton';
import { useFireOnce } from '../Hooks/useFireOnce';
import { useProfile } from '../Hooks/useProfile';
import { useTerms } from '../Hooks/useTerms';
import { useTranslate } from '../Hooks/useTranslate';

export const SettingsPage = () => {
  const { t } = useTranslate('settings');
  const fireOnce = useFireOnce();

  useEffect(() => {
    fireOnce(() => ampli.settingsPageViewed());
  }, [fireOnce]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { terms } = useTerms();
  const { me, company } = useProfile();
  const flags = useFlags();

  const tabs = [
    {
      key: 'profile',
      scopes: [],
      allowedOnMobile: true,
      trackingFn: () => ampli.profilePageViewed(),
    },
    {
      key: 'company',
      scopes: ['organization.write'],
      trackingFn: () => ampli.companyPageViewed(),
    },
    {
      key: 'users',
      scopes: ['organization.write'],
      trackingFn: () => ampli.teamPageViewed(),
    },
    flags.featureConnectedAccounts && {
      key: 'connect',
      scopes: ['admin'],
      allowedOnMobile: true,
    },
    {
      key: 'integrations',
      scopes: ['organization.write'],
      allowedOnMobile: true,
      trackingFn: () => ampli.integrationsPageViewed(),
    },
    {
      key: 'notifications',
      scopes: ['organization.write'],
      trackingFn: () => ampli.notificationsPageViewed(),
    },
    { key: 'terms', scopes: ['organization.write'] },
  ].filter(Boolean);

  return (
    <PageTabs title={t('title')} tabs={tabs}>
      {!(me.data && company.data) ? <SimpleSkeleton /> : <Outlet />}
    </PageTabs>
  );
};
