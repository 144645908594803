import { useEffect, useState } from 'react';

import { IcalDialogCreateStep } from './steps/create/ical-dialog-create-step';
import { IcalDialogDoneStep } from './steps/done/ical-dialog-done-step';

export enum IcalDialogStep {
  create,
  done,
}

type IcalDialogStepLoaderProps = {
  hasLoadedInitially: boolean;
};

export const IcalDialogStepLoader = ({
  hasLoadedInitially,
}: IcalDialogStepLoaderProps) => {
  const [step, setStep] = useState<IcalDialogStep>(IcalDialogStep.create);

  useEffect(() => {
    if (hasLoadedInitially) setStep(IcalDialogStep.done);
  }, [hasLoadedInitially]);

  const CurrentStep = [IcalDialogCreateStep, IcalDialogDoneStep][step];

  return (
    <CurrentStep hasLoadedInitially={hasLoadedInitially} setStep={setStep} />
  );
};
