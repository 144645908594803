import create from 'zustand';
import { persist } from 'zustand/middleware';

import { TEventFilter } from '../Utils/eventHelpers';

interface Store {
  experienceEventsFilter: TEventFilter;
  setExperienceEventsFilter: (value: TEventFilter) => void;
  currentPage: number;
  setCurrentPage: (value: number) => void;
}

export const useEventStore = create(
  persist<Store>(
    (set) => ({
      experienceEventsFilter: {
        state: 'future',
        status: 'anyStatus',
        locationId: undefined,
      },
      setExperienceEventsFilter: (value) =>
        set({ experienceEventsFilter: value }),
      currentPage: 1,
      setCurrentPage: (value) => set({ currentPage: value }),
    }),
    {
      name: 'holdbar-admin',
      getStorage: () => sessionStorage,
    }
  )
);
