import { useQuery } from '@tanstack/react-query';

import { getEconomicAccounts } from '../../../Api';
import { useProfile } from '../../../Hooks/useProfile';
import { EconomicAccountsQueryKey } from './economic-query-keys';

export const useEconomicAccounts = () => {
  const { company } = useProfile();

  return useQuery({
    queryKey: EconomicAccountsQueryKey,
    queryFn: async () => {
      const accounts = await getEconomicAccounts();

      return accounts.items.filter((account) =>
        ['status', 'profitAndLoss'].includes(account.accountType)
      );
    },
    enabled: !!company.data?.id,
  });
};
