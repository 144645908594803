import { Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useProfile } from '../../../Hooks/useProfile';
import { DashboardOnboarding } from '../dashboard-onboarding/dashboard-onboarding';
import { useDashboardPageContext } from '../use-dashboard-page-context';
import { DashboardGraphsFilter } from './dashboard-graphs-filter/dashboard-graphs-filter';
import { DashboardGraphsLoader } from './dashboard-graphs-loader/dashboard-graphs-loader';
import { DashboardGraphsSkeleton } from './dashboard-graphs-skeleton';
import { DashboardRebrandBanner } from './dashboard-rebrand-banner';

export const DashboardGraphs = () => {
  const { onboardingCompleted } = useProfile();
  const { isError, categoryTotals } = useDashboardPageContext();
  const flags = useFlags();

  if (onboardingCompleted === false) {
    return <DashboardOnboarding />;
  }

  if (
    onboardingCompleted === undefined ||
    (categoryTotals === undefined && !isError)
  ) {
    return <DashboardGraphsSkeleton />;
  }

  return (
    <Stack gap={4}>
      {flags.featureRebrandDashboardBanner && <DashboardRebrandBanner />}
      <DashboardGraphsFilter />
      <DashboardGraphsLoader />
    </Stack>
  );
};
