import NiceModal from '@ebay/nice-modal-react';
import { useCallback, useMemo } from 'react';

import { ampli } from '../../../../Ampli';
import { isStepComplete, useOnBoarding } from '../../../../Hooks/useOnBoarding';
import { useProfile } from '../../../../Hooks/useProfile';
import { DashboardHeaderActionsExperienceModal } from './dashboard-header-actions-experience-modal';

export const useDashboardHeaderActions = () => {
  const { companyProfileUrl } = useProfile();

  const {
    onBoarding: { data: onBoarding },
  } = useOnBoarding();

  const { stripeCompleted, experienceCompleted } = useMemo(
    () => ({
      stripeCompleted: isStepComplete('payment', onBoarding?.items),
      experienceCompleted: isStepComplete('experience', onBoarding?.items),
    }),
    [onBoarding?.items]
  );

  const handleStripeClick = () => {
    ampli.overviewStripeLinkClicked();

    window.open('https://dashboard.stripe.com/dashboard', '_blank');
  };

  const handlePageClick = useCallback(() => {
    ampli.overviewMyHoldbarPageClicked();

    if (!isStepComplete('experience', onBoarding?.items)) {
      return NiceModal.show(DashboardHeaderActionsExperienceModal);
    }

    window.open(companyProfileUrl(), '_blank');
  }, [onBoarding?.items, companyProfileUrl]);

  return {
    stripeCompleted,
    experienceCompleted,
    handleStripeClick,
    handlePageClick,
  };
};
