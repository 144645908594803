import { lightTheme, Text } from '@holdbar-com/pixel';
import { Skeleton, Stack } from '@mui/material';

import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../../../domain/use_booking_details_context';
import { customerRows } from '../../domain/use_booking_details_summary';

export const BookingDetailsSummaryCustomerMobile = () => {
  const { t } = useTranslate('dialogs.booking');

  const { booking, receipt } = useBookingDetailsContext();

  if (!booking) return null;

  return (
    <Stack gap={2} fontSize={lightTheme.typography.small.normal.fontSize}>
      <Text variant="medium">{t('sections.customer')}</Text>
      {booking
        ? customerRows.map((row) => row.value(booking, t, receipt))
        : customerRows.map((row) => <Skeleton key={row.key} width="60%" />)}
    </Stack>
  );
};
