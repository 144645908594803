import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { ampli } from '../../../../Ampli';
import { OnboardingItem, useOnBoarding } from '../../../../Hooks/useOnBoarding';
import { useProfile } from '../../../../Hooks/useProfile';

type OnboardingWidgetState = {
  items: OnboardingItem[];
  step: number;
  direction: number;
  finishing: boolean;
  handleChangeStep: (index: number) => void;
  handleFinishOnboarding: () => void;
};

export const OnboardingWidgetContext =
  createContext<OnboardingWidgetState | null>(null);

export const useOnboardingWidgetContext = () => {
  const context = useContext(OnboardingWidgetContext);
  if (!context) {
    throw new Error(
      'useOnboardingWidgetContext must be used within a OnboardingWidgetContextProvider'
    );
  }
  return context;
};

interface OnboardingWidgetContextProviderProps {
  children: React.ReactNode;
}

export const OnboardingWidgetContextProvider = ({
  children,
}: OnboardingWidgetContextProviderProps) => {
  const {
    onBoarding: { data: { items = [] } = { items: [] } },
  } = useOnBoarding();

  const { finishOnboarding } = useProfile();

  const [[step, direction], setStep] = useState([0, 0]);
  const [finishing, setFinishing] = useState(false);

  const handleChangeStep = useCallback((index: number) => {
    onBoardingStepTrackingFns[index]?.();
    setStep(([current]) => [index, current > index ? 0 : 1]);
  }, []);

  useEffect(() => {
    if (items?.length > 0) {
      const pendingStep = items.findIndex((el) => !el.completed);
      handleChangeStep(pendingStep !== -1 ? pendingStep : 0);
    }
  }, [items, handleChangeStep]);

  const handleFinishOnboarding = async () => {
    ampli.onboardingListDoneButtonClicked();

    setFinishing(true);
    await finishOnboarding.mutateAsync();
    setFinishing(false);
  };

  return (
    <OnboardingWidgetContext.Provider
      value={{
        items,
        step,
        direction,
        finishing,
        handleChangeStep,
        handleFinishOnboarding,
      }}
    >
      {children}
    </OnboardingWidgetContext.Provider>
  );
};

const onBoardingStepTrackingFns = [
  () => ampli.onboardingListExperienceBulletClicked(),
  () => ampli.onboardingListEventBulletClicked(),
  () => ampli.onboardingListPaymentBulletClicked(),
  () => ampli.onboardingListImplementationBulletClicked(),
];
