import type { TDiscount } from '../Hooks/useDiscount';
import { apiServices } from './apiServices';
import api, { IShallowSuccessResponse } from './index';

export const getDiscounts = async () => {
  if (typeof apiServices.discounts === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DISCOUNTS_URL is not defined'
    );
  }

  const { data } = await api.get<TDiscount[]>(`${apiServices.discounts}`);

  return data;
};

export const getDiscount = async (id: string) => {
  if (typeof apiServices.discounts === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DISCOUNTS_URL is not defined'
    );
  }

  if (!id) {
    throw Error('Missing discount id');
  }

  const { data } = await api.get<TDiscount>(`${apiServices.discounts}/${id}`);

  return data;
};

export const updateDiscount = async (
  id: string,
  payload: Record<string, any>
) => {
  if (typeof apiServices.discounts === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DISCOUNTS_URL is not defined'
    );
  }

  const { data } = await api.put<IShallowSuccessResponse>(
    `${apiServices.discounts}/${id}`,
    payload
  );

  return data;
};

export const deleteDiscount = async (id: string) => {
  if (typeof apiServices.discounts === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DISCOUNTS_URL is not defined'
    );
  }

  const { data } = await api.delete<IShallowSuccessResponse>(
    `${apiServices.discounts}/${id}`
  );

  return data;
};
