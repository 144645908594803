import { Box, MenuItem, Select } from '@mui/material';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { trackOverviewIntervalOpened } from '../../../../../tracking/overview/interactions';
import { TimePeriod, timePeriods } from '../../../types';
import { useDashboardPageContext } from '../../../use-dashboard-page-context';
import { renderPeriod } from './helpers';
import { useDashboardGraphsFilterPeriodContext } from './use-dashboard-graphs-filter-period-context';

export const DashboardGraphsFilterPeriodInterval = () => {
  const { t } = useTranslate('dashboard.timePeriods');
  const { isSm } = useResponsive();

  const { fromDate } = useDashboardPageContext();

  const { interval, currentInterval, handleUpdateInterval } =
    useDashboardGraphsFilterPeriodContext();

  return (
    <Select
      labelId="select-interval"
      value={interval}
      onChange={(e) => handleUpdateInterval(e.target.value as TimePeriod)}
      onOpen={() => trackOverviewIntervalOpened()}
      size="small"
      sx={{
        width: isSm ? '100%' : 150,
        minHeight: 40,
      }}
      renderValue={() => (
        <Box style={{ whiteSpace: 'pre-line', fontSize: 'small' }}>
          {renderPeriod(fromDate, currentInterval, t)}
        </Box>
      )}
    >
      {timePeriods
        .filter((period) => !(isSm && period === 'custom'))
        .map((period) => (
          <MenuItem key={period} value={period}>
            {t(period)}
          </MenuItem>
        ))}
    </Select>
  );
};
