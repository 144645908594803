import { ampli } from '../../Ampli';

export const trackStorefrontTagManagementViewed = () =>
  ampli.storefrontTagManagementViewed();

export const trackStorefrontTagManagementNewTagClicked = () =>
  ampli.storefrontTagManagementNewTagClicked();

export const trackStorefrontTagManagementNewTagAdded = () =>
  ampli.storefrontTagManagementNewTagAdded();

export const trackStorefrontTagManagementTagDeleteClicked = () =>
  ampli.storefrontTagManagementTagDeleteClicked();

export const trackStorefrontTagManagementTagDeleted = () =>
  ampli.storefrontTagManagementTagDeleted();

export const trackStorefrontTagManagementTagEditClicked = () =>
  ampli.storefrontTagManagementTagEditClicked();

export const trackStorefrontTagManagementTagEdited = () =>
  ampli.storefrontTagManagementTagEdited();

export const trackStorefrontTagManagementTagAddToClicked = () =>
  ampli.storefrontTagManagementTagAddToClicked();

export const trackStorefrontTagManagementTagAddToCompleted = (
  number_of_experiences: number
) => ampli.storefrontTagManagementTagAddToCompleted({ number_of_experiences });
