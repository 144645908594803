/**
 * This module includes mapping from process.env to app specific environment values.
 * The purpose is to make it possible to change process.env names easier + make it type safe across multiple files.
 */

export type Environment = 'production' | 'development';

export const environment: Environment = (process.env.REACT_APP_ENVIRONMENT ??
  'development') as Environment;

export const isProduction = environment === 'production';

export const getHostingEnvironmentName = () => {
  const hostname = window.location.hostname;

  if (hostname.includes('localhost')) {
    return 'local';
  }

  if (hostname.includes('dev')) {
    return 'development';
  }

  if (hostname.includes('staging')) {
    return 'staging';
  }

  if (
    [
      'app.holdbar.com',
      'backoffice.holdbar.com',
      'app.understory.io',
      'backoffice.understory.io',
    ].includes(hostname)
  ) {
    return 'production';
  }

  return 'local';
};
