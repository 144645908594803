import { ampli } from '../../Ampli';
import { FlowTypeEnum } from '../../Sections/StorefrontSections/BookingFlowSection/domain/types';

export const trackStorefrontImplementationViewed = () =>
  ampli.storefrontImplementationViewed();

export const trackStorefrontImplementationExamplesClicked = () =>
  ampli.storefrontImplementationExamplesClicked();

export const trackStorefrontImplementationExperienceOpened = () =>
  ampli.storefrontImplementationExperienceOpened();

export const trackStorefrontImplementationExperienceSelected = (
  selected_experience: string
) =>
  ampli.storefrontImplementationExperienceSelected({
    selected_experience,
  });

export const trackStorefrontImplementationFlowTypeChanged = (
  selected_flow_type: FlowTypeEnum
) => ampli.storefrontImplementationFlowTypeChanged({ selected_flow_type });

export const trackStorefrontImplementationLanguageOpened = () =>
  ampli.storefrontImplementationLanguageOpened();

export const trackStorefrontImplementationLanguageSelected = (
  language: string
) => ampli.storefrontImplementationLanguageSelected({ language });

export const trackStorefrontImplementationLinkCopyButtonClicked = (
  integration_link?: string
) =>
  ampli.storefrontImplementationLinkCopyButtonClicked({
    integration_link,
  });

export const trackStorefrontImplementationLinkKeyboardCopied = (
  integration_link?: string
) =>
  ampli.storefrontImplementationLinkKeyboardCopied({
    integration_link,
  });

export const trackStorefrontImplementationTagsOpened = () =>
  ampli.storefrontImplementationTagsOpened();
export const trackStorefrontImplementationTagsSelected = (tags: string[]) =>
  ampli.storefrontImplementationTagsSelected({
    tags: tags,
  });
