import { useState } from 'react';

import { ContextMenu } from '../../../../../../Components/context-menu/context-menu';
import { ContextMenuItemProps } from '../../../../../../Components/context-menu/context-menu-item';

type BookingDetailsActionsMobileProps = {
  options: ContextMenuItemProps[];
};

export const BookingDetailsActionsMobile = ({
  options,
}: BookingDetailsActionsMobileProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <ContextMenu
      type="button"
      open={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
      options={options}
    />
  );
};
