import { Button } from '@holdbar-com/pixel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useResponsive from '../../../../../../Hooks/layout/useResponsive';
import { useExperience } from '../../../../../../Hooks/useExperience';
import { trackEventFlowDetailsStepCompleted } from '../../../../../../tracking/events/flow/details/trackEventFlowDetailsStepCompleted';
import { useEventUpsert } from '../../../domain/event_upsert_context';
import { EventUpsertContent } from '../../layout/EventUpsertContent';
import { EventDetailsUpsertForm } from './details_form/EventUpsertDetailsForm';
import {
  DetailsFormData,
  useDetailsFormValidation,
} from './details_form/use_details_form_validation';

type EventUpsertDetailsProps = {
  onSubmit: () => void;
};

export const EventUpsertDetails = ({ onSubmit }: EventUpsertDetailsProps) => {
  const { t } = useTranslation();
  const { currentEvent, updateMultipleEventFields } = useEventUpsert();
  const { isSm } = useResponsive();
  const {
    experiences: { data: experiences },
  } = useExperience(undefined, { type: 'owned' });

  const formValidationSchema = useDetailsFormValidation(t, currentEvent);
  const form = useForm<DetailsFormData>({
    resolver: yupResolver(formValidationSchema),
  });

  useEffect(() => {
    form.reset({
      experienceId: currentEvent.experienceId,
      locationId: currentEvent.locationId,
      seatCount: currentEvent.seatCount,
      addresses: currentEvent.addresses,
      languages: currentEvent.languages,
    } as DetailsFormData);
  }, [
    currentEvent.addresses,
    currentEvent.experienceId,
    currentEvent.locationId,
    currentEvent.seatCount,
    currentEvent.languages,
    form,
  ]);

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const handleNextStep = () => {
    const nextStep =
      currentEvent.visibility === 'public' || currentEvent.variant === 'edit'
        ? 'time'
        : 'booking';

    updateMultipleEventFields({
      ...form.getValues(),
      step: nextStep,
    });

    trackEventFlowDetailsStepCompleted(
      form.getValues(),
      currentEvent,
      experiences
    );
  };

  const handleSaveAndClose = handleSubmit(async () => {
    updateMultipleEventFields({
      ...form.getValues(),
    });

    onSubmit();
  });

  const action = (
    <Button
      size="medium"
      variant="primary"
      width={isSm ? 'auto' : 208}
      disabled={Object.keys(errors).length > 0}
      onClick={handleSubmit(() => handleNextStep())}
    >
      {t('eventUpsert.actions.nextStep')}
    </Button>
  );

  return (
    <FormProvider {...form}>
      <EventUpsertContent
        title={t('eventUpsert.flow.details.title')}
        action={action}
        hasInvalidInput={Object.keys(errors).length > 0}
        handleSaveAndClose={handleSaveAndClose}
      >
        <EventDetailsUpsertForm />
      </EventUpsertContent>
    </FormProvider>
  );
};
