const COUNTRY_CODE_GREENLAND = 'GL';
const COUNTRY_CODE_FAROE_ISLANDS = 'FO';

export function isStripeAllowed(countryCode: string | undefined | null) {
  return (
    countryCode !== COUNTRY_CODE_GREENLAND &&
    countryCode !== COUNTRY_CODE_FAROE_ISLANDS
  );
}

export function isQuickpayAllowed(countryCode: string | undefined | null) {
  return (
    countryCode === COUNTRY_CODE_GREENLAND ||
    countryCode === COUNTRY_CODE_FAROE_ISLANDS
  );
}
