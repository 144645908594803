import NiceModal from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { ExperienceTag } from '@holdbar-com/utils-types';
import { AddRounded, CheckRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useController } from 'react-hook-form';

import { getLocalized } from '../../../Hooks/useBookings';
import { useExperienceTags } from '../../../Hooks/useExperienceTags';
import { useTranslate } from '../../../Hooks/useTranslate';
import { CreateTagDialog } from '../../StorefrontSections/tag-management/dialogs/create-tag';

export const ExperienceTagsSection = () => {
  const { t, i18n } = useTranslate('experience.tags.section');

  const {
    tags: { data: tags },
  } = useExperienceTags();

  const { field: tagIds } = useController({
    name: 'tagIds',
    defaultValue: [],
  });

  const handleSelect = (id: ExperienceTag['id']) => {
    if (tagIds.value.includes(id)) {
      const filterValue = [...tagIds.value].filter((tagId) => tagId !== id);
      return tagIds.onChange(filterValue);
    }
    return tagIds.onChange([...tagIds.value, id]);
  };

  return (
    <Stack gap={1}>
      <Stack>
        <Text variant="medium">{t('title')}</Text>
        <Text fontSize="small">{t('description')}</Text>
      </Stack>
      <Stack alignItems="flex-start" gap={2}>
        {!tags || !tags.length ? (
          <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
            {t('noTagsAdded', 'experience.tags.section')}
          </Text>
        ) : (
          <Stack direction="row" gap={1} flexWrap="wrap">
            {tags?.map((tag) => {
              const isSelected = tagIds.value.includes(tag.id);

              return (
                <TagChip
                  key={tag.id}
                  selected={isSelected}
                  onClick={() => handleSelect(tag.id)}
                >
                  {getLocalized(tag.name, i18n.language) ??
                    Object.values(tag.name)[0]}{' '}
                  {isSelected && <CheckRounded />}
                </TagChip>
              );
            })}
          </Stack>
        )}
        <Button
          type="button"
          variant="secondary"
          size="medium"
          leftIcon={<AddRounded />}
          onClick={() => NiceModal.show(CreateTagDialog)}
        >
          {t('buttonLabel', 'storefront.tags.header')}
        </Button>
      </Stack>
    </Stack>
  );
};

const TagChip = styled.button<{ selected: boolean }>`
  color: ${(props) =>
    props.selected
      ? lightTheme.palette.action.a300
      : lightTheme.palette.contrast.black};
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  background: ${(props) =>
    props.selected
      ? lightTheme.palette.action.a100
      : lightTheme.palette.contrast.white};
  border-width: 1px;
  border-style: solid;
  border-radius: 40px;
  border-color: ${(props) =>
    props.selected
      ? lightTheme.palette.action.a300
      : lightTheme.palette.neutral.n300};
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  height: 40px;
`;
