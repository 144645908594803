import { ampli } from '../../Ampli';

export const trackStorefrontCustomizeViewed = () =>
  ampli.storefrontCustomizeViewed();

export const trackStorefrontCustomizeStylingFontOpened = () =>
  ampli.storefrontCustomizeStylingFontOpened();

export const trackStorefrontCustomizeStylingFontSelected = (font: string) =>
  ampli.storefrontCustomizeStylingFontSelected({ font });

export const trackStorefrontCustomizeStylingColorFieldChanged = (
  color: string
) => ampli.storefrontCustomizeStylingColorFieldChanged({ color });

export const trackStorefrontCustomizeStylingColorPickerOpened = () =>
  ampli.storefrontCustomizeStylingColorPickerOpened();

export const trackStorefrontCustomizeStylingButtonChanged = (
  button_style: string
) => ampli.storefrontCustomizeStylingButtonChanged({ button_style });

export const trackStorefrontCustomizeOrderDropdownOpened = () =>
  ampli.storefrontCustomizeOrderDropdownOpened();

export const trackStorefrontCustomizeOrderDropdownSelected = (
  order_type: string
) => ampli.storefrontCustomizeOrderDropdownSelected({ order_type });

export const trackStorefrontCustomizeFormSaved = () =>
  ampli.storefrontCustomizeFormSaved();

export const trackStorefrontCustomizeFormDiscarded = () =>
  ampli.storefrontCustomizeFormDiscarded();
