import { Stack } from '@mui/material';
import { motion } from 'framer-motion';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { fadeInAnimation } from '../../animations';
import { DashboardRequestReport } from '../../dashboard-header/dashboard-header-actions/dashboard-request-report';
import { DashboardGraphsFilterExperience } from './experience/dashboard-graphs-filter-experience';
import { DashboardGraphsFilterPeriod } from './period/dashboard-graphs-filter-period';

export const DashboardGraphsFilter = () => {
  const { isSm, isMd } = useResponsive();

  return (
    <motion.div {...fadeInAnimation}>
      <Stack
        gap={2}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={isMd ? 'flex-start' : 'center'}
        width={'100%'}
      >
        <Stack
          gap={isSm ? 1 : 2}
          direction={!isSm && isMd ? 'column' : 'row'}
          flex={1}
        >
          <DashboardGraphsFilterExperience />
          <DashboardGraphsFilterPeriod />
        </Stack>
        {!isSm && <DashboardRequestReport />}
      </Stack>
    </motion.div>
  );
};
