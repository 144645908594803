import { useTranslation } from 'react-i18next';

export type LocalizedString = Record<string, string>;

export type LocalizerFunction = (
  value: string | LocalizedString | undefined | null,
  defaultValue?: string
) => string;

export const getLocalizedString = (
  value: string | LocalizedString | undefined | null,
  language: string,
  defaultValue: string = ''
) => {
  if (!value) return defaultValue;

  if (typeof value === 'string') {
    return value || defaultValue;
  }

  return value[language] || Object.values(value)[0] || defaultValue;
};

export const useLocalizedStringFormatter = (): LocalizerFunction => {
  const { i18n } = useTranslation();

  return (value, defaultValue = '') =>
    getLocalizedString(value, i18n.language, defaultValue);
};
