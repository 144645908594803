import { Button } from '@holdbar-com/pixel';
import { AutoAwesomeRounded } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { generateStorefront } from '../../../../Api/ai-onboarding';
import { validUrl } from '../../../../Utils/helpers';
import { PreviewFormData } from './use-preview-form-validation';

export const PreviewFormSubmit = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);

  const form = useFormContext<PreviewFormData>();

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = async ({ website }: PreviewFormData) => {
    setIsLoading(true);
    try {
      website = website.startsWith('http') ? website : `https://${website}`;
      if (!validUrl(website)) {
        throw new Error('Invalid url');
      }

      const response = await generateStorefront({
        companyUrl: website,
        locale: i18n.resolvedLanguage,
        generate: {
          company: true,
          events: true,
          experience: true,
        },
      });

      navigate(`/generating-preview/${response.id}`);
    } catch (err) {
      console.log(err);
      toast.error(t('auth.preview.previewForm.error'));
      setIsLoading(false);
    }
  };

  const buttonContent = isLoading ? (
    <CircularProgress size="1em" color="inherit" />
  ) : (
    t('auth.preview.actions.getStarted')
  );

  return (
    <motion.div
      initial={{ opacity: 0.4, width: '85%' }}
      animate={{ opacity: 1, width: '100%' }}
      transition={{ duration: 1.2 }}
    >
      <Button
        type="submit"
        fullWidth
        size="medium"
        variant="primary"
        disabled={Object.keys(errors).length > 0 || isLoading}
        rightIcon={!isLoading && <AutoAwesomeRounded fontSize="small" />}
        onClick={handleSubmit(onSubmit)}
      >
        {buttonContent}
      </Button>
    </motion.div>
  );
};
