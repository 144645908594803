import { apiServices } from './apiServices';
import api from './index';

export const getSearchResults = async (query: string) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await api.get(apiServices.internalApi + '/v1/search', {
    params: { term: query },
  });

  return data;
};
