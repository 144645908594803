import NiceModal from '@ebay/nice-modal-react';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { isVirtualId } from '@holdbar-com/utils-events';
import {
  ArrowDropDownRounded,
  DateRange,
  EventBusyOutlined,
  EventOutlined,
  EventRepeatRounded,
  LockOutlined,
  MoreVert,
  SvgIconComponent,
} from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import PersonIcon from '@mui/icons-material/Person';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import {
  Avatar,
  Box,
  BoxProps,
  Card,
  Grid,
  GridProps,
  IconButton,
  MenuItem,
  MenuItemProps,
  Pagination,
  Select,
  Skeleton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { Popover } from '@radix-ui/themes';
import { animated, useTransition } from '@react-spring/web';
import { UseQueryResult } from '@tanstack/react-query';
import { easeCubic } from 'd3-ease';
import { format } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  EventDeletionError,
  isEventDeletionError,
} from '../../features/events/event_delete/domain/errors';
import { showEventDeletionErrorDialog } from '../../features/events/event_delete/ui/dialogs';
import { useGetLocationsByExperienceId } from '../../Hooks/data/useLocations';
import { useCancelEvent } from '../../Hooks/events/useCancelEvent';
import { useDeleteEvent } from '../../Hooks/events/useDeleteEvent';
import {
  EVENT_LIST_MAX_EVENTS_PER_PAGE,
  useExperienceEvents,
} from '../../Hooks/events/useExperienceEvents';
import { useUpdateEvent } from '../../Hooks/events/useUpdateEvent';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useErrorRetry } from '../../Hooks/useErrorRetry';
import { useExperience } from '../../Hooks/useExperience';
import { useTranslate } from '../../Hooks/useTranslate';
import { TUser, useUsers } from '../../Hooks/useUsers';
import { CancelEventDialog } from '../../Modals/cancel_event';
import {
  CancellationStates,
  ICancelEventData,
} from '../../Modals/cancel_event/domain/types';
import { ConfirmDialog } from '../../Modals/ConfirmDialog';
import { showConfirmDialogProps } from '../../Modals/ConfirmDialog';
import { OptionsDialog } from '../../Modals/OptionsDialog';
import { useEventStore } from '../../Store/useEventListStore';
import { trackEventFlowOpened } from '../../tracking/events/flow/trackEventFlowOpened';
import { trackExperienceEventActivated } from '../../tracking/experiences/details/list/trackExperienceEventActivated';
import { trackExperienceEventCancellationCompleted } from '../../tracking/experiences/details/list/trackExperienceEventCancellationCompleted';
import { trackExperienceEventCancellationExited } from '../../tracking/experiences/details/list/trackExperienceEventCancellationExited';
import { trackExperienceEventCancellationStarted } from '../../tracking/experiences/details/list/trackExperienceEventCancellationStarted';
import { trackExperienceEventDeactivated } from '../../tracking/experiences/details/list/trackExperienceEventDeactivated';
import { trackExperienceEventDetailsOpened } from '../../tracking/experiences/details/list/trackExperienceEventDetailsOpened';
import { trackExperienceEventDetailsThreeDotsClicked } from '../../tracking/experiences/details/list/trackExperienceEventDetailsThreeDotsClicked';
import { trackExperienceEventEditStarted } from '../../tracking/experiences/details/list/trackExperienceEventEditStarted';
import { trackExperienceEventStateFilterOpened } from '../../tracking/experiences/details/list/trackExperienceEventStateFilterOpened';
import { trackExperienceEventStateFilterUpdated } from '../../tracking/experiences/details/list/trackExperienceEventStateFilterUpdated';
import { trackExperienceEventStatusFilterOpened } from '../../tracking/experiences/details/list/trackExperienceEventStatusFilterOpened';
import { trackExperienceEventStatusFilterUpdated } from '../../tracking/experiences/details/list/trackExperienceEventStatusFilterUpdated';
import { trackExperienceGoToPagination } from '../../tracking/experiences/details/list/trackExperienceGoToPagination';
import { trackExperienceLocationFilterOpened } from '../../tracking/experiences/details/list/trackExperienceLocationFilterOpened';
import { trackExperienceLocationFilterUpdated } from '../../tracking/experiences/details/list/trackExperienceLocationFilterUpdated';
import { languageOptions } from '../../Utils/config';
import { TEvent, TEventFilter } from '../../Utils/eventHelpers';
import { ShowForScope } from '../AllowForScope/AllowForScope';
import { states, StatusChip } from '../CustomCard/CustomCard';
import { CustomChip } from '../CustomChip/CustomChip';
import { EmptyScreen } from '../EmptyScreen/EmptyScreen';
import { ListSkeleton } from '../ListSkeleton/ListSkeleton';
import { LoadingFullscreen } from '../Loading/LoadingFullscreen';
import { StyledPopoverContent } from '../Popover/radix_popover_styles';
import { EventsListItemEditParticipants } from './edit_participants/EventsListItemEditParticipants';

export type TShallowUser = {
  name: string;
  profilePicture: string;
  id: string;
};

export const renderRangeDate = (start?: string, end?: string) => {
  if (!start || !end) return '';
  const sd = new Date(start);
  const ed = new Date(end);

  if (
    sd.getMonth() === ed.getMonth() &&
    sd.getFullYear() === ed.getFullYear()
  ) {
    return `${format(sd, 'd')}. - ${format(ed, 'PPP')}`;
  }
  return `${format(sd, 'PPP')} - ${format(ed, 'PPP')}`;
};

export const renderStatus = (el: TEvent, experienceStatus: string) => {
  if (!el || el.status === 'draft') return 'draft';
  if (el.status === 'cancelled') return 'cancelled';
  if (el.status === CancellationStates.FAILED_PARTLY)
    return CancellationStates.FAILED_PARTLY;
  if (el.status === CancellationStates.FAILED_ENTIRELY)
    return CancellationStates.FAILED_ENTIRELY;

  if (new Date(el.startDateTime) < new Date()) return 'done';
  return el.status === 'inactive' || experienceStatus === 'inactive'
    ? 'inactive'
    : 'active';
};

const renderNames = (users: TUser[]) => {
  return users
    .map((el) => {
      const [first] = (el.name ?? el.email).split(' ');
      return users.length > 1 ? first : el.name;
    })
    .join(', ');
};

const isLast = (items: unknown[], index: number) => items.length === index + 1;

const renderGuides = (
  guides?: string[],
  users?: UseQueryResult<TUser[], unknown>
) => {
  if (!guides || !users || users.isLoading) {
    return <Skeleton width={100} />;
  }

  const allGuides = guides
    .map((el) => users?.data?.find((user) => user.id === el))
    .filter((el) => el) as TUser[];
  if (!allGuides) {
    return <Skeleton width={100} />;
  }

  const shouldRenderNames = allGuides.length === 1;

  return (
    <Stack direction={'row'} alignItems={'center'}>
      {allGuides.map((guide, i) => {
        if (!guide) {
          return '';
        }
        return isLast(guides, i) ? (
          <Grid
            item
            component={CustomChip}
            avatar={
              <Avatar alt={guide.name} src={guide.pictures?.profile?.url} />
            }
            label={shouldRenderNames ? renderNames(allGuides) : ''}
            size={'medium'}
            key={`guide-${guide.id}`}
            sx={{
              p: { xs: 1.5, md: 1 },
              pl: { xs: '6px', md: 1 },
              border: 'none',
              pointerEvents: 'none',
              color: 'grey.600',
              ml: '-8px',
              borderRadius: 100,
              '& .MuiChip-label': {
                minWidth: { xs: '140px', md: 'none' },
                maxWidth: { xs: '140px', md: '100%' },
                fontWeight: { xs: 600, md: 400 },
                pr: { xs: 0, md: 1 },
                color: { xs: '#1C1C1E', md: '#6F7E8C' },
              },
            }}
          />
        ) : (
          <Avatar
            alt={guide.name}
            sx={{ height: 24, width: 24, ml: 0.5 }}
            src={guide.pictures?.profile?.url}
          />
        );
      })}
    </Stack>
  );
};

interface Column {
  key: string;
  label?: string;
  props?: GridProps;
  valueProps?: GridProps;
  value: (el: TEvent, parameter?: any) => any;
}
const columns: Column[] = [
  {
    key: 'none',
    props: {
      xs: 0.5,
    },
    value: (el) =>
      el.visibility === 'private' ? (
        <LockOutlined />
      ) : el.recurring?.selectedOptionKey === 'yes' ? (
        <EventRepeatRounded />
      ) : el.isOneDay ? (
        <EventOutlined />
      ) : (
        <DateRange />
      ),
    valueProps: {
      display: 'flex',
      pl: 0.5,
      alignItems: 'center',
    },
  },
  {
    key: 'date',
    label: 'Dato',
    value: (el) => {
      return !el?.isOneDay
        ? renderRangeDate(el?.startDateTime, el?.endDateTime)
        : format(new Date(el?.startDateTime ?? null), 'PPP');
    },
    valueProps: {
      fontWeight: 600,
    },
    props: {
      xs: 2.5,
    },
  },
  {
    key: 'time',
    label: 'Tid',
    props: {
      xs: 1.5,
      pr: 1,
    },
    value: (el) =>
      `${format(new Date(el?.startDateTime), 'HH:mm')}${
        el?.endDateTime ? ` - ${format(new Date(el.endDateTime), 'HH:mm')}` : ''
      }`,
  },
  {
    key: 'languages',
    label: 'Sprog',
    props: {
      xs: 1.5,
    },
    valueProps: {
      fontSize: '0.8em',
    },
    value: (el) =>
      el?.languages?.map((el) => languageOptions(el).emoji).join(' '),
  },
  {
    key: 'assignedGuide',
    label: 'Guide',
    props: {
      xs: 2.8,
    },
    value: (el, users) => renderGuides(el.assignedGuides, users),
  },
  {
    key: 'slots',
    label: 'Deltagere',
    props: {
      xs: 1.2,
    },
    value: (el) => {
      if (el.status === 'cancelled') return '';
      if (el.visibility === 'private') return el.slots?.booked;
      return <EventsListItemEditParticipants event={el} />;
    },
    valueProps: {
      fontWeight: 600,
    },
  },
  {
    key: 'status',
    label: 'Status',
    props: {
      xs: 1.2,
    },
    value: (el, experienceStatus) => {
      return <StatusChip status={renderStatus(el, experienceStatus)} />;
    },
  },
];

const [, date, time, languages, guide, slots, status] = columns;

const StyledItem = styled(Grid)<any>({
  alignItems: 'center',
  zIndex: 10,
  '&:hover': {
    backgroundColor: 'rgb(252,252,252)',
  },
});

export const StyledSelect = styled(Select)({
  backgroundColor: 'transparent',
  marginTop: 2,
  marginRight: 10,
  '&:before': {
    borderBottom: 'none',
  },
  '& .MuiSelect-select.MuiInput-input': {
    fontWeight: 600,
    fontSize: '1em',
    paddingRight: '24px !important',
  },
});

export const getMins = (date: Date | string = '') => {
  if (!date) return '';
  const d = typeof date === 'string' ? new Date(date) : date;
  const h = d.getHours();
  const m = d.getMinutes();
  return h * 60 + m;
};

interface IOption {
  key: string;
  icon: SvgIconComponent;
  props?: Pick<MenuItemProps, 'color'>;
}

export const EventsList = ({
  experienceId,
  handleCreate,
  experienceStatus,
  ...props
}: BoxProps & {
  experienceId?: string;
  handleCreate: () => void;
  events?: TEvent[];
  experienceStatus?: string;
}) => {
  const { t } = useTranslate('events');
  const navigate = useNavigate();
  const location = useLocation();
  const { isMd } = useResponsive();

  const [removingListItem, setRemovingListItem] = useState(false);
  const [cancelIsLoading, setCancelIsLoading] = useState(false);

  const {
    currentPage,
    setCurrentPage,
    experienceEventsFilter,
    setExperienceEventsFilter,
  } = useEventStore();

  const goingToEventRef = useRef(true);
  const setGoingToEvent = (value: boolean) => {
    goingToEventRef.current = value;
  };

  const [filter, setFilter] = useState<TEventFilter>(experienceEventsFilter);

  useEffect(() => {
    window.scrollTo(0, 0);

    setTimeout(() => {
      setGoingToEvent(false);
    }, 100);

    return () => {
      if (goingToEventRef.current === false) {
        setExperienceEventsFilter({
          state: 'future',
          status: 'anyStatus',
          locationId: undefined,
        });
        setCurrentPage(1);
      }
    };
  }, [setCurrentPage, setExperienceEventsFilter]);

  const {
    experience: { data: experience },
  } = useExperience(experienceId);

  const {
    eventsForExperience: { data, isLoading },
    refetch,
  } = useExperienceEvents(experience?.ownerExperienceId, filter, currentPage);

  const {
    locations: { data: locations },
  } = useGetLocationsByExperienceId(experience?.ownerExperienceId);

  const _events = useMemo(() => data?.events, [data]);
  const noEventsInList = _events && _events?.length === 0;

  const { users } = useUsers();

  const { deleteEvent } = useDeleteEvent();
  const { cancelEvent } = useCancelEvent(experienceId);
  const { updateEvent } = useUpdateEvent(
    undefined,
    experienceId,
    filter,
    currentPage
  );

  useEffect(() => {
    if (isLoading === false && !_events) {
      refetch();
    }
  }, [isLoading, _events, refetch]);

  const [eventsInView, setEventsInView] = useState<TEvent[]>(_events ?? []);

  useEffect(() => {
    setEventsInView(_events ?? []);
  }, [_events, users]);

  const getOptions = (el: TEvent) => {
    const isCompleted =
      el.startDateTime && new Date(el.startDateTime) < new Date();

    const statusAction = el?.status === 'active' ? 'deactivate' : 'activate';
    const statusIcon =
      el?.status === 'active'
        ? PauseCircleOutlineOutlinedIcon
        : PlayCircleFilledWhiteOutlinedIcon;

    const changeStatusOption = {
      key: statusAction,
      icon: statusIcon,
    };

    const editOption = {
      key: 'edit',
      icon: EditOutlinedIcon,
    };

    const cancelOption = {
      key: 'cancel',
      icon: EventBusyOutlined,
    };

    const allOptions: IOption[] = [];

    if (
      (el?.status === 'active' || el?.status === 'inactive') &&
      experienceStatus !== 'inactive' &&
      !isCompleted
    ) {
      allOptions.push(changeStatusOption);
      allOptions.push(editOption);
    }

    if (el?.status !== 'cancelled' && el?.status !== 'draft' && !isCompleted) {
      allOptions.push(cancelOption);
    }

    return allOptions.map((el) => ({
      ...el,
      label: t(`${el.key}`, 'events.detailsPage.card.options'),
    }));
  };

  const { showErrorRetryDialog } = useErrorRetry();

  const showConfirmDeletionDialog = ({
    id,
    events,
    dialogProps,
    successMessage,
    loadingMessage,
    onConfirm,
  }: showConfirmDialogProps) => {
    NiceModal.show(ConfirmDialog, dialogProps).then(async () => {
      toast.loading(loadingMessage, {
        toastId: id,
      });

      setRemovingListItem(true);
      if (events) setEventsInView(events);
      setTimeout(() => {
        setRemovingListItem(false);
      }, 2000);

      try {
        await onConfirm().then(() => {
          toast.dismiss(id);
          toast.success(successMessage, { delay: 700 });
        });
      } catch (error) {
        setEventsInView(eventsInView);
        toast.dismiss(id);

        if (
          isEventDeletionError(error, EventDeletionError.EVENT_HAS_BOOKINGS)
        ) {
          showEventDeletionErrorDialog(t);
          return;
        }

        const retry = await showErrorRetryDialog();
        if (retry) {
          showConfirmDeletionDialog({
            id,
            events,
            dialogProps,
            successMessage,
            loadingMessage,
            onConfirm,
          });
        }
      }
    });
  };

  const toggleStatusTo = (newStatus: keyof typeof states, event: TEvent) => {
    updateEvent.mutateAsync({ ...event, status: newStatus });
  };

  const tryCancelling = async (event: TEvent, payload: ICancelEventData) => {
    setCancelIsLoading(true);

    try {
      await cancelEvent.mutateAsync({ payload, eventId: event.id });
      setCancelIsLoading(false);
      trackExperienceEventCancellationCompleted(
        event.id,
        payload.shouldRefund,
        Object.values(payload.cancellationNote[0])[0],
        _events ?? [],
        experience
      );
      toast.success(t('toast.cancellingSuccess', 'events.detailsPage.card'));
    } catch (error) {
      setCancelIsLoading(false);
      let retry = false;
      const errorCode = error instanceof Error ? error.message : '';

      if (errorCode === CancellationStates.FAILED_PARTLY) {
        retry = await showErrorRetryDialog(
          t('dialogs.cancellingError.titlePartly', 'events.detailsPage.card'),
          t(
            'dialogs.cancellingError.descriptionPartly',
            'events.detailsPage.card'
          )
        );
      } else {
        retry = await showErrorRetryDialog(
          t('dialogs.cancellingError.titleGeneric', 'events.detailsPage.card'),
          t(
            'dialogs.cancellingError.descriptionGeneric',
            'events.detailsPage.card'
          )
        );
      }

      if (retry) {
        tryCancelling(event, payload);
      }
    }
  };

  const handleClick =
    (eventId: string, shouldEdit = false) =>
    async () => {
      if (!shouldEdit)
        trackExperienceEventDetailsOpened(eventId, _events ?? [], experience);
      if (shouldEdit) trackEventFlowOpened(location.pathname, 'edit');

      setGoingToEvent(true);
      const { startDateTime, endDateTime, id, isRecurring } =
        _events?.find((event) => event.id === eventId) ?? ({} as any);
      return navigate(`/event/${id}${shouldEdit ? '/edit' : ''}`, {
        state: {
          returnUrl: location.pathname,
          ...(isRecurring && {
            startDateTime,
            endDateTime,
          }),
        },
      });
    };

  const handleSelectOption = (key: string, id: string) => () => {
    if (id === undefined) return;

    const event = _events?.find((el) => el.id === id);
    if (!event) return;

    if (key === 'edit') {
      trackExperienceEventEditStarted(id, _events ?? [], experience);
      handleClick(id, true)();
    }

    if (key === 'activate') {
      trackExperienceEventActivated(id, _events ?? [], experience);
      toggleStatusTo('active', event);
    }

    if (key === 'deactivate') {
      trackExperienceEventDeactivated(id, _events ?? [], experience);
      toggleStatusTo('inactive', event);
    }

    if (key === 'cancel') {
      trackExperienceEventCancellationStarted(id, _events ?? [], experience);

      NiceModal.show(CancelEventDialog, {
        booked: Boolean(event.slots?.booked),
        eventId: id,
      })
        .then((noteAndRefund: unknown) => {
          if (noteAndRefund) {
            tryCancelling(event, noteAndRefund as ICancelEventData);
          }
        })
        .catch(() => {
          trackExperienceEventCancellationExited(id, _events ?? [], experience);
        });
    }

    if (key === 'delete') {
      if (
        event?.slots?.booked &&
        (event?.slots?.booked > 0 || event?.bookings?.length > 0)
      ) {
        showEventDeletionErrorDialog(t);
      } else if (isVirtualId(event?.id)) {
        NiceModal.show<string>(OptionsDialog, {
          title: t('title', 'dialogs.deleteRecurringEvent'),
          description: t(
            'descriptionWithOptions',
            'dialogs.deleteRecurringEvent'
          ),
          buttons: [
            {
              key: 'wholeSeries',
              label: t('actions.secondary', 'dialogs.confirmDeleteRecurring'),
              props: {
                variant: 'outlined',
                color: 'error',
              },
            },
            {
              key: 'onlyThis',
              label: t('actions.primary', 'dialogs.confirmDeleteRecurring'),
              props: {
                variant: 'contained',
              },
            },
          ],
        }).then((choice: string) => {
          const _id =
            choice === 'wholeSeries' ? event.parentId ?? event.id : event.id;

          if (choice === 'wholeSeries') {
            toast.loading(t('detailsPage.card.toast.deletingSeries'), {
              toastId: _id,
            });
          } else {
            toast.loading(t('detailsPage.card.toast.deletingEvent'), {
              toastId: _id,
            });
          }

          // optimistic remove item and update list
          if (choice === 'wholeSeries') {
            setRemovingListItem(true);
            setEventsInView(
              eventsInView.filter(
                (event) =>
                  (event.parentId !== id && event.id !== id) ||
                  event.bookings?.length > 0
              )
            );
            setTimeout(() => {
              setRemovingListItem(false);
            }, 2000);
          } else {
            setRemovingListItem(true);
            setEventsInView(eventsInView.filter((event) => event.id !== id));
            setTimeout(() => {
              setRemovingListItem(false);
            }, 2000);
          }

          deleteEvent
            .mutateAsync(_id)
            .then(() => {
              toast.dismiss(_id);
              toast.success(
                choice === 'wholeSeries'
                  ? t('detailsPage.card.toast.deletingSeriesSuccess')
                  : t('detailsPage.card.toast.deletingEventSuccess'),
                {
                  delay: 700,
                }
              );
            })
            .catch(() => {
              // Revert changes
              toast.error(t('detailsPage.card.toast.deletingError'));
              setEventsInView(eventsInView);
              toast.dismiss(id);
            });
        });
      } else if (event?.recurring?.selectedOptionKey === 'yes') {
        const { id } = event;
        const newEventsInView = eventsInView.filter(
          (event) => event.parentId !== id && event.id !== id
        );
        showConfirmDeletionDialog({
          id: id,
          events: newEventsInView,
          dialogProps: {
            title: t('title', 'dialogs.deleteRecurringEvent'),
            description: t('description', 'dialogs.deleteRecurringEvent'),
            confirmLabel: t('confirmLabel', 'dialogs.deleteRecurringEvent'),
            important: true,
          },
          successMessage: t('detailsPage.card.toast.deletingSeriesSuccess'),
          loadingMessage: t('detailsPage.card.toast.deletingSeries'),
          onConfirm: () => deleteEvent.mutateAsync(id),
        });
      } else {
        const { id } = event;
        const newEventsInView = eventsInView.filter((event) => event.id !== id);
        showConfirmDeletionDialog({
          id: id,
          events: newEventsInView,
          dialogProps: {
            title: t('title', 'dialogs.deleteEvent'),
            description: t('description', 'dialogs.deleteEvent'),
            confirmLabel: t('confirmLabel', 'dialogs.deleteEvent'),
          },
          successMessage: t('detailsPage.card.toast.deletingEventSuccess'),
          loadingMessage: t('detailsPage.card.toast.deletingEvent'),
          onConfirm: () => deleteEvent.mutateAsync(id),
        });
      }
    }
  };

  const handleStateFilter = (evt: any) => {
    const newData = {
      ...filter,
      state: evt.target.value,
    };
    setFilter(newData);
    setExperienceEventsFilter(newData);
    setCurrentPage(1);
    trackExperienceEventStateFilterUpdated(
      _events ?? [],
      newData.state,
      experience
    );
  };
  const handleStatusFilter = (evt: any) => {
    const newData = {
      ...filter,
      status: evt.target.value,
    };
    setFilter(newData);
    setExperienceEventsFilter(newData);
    setCurrentPage(1);
    trackExperienceEventStatusFilterUpdated(
      _events ?? [],
      newData.status,
      experience
    );
  };
  const handleLocationFilter = (evt: any) => {
    const newData = {
      ...filter,
      locationId: evt.target.value === 'all' ? undefined : evt.target.value,
    };
    setFilter(newData);
    setExperienceEventsFilter(newData);
    setCurrentPage(1);
    trackExperienceLocationFilterUpdated(
      _events ?? [],
      newData.locationId,
      experience
    );
  };

  const transitions = useTransition(eventsInView, {
    keys: (item: any) => item.id,
    from: { opacity: 1, height: 60 },
    leave: { opacity: 0, height: 0 },
    config: { duration: 600, easing: easeCubic },
  });

  return (
    <Box {...props}>
      <LoadingFullscreen isLoading={cancelIsLoading || isLoading} />
      {isMd ? null : (
        <Box display={'flex'} alignItems={'baseline'}>
          <Typography variant={'h4'}>{t('title')}</Typography>
          <Typography mr={1} ml={2}>
            {t('show', 'utils.generic')}:{' '}
          </Typography>
          {locations && locations.length > 1 && (
            <StyledSelect
              variant={'standard'}
              IconComponent={ArrowDropDownRounded}
              onChange={handleLocationFilter}
              value={filter.locationId ?? 'all'}
              displayEmpty={true}
              label="Location"
              onOpen={() =>
                trackExperienceLocationFilterOpened(
                  _events ?? [],
                  filter.locationId,
                  experience
                )
              }
            >
              <MenuItem value={'all'}>
                {t('allLocations', 'utils.tables.filters')}
              </MenuItem>
              {locations.map((el, index) => (
                <MenuItem value={el.locationId} key={`loc-${index}`}>
                  {el.locationName}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
          <StyledSelect
            variant={'standard'}
            IconComponent={ArrowDropDownRounded}
            value={filter.state}
            onChange={handleStateFilter}
            label="State"
            onOpen={() =>
              trackExperienceEventStateFilterOpened(
                _events ?? [],
                filter.state,
                experience
              )
            }
          >
            {['all', 'future', 'past'].map((el, index) => (
              <MenuItem key={index} value={el}>
                {t(el, `utils.tables.filters`)}
              </MenuItem>
            ))}
          </StyledSelect>
          <StyledSelect
            variant={'standard'}
            IconComponent={ArrowDropDownRounded}
            value={filter.status}
            onChange={handleStatusFilter}
            label="Status"
            onOpen={() =>
              trackExperienceEventStatusFilterOpened(
                _events ?? [],
                filter.status,
                experience
              )
            }
          >
            {[
              'anyStatus',
              'statusIsActive',
              'statusIsInactive',
              'statusIsCancelled',
            ].map((el, index) => (
              <MenuItem key={index} value={el}>
                {t(el, `utils.tables.filters`)}
              </MenuItem>
            ))}
          </StyledSelect>
        </Box>
      )}

      {isMd ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={'space-between'}
          mb={3}
        >
          <Typography
            sx={{
              color: '#1C1C1E',
              fontWeight: 600,
              textTransform: 'capitalize',
              fontSize: '18px',
            }}
          >
            {t('title')}
          </Typography>
          <Stack direction="row" alignItems="center" gap={0.5}>
            {locations && locations.length > 1 && (
              <>
                <Typography>{t('show', 'utils.generic')}:</Typography>
                <StyledSelect
                  variant={'standard'}
                  IconComponent={ArrowDropDownRounded}
                  onChange={handleLocationFilter}
                  value={filter.locationId ?? 'all'}
                  displayEmpty={true}
                  label="Location"
                  sx={{
                    maxWidth: '200px',
                  }}
                >
                  <MenuItem value={'all'}>
                    {t('allLocations', 'utils.tables.filters')}
                  </MenuItem>
                  {locations.map((el, index) => (
                    <MenuItem value={el.locationId} key={`loc-${index}`}>
                      {el.locationName}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </>
            )}
          </Stack>
        </Stack>
      ) : (
        <Grid container pl={'20px'} pr={'20px'} pb={1} pt={2} mb={1}>
          {columns.map((el) => (
            <Grid
              fontSize={'0.75em'}
              fontWeight={600}
              textTransform={'uppercase'}
              key={el.key}
              component={Typography}
              item
              {...el.props}
            >
              {el.key !== 'none' && t(el.key, 'utils.tables.header')}
            </Grid>
          ))}
        </Grid>
      )}
      {(!_events || isLoading) && <ListSkeleton />}
      {noEventsInList && !isLoading && !removingListItem && (
        <EmptyScreen
          imageSrc={'/empty-state-nature.svg'}
          title={t('emptyState.title')}
          description={t('emptyState.description')}
          onClick={handleCreate}
          scopes={['event.write']}
          buttonLabel={t('emptyState.action')}
          pt={6}
          pb={5}
        />
      )}
      {isMd
        ? transitions((style, item: any, _, index) => (
            <animated.div>
              <Box sx={{ pr: { xs: 0 }, mb: 2 }} key={index}>
                <Card
                  onClick={handleClick(item.id)}
                  sx={{
                    px: 2,
                    py: '12px',
                    border: '1px solid #E5E5EA',
                    boxShadow: 'none',
                  }}
                >
                  {/* first row */}
                  <Box
                    sx={{
                      mb: '16px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                      <Box>{date.value?.(item)}</Box>
                      <Box>{time.value?.(item)}</Box>
                    </Box>
                    <Box
                      sx={{
                        color: '#636366',
                        textTransform: 'capitalize',
                      }}
                    >
                      {status.value?.(item)}
                    </Box>
                  </Box>

                  {/* second row */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box>{guide.value?.(item, users)}</Box>
                      <Box>{languages.value?.(item)}</Box>
                    </Box>
                    <Box
                      sx={{
                        color:
                          (item.slots?.booked / item.slots?.total) * 100 >= 80
                            ? '#34C759'
                            : '#C7C7CC',
                        fontWeight: 600,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                        }}
                      >
                        <PersonIcon sx={{ mb: '2px' }} />
                        <p>{slots.value?.(item)}</p>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </animated.div>
          ))
        : transitions((style, item) => {
            if (!item) return;
            return (
              <animated.div style={style}>
                <Popover.Root>
                  <Box p={1}>
                    <StyledItem
                      p={1.5}
                      component={Card}
                      container
                      key={item.id}
                      onClick={handleClick(item.id)}
                      sx={{ cursor: 'pointer' }}
                    >
                      {columns.map((el) => (
                        <Grid
                          fontSize={'0.88em'}
                          key={el.key}
                          item
                          {...el.props}
                          {...el.valueProps}
                          component={Typography}
                        >
                          {el.value?.(
                            item as any,
                            el.key === 'status'
                              ? experienceStatus
                              : el.key === 'assignedGuide'
                                ? users
                                : undefined
                          )}
                        </Grid>
                      ))}
                      <Grid item flexGrow={1} textAlign={'right'}>
                        {getOptions(item).length !== 0 ? (
                          <ShowForScope scopes={['event.write']}>
                            <Popover.Trigger>
                              <IconButton
                                size={'medium'}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  trackExperienceEventDetailsThreeDotsClicked(
                                    item.id,
                                    _events ?? [],
                                    experience
                                  );
                                }}
                              >
                                <MoreVert />
                              </IconButton>
                            </Popover.Trigger>
                          </ShowForScope>
                        ) : (
                          <Stack height={36}></Stack>
                        )}
                      </Grid>
                    </StyledItem>
                  </Box>
                  <StyledPopoverContent sideOffset={5} align="end">
                    <Stack gap={1} width="100%" py={1}>
                      {getOptions(item).map((el) => {
                        const Icon = el.icon as any;
                        return (
                          <Stack
                            tabIndex={0}
                            direction="row"
                            alignItems="center"
                            p={1}
                            gap={1}
                            width={200}
                            key={el.key}
                            // If the user clicks the cancel option and the bookings are loading, the button will be disabled
                            onClick={handleSelectOption(el.key, item.id)}
                            onKeyDown={(evt: any) => {
                              if (evt.key === 'Enter') {
                                handleSelectOption(el.key, item.id)();
                              }
                            }}
                            sx={{
                              cursor: 'pointer',
                              ':hover': {
                                backgroundColor: lightTheme.palette.neutral.n50,
                              },
                            }}
                          >
                            <Icon
                              fontSize="small"
                              style={{ color: el.props?.color }}
                              {...el.props}
                            />
                            <Text
                              fontSize="small"
                              style={{ color: el.props?.color }}
                            >
                              {el.label}
                            </Text>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </StyledPopoverContent>
                </Popover.Root>
              </animated.div>
            );
          })}
      {data && !noEventsInList && !isLoading && (
        <Stack
          sx={{
            mt: 4,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              color: '#6F7E8C',
              fontSize: '12px',
              textTransform: 'lowercase',
            }}
          >
            {t('pagination', {
              eventCount: `${
                (currentPage - 1) * EVENT_LIST_MAX_EVENTS_PER_PAGE + 1
              } - ${
                (currentPage - 1) * EVENT_LIST_MAX_EVENTS_PER_PAGE +
                eventsInView.length
              }`,
              totalCount: data.totalCount,
            })}
          </Typography>
          <Pagination
            count={Math.ceil(data.totalCount / EVENT_LIST_MAX_EVENTS_PER_PAGE)}
            page={currentPage}
            onChange={(_, page) => {
              if (page === currentPage) return;
              setCurrentPage(page);
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });

              trackExperienceGoToPagination(page);
            }}
          />
        </Stack>
      )}
    </Box>
  );
};
