import { Dispatch, FC, SetStateAction, useMemo } from 'react';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useLocalizedStringFormatter } from '../../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { FILTER_NO_VALUES } from '../../constants';
import { AccordionFilter } from './AccordionFilter';
import { MultiSelectFilter } from './MultiSelectFilter';

type LocalizedString = Record<string, string>;

export type SpecificFilterProps = {
  inputId: string;
  label: string;
  options: Array<{
    id: string;
    name: string;
  }>;
  checkedIds: string[];
  onChange: Dispatch<SetStateAction<string[]>>;
  onOpen?: () => void;
  isLoading?: boolean;
  fullWidth?: boolean;
  sx?: Record<string, unknown>;
};

type FilterProps = Omit<SpecificFilterProps, 'options'> & {
  options: Array<{
    id: string | LocalizedString | undefined;
    name: string | LocalizedString | undefined;
  }>;
  sortOptions?: (a: { name: string }, b: { name: string }) => number;
  includeEmptyOption?: boolean;
};

export const Filter: FC<FilterProps> = ({
  options,
  includeEmptyOption,
  sortOptions,
  ...rest
}) => {
  const { t } = useTranslate('utils.tables.header');
  const localized = useLocalizedStringFormatter();

  const mappedOptions = useMemo(() => {
    const localizedOptions = options.map(({ id, name }) => ({
      id: localized(id),
      name: localized(name),
    }));

    const emptyOption = includeEmptyOption
      ? [
          {
            id: FILTER_NO_VALUES,
            name: `(${t('none')})`,
          },
        ]
      : [];

    if (!sortOptions) return [...emptyOption, ...localizedOptions];

    return [...emptyOption, ...localizedOptions.sort(sortOptions)];
  }, [options, includeEmptyOption, t, sortOptions, localized]);

  const { isSm } = useResponsive();

  if (isSm) {
    return <AccordionFilter {...rest} options={mappedOptions} />;
  }

  return <MultiSelectFilter {...rest} options={mappedOptions} />;
};
