import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../Hooks/useTranslate';
import {
  trackOverviewRequestReportTypeOpened,
  trackOverviewRequestReportTypeSelected,
} from '../../../../tracking/overview/request-report';
import { REPORT_TYPES } from '../../request-report-dialog';
import { RequestReportDialogFormType } from '../use-request-report-form-validation';

export const RequestReportFormType = () => {
  const { t } = useTranslate('dialogs.requestReport.types');

  const {
    control,
    formState: { errors },
  } = useFormContext<Pick<RequestReportDialogFormType, 'type'>>();
  return (
    <Controller
      name="type"
      defaultValue=""
      control={control}
      render={({ field }) => (
        <FormControl>
          <InputLabel error={!!errors.type} required>
            {t('type', 'utils.tables.header')}
          </InputLabel>
          <Select
            {...field}
            fullWidth
            error={!!errors.type}
            label={t('type', 'utils.tables.header')}
            onOpen={trackOverviewRequestReportTypeOpened}
            onChange={(e) => {
              field.onChange(e);
              trackOverviewRequestReportTypeSelected(e.target.value);
            }}
            required
          >
            {REPORT_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {t(type)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
