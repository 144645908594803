import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { BookingFormData } from './use_booking_form_validation';

export const EventUpsertBookingFormNameField = ({
  width = '100%',
}: {
  width?: string;
}) => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();

  const {
    control,
    formState: { errors },
  } = useFormContext<Pick<BookingFormData, 'name'>>();

  return (
    <Controller
      name="name"
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          sx={{ width }}
          label={t('eventUpsert.flow.booking.form.name.label')}
          autoFocus
          required
          value={currentEvent.booking?.name || ''}
          onChange={(e) => {
            field.onChange(e);
            updateEventField('booking', {
              ...currentEvent.booking,
              name: e.target.value,
            });
          }}
          helperText={errors.name?.message}
          error={!!errors.name}
        />
      )}
    />
  );
};
