import { Text } from '@holdbar-com/pixel';
import {
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  styled,
} from '@mui/material';
import { FC, useCallback } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { FILTER_ALL_VALUES } from '../../constants';
import { SpecificFilterProps } from './Filter';
import { FilterValue } from './FilterValue';

export const MultiSelectFilter: FC<SpecificFilterProps> = ({
  inputId,
  label,
  options,
  checkedIds,
  onChange,
  onOpen,
  isLoading,
  fullWidth = true,
  sx = { maxWidth: 200 },
}) => {
  const { t } = useTranslate('');

  const onSelectChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = event;

      const asArray = typeof value === 'string' ? value.split(',') : value;

      if (asArray.includes(FILTER_ALL_VALUES)) {
        // If the user selects all, we want to toggle all options
        onChange(
          asArray.length >= options.length ? [] : options.map(({ id }) => id)
        );
        return;
      }

      onChange(asArray);
    },
    [onChange, options]
  );

  const allLabel = t('all', 'utils.tables.filters');

  if (isLoading) {
    return (
      <Skeleton
        width="100%"
        style={{ maxWidth: 200 }}
        height={38}
        variant="rounded"
      />
    );
  }

  return (
    <FormControl fullWidth={fullWidth} size="small" sx={sx}>
      <InputLabel shrink id={inputId}>
        {label}
      </InputLabel>
      <Select
        labelId={inputId}
        disabled={options.length === 0}
        multiple
        input={<OutlinedInput notched label={label} />}
        value={checkedIds}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
          autoFocus: false,
        }}
        onChange={onSelectChange}
        onOpen={onOpen}
        displayEmpty
        renderValue={(selected) => (
          <FilterValue options={options} selected={selected} />
        )}
        style={{ maxHeight: 150 }}
      >
        <MenuItem value={FILTER_ALL_VALUES}>
          <Stack direction="row" alignItems="center" gap={1}>
            <StyledCheckbox
              checked={
                options.length === checkedIds.length || checkedIds.length === 0
              }
            />
            <Text fontSize="small" textTransform="capitalize">
              {allLabel}
            </Text>
          </Stack>
        </MenuItem>
        <Divider />
        {options.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            <Stack direction="row" alignItems="center" gap={1}>
              <StyledCheckbox checked={checkedIds.includes(id)} />
              <Text fontSize="small" textTransform="capitalize">
                {name}
              </Text>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  margin: 0;
`;
