import {
  Box,
  BoxProps,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, {
  ChangeEvent,
  ComponentPropsWithoutRef,
  ReactNode,
  useMemo,
} from 'react';
import { useController, UseControllerReturn } from 'react-hook-form';

type TOption = Partial<Omit<FormControlLabelProps, 'label'>> & {
  optionKey?: string;
  key?: string | boolean;
  label: string | ReactNode;
  formLabel?: ReactNode;
  type?: 'option';
  disabled?: boolean;
};

interface IOptionGroupProps {
  name?: string;
  defaultValue?: string;
  options: TOption[];
  onChangeValue?: (newValue: any) => void;
  field?: UseControllerReturn<any, any>['field'];
}

export const OptionGroupSelectedSection = ({
  optionKey,
  controllerName,
  children,
  render,
  ...props
}: BoxProps & {
  optionKey: string | boolean;
  controllerName?: string;
  render?: ({ controllerName }: { controllerName: string }) => ReactNode;
}) => {
  return (
    <Box {...props}>
      {controllerName && render ? render({ controllerName }) : children}
    </Box>
  );
};

export const OptionGroupOption = () => {
  return <></>;
};

export const OptionGroup = ({
  name,
  children,
  onChangeValue,
  field,
  defaultValue,
  options,
}: ComponentPropsWithoutRef<any> & IOptionGroupProps) => {
  const {
    field: { onChange, ..._field },
  } = useController({
    name: field ? '' : name!,
    defaultValue,
  });

  const _options = useMemo(() => {
    const output: TOption[] = [];
    if (!children) return options;
    React.Children.forEach(children, (el: React.ReactElement<TOption>) => {
      if (el.props.type === 'option') {
        output.push(el.props);
      }
    });
    return [...output, ...options];
  }, [options, children]);

  const handleChange = (
    evt: ChangeEvent<HTMLInputElement>,
    newValue: string
  ) => {
    const _val: string | boolean =
      newValue === 'false' ? false : newValue === 'true' ? true : newValue;
    (field ? field.onChange : onChange)(_val);
    onChangeValue?.(_val as any);
  };

  return (
    <RadioGroup
      {...(field ?? _field)}
      value={String((field ?? _field).value)}
      onChange={handleChange}
    >
      {_options.map(({ disabled = false, ...el }) => {
        return (
          <>
            <FormControlLabel
              key={el.key + ''}
              value={String(el.optionKey ?? el.key)}
              control={<Radio />}
              disabled={disabled}
              label={el.formLabel ?? el.label}
            />
            {React.Children.map(children, (c: any) => {
              if (c.props.optionKey !== el.key) return null;
              return c.props.optionKey === (field ?? _field).value
                ? React.cloneElement(c as any, {
                    controllerName: `${name?.split('.')[0]}.${el.key}`,
                  })
                : null;
            })}
          </>
        );
      })}
    </RadioGroup>
  );
};
