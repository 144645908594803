import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { languageOptions } from '../../Utils/config';

export const useSelectedLanguages = (languages?: string[]) => {
  const { i18n } = useTranslation();

  return useMemo(
    () => mapSelectedLanguages(languages, i18n.languages),
    [i18n.languages, languages]
  );
};

const mapSelectedLanguages = (
  selected: string[] | undefined,
  backofficeLanguages: readonly string[]
) => {
  if (!selected) return undefined;

  const possibleLanguages = selected.flatMap((lang) => {
    const langConfig = languageOptions(lang);
    if (!langConfig) {
      return [];
    }

    const { emoji: label, icon } = langConfig;
    return { key: lang, label: label!, icon: icon };
  });

  // Sorts the languages based on detected language in the backoffice
  return possibleLanguages.sort((a, b) => {
    const aIndex = backofficeLanguages.indexOf(a.key);
    const bIndex = backofficeLanguages.indexOf(b.key);

    if (aIndex === -1) {
      return 1;
    }

    if (bIndex === -1) {
      return -1;
    }

    return aIndex - bIndex;
  });
};
