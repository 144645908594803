import { lightTheme } from '@holdbar-com/pixel';
import { Step, StepConnector, Stepper } from '@mui/material';

import { useOnboardingWidgetContext } from '../use-onboarding-widget-context';
import { OnboardingWidgetStepperButton } from './onboarding-widget-stepper-button';

export const OnboardingWidgetStepper = () => {
  const { items, step, handleChangeStep } = useOnboardingWidgetContext();

  return (
    <Stepper
      activeStep={step}
      orientation="vertical"
      sx={{ minWidth: 200, height: '100%' }}
      connector={
        <StepConnector
          sx={{
            '& span': {
              borderColor: `#E5E5EA !important`,
              height: 1,
              borderLeftWidth: '2px',
            },
          }}
        />
      }
    >
      {items
        ?.filter((f) => f.key !== 'success')
        .map((step, index) => (
          <Step
            completed={step?.completed === true}
            sx={{
              cursor: 'pointer',
              '&:hover': { opacity: 0.8 },
              '& svg.Mui-completed': {
                color: lightTheme.palette.success.s300,
              },
              '& .MuiSvgIcon-root:not(.Mui-active):not(.Mui-completed)': {
                color: '#E5E5EA',
                '& text': { fill: '#8E8E93' },
              },
            }}
            key={step.key}
            onClick={() => handleChangeStep(index)}
          >
            <OnboardingWidgetStepperButton step={step} />
          </Step>
        ))}
    </Stepper>
  );
};
