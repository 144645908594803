import { Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import { Page } from '../../../Components/Page/Page';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { useTranslate } from '../../../Hooks/useTranslate';
import { useVoucher } from '../../../Hooks/useVoucher';
import { giftCardOverviewViewed } from '../../../tracking/giftCards/giftCardEvents';
import { GiftCardsList } from './giftcards_list/GiftCardsList';
import { GiftCardsSearchForm } from './giftcards_search_form/GiftCardsSearchForm';

export const GiftCardsView = () => {
  const { t } = useTranslate('giftcards');
  const fireOnce = useFireOnce();
  const vouchers = useVoucher();

  const totalGiftCards = vouchers?.boughtVouchers?.data?.length ?? 0;
  const activeGiftCards =
    vouchers?.boughtVouchers?.data?.filter((x) => x.status === 'active')
      .length ?? 0;

  useEffect(() => {
    if (vouchers.boughtVouchers.isSuccess) {
      fireOnce(() => {
        giftCardOverviewViewed({
          activeGiftCards,
          totalGiftCards,
        });
      });
    }
  }, [
    vouchers.boughtVouchers.isSuccess,
    totalGiftCards,
    activeGiftCards,
    fireOnce,
  ]);

  const [searchState, setSearchState] = useState({
    query: '',
    status: 'all',
  });

  return (
    <Page maxWidth={1200} pb={10}>
      <Stack gap={3}>
        <Text fontSize={'h5'}>{t('title')}</Text>
        <GiftCardsSearchForm
          searchState={searchState}
          setSearchState={setSearchState}
        />
        <GiftCardsList searchState={searchState} />
      </Stack>
    </Page>
  );
};
