export function encodeConnectionPath(companyId: string, token: string) {
  return btoa(`${companyId}-${token}`);
}

export function decodeConnectionPath(connectionPath: string) {
  const string = atob(connectionPath);
  const [companyId, token] = string.split('-');
  return {
    companyId,
    token: token,
  };
}
