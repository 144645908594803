import styled from '@emotion/styled';
import { Text } from '@holdbar-com/pixel';
import { renderDateTime } from '@holdbar-com/utils-date';
import { capitalize, Divider, Skeleton, Stack } from '@mui/material';

import { CopyLink } from '../../../../../../Components/CopyLink/CopyLink';
import { Link } from '../../../../../../Components/Link/Link';
import { getLocalized, TBooking } from '../../../../../../Hooks/useBookings';
import {
  trackBookingGuestEmailCopied,
  trackBookingGuestEmailStarted,
} from '../../../../../../tracking/bookings/details';
import { TBookingRow, TCustomerRow } from '../../../domain/types';

export const bookingRows: TBookingRow[] = [
  {
    key: 'experience',
    value: (booking) => (
      <Link
        href={`/experience/${booking.experienceId}`}
        style={{ fontSize: 'inherit' }}
      >
        {booking.experienceHeadline}
      </Link>
    ),
  },
  {
    key: 'date',
    value: (booking) =>
      renderDateTime(booking.startDateTime, booking.endDateTime, {
        standard: 'ddmmyyyyhh',
        sameDay: 'dmhh',
      }),
  },
  {
    key: 'countParticipants',
    value: (booking, t, extraData) => {
      if (!extraData) return;
      const { language } = extraData;

      return (
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          sx={{ flexWrap: 'wrap' }}
        >
          <StyledText>
            {countVariants(booking.items)} {t!('guests', 'utils.generic')}
          </StyledText>
          <StyledText>
            ({renderGuestTypes(booking, undefined, language)})
          </StyledText>
        </Stack>
      );
    },
  },
  {
    key: 'channel',
    value: (booking) => capitalize(booking?.channel ?? 'understory'),
  },
  {
    key: 'specialInfo',
    value: (booking) => {
      if (!booking.customDataInputs) {
        return '';
      }

      return (
        <Stack divider={<Divider sx={{ marginBlock: 1 }} />}>
          {booking?.customDataInputs?.map((el, i) => {
            const inputsWithValues = el.inputs.filter((input) =>
              Boolean(input.value)
            );
            if (!inputsWithValues.length) return null;

            return (
              <Stack key={i} gap={0.5}>
                {inputsWithValues.map((inp) => {
                  if (!inp?.value) return null;

                  return (
                    <Stack
                      key={inp.id + i}
                      direction="row"
                      justifyContent="space-between"
                      gap={2}
                    >
                      <StyledText style={{ maxWidth: '70%' }}>
                        {inp.name}
                      </StyledText>
                      <StyledText style={{ maxWidth: '70%' }}>
                        {inp.value}
                      </StyledText>
                    </Stack>
                  );
                })}
              </Stack>
            );
          })}
        </Stack>
      );
    },
  },
  {
    key: 'movedTo',
    value: (booking, _, extraData) => {
      if (!extraData) return '';
      const { eventMovedTo } = extraData;
      if (!eventMovedTo) return '';

      return (
        <Link
          href={`/event/${booking.movedToEvent}`}
          style={{ fontSize: 'inherit' }}
        >
          {renderDateTime(
            eventMovedTo.startDateTime,
            eventMovedTo.endDateTime,
            {
              standard: 'ddmmyyyyhh',
              sameDay: 'dmhh',
            }
          )}
        </Link>
      );
    },
  },
  {
    key: 'movedFrom',
    value: (booking, _, extraData) => {
      if (!extraData) return '';
      const { eventMovedFrom } = extraData;
      if (!eventMovedFrom) return '';

      return (
        <Link
          href={`/event/${booking.movedFromEvent}`}
          style={{ fontSize: 'inherit' }}
        >
          {renderDateTime(
            eventMovedFrom.startDateTime,
            eventMovedFrom.endDateTime,
            {
              standard: 'ddmmyyyyhh',
              sameDay: 'dmhh',
            }
          )}
        </Link>
      );
    },
  },
];

export const customerRows: TCustomerRow[] = [
  {
    key: 'customerInfo',
    value: (el, t) => (
      <Stack gap={1} key={'customerInfo'}>
        <Text variant="medium">{el.customer?.name ?? ''}</Text>
        {el.customer?.location && (
          <Stack>
            <StyledText>{el.customer.location.address}</StyledText>
            <StyledText>
              {el.customer.location.zipCode} {el.customer.location.city}
            </StyledText>
            {el.customer.location.country && (
              <Text fontSize="small">
                {t!(el.customer.location.country, 'countryCodes')}
              </Text>
            )}
          </Stack>
        )}
      </Stack>
    ),
  },
  {
    key: 'customerContact',
    value: (el, _, receipt) => (
      <Stack gap={0.5} key={'customerContact'}>
        {el.customer.email && (
          <CopyLink
            href={`mailto:${el.customer.email}`}
            label={el.customer?.email}
            size="large"
            fontSize="small"
            isEmail={true}
            onClickText={() => trackBookingGuestEmailStarted(el, receipt?.data)}
            onClickCopy={() => trackBookingGuestEmailCopied(el, receipt?.data)}
          />
        )}
        <Text fontSize="small">{el.customer?.phone}</Text>
      </Stack>
    ),
  },
  {
    key: 'customerData',
    value: (el, t, receipt) => {
      if (!el.language || !t) {
        return '';
      }
      return (
        <Stack gap={1} key="customerData" mb={1}>
          <StyledText key={'source'}>
            {`${t('source')}: ${capitalize(el.source ?? 'holdbar')}`}
          </StyledText>
          {receipt ? (
            <Stack direction="row" gap={0.5} alignItems="center">
              <StyledText key={'marketingConsent'}>
                {t('marketingConsent')}:
              </StyledText>
              {receipt.isLoading ? (
                <Skeleton width={60} />
              ) : receipt.data?.metadata.marketingConsentGiven ? (
                t('consent')
              ) : (
                t('noConsent')
              )}
            </Stack>
          ) : null}
          <StyledText key={'customerLanguage'}>
            {`${t('language', 'utils.generic')}: `}
            {t(el.language, 'utils.languages')}
          </StyledText>
        </Stack>
      );
    },
  },
];

const StyledText = styled(Text)`
  font-size: inherit;
`;

export const renderGuestTypes = (
  booking?: TBooking,
  type?: string,
  language?: string
) => {
  if (!booking) {
    return '';
  }
  const enriched = Object.entries(booking?.items ?? {}).reduce(
    (tickets, [id, count]) => {
      const { name: n } =
        booking.variants?.find((v) => id.endsWith(v.id)) ?? {};
      const name = getLocalized(n, language ?? 'da') ?? 'Standard';
      if (type && !id.startsWith(type)) {
        return tickets;
      }
      return {
        ...tickets,
        [name]: (tickets[name] ?? 0) + Number(count),
      };
    },
    {} as { [key: string]: string }
  );
  return Object.entries(enriched)
    .map(([name, count]) => {
      return `${count} x ${name}`;
    })
    .join(', ');
};

export const countVariants = (obj?: object) =>
  Object.entries(obj ?? {}).reduce((total, [id, count]) => {
    return id.startsWith('variant') ? total + Number(count) : total;
  }, 0);
