import { CheckIn } from '@holdbar-com/utils-types';

import { TBooking } from '../Hooks/useBookings';
import { apiServices } from './apiServices';
import api from './index';

export const getBookings = async (eventId?: string) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  return await fetchAllPages<TBooking>(apiServices.bookings, {
    eventId,
  });
};

export const getBookingsByDate = async (date: string) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  return await fetchAllPages<TBooking>(apiServices.bookings, {
    date,
  });
};

export const getBooking = async (id: string) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  const { data } = await api.get<TBooking>(`${apiServices.bookings}/${id}`);

  return data;
};

export const createBooking = async <T, R = any>(id: string, payload: T) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  const { data } = await api.post<R>(`${apiServices.bookings}/${id}`, {
    ...payload,
  });

  return data;
};

export const updateBooking = async <T>(id: string, payload: T) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  await api.put(`${apiServices.bookings}/${id}`, {
    ...payload,
  });
};

export const cancelBooking = async (
  id: string,
  shouldRefund: boolean = false
) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  await api.post<unknown>(`${apiServices.bookings}/${id}/cancel`, {
    shouldRefund,
  });
};

export const moveBooking = async (id: string, toEventId: string) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  const { data } = await api.post<{
    newBookingId: string;
    newEventId: string;
  }>(`${apiServices.bookings}/${id}/move`, {
    eventId: toEventId,
  });

  return data;
};

export const checkInBooking = async (id: string, method: CheckIn['method']) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  await api.post<unknown>(`${apiServices.bookings}/${id}/check-in`, { method });
};

const NEXT_CURSOR_HEADER = 'next-cursor';
const NEXT_CURSOR_QUERY = 'nextCursor';

const fetchAllPages = async <T>(
  baseUrl: string,
  params: Partial<Record<string, string>>
): Promise<T[]> => {
  const { data, headers } = await api.get<T[]>(baseUrl, { params });

  const nextCursor = headers[NEXT_CURSOR_HEADER];
  if (!nextCursor || nextCursor === params[NEXT_CURSOR_QUERY]) {
    return data;
  }

  return data.concat(
    await fetchAllPages<T>(baseUrl, {
      ...params,
      [NEXT_CURSOR_QUERY]: nextCursor,
    })
  );
};
