import styled from '@emotion/styled';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { Event, Location } from '@holdbar-com/utils-types';
import { AddOutlined } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { ShowForScope } from '../../../../Components/AllowForScope/AllowForScope';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { IExperience } from '../../../../Hooks/useExperience';
import { isSharedExperience } from '../../utils/is-shared-experience';
import { ExperienceDetailsCard } from '../experience-details-card';
import { User } from '../experience-details-page';
import { EventList } from './event-list';
import { EventListFilter } from './event-list-filter';
import { EventListForm, EventListFormInput } from './event-list-form';
import { EventListPagination } from './event-list-pagination';

type EventListCardProps = {
  eventFilters: EventListFormInput;
  events: Event[];
  maxPage: number;
  users: User[];
  experience: IExperience;
  locations: Location[];
};

export const EventListCard = ({
  eventFilters,
  events,
  maxPage,
  users,
  experience,
  locations,
}: EventListCardProps) => {
  const { t } = useTranslation();
  const { isSm } = useResponsive();
  const location = useLocation();

  const returnURL = location.pathname + location.search;

  return (
    <EventListForm eventFilters={eventFilters}>
      <ExperienceDetailsCard>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingBottom: { xs: 1, sm: 'unset' }, flexWrap: 'wrap' }}
          gap={1}
        >
          <Text variant="medium" fontSize="small">
            {t('experience.details.eventList.title')}
          </Text>
          <EventListFilter locations={locations} />
          {isSm && !isSharedExperience(experience) && (
            <ShowForScope scopes={['event.write']}>
              <StyledButton
                to="/event/create"
                state={{ experienceId: experience.id, returnUrl: returnURL }}
              >
                <AddOutlined fontSize="small" />
              </StyledButton>
            </ShowForScope>
          )}
        </Stack>
        <Divider sx={{ display: { sm: 'none' } }} />
        <EventList events={events} users={users} experience={experience} />
        <EventListPagination
          currentPage={eventFilters.page}
          maxPage={maxPage}
        />
      </ExperienceDetailsCard>
    </EventListForm>
  );
};

const StyledButton = styled(Link)({
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 8,
  borderColor: lightTheme.palette.neutral.n200,
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 32,
  width: 32,
});
