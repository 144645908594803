import { lightTheme, Text } from '@holdbar-com/pixel';
import { Event } from '@holdbar-com/utils-types';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IExperience } from '../../../../Hooks/useExperience';
import { getEventStatus } from '../../../events/get-event-status';
import { StatusChip } from '../experience-details-header';
import { EventTime } from './event-list-item-shared';

export const EventListItemMobile = ({
  experience,
  event,
}: {
  event: Event;
  experience: IExperience;
}) => {
  const { t } = useTranslation();
  const isCancelled = event.states.statusIsCancelled;

  return (
    <Stack sx={{ paddingY: 2, display: { md: 'none' } }}>
      <Stack direction="row" alignItems="center" gap={1}>
        <EventTime
          startDateTime={event.startDateTime}
          endDateTime={event.endDateTime}
        />
        <StatusChip
          variant="indicator"
          status={getEventStatus(event.status, event.states, experience.status)}
        />
      </Stack>
      {isCancelled ? (
        <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
          {t('utils.statusOptions.cancelled')}
        </Text>
      ) : (
        <Text fontSize="xsmall">
          {t('experience.details.value.guests', {
            bookedCount:
              event.bookings?.reduce(
                (total, booking) => (total += booking.slots),
                0
              ) ?? 0,
            totalCount: event.seatCount.value,
          })}
        </Text>
      )}
    </Stack>
  );
};
