import { lightTheme } from '@holdbar-com/pixel';
import {
  addMonths,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getEventTitle,
  getGuidesByIds,
  TEventColor,
  TEventWithTitle,
} from '../../Utils/eventHelpers';
import { useGetLocations } from '../data/useLocations';
import { useExperience } from '../useExperience';
import { useProfile } from '../useProfile';
import { useUsers } from '../useUsers';
import { useGetEvents, usePrefetchEvents } from './useGetEvents';

/**
 * Given a date, the hook will fetch events for the entire month,
 * and prefetch one month prior and one month after.
 */
export const useCalendarEvents = (
  date: Date
): {
  events: TEventWithTitle[];
  isLoading: boolean;
} => {
  const { i18n } = useTranslation();

  const { company } = useProfile();
  const { experiences } = useExperience();
  const { locations } = useGetLocations(company.data?.id ?? '');
  const {
    users: { data: users },
  } = useUsers();

  const { events } = useGetEvents(getFetchingPeriod(date));

  // Prefetch events for the previous and next month
  usePrefetchEvents(getFetchingPeriod(subMonths(date, 1)));
  usePrefetchEvents(getFetchingPeriod(addMonths(date, 1)));

  const experienceColors = useMemo(() => {
    const experienceIds = (experiences.data ?? [])
      .filter((f) => f.status === 'active')
      .map((el) => el.id)
      .filter((el, index, arr) => arr.indexOf(el) === index);

    return experienceIds.reduce<Record<string, TEventColor>>(
      (acc, id, index) => {
        if (!id) return acc;

        const experienceStyle = getColors(index);

        acc[id] = {
          ...experienceStyle,
          color: lightTheme.palette.contrast.black,
        };
        return acc;
      },
      {}
    );
  }, [experiences.data]);

  const mappedEvents = useMemo(() => {
    if (!events.data || !locations.data || !experiences.data || !users)
      return [];

    return events.data.flatMap((event) => {
      const location = locations.data.find(
        (loc) => loc.locationId === event.locationId
      );

      const experience = experiences.data.find(
        (exp) => exp.id === event.experienceId
      );

      const colors = experienceColors[event.experienceId];
      if (!colors) return [];

      const guides = getGuidesByIds(event.assignedGuides ?? [], users);

      return {
        ...event,
        colors,
        title: getEventTitle(experience, i18n.language),
        location: location?.locationName ?? '-',
        ticketTypes: [],
        variants: experience?.price?.variants,
        guide: {
          email: '',
          name: '',
        },
        guides,
      };
    });
  }, [
    events.data,
    experienceColors,
    experiences.data,
    i18n.language,
    locations.data,
    users,
  ]);

  return {
    isLoading:
      events.isLoading ||
      locations.isLoading ||
      experiences.isLoading ||
      company.isLoading,
    events: mappedEvents,
  };
};
const getFetchingPeriod = (date: Date) => {
  const from = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
  const to = endOfWeek(endOfMonth(date), { weekStartsOn: 1 });

  return { from, to };
};

const colors = [
  { borderLeftColor: '#022C12', backgroundColor: '#F5F7F6' }, // Moss
  { borderLeftColor: '#D5DFCA', backgroundColor: '#D5DFCA40' }, // Lichen
  { borderLeftColor: '#091329', backgroundColor: '#EBECEE' }, // Dark Blue
  { borderLeftColor: '#933F29', backgroundColor: '#F6F0EE' }, // Rust
  { borderLeftColor: '#380C0F', backgroundColor: '#EDE9E9' }, // Dark Red
  { borderLeftColor: '#8A0000', backgroundColor: '#F8F0F0' }, // Red
  { borderLeftColor: '#D5BDEA', backgroundColor: '#FBF8FD' }, // Lilac
  { borderLeftColor: '#C9E9FF', backgroundColor: '#F8FCFF' }, // Sky Blue
  { borderLeftColor: '#EEF968', backgroundColor: '#FDFEED' }, // Citrus
];

const getColors = (index: number) => colors[index % colors.length];
