import { Stack } from '@mui/material';

import { OnBoardingWidget } from './onboarding-widget/onboarding-widget';
import { OnboardingWidgetContextProvider } from './onboarding-widget/use-onboarding-widget-context';
import { QuickSetupSection } from './quick-setup-section';

export const DashboardOnboarding = () => {
  return (
    <OnboardingWidgetContextProvider>
      <Stack gap={4} mt={6}>
        <QuickSetupSection />
        <OnBoardingWidget />
      </Stack>
    </OnboardingWidgetContextProvider>
  );
};
