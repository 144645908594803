import NiceModal from '@ebay/nice-modal-react';
import { useCallback, useMemo } from 'react';

import { ampli } from '../../../../../Ampli';
import { initPaymentSetup } from '../../../../../Api/Api';
import { useOnBoarding } from '../../../../../Hooks/useOnBoarding';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { StripeDialog } from '../../../../../Modals/StripeDialog';
import { IntegrationObject } from '../use-integrations';
import { isStripeAllowed } from './payment-integration-permissions';

export const useStripeIntegration = (): IntegrationObject | null => {
  const { t } = useTranslate('settings.integrations');
  const { onBoarding } = useOnBoarding();

  const { company } = useProfile();

  const paymentStatus = useMemo(() => {
    const payment = onBoarding.data?.items.find(
      (el) => el.key === 'payment' && el.steps.some((x) => x.key === 'setup')
    );

    return payment && payment.steps.length > 0 ? payment.steps[0] : undefined;
  }, [onBoarding.data]);

  const openPage = (url: string) => {
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.target = '_blank';
    anchorElement.click();
  };

  const handleConnectStripe = useCallback(
    (status: string) => async () => {
      if (status === 'connect') {
        NiceModal.show(StripeDialog, {
          onClick: async (accountStatus: 'new' | 'existing' | undefined) => {
            const { url } = await initPaymentSetup('stripe', accountStatus);
            ampli.integrationsStripeConnectFlowStarted();
            openPage(url);
          },
        });
      } else {
        if (paymentStatus?.metadata?.existingAccount) {
          const { url } = await initPaymentSetup('stripe', 'existing');
          openPage(url);
        } else {
          const { url } = await initPaymentSetup('stripe', 'new');
          openPage(url);
        }
      }
    },
    [paymentStatus]
  );

  const stripeIntegration = useMemo(() => {
    return {
      id: 'stripe',
      icon: '/integrations/stripe.png',
      title: t('payments.stripe.title'),
      description: t('payments.stripe.description'),
      extendedDescription: t('payments.stripe.extendedDescription'),
      status: paymentStatus?.metadata?.status ?? 'connect',
      buttonLabel: (status: string) => t(`payments.status.${status}`),
      onInstall: handleConnectStripe,
    };
  }, [paymentStatus, t, handleConnectStripe]);

  if (!isStripeAllowed(company.data?.location?.country)) {
    return null;
  }

  return stripeIntegration;
};
