import { ampli } from '../../Ampli';
import { DateWithTime } from '../../features/onboarding/onboarding-flow-form';
import { getFormattedDate } from '../helpers';
import { StepCompletedProperties } from './global-events';

/* Start step */

export const trackQuickSetupWebsiteFieldOpened = () => {
  ampli.quickSetupWebsiteFieldOpened();
};

export const trackQuickSetupCreateManuallyClicked = () => {
  ampli.quickSetupCreateManuallyClicked();
};

export const trackQuickSetupDraftSiteSuccess = (storefront_url: string) => {
  ampli.quickSetupDraftSiteSuccess({ storefront_url });
};

export const trackQuickSetupDraftSiteEditDetailsClicked = (
  experience_id: string
) => {
  ampli.quickSetupDraftSiteEditDetailsClicked({ experience_id });
};

export const trackQuickSetupDraftSiteGoToExperienceClicked = (
  experience_id: string
) => {
  ampli.quickSetupDraftSiteGoToExperienceClicked({ experience_id });
};

export const trackQuickSetupDraftSiteError = () => {
  ampli.quickSetupDraftSiteError();
};

export const trackQuickSetupStartStepCompleted = (
  properties: StepCompletedProperties
) => {
  return ampli.quickSetupStartStepCompleted(properties);
};

/* Name step */
export const trackQuickSetupNameStepNameFieldOpened = () => {
  ampli.quickSetupNameStepNameFieldOpened();
};

export const trackQuickSetupNameStepCompleted = (
  properties: StepCompletedProperties
) => {
  ampli.quickSetupNameStepCompleted(properties);
};

/* Description step */
export const trackQuickSetupDescriptionStepCompleted = (
  properties: StepCompletedProperties
) => {
  ampli.quickSetupDescriptionStepCompleted(properties);
};

/* Media step */
export const trackQuickSetupMediaStepUploadFilesClicked = () => {
  ampli.quickSetupMediaStepUploadFilesClicked();
};

export const trackQuickSetupMediaStepStockPhotoSelected = (
  image_url: string,
  number_of_stock_photos: number
) => {
  ampli.quickSetupMediaStepStockPhotoSelected({
    image_url,
    number_of_stock_photos,
  });
};

export const trackQuickSetupMediaStepMediaItemDeleted = () => {
  ampli.quickSetupMediaStepMediaItemDeleted();
};

export const trackQuickSetupMediaStepCompleted = (
  properties: StepCompletedProperties
) => {
  ampli.quickSetupMediaStepCompleted(properties);
};

/* Location step */
export const trackQuickSetupLocationStepLocationFieldOpened = () => {
  ampli.quickSetupLocationStepLocationFieldOpened();
};

export const trackQuickSetupLocationStepCompleted = (
  properties: StepCompletedProperties
) => {
  ampli.quickSetupLocationStepCompleted(properties);
};

/* Time step */
export type TimeStepSelectionParameters = {
  date: string;
  time: string;
};

export const getTimeStepSelectionProperties = ({
  date,
  time,
}: TimeStepSelectionParameters) => {
  return {
    date: getFormattedDate(DateWithTime(time, new Date(date))),
  };
};

export const trackQuickSetupTimeStepFromDateOpened = () => {
  ampli.quickSetupTimeStepFromDateOpened();
};

export const trackQuickSetupTimeStepFromDateSelected = (
  props: TimeStepSelectionParameters
) => {
  const { date: event_start_date } = getTimeStepSelectionProperties(props);
  ampli.quickSetupTimeStepFromDateSelected({ event_start_date });
};

export const trackQuickSetupTimeStepFromTimeOpened = () => {
  ampli.quickSetupTimeStepFromTimeOpened();
};

export const trackQuickSetupTimeStepFromTimeSelected = (
  props: TimeStepSelectionParameters
) => {
  const { date: event_start_date } = getTimeStepSelectionProperties(props);
  ampli.quickSetupTimeStepFromTimeSelected({ event_start_date });
};

export const trackQuickSetupTimeStepToDateOpened = () => {
  ampli.quickSetupTimeStepToDateOpened();
};

export const trackQuickSetupTimeStepToDateSelected = (
  props: TimeStepSelectionParameters
) => {
  const { date: event_end_date } = getTimeStepSelectionProperties(props);
  ampli.quickSetupTimeStepToDateSelected({ event_end_date });
};

export const trackQuickSetupTimeStepToTimeOpened = () => {
  ampli.quickSetupTimeStepToTimeOpened();
};

export const trackQuickSetupTimeStepToTimeSelected = (
  props: TimeStepSelectionParameters
) => {
  const { date: event_end_date } = getTimeStepSelectionProperties(props);
  ampli.quickSetupTimeStepToTimeSelected({ event_end_date });
};

export const trackQuickSetupTimeStepCompleted = (
  properties: StepCompletedProperties
) => {
  ampli.quickSetupTimeStepCompleted(properties);
};

/* Price step */
export const trackQuickSetupPriceStepTicketNameFieldOpened = () => {
  ampli.quickSetupPriceStepTicketNameFieldOpened();
};

export const trackQuickSetupPriceStepTicketPriceFieldOpened = () => {
  ampli.quickSetupPriceStepTicketPriceFieldOpened();
};

export const trackQuickSetupPriceStepCompleted = (
  properties: StepCompletedProperties
) => {
  ampli.quickSetupPriceStepCompleted(properties);
};

/* End step */
export const trackQuickSetupGoToExperienceClicked = () => {
  ampli.quickSetupGoToExperienceClicked();
};

export const trackQuickSetupOpenPreviewClicked = (url: string) => {
  ampli.quickSetupOpenPreviewClicked({ url });
};
