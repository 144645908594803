import { useFormContext } from 'react-hook-form';

import { TranslatableGroup } from '../../../../../Components/TranslatableGroup/translatable-group';
import { useStorefrontLanguages } from '../../../../../Hooks/translatable/use-storefront-languages';
import { useTranslate } from '../../../../../Hooks/useTranslate';

export const CompanyFormAbout = () => {
  const { t } = useTranslate('settings.company');

  const form = useFormContext();

  const storefrontLanguages = useStorefrontLanguages() ?? [];

  return (
    <TranslatableGroup
      sx={{ maxWidth: 680, mt: 1 }}
      langs={storefrontLanguages}
      id={'description'}
      input={{
        type: 'editor',
        placeholder: t('about.inputPlaceholder'),
      }}
      title={t('about.title')}
      error={
        form.formState.errors.description?.message
          ? t(
              form.formState.errors.description?.message as string,
              'utils.errors'
            )
          : undefined
      }
    />
  );
};
