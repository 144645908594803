import { Box, Stack } from '@mui/material';
import { SetStateAction, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { SimpleSkeleton } from '../../../../Components/SimpleSkeleton/SimpleSkeleton';
import { useCalendarFeed } from '../../../../Hooks/useCalendarFeed';
import { IcalDialogStep } from '../../ical-dialog-step-loader';
import { IcalDialogCreateStepForm } from './form/ical-dialog-create-step-form';
import { IcalDialogCreateStepFormActions } from './form/ical-dialog-create-step-form-actions';
import { IcalFormValues } from './use-ical-dialog-create-step';

type IcalDialogCreateStepProps = {
  hasLoadedInitially: boolean;
  setStep: React.Dispatch<SetStateAction<IcalDialogStep>>;
};

export const IcalDialogCreateStep = ({
  hasLoadedInitially,
  setStep,
}: IcalDialogCreateStepProps) => {
  const [loading, setLoading] = useState(false);

  const { feed, updateFeed } = useCalendarFeed();

  const { handleSubmit } = useFormContext<IcalFormValues>();

  const onSubmit = async (values: IcalFormValues) => {
    setLoading(true);
    try {
      const {
        onlyEventsWithBookings: { selectedOptionKey: onlyEventsWithBookings },
        filterGuides,
        language,
      } = values;

      const payload = {
        language: language.value ?? 'en',
        ...(filterGuides.selectedOptionKey &&
          filterGuides.value.length > 0 && {
            filterGuides: [...filterGuides.value],
          }),
        onlyEventsWithBookings,
      };
      await new Promise((res) => setTimeout(res, 1200));
      updateFeed.mutate(payload);

      setStep(IcalDialogStep.done);
    } finally {
      setLoading(false);
    }
  };

  if (feed.isLoading) {
    return <SimpleSkeleton />;
  }

  return (
    <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <IcalDialogCreateStepForm />
        <IcalDialogCreateStepFormActions
          {...{ loading, hasLoadedInitially, setStep }}
        />
      </Stack>
    </Box>
  );
};
