import styled from '@emotion/styled';
import { Button, lightTheme } from '@holdbar-com/pixel';
import { MediaItem } from '@holdbar-com/utils-types';
import { CheckRounded, ClearOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useMemo } from 'react';

import { LocalMediaItem } from '../preview-experience/media-preview';
import { Progress } from './media-upload';

export const Media = ({
  mediaItem,
  handleClear,
  handleClick,
  uploaded,
  progress,
  disabled,
  children,
}: {
  mediaItem: LocalMediaItem;
  handleClear?: (mediaItem: MediaItem) => void;
  handleClick?: () => void;
  uploaded?: boolean;
  progress?: number;
  disabled?: boolean;
  children: React.ReactNode;
}) => {
  const disableClick = useMemo(
    () => disabled || uploaded || progress,
    [disabled, uploaded, progress]
  );

  return (
    <Stack
      position="relative"
      justifyContent="center"
      alignItems="center"
      onClick={() => (!disableClick ? handleClick?.() : {})}
      sx={{
        opacity: disableClick ? '0.75' : 'unset',
        ':hover':
          !disableClick && handleClick
            ? { cursor: 'pointer', opacity: 0.75 }
            : {},
        transition: 'opacity 0.1s ease-in-out',
      }}
    >
      {children}
      {handleClear && (
        <ClearButton
          type="button"
          variant="text"
          size="small"
          onClick={() => handleClear(mediaItem)}
        >
          <ClearOutlined fontSize="inherit" />
        </ClearButton>
      )}
      {uploaded && (
        <UploadedLabel>
          <CheckRounded fontSize="inherit" />
        </UploadedLabel>
      )}
      {typeof progress === 'number' && <Progress progress={progress} />}
    </Stack>
  );
};

const ClearButton = styled(Button)({
  position: 'absolute',
  top: '8px',
  right: '8px',
  borderRadius: '100%',
  height: '18px',
  width: '18px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  fontSize: '14px',
  transition: 'backgroudn 0.1 ease-in-out',
  span: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '&:hover': {
    background: lightTheme.palette.neutral.n100,
  },
});

const UploadedLabel = styled(Stack)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  justifyContent: 'center',
  alignItems: 'center',
  background: lightTheme.palette.success.s400,
  borderRadius: '100%',
  height: '48px',
  width: '48px',
  color: 'white',
  fontSize: '20px',
});
