import { LinkButton } from '@holdbar-com/pixel';
import { useHref } from 'react-router';
import { useLinkClickHandler } from 'react-router-dom';

export function RouterLinkButton({
  href,
  variant,
  children,
}: {
  href: string;
  variant: 'primary' | 'secondary';
  children: React.ReactNode;
}) {
  const to = useHref(href);
  const clickHandler = useLinkClickHandler(to);
  return (
    <LinkButton
      variant={variant}
      size="medium"
      href={to}
      onClick={(event) => {
        if (!event.defaultPrevented) {
          clickHandler(event);
        }
      }}
    >
      {children}
    </LinkButton>
  );
}
