import { ampli } from '../../Ampli';

export const trackConnectLinkClicked = (source?: string) =>
  ampli.connectLinkClicked({ source });

export const trackConnectPageViewed = (
  num_invitations: number,
  num_connections: number
) => ampli.connectPageViewed({ num_invitations, num_connections });

export const trackInviteNewConnectionStarted = () =>
  ampli.connectInviteNewConnectionStarted();

export const trackInviteNewConnectionCompleted = (
  experience_selection: 'all' | 'selection' | 'none',
  num_experiences_available: number,
  num_experiences_selected: number
) =>
  ampli.connectInviteNewConnectionCompleted({
    experience_selection,
    num_experiences_available,
    num_experiences_selected,
  });

export const trackConnectEditStarted = (
  cnct_company_name: string,
  num_monthly_bookings?: number,
  num_total_bookings?: number
) =>
  ampli.connectEditStarted({
    cnct_company_name,
    num_monthly_bookings,
    num_total_bookings,
  });

export const trackConnectEditCompleted = (change_selection: string) =>
  ampli.connectEditSaved({
    change_selection,
  });

export const trackConnectEmailCopied = (
  cnct_company_name: string,
  num_monthly_bookings?: number,
  num_total_bookings?: number
) =>
  ampli.connectEMailCopied({
    cnct_company_name,
    num_monthly_bookings,
    num_total_bookings,
  });

export const trackConnectDisconnectStarted = (
  cnct_company_name: string,
  num_monthly_bookings?: number,
  num_total_bookings?: number
) =>
  ampli.connectDisconnectStarted({
    cnct_company_name,
    num_monthly_bookings,
    num_total_bookings,
  });

export const trackConnectDisconnectCompleted = (
  cnct_company_name: string,
  num_monthly_bookings?: number,
  num_total_bookings?: number
) =>
  ampli.connectDisconnectCompleted({
    cnct_company_name,
    num_monthly_bookings,
    num_total_bookings,
  });

export const trackConnectDetailsViewed = (
  cnct_company_name: string,
  days_since_status: number,
  status_connect: string
) =>
  ampli.connectConnectionDetailsViewed({
    cnct_company_name,
    days_since_status,
    status_connect,
  });

export const trackAcceptConnectionStarted = () =>
  ampli.connectAcceptConnectionStarted();

export const trackAcceptConnectionCompleted = (
  experience_selection: 'all' | 'selection' | 'none',
  num_experiences_available: number,
  num_experiences_selected: number,
  days_since_invite_received: number
) =>
  ampli.connectAcceptConnectionCompleted({
    experience_selection,
    num_experiences_available,
    num_experiences_selected,
    days_since_invite_received,
  });
