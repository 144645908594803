import { Stack } from '@mui/material';

import useResponsive from '../../../../../../Hooks/layout/useResponsive';
import { DashboardGraphsLoaderCardMetricsList } from './dashboard-graphs-loader-card-metrics-list';
import { DashboardGraphsLoaderCardMetricsSelected } from './dashboard-graphs-loader-card-metrics-selected';

type DashboardGraphsLoaderCardMetricsProps = {
  selectedMetric: {
    key: string;
    value: number;
  } | null;
  metrics: {
    [key: string]: number;
  };
  handleSelectMetric: (key: string) => void;
};

export const DashboardGraphsLoaderCardMetrics = ({
  selectedMetric,
  metrics,
  handleSelectMetric,
}: DashboardGraphsLoaderCardMetricsProps) => {
  const { isSm } = useResponsive();

  return (
    <Stack
      gap={2}
      direction={isSm ? 'column' : 'row'}
      justifyContent={'space-between'}
    >
      <DashboardGraphsLoaderCardMetricsSelected
        selectedMetric={selectedMetric}
      />
      <DashboardGraphsLoaderCardMetricsList
        selectedMetric={selectedMetric}
        metrics={metrics}
        handleSelectMetric={handleSelectMetric}
      />
    </Stack>
  );
};
