import { Button, lightTheme } from '@holdbar-com/pixel';
import { styled } from '@mui/material';

import { ShortcutTooltip } from '../../../Components/tooltip/shortcut-tooltip';
import { useShortcuts } from '../../../Hooks/useShortcuts';
import { useTranslate } from '../../../Hooks/useTranslate';
import { trackCalendarViewChanged } from '../../../tracking/calendar/trackCalendarViewChanged';
import { CALENDAR_VIEWS, useCalendar } from '../context';

export const CalendarViewSelector = () => {
  const { selectedView, setSelectedView } = useCalendar();

  const { t } = useTranslate('utils.generic');

  useShortcuts(
    CALENDAR_VIEWS.map((view) => ({
      key: view[0],
      action: () => setSelectedView(view),
    }))
  );

  return (
    <ButtonGroup>
      {CALENDAR_VIEWS.map((view) => (
        <ShortcutTooltip
          key={view}
          title={`${t('changeView', 'calendar')} ${t(view)}`}
          shortcut={view[0]}
          hideCommandKey
        >
          <StyledButton
            size={'medium'}
            onClick={() => {
              trackCalendarViewChanged(view);
              setSelectedView(view);
            }}
            variant={'text'}
            data-selected={selectedView === view}
          >
            {t(view)}
          </StyledButton>
        </ShortcutTooltip>
      ))}
    </ButtonGroup>
  );
};

const StyledButton = styled(Button)({
  flex: 1,
  height: '32px',
  padding: '0 24px',
  backgroundColor: lightTheme.palette.contrast.white,
  color: lightTheme.palette.contrast.black,

  border: '1px solid',
  borderRadius: 0,
  borderColor: lightTheme.palette.neutral.n200,

  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },

  '&[data-selected=true]': {
    backgroundColor: lightTheme.palette.neutral.n100,
    borderColor: lightTheme.palette.action.a300,
    color: lightTheme.palette.action.a300,
  },

  // If the button has a sibling that is selected, hide the right border
  '&:has(+ [data-selected=true])': {
    borderRightColor: 'transparent',
  },

  // If the button is not the first child and is not selected, hide the left border
  '&:not(:first-of-type, [data-selected=true])': {
    borderLeftColor: 'transparent',
  },

  '&:first-of-type': {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },

  '&:last-child': {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const ButtonGroup = styled('div')({
  display: 'flex',

  width: 'fit-content',
});
