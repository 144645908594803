import { TFunction } from 'i18next';

const renderDuration = (t: TFunction, ...params: string[]) => {
  const last = params.pop();
  if (params.length === 0) {
    return last ?? '';
  }
  return params.join(', ') + ` ${t('and')} ` + last;
};

export const renderDurationString = (
  t: TFunction,
  mins: number | undefined
) => {
  if (typeof mins !== 'number') {
    return '';
  }
  const days = Math.floor(mins / (60 * 24));
  const hours = Math.floor((mins % (60 * 24)) / 60);
  const minutes = mins % 60;

  const units = [
    { keySingular: 'day', keyPlural: 'days', value: days },
    { keySingular: 'hour', keyPlural: 'hours', value: hours },
    { keySingular: 'minute', keyPlural: 'minutes', value: minutes },
  ]
    .filter((unit) => unit.value > 0)
    .map(({ keySingular, keyPlural, value }) => {
      const plural = value > 1;
      return `${value} ${t(plural ? keyPlural : keySingular)}`;
    });

  return renderDuration(t, ...units);
};
