import { matchIsValidTel } from 'mui-tel-input';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useVariantSelectValidation } from '../../../../../../../Components/VariantSelect/use-variant-select-validation';

export const useBookingFormValidation = (experienceId: string) => {
  const { t } = useTranslation();

  const guestShape = useVariantSelectValidation(experienceId);

  return useMemo(() => {
    return yup.object({
      name: yup
        .string()
        .required(t('eventUpsert.flow.booking.form.errors.required')),
      email: yup
        .string()
        .email(t('eventUpsert.flow.booking.form.errors.email'))
        .required(t('eventUpsert.flow.booking.form.errors.required')),
      phone: yup
        .string()
        .notRequired()
        .test('mustMatchCountry', t('utils.errors.incorrectPhone'), (value) =>
          value ? matchIsValidTel(value) : true
        ),
      internalNotes: yup.string().notRequired(),
      language: yup
        .string()
        .required(t('eventUpsert.flow.booking.form.errors.required')),
      guests: guestShape,
      shouldSendNotification: yup.boolean().notRequired(),
      paymentMethod: yup.string().required().default('none'),
      vatNumber: yup.string().notRequired(),
      companyName: yup.string().notRequired(),
      location: yup
        .object({
          address: yup.string().notRequired(),
          city: yup.string().notRequired(),
          zipCode: yup.string().notRequired(),
          country: yup.string().notRequired(),
        })
        .notRequired(),
    });
  }, [guestShape, t]);
};

export type BookingFormData = yup.InferType<
  ReturnType<typeof useBookingFormValidation>
>;
