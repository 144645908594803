import { Location } from '@holdbar-com/utils-types';
import { MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { eventStates, eventStatuses } from '../../../../Utils/eventHelpers';
import { EventListFormInput } from './event-list-form';

type EventListFilterProps = {
  locations: Location[];
};

export const EventListFilter = ({ locations }: EventListFilterProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<EventListFormInput>();

  const { field: locationField } = useController({
    name: 'locationId',
    defaultValue: 'all',
  });
  const { field: stateField } = useController({ name: 'state', control });
  const { field: statusField } = useController({ name: 'status', control });

  return (
    <Stack direction="row" gap={1} sx={{ display: { xs: 'none', sm: 'flex' } }}>
      {!!locations?.length && (
        <Select
          size="small"
          input={<OutlinedInput />}
          sx={{ minWidth: 140 }}
          value={locationField.value}
          onChange={(e) => locationField.onChange(e.target.value)}
        >
          <MenuItem value="all">
            {t('utils.tables.filters.allLocations', '')}
          </MenuItem>
          {locations.map(({ locationName, locationId }, index) => (
            <MenuItem value={locationId} key={`loc-${index}`}>
              {locationName}
            </MenuItem>
          ))}
        </Select>
      )}
      <Select
        size="small"
        input={<OutlinedInput />}
        sx={{ minWidth: 160 }}
        value={stateField.value}
        onChange={(e) => stateField.onChange(e.target.value)}
      >
        {eventStates.map((state) => (
          <MenuItem key={state} value={state}>
            {t(`experience.details.eventList.stateFilter.label.${state}`)}
          </MenuItem>
        ))}
      </Select>
      <Select
        size="small"
        input={<OutlinedInput />}
        sx={{ minWidth: 140 }}
        value={statusField.value}
        onChange={(e) => statusField.onChange(e.target.value)}
      >
        {eventStatuses.map((status) => (
          <MenuItem key={status} value={status}>
            {t(`experience.details.eventList.statusFilter.label.${status}`)}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
