import { StorefrontFeatures } from '@holdbar-com/utils-types';
import { useEffect } from 'react';

import { ampli } from '../Ampli';
import { useProfile } from './useProfile';

export function useAmplitude() {
  const { me, company, userinfo } = useProfile();

  useEffect(() => {
    const ampliProps = {} as {
      userId: string | undefined;
      role: string | undefined;
      companyId: string | undefined;
      companyName: string | undefined;
      userEmail: string | undefined;
    };

    if (me.isSuccess) {
      // Ampli
      ampliProps.userId = me?.data?.id;
      ampliProps.userEmail = me?.data?.email;
    }

    if (userinfo.isSuccess) {
      // Ampli
      ampliProps.role = userinfo.data?.role;
    }

    if (company.isSuccess) {
      // Ampli
      ampliProps.companyId = company?.data?.id;
      ampliProps.companyName = company?.data?.name;
    }

    // Identify with Ampli
    if (ampliProps.userId) {
      const { features } = company.data ?? {};
      const enabledFeatures = Object.keys(features ?? {}).filter(
        (key) => features?.[key as keyof StorefrontFeatures]?.enabled
      );

      ampli.identify(ampliProps.userId, {
        Role: ampliProps.role,
        'Company Id': ampliProps.companyId,
        'Company Name': ampliProps.companyName,
        'Booking Reminder Enabled': !!features?.bookingReminder?.enabled,
        'Booking Reminder Time':
          features?.bookingReminder?.params?.hoursBeforeEvent,
        'Gift Card Purchase Enabled': !!features?.giftCards?.enabled,
        'Move Booking Enabled': !!features?.allowGuestsMoveBooking?.enabled,
        'Cancel Booking Enabled': !!features?.allowGuestsCancelBooking?.enabled,
        'Upcoming Events Enabled':
          !!features?.showUpcomingEventsInStorefront?.enabled,
        'Event Requests Enabled': !!features?.requests?.enabled,
        '# Features': enabledFeatures.length,
        'Feature List': enabledFeatures,
        'Is Internal':
          ampliProps.userEmail?.includes('@holdbar.com') ||
          ampliProps.userEmail?.includes('@understory.io'),
        'Onboarding Path': me.data?.metadata?.signupType ?? undefined,
      });

      if (company.data?.id) {
        ampli.client?.setGroup('company', [company.data.id, company.data.name]);
      }
    }
  }, [
    me.isSuccess,
    me.data,
    company.isSuccess,
    company.data,
    userinfo.data?.role,
    userinfo.isSuccess,
  ]);
}
