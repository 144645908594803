import { Box, BoxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

export const SectionContainer = ({
  title,
  children,
  ...props
}: BoxProps & { title?: string; children: ReactNode }) => (
  <Box p={3} {...props}>
    {title && (
      <Typography variant={'h4'} mb={2}>
        {title}
      </Typography>
    )}
    {children}
  </Box>
);
