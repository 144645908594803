import { apiServices } from './apiServices';
import api from './index';

export type CalendarFeedConfig = {
  language: string;
  onlyEventsWithBookings: boolean;
  accessKey?: string;
  filterGuides?: string[];
};

export const getCalendarFeed = async () => {
  if (typeof apiServices['calendar-feeds'] === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_CALENDAR_FEEDS_URL is not defined'
    );
  }

  const { data } = await api.get(`${apiServices['calendar-feeds']}/feed`);

  return data;
};

export const updateCalendarFeed = async (payload: CalendarFeedConfig) => {
  if (typeof apiServices['calendar-feeds'] === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_CALENDAR_FEEDS_URL is not defined'
    );
  }

  const { data } = await api.put<{ accessKey: string }>(
    `${apiServices['calendar-feeds']}/feed`,
    { ...payload }
  );

  return data;
};

export const deleteCalendarFeed = async () => {
  if (typeof apiServices['calendar-feeds'] === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_CALENDAR_FEEDS_URL is not defined'
    );
  }

  await api.delete(`${apiServices['calendar-feeds']}/feed`);
};
