import { useEffect, useMemo } from 'react';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useExperience } from '../../../../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import {
  trackOverviewExperienceFilterOpened,
  trackOverviewExperienceFilterSelected,
} from '../../../../../tracking/overview/interactions';
import { MultiSelectFilter } from '../../../../calendar/filters/view/MultiSelectFilter';
import { useDashboardPageContext } from '../../../use-dashboard-page-context';

export const DashboardGraphsFilterExperience = () => {
  const { t } = useTranslate('utils.generic');
  const { isSm } = useResponsive();
  const localized = useLocalizedStringFormatter();
  const {
    experiences: { data, isLoading },
  } = useExperience();
  const { experienceFilter, setExperienceFilter } = useDashboardPageContext();

  useEffect(() => {
    (document.activeElement as HTMLElement)?.blur();
  }, [experienceFilter]);

  const options = useMemo(() => {
    if (!data) return [];

    return data
      .filter(({ status }) => status === 'active')
      .map(({ id, headline }) => ({
        id,
        name: localized(headline),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [data, localized]);

  return (
    <MultiSelectFilter
      inputId="experiences-filter"
      label={t('experiences')}
      options={options}
      checkedIds={experienceFilter}
      onChange={(value) => {
        setExperienceFilter(value);
        trackOverviewExperienceFilterSelected(value.toString(), data);
      }}
      isLoading={isLoading}
      onOpen={() => trackOverviewExperienceFilterOpened(data?.length ?? 0)}
      fullWidth={false}
      sx={{
        minWidth: isSm ? '50%' : 180,
        maxWidth: isSm ? '50%' : 180,
        flexGrow: 1,
        '& .MuiInputBase-root': { height: 40 },
      }}
    />
  );
};
