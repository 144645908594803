import { Recipient } from '../features/bookings/booking_details/ui/booking_details_summary/ui/notifications/domain/types';
import { apiServices } from './apiServices';
import api from './index';

export const getTemplates = async () => {
  if (typeof apiServices.notifications === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined'
    );
  }

  const { data } = await api.get<{ groupKey: string; templateId: string }[]>(
    `${apiServices.notifications}/templates/editable`
  );

  return data;
};

export const getSettings = async () => {
  if (typeof apiServices.notifications === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined'
    );
  }

  const { data } = await api.get<{ [k: string]: boolean }>(
    `${apiServices.notifications}/settings`
  );

  return data;
};

export const updateSettings = async (payload?: { [k: string]: boolean }) => {
  if (typeof apiServices.notifications === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined'
    );
  }

  if (!payload) {
    throw new Error('Missing payload');
  }

  const { data } = await api.put(
    `${apiServices.notifications}/settings`,
    payload
  );

  return data;
};

export const getNotificationLog = async (notificationId: string) => {
  if (typeof apiServices.notifications === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined'
    );
  }

  const { data } = await api.get(
    `${apiServices.notifications}/logs/${notificationId}`
  );

  return data;
};

export const resendNotification = async (
  notificationId: string,
  bookingRecipient: Recipient,
  newRecipient?: Recipient
) => {
  if (typeof apiServices.notifications === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined'
    );
  }

  await api.post(`${apiServices.notifications}/resend`, {
    notificationId,
    recipient: bookingRecipient,
    ...(newRecipient && { newRecipient }),
  });
};

export const sendSlackNotification = async (
  message: string,
  channel:
    | '#customer-report-requests'
    | '#growth-opportunity-requests'
    | '#product-integration-requests'
    | '#product-connect-requests'
) => {
  if (typeof apiServices.notifications === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined'
    );
  }

  await api.post(`${apiServices.notifications}/notifications/slack`, {
    message,
    channel,
  });
};
