import { lightTheme, Text } from '@holdbar-com/pixel';
import { Card, Divider, Stack, styled, SxProps } from '@mui/material';
import { ReactNode } from 'react';

type InfoBoxProps = {
  title: string;
  subtitle?: string;
  actions?: ReactNode;
  children: ReactNode;
  sx?: SxProps;
};

export const InfoBox = ({
  title,
  actions,
  children,
  sx,
  subtitle,
}: InfoBoxProps) => {
  return (
    <StyledInfoBox sx={sx}>
      <StyledHeader pt={3} pb={2} px={4}>
        <Stack>
          <StyledText variant="medium">{title}</StyledText>
          {subtitle && (
            <StyledSubTitle fontSize="xsmall">{subtitle}</StyledSubTitle>
          )}
        </Stack>
        {actions}
      </StyledHeader>
      <Divider sx={{ marginLeft: 4, marginRight: 4 }} />
      <Stack
        px={4}
        py={3}
        fontSize={lightTheme.typography.small.normal.fontSize}
      >
        {children}
      </Stack>
    </StyledInfoBox>
  );
};

const StyledInfoBox = styled(Card)({
  width: '100%',
  padding: 0,
  borderRadius: 8,
});

const StyledHeader = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledText = styled(Text)({
  userSelect: 'none',
});

const StyledSubTitle = styled(Text)({
  color: lightTheme.palette.neutral.n300,
  wordWrap: 'break-word',
});
