import {
  ActionFunctionArgs,
  LoaderFunction,
  redirect,
  useLoaderData,
} from 'react-router';
import { Form, useSubmit } from 'react-router-dom';

import {
  getConnections,
  getInvitation,
  getPublicCompanyProfile,
  getUserinfo,
  refreshToken,
} from '../../../Api';
import { useAuthStore as authStore } from '../../../Store/useAuthStore';
import {
  Account,
  ChooseAccountDialog,
  CompanyPickerFormValues,
} from '../components/choose-account-dialog';
import { connectStore } from '../connect-store';

type LoaderData = {
  companies: Account[];
  invitedEmail: string;
  currentUserEmail: string;
  invitationToken: string;
};

export const loader: LoaderFunction<LoaderData> = async ({ params }) => {
  const token = params.token;
  if (!token) {
    throw new Response('Invalid token', { status: 400 });
  }
  const [invitation, connections, userInfo] = await Promise.all([
    getInvitation(token),
    getConnections(),
    getUserinfo(),
  ]);
  if (!invitation) {
    throw new Response('Not found', { status: 404 });
  }

  if (connections.some((c) => 'token' in c && c.token === invitation.token)) {
    // The invitation was made explicitly for this company. Redirect to /settings/connect where it will be shown.',
    return redirect('/settings/connect');
  }

  const memberOfCompanies = (
    await Promise.all(
      userInfo.memberOfCompanies.map(async (companyId) =>
        getPublicCompanyProfile(companyId)
      )
    )
  )
    .filter((x: Account | null): x is Account => x !== null)
    .map((x) => ({
      id: x.id,
      name: x.name,
      logo: x.pictures?.logo?.url,
    }));

  return {
    companies: memberOfCompanies,
    invitedEmail: invitation.receiverEmail,
    currentUserEmail: userInfo.email,
    invitationToken: token,
  };
};

export async function action({ request, params }: ActionFunctionArgs) {
  const invitationToken = params.token;
  const json = (await request.json()) as CompanyPickerFormValues;

  const { auth, setAuth } = authStore.getState();
  if (!auth) {
    return redirect('/login');
  }

  connectStore
    .getState()
    .chooseAccountForInvitation(json.org, invitationToken!);

  const userInfo = await getUserinfo();
  if (userInfo.org === json.org) {
    return redirect('/settings/connect');
  }

  const authToken = await refreshToken(auth.refresh_token, json.org);
  setAuth(authToken);

  return (window.location.href = '/settings/connect');
}

export function InvitationDialog() {
  const { companies, invitedEmail, currentUserEmail, invitationToken } =
    useLoaderData() as LoaderData;
  const submit = useSubmit();

  return (
    <Form method="post">
      <ChooseAccountDialog
        onSubmit={(data) =>
          submit(data, { method: 'POST', encType: 'application/json' })
        }
        companies={companies}
        invitedEmail={invitedEmail}
        currentUserEmail={currentUserEmail}
        invitationToken={invitationToken}
      />
    </Form>
  );
}
