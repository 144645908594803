import NiceModal from '@ebay/nice-modal-react';
import { captureException } from '@sentry/react';
import {
  ComponentPropsWithoutRef,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ObjectSchema } from 'yup';

import { useTranslate } from '../../Hooks/useTranslate';
import { OptionsDialog } from '../../Modals/OptionsDialog';

interface IContext {
  errors?: {
    [k: string]: string;
  };
  getError?: (k: string, s?: string) => string | undefined;
  clearError?: (k: string, s?: string) => void;
  validate?: (data: any, useModal?: boolean) => Promise<void | 'saveDraft'>;
  setSchema?: (schema?: any) => void;
  setId?: (id?: any) => void;
  id?: string;
}

export const ErrorStore = createContext<IContext>({});

export const useErrors = (schema?: ObjectSchema<any>, id?: string) => {
  const { errors, getError, clearError, validate, setSchema, setId } =
    useContext(ErrorStore);
  useEffect(() => {
    setId?.((p: any) => id ?? p);
    setSchema?.((p: any) => {
      if (id && !schema) {
        return null;
      }
      return schema ?? p;
    });
  }, []);
  return {
    errors,
    getError,
    clearError,
    validate,
  } as Required<IContext>;
};

export const ErrorProvider = ({ children }: ComponentPropsWithoutRef<any>) => {
  const { t } = useTranslate('dialogs.createValidationMissingProps');

  const [schema, setSchema] = useState<ObjectSchema<any> | null>(null);
  const [id, setId] = useState<string | null>(null);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setErrors({});
  }, [id]);

  const validate = async (data: any, useModal = true) => {
    if (!schema) {
      return;
    }
    try {
      await schema?.validate(data, { abortEarly: false, strict: true });
      setErrors({});
    } catch (err: any) {
      captureException(err, (scope) => {
        scope.setTransactionName(`Failed to validate form`);
        scope.setExtra('errors', err.errors);
        return scope;
      });
      if (useModal) {
        const choice = await NiceModal.show(OptionsDialog, {
          title: t('title', { errorCount: err.errors.length }),
          headline: t('headline'),
          buttons: [
            {
              key: 'saveDraft',
              label: t('actions.secondary'),
              props: {
                variant: 'outlined',
                color: 'secondary',
              },
            },
            {
              key: 'continue',
              label: t('actions.primary'),
              props: {
                variant: 'contained',
              },
            },
          ],
        });

        if (choice === 'saveDraft') {
          return Promise.reject('saveDraft');
        }
      }

      const mapped = (err.inner as any[]).reduce(
        (acc, { path, type, message }) => {
          return {
            ...acc,
            [path]: {
              type,
              message,
            },
          };
        },
        {} as { [key: string]: { type: string; message: string } }
      );
      setErrors(mapped);
      return Promise.reject();
    }
  };

  const clearError = (key: string, suffix?: string) => {
    setErrors((p: any) => {
      if (suffix) {
        delete p[`${key}.${suffix}`];
      }
      delete p[key];
      delete p[`${key}.da`];
      delete p[`${key}.en`];
      delete p[`${key}.selectedOptionKey`];
      delete p[`${key}.value`];
      return { ...p };
    });
  };

  const getError = useCallback(
    (key: string, suffix?: string) => {
      const found =
        errors?.[key] ??
        (suffix ? errors?.[`${key}.${suffix}`] : null) ??
        errors?.[`${key}.selectedOptionKey`] ??
        errors?.[`${key}.value`] ??
        errors?.[`${key}.da`] ??
        errors?.[`${key}.en`];
      return found?.type === 'required'
        ? t('required', 'utils.errors')
        : found?.message
          ? t(found.message, 'utils.errors') ?? t('required', 'utils.errors')
          : undefined;
    },
    [errors]
  );

  return (
    <ErrorStore.Provider
      value={{ errors, getError, clearError, setSchema, setId, validate }}
    >
      {children}
    </ErrorStore.Provider>
  );
};
