import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { CircularProgress, Dialog, Stack } from '@mui/material';
import { useState } from 'react';

import useResponsive from '../Hooks/layout/useResponsive';
import { useTranslate } from '../Hooks/useTranslate';
import { CloseIcon } from '../Icons';

interface ConfirmDialogProps {
  title: string;
  description: string;
  cta: string;
  important?: boolean;
  hideCancel?: boolean;
  showLoading?: boolean;
  closeOnConfirm?: boolean;
  onConfirm: () => Promise<void>;
}

export const ConfirmDialog = NiceModal.create<ConfirmDialogProps>(
  ({
    title,
    description,
    cta,
    important,
    hideCancel,
    showLoading,
    closeOnConfirm = true,
    onConfirm,
  }) => {
    const { t } = useTranslate('utils.generic');
    const { isSm } = useResponsive();
    const modal = useModal();
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
      modal.reject('confirm-dialog');
      modal.hide();
    };

    const handleConfirm = async () => {
      if (showLoading) {
        setLoading(true);
        await onConfirm();
        setLoading(false);
      } else {
        onConfirm();
      }

      if (closeOnConfirm) handleClose();
    };

    return (
      <Dialog
        open={modal.visible}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        fullScreen={isSm}
      >
        <Stack gap={4} p={4} height={'100%'} justifyContent={'space-between'}>
          <Stack gap={2}>
            <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
              <Stack gap={1}>
                <Text fontSize="xlarge" variant="medium">
                  {title}
                </Text>
                {!isSm && (
                  <Text
                    fontSize="small"
                    color={lightTheme.palette.neutral.n400}
                  >
                    {description}
                  </Text>
                )}
              </Stack>
              <CloseIcon
                onClick={handleClose}
                sx={{ cursor: 'pointer', fontSize: 32, color: '#636366' }}
              />
            </Stack>
            {isSm && (
              <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
                {description}
              </Text>
            )}
          </Stack>
          <Stack
            gap={isSm ? 1 : 2}
            width={'100%'}
            direction={isSm ? 'column-reverse' : 'row'}
          >
            {!hideCancel && (
              <Button
                variant="secondary"
                size="medium"
                onClick={handleClose}
                style={{ flexGrow: 1, maxWidth: isSm ? '100%' : '50%' }}
              >
                {t('cancel')}
              </Button>
            )}
            <Button
              variant={important ? 'secondary' : 'primary'}
              size="medium"
              type="submit"
              disabled={loading}
              style={{
                flexGrow: 1,
                color: important ? lightTheme.palette.error.e400 : undefined,
                maxWidth: isSm || hideCancel ? '100%' : '50%',
              }}
              onClick={handleConfirm}
            >
              {loading ? <CircularProgress size={16} color="inherit" /> : cta}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    );
  }
);
