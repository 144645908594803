import { AxiosRequestConfig } from 'axios';

import { apiServices } from './apiServices';
import api from './index';

export const initializeGoogleAuth = async () => {
  if (typeof apiServices['auth-google'] === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_AUTH_GOOGLE is not defined'
    );
  }

  const { data } = await api.post<{ url: string }>(
    `${apiServices['auth-google']}`,
    {}
  );

  return data;
};

export const getTranslation = async (payload: {
  text: string;
  targetLanguageCode: string;
  sourceLanguageCode?: string;
}) => {
  if (typeof apiServices.translate === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_TRANSLATE_URL is not defined'
    );
  }

  const { data } = await api.post<{ text: string }>(
    `${apiServices.translate}`,
    { ...payload }
  );

  return data;
};

export const initPaymentSetup = async (
  paymentMethod: string,
  accountStatus: 'new' | 'existing' | undefined,
  params: AxiosRequestConfig['params'] = {}
) => {
  if (typeof apiServices.payment === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PAYMENT_URL is not defined'
    );
  }

  const url = `${apiServices.payment}/setup/${paymentMethod}`;

  if (accountStatus === 'existing') {
    params['existing'] = 1;
  }

  const { data } = await api.get<{ url: string }>(url, {
    params,
  });

  return data;
};

export const disconnectPayment = async (paymentMethod: string) => {
  if (typeof apiServices.payment === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PAYMENT_URL is not defined'
    );
  }

  const url = `${apiServices.payment}/setup/${paymentMethod}`;
  const { data } = await api.delete<{ url: string }>(url);

  return data;
};

export const messageParticipants = async (
  eventId: string,
  userId: string,
  message: string
) => {
  if (typeof apiServices.events === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  const { data } = await api.post(`${apiServices.events}/${eventId}/message`, {
    message,
    userId,
  });

  return data;
};
