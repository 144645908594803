import { useQuery, useQueryClient } from '@tanstack/react-query';

import * as api from '../../Api';
import { TEventFilter, transformEvents } from '../../Utils/eventHelpers';

export const EVENT_LIST_MAX_EVENTS_PER_PAGE = 100;

export const useExperienceEvents = (
  experienceId?: string,
  eventFilter: TEventFilter = { state: 'future', status: 'anyStatus' },
  page: number = 1,
  limit: number = EVENT_LIST_MAX_EVENTS_PER_PAGE
) => {
  const queryClient = useQueryClient();

  const EventsForExperienceQueryKey = [
    'events',
    { type: 'experience', experienceId },
    page,
    eventFilter,
    limit,
  ];

  const fetchEventsForExperience = async (pageParam: number) => {
    const { events, totalCount } = await api.getEventsInExperience(
      experienceId as string,
      (pageParam - 1) * limit,
      limit,
      eventFilter
    );
    return {
      totalCount,
      events: transformEvents(events),
    };
  };

  const eventsForExperience = useQuery({
    queryKey: EventsForExperienceQueryKey,
    queryFn: () => fetchEventsForExperience(page),
    enabled: Boolean(experienceId),
  });

  const fetchUpcomingEventsCount = async () => {
    const { events } = await api.getEventsInExperience(
      experienceId as string,
      0,
      500,
      { state: 'future', status: 'anyStatus' }
    );
    return events.filter((event) => event.status === 'active').length;
  };

  const UpcomingEventsCountQueryKey = ['upcomingEventsCount', experienceId];

  const upcomingEventsCount = useQuery({
    queryKey: UpcomingEventsCountQueryKey,
    queryFn: () => fetchUpcomingEventsCount(),
    enabled: Boolean(experienceId),
  });

  return {
    eventsForExperience,
    EventsForExperienceQueryKey,
    upcomingEventsCount,
    refetch: () =>
      queryClient.invalidateQueries({
        queryKey: EventsForExperienceQueryKey,
      }),
  };
};
