import {
  ConnectedItem,
  Connection,
  RequestItem,
} from '@holdbar-com/utils-types';

import { apiServices } from './apiServices';
import api from './index';

const API_ENDPOINT = apiServices.internalApi;

export async function getConnections() {
  if (typeof API_ENDPOINT === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const response = await api.get<Connection[]>(
    `${API_ENDPOINT}/v1/connect/connections`
  );

  return response.data;
}

export async function getConnectionItem(companyId: string, token: string) {
  if (typeof API_ENDPOINT === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const response = await api.get<Connection>(
    `${API_ENDPOINT}/v1/connect/connections/${companyId}-${token}`
  );

  return response.data;
}

export async function getInvitation(token: string) {
  if (typeof API_ENDPOINT === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const response = await api.get<RequestItem | null>(
    `${API_ENDPOINT}/v1/connect/invitations/${token}`
  );

  return response.data;
}

export async function createConnection({
  email,
  sharedExperiences,
}: {
  email: string;
  sharedExperiences: ConnectedItem['sharedExperiences'];
}) {
  if (typeof API_ENDPOINT === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.post(`${API_ENDPOINT}/v1/connect/connections`, {
    email,
    sharedExperiences,
  });
}

export async function acceptConnectionRequest(
  token: string,
  sharedExperiences: ConnectedItem['sharedExperiences']
) {
  if (typeof API_ENDPOINT === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.post(`${API_ENDPOINT}/v1/connect/invitations/${token}`, {
    accepted: true,
    sharedExperiences,
  });
}

export async function declineConnectionRequest(token: string) {
  if (typeof API_ENDPOINT === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.post(`${API_ENDPOINT}/v1/connect/invitations/${token}`, {
    accepted: false,
  });
}

export async function updateConnectionItem(
  item: Pick<Connection, 'companyId' | 'token'> & {
    sharedExperiences: ConnectedItem['sharedExperiences'];
  }
) {
  if (typeof API_ENDPOINT === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.post(
    `${API_ENDPOINT}/v1/connect/connections/${item.companyId}-${item.token}`,
    {
      sharedExperiences: item.sharedExperiences,
    }
  );
}

export async function disconnectConnection(
  item: Pick<Connection, 'companyId' | 'token'>
) {
  if (typeof API_ENDPOINT === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.delete(
    `${API_ENDPOINT}/v1/connect/connections/${item.companyId}-${item.token}`
  );
}
