import { Dispatch } from 'react';

export enum FlowTypeEnum {
  Storefront = 'storefront',
  Checkout = 'checkout',
  Widget = 'widget',
}

export enum ActionTypeEnum {
  SetFlowType = 'SET_FLOW_TYPE',
  SetExperience = 'SET_EXPERIENCE',
  SetLanguage = 'SET_LANGUAGE',
  SetTags = 'SET_TAGS',
}

export type ExperiencePayloadType =
  | 'all'
  | {
      companyId: string;
      experienceId: string;
      /** `distributorId` is only present on shared experiences */
      distributorId?: string;
      /**
       * `ownerExperienceId` is only present on shared experiences.
       * This id is also stored here becuase the filter/select input uses
       * `experience.id`, while we need to use `ownerExperienceId` when
       * creating the widget
       */
      ownerExperienceId?: string;
    };

export type ActionType =
  | {
      type: ActionTypeEnum.SetFlowType;
      payload: FlowTypeEnum;
    }
  | { type: ActionTypeEnum.SetExperience; payload: ExperiencePayloadType }
  | {
      type: ActionTypeEnum.SetLanguage;
      payload: string;
    }
  | {
      type: ActionTypeEnum.SetTags;
      payload: string[];
    };

export interface BookingFlowSectionContextType {
  selectedFlowType: FlowTypeEnum;
  selectedExperience: ExperiencePayloadType;
  selectedLanguage: string;
  selectedTags?: string[];
  dispatch: Dispatch<ActionType>;
}
