import { Skeleton, Stack } from '@mui/material';
import { useMemo } from 'react';

import { InfoBox } from '../../../../../../../Components/InfoBox/InfoBox';
import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { PaymentStatus } from '../../../../../../events/event_details/BookingList/shared';
import { useBookingDetailsContext } from '../../../../domain/use_booking_details_context';
import { BookingDetailsSummaryPaymentOpenLink } from './ui/BookingDetailsSummaryPaymentOpenLink';
import { BookingDetailsSummaryReceiptError } from './ui/BookingDetailsSummaryReceiptError';
import { BookingOrderDetails } from './ui/BookingOrderDetails';
import { BookingOrderSummary } from './ui/BookingOrderSummary';

export const BookingDetailsSummaryPayment = () => {
  const { t } = useTranslate('dialogs.booking');
  const {
    receipt: { data: order, ...orderQuery },
  } = useBookingDetailsContext();

  const showPayment = useMemo(() => {
    if (orderQuery.isLoading || orderQuery.error) {
      return true;
    }

    return order?.transactions.every(
      (transaction) => transaction.transactionMethod !== 'integration'
    );
  }, [order, orderQuery.error, orderQuery.isLoading]);

  if (!showPayment) {
    return null;
  }

  if (orderQuery.isLoading) {
    return (
      <InfoBox title={t('sections.payment')}>
        <Skeleton height={50} />
        <Skeleton height={50} />
      </InfoBox>
    );
  }

  const failedGettingOrder = orderQuery.error || !order;

  return (
    <InfoBox
      title={t('sections.payment')}
      subtitle={`ID: ${order?.id}`}
      actions={order?.id && <PaymentStatus receiptId={order.id} />}
    >
      {failedGettingOrder ? (
        <BookingDetailsSummaryReceiptError />
      ) : (
        <Stack gap={2}>
          <BookingOrderSummary order={order} />
          <BookingDetailsSummaryPaymentOpenLink />
          <BookingOrderDetails order={order} />
        </Stack>
      )}
    </InfoBox>
  );
};
