import {
  MuiTelInput,
  MuiTelInputCountry,
  MuiTelInputProps,
} from 'mui-tel-input';
import { forwardRef } from 'react';

import { useProfile } from '../../Hooks/useProfile';
import { PhotoIcon } from '../../Icons';

export type PhoneInputProps = MuiTelInputProps;

export const PhoneInput = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ defaultCountry, ...props }: PhoneInputProps, _ref) => {
    const { company } = useProfile();

    return (
      <MuiTelInput
        defaultCountry={
          defaultCountry ??
          (company.data?.location?.country?.toUpperCase() as MuiTelInputCountry) ??
          undefined
        }
        unknownFlagElement={<PhotoIcon />}
        {...props}
      />
    );
  }
);

PhoneInput.displayName = 'PhoneInput';
