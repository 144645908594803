import {
  CompanyProfile,
  PublicCompanyProfile,
  PublicUserProfile,
  StorefrontFeatures,
} from '@holdbar-com/utils-types';
import axios from 'axios';

import { IMyInfo } from '../Hooks/useProfile';
import { TUser } from '../Hooks/useUsers';
import { apiServices } from './apiServices';
import api from './index';
import { HoldbarApiError } from './Invitation';

export interface TokenPayload {
  sub: string;
  alt_subs?: string[];
  auth_time?: Date;
  email?: string;
  org?: string;
  companyId?: string;
  scope?: string;
  role?: string;
  memberOfCompanies: string[];
  connections: ConnectedCompany[];
}

export type ConnectedCompany = {
  companyId: string;
  sharedExperiences: 'all' | 'none' | string[];
};

export const getCompanyProfile = async () => {
  if (typeof apiServices.profiles === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  const { data } = await api.get<CompanyProfile>(
    `${apiServices.profiles}/company`
  );

  return data;
};

export const getPublicCompanyProfile = async (companyId: string) => {
  if (typeof apiServices.profiles === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  const { data } = await api.get<PublicCompanyProfile>(
    `${apiServices.profiles}/company/${companyId}/public`
  );

  return data;
};

export const getCompanyUsers = async () => {
  if (typeof apiServices.profiles === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  const { data } = await api.get<TUser[]>(
    `${apiServices.profiles}/company/users`
  );

  return data;
};

export const getMyProfile = async () => {
  if (typeof apiServices.profiles === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  const { data } = await api.get<any>(`${apiServices.profiles}/me`);

  return data;
};

export const updateCompanyProfile = async (
  payload: Partial<CompanyProfile>,
  createNew = false
) => {
  if (typeof apiServices.profiles === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  if (createNew) {
    const path = `${apiServices.profiles}/companies`;
    const { data } = await api.post<Partial<CompanyProfile>>(path, {
      ...payload,
    });

    return data;
  }

  const path = `${apiServices.profiles}/company`;

  const { data } = await api.put<Partial<CompanyProfile>>(path, {
    ...payload,
  });

  return data;
};

export const updateCompanyFeature = async <T extends keyof StorefrontFeatures>(
  feature: T,
  payload: StorefrontFeatures[T]
) => {
  if (typeof apiServices.profiles === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  const path = `${apiServices.profiles}/company/features/${feature}`;

  const { data } = await api.put(path, { ...payload });

  return data;
};

export const updateMyProfile = async (payload: IMyInfo) => {
  if (typeof apiServices.profiles === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  const { data } = await api.put<IMyInfo>(`${apiServices.profiles}/me`, {
    ...payload,
  });

  return data;
};

export const firstLogin = async () => {
  if (typeof apiServices.profiles === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES is not defined'
    );
  }

  return api.post(`${apiServices.profiles}/company/first-login`);
};

export const removeUser = async (userId: string) => {
  try {
    if (typeof process.env.REACT_APP_API_ACCOUNTS_URL === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
      );
    }

    await api.delete(
      `${process.env.REACT_APP_API_ACCOUNTS_URL}/company/users/${userId}`
    );

    return {
      error: false,
    };
  } catch (error) {
    const apiError = error as HoldbarApiError;
    return {
      error: true,
      code: apiError.response?.data.error,
    };
  }
};

export const getUserinfo = async () => {
  const url = process.env.REACT_APP_API_ACCOUNTS_URL;
  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }
  const { data } = await api.get<TokenPayload>(`${url}/userinfo`);

  return data;
};

export async function getPublicUserProfile(userId: string) {
  const url = apiServices.profiles;
  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  try {
    const { data } = await api.get<PublicUserProfile>(
      `${url}/users/${userId}/public`
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return null;
    }

    throw error;
  }
}
