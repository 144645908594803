import { lightTheme } from '@holdbar-com/pixel';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Avatar } from '../../Components/Avatar';
import { CompanyPickerFormValues } from '../../features/connect/components/choose-account-dialog';

const Styled = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  flexGrow: 1,
  ['& > *:not(:last-child)']: {
    marginBottom: 16,
    marginTop: 0,
  },
});

type FieldValues = {
  org: string;
};

export interface CompanyPickerProps {
  companies: { id: string; name: string; logo?: string }[];
  onSubmit: (data: CompanyPickerFormValues) => void;
}

export const CompanyPicker = ({ companies, onSubmit }: CompanyPickerProps) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    string | undefined
  >(undefined);

  const formRef = useRef<HTMLFormElement>(null);

  const { register, handleSubmit, setValue } = useFormContext<FieldValues>();

  register('org', {
    /*
    validate: (value) => {
      if(!companies || companies.length < 2){
        return true
      }
      return value ? true : t('auth.errors.required')
    }
    */
  });

  const _onSubmit = async (data: FieldValues) => {
    try {
      onSubmit(data);
    } catch (err: any) {
      console.log(err);
    }
  };
  // Set defaultValue property on TextField, as using setValue won't move the label away.
  // According to the GitHub issue posted here, https://github.com/react-hook-form/react-hook-form/issues/220#issuecomment-649174176,
  // setting the defaultValue to anything but undefined, should resolve the issue.
  return (
    <Styled onSubmit={handleSubmit(_onSubmit)} ref={formRef}>
      <Box>
        <List component="nav" sx={{ p: 0 }}>
          {companies.map((company) => (
            <ListItemButton
              key={company.id}
              selected={selectedCompanyId === company.id}
              onClick={() => {
                setValue('org', company.id);
                setSelectedCompanyId(company.id);
                formRef.current?.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true })
                );
              }}
              sx={{ gap: 1, color: lightTheme.palette.contrast.black }}
            >
              <ListItemIcon>
                <Avatar imageUrl={company.logo} stringValue={company.name} />
              </ListItemIcon>
              <ListItemText primary={company.name} />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Styled>
  );
};
