import { useQuery } from '@tanstack/react-query';

import { getEconomicVatAccounts } from '../../../Api';
import { useProfile } from '../../../Hooks/useProfile';
import { EconomicVatAccountsQueryKey } from './economic-query-keys';

export const useEconomicVatAccounts = () => {
  const { company } = useProfile();

  return useQuery({
    queryKey: EconomicVatAccountsQueryKey,
    queryFn: async () => {
      return await getEconomicVatAccounts();
    },
    enabled: !!company.data?.id,
  });
};
