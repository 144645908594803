/**
 * Deserialize a Understory QR code string
 *
 * The HB format is currently: HB;{type};{version};{versionParts}
 *
 * @param qrText
 * @returns
 */
export const deserializeQr = (qrText: string) => {
  try {
    const parts = qrText.split(':');
    if (parts.length < 2) return null;

    const [hbTag, type, ...rest] = parts;
    if (hbTag !== 'HB') return null;

    if (type === 'TICKET') {
      return deserializeQrTicket(rest);
    }

    return null;
  } catch (error) {
    console.error('Failed to deserialize QR code', error);
    return null;
  }
};

/**
 * Deserialize a Understory ticket QR code string
 *
 * The ticket format is currently: HB;TICKET;version;bookingId
 *
 * @param parts
 * @returns
 */
const deserializeQrTicket = (parts: string[]): { bookingId: string } | null => {
  if (parts.length < 2) return null;

  const [, bookingId] = parts;

  return { bookingId: bookingId.toLowerCase() };
};
