import {
  BookingStatus,
  Connection,
  EventStatus,
  ExperienceStatus,
  GiftCardStatus,
} from '@holdbar-com/utils-types';

import Broken from '../icons/broken';
import Connected from '../icons/connected';
import Pending from '../icons/pending';
import { BadgeProps } from './badge';

type ExperienceBadge = {
  type: 'experience';
  state: ExperienceStatus;
};

type IntegrationBadge = {
  type: 'integration';
  state: 'integrated' | 'pending' | 'disconnected';
};

type EventBadge = {
  type: 'event';
  state: EventStatus | 'done';
};

type BookingBadge = {
  type: 'booking';
  state: Exclude<BookingStatus, 'processing'> | 'paid';
};

export type PaymentBadge = {
  type: 'payment';
  state:
    | 'paid'
    | 'refunded'
    | 'refunded-partially'
    | 'paid-partially'
    | 'unpaid'
    | 'external'
    | 'none'
    | 'free';
};

type UserBadge = {
  type: 'user';
  state: 'active' | 'invited';
};

export type DiscountBadge = {
  type: 'discount';
  state: 'active' | 'expired';
};

type GiftCardBadge = {
  type: 'giftcard';
  state: GiftCardStatus;
};

type ConnectionBadge = {
  type: 'connection';
  state: Connection['state'];
};

export type StatusBadgeType =
  | ExperienceBadge
  | EventBadge
  | BookingBadge
  | PaymentBadge
  | UserBadge
  | DiscountBadge
  | GiftCardBadge
  | ConnectionBadge
  | IntegrationBadge;

export const badgeConfigs: Record<
  StatusBadgeType['type'],
  Record<
    string,
    { appearance: BadgeProps['appearance']; icon?: React.ReactNode }
  >
> = {
  integration: {
    integrated: {
      appearance: 'success',
      icon: <Connected />,
    },
    pending: {
      appearance: 'warning',
      icon: <Pending />,
    },
    disconnected: {
      appearance: 'error',
      icon: <Broken />,
    },
  },
  experience: {
    active: {
      appearance: 'success',
    },
    archived: {
      appearance: 'default',
    },
    draft: {
      appearance: 'default',
    },
    inactive: {
      appearance: 'warning',
    },
  },
  event: {
    active: {
      appearance: 'success',
    },
    cancelled: {
      appearance: 'error',
    },
    done: {
      appearance: 'default',
    },
    /** Is this used? */
    draft: {
      appearance: 'default',
    },
    inactive: {
      appearance: 'warning',
    },
  },
  booking: {
    active: {
      appearance: 'success',
    },
    paid: {
      appearance: 'success',
    },
    cancelled: {
      appearance: 'error',
    },
    'checked-in': {
      appearance: 'action',
    },
    moved: {
      appearance: 'default',
    },
    unpaid: {
      appearance: 'warning',
    },
  },
  payment: {
    paid: {
      appearance: 'success',
    },
    refunded: {
      appearance: 'default',
    },
    free: {
      appearance: 'success',
    },
    'refunded-partially': {
      appearance: 'default',
    },
    'paid-partially': {
      appearance: 'warning',
    },
    unpaid: {
      appearance: 'warning',
    },
    external: {
      appearance: 'action',
    },
    none: {
      appearance: 'default',
    },
  },
  user: {
    active: {
      appearance: 'success',
    },
    invited: {
      appearance: 'default',
    },
  },
  discount: {
    active: {
      appearance: 'success',
    },
    expired: {
      appearance: 'default',
    },
  },
  giftcard: {
    active: {
      appearance: 'success',
    },
    cancelled: {
      appearance: 'error',
    },
    expired: {
      appearance: 'warning',
    },
    spent: {
      appearance: 'default',
    },
  },
  connection: {
    connected: {
      appearance: 'success',
      icon: <Connected />,
    },
    pending: {
      appearance: 'warning',
      icon: <Pending />,
    },
    disconnected: {
      appearance: 'default',
      icon: <Broken />,
    },
    requested: {
      appearance: 'warning',
      icon: <Pending />,
    },
  },
};
