import { Skeleton, Stack } from '@mui/material';

import useResponsive from '../../../Hooks/layout/useResponsive';

export const DashboardGraphsSkeleton = () => {
  const { isSm } = useResponsive();

  return (
    <Stack gap={4}>
      <Stack
        gap={isSm ? 1 : 2}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <Skeleton variant={'rounded'} width={isSm ? '100%' : 478} height={40} />
        <Skeleton variant={'rounded'} width={isSm ? '100%' : 180} height={40} />
      </Stack>
      <Stack gap={2} direction={'row'} height={485}>
        {!isSm && (
          <Skeleton variant={'rounded'} width={'50%'} height={'100%'} />
        )}
        <Skeleton
          variant={'rounded'}
          width={isSm ? '100%' : '50%'}
          height={'100%'}
        />
      </Stack>
    </Stack>
  );
};
