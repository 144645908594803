import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Text } from '@holdbar-com/pixel';
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseRounded } from '@mui/icons-material';
import { Dialog, Stack } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import useResponsive from '../../../../../../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../../../../../../Hooks/useTranslate';
import { Recipient } from '../../../domain/types';
import { NotificationsResendModalForm } from './resend_form/NotificationsResendModalForm';
import {
  NotificationsResendModalFormType,
  useNotificationsResendModalFormValidation,
} from './resend_form/use_notifications_resend_modal_form';

type NotificationsResendModalProps = {
  bookingRecipient: Recipient;
  notificationId: string;
  bookingId: string;
  disallowChangeRecipient?: boolean;
};

export const NotificationsResendModal =
  NiceModal.create<NotificationsResendModalProps>(
    ({
      bookingRecipient,
      notificationId,
      bookingId,
      disallowChangeRecipient,
    }) => {
      const { t } = useTranslate('dialogs.booking.notifications.resendModal');
      const { isSm } = useResponsive();
      const modal = useModal();

      const formValidationSchema = useNotificationsResendModalFormValidation(t);
      const form = useForm({
        resolver: yupResolver(formValidationSchema),
        defaultValues: bookingRecipient as NotificationsResendModalFormType,
      });

      const handleClose = () => {
        modal.reject('NotificationsResendModal');
        modal.hide();
        form.reset();
      };

      useEffect(() => {
        form.reset(bookingRecipient as NotificationsResendModalFormType);
      }, [bookingRecipient]);

      return (
        <FormProvider {...form}>
          <Dialog
            open={modal.visible}
            onClose={handleClose}
            fullWidth
            maxWidth={'sm'}
            fullScreen={isSm}
          >
            <Stack gap={3} p={4}>
              <CloseRounded onClick={handleClose} sx={{ cursor: 'pointer' }} />
              <Stack gap={6}>
                <Stack gap={1}>
                  <Text fontSize={'xlarge'} variant="medium">
                    {t('title')}
                  </Text>
                  <Text>{t('description')}</Text>
                </Stack>
                <NotificationsResendModalForm
                  notificationId={notificationId}
                  bookingId={bookingId}
                  bookingRecipient={bookingRecipient}
                  disallowChangeRecipient={disallowChangeRecipient}
                />
              </Stack>
            </Stack>
          </Dialog>
        </FormProvider>
      );
    }
  );
