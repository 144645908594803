import { useCallback } from 'react';

import { ampli } from '../../../../Ampli';
import FeatureItem from '../../../../Components/FeatureItem/FeatureItem';
import { useProfile } from '../../../../Hooks/useProfile';
import FeatureInput from '../inputs/FeatureInput';

const FEATURE_ID = 'requests';

export const EventRequestsFeature = () => {
  const {
    company: { data: company },
    companyProfileUrl,
  } = useProfile();

  const trackToggling = useCallback((enabled: boolean) => {
    if (enabled) {
      ampli.eventRequestsToggledOn({ feature_enabled: 'requests' });
    } else {
      ampli.eventRequestsToggledOff();
    }
  }, []);

  return (
    <FeatureItem
      feature={FEATURE_ID}
      link={companyProfileUrl() + '/request'}
      onToggle={trackToggling}
    >
      <FeatureInput
        name="emailAddress"
        defaultValue={company?.features?.requests?.params?.emailAddress ?? ''}
        type="input"
        required={true}
      />
    </FeatureItem>
  );
};
