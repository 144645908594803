import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { BookingFormData } from './use_booking_form_validation';

export const EventUpsertBookingFormEmailField = () => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();

  const {
    control,
    formState: { errors },
  } = useFormContext<Pick<BookingFormData, 'email'>>();

  return (
    <Controller
      name="email"
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          sx={{ width: '100%' }}
          required
          label={t('eventUpsert.flow.booking.form.email.label')}
          value={currentEvent.booking?.email || ''}
          onChange={(e) => {
            field.onChange(e);
            updateEventField('booking', {
              ...currentEvent.booking,
              email: e.target.value,
            });
          }}
          helperText={errors.email?.message}
          error={!!errors.email}
        />
      )}
    />
  );
};
