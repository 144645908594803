import { useEconomicIntegration } from './integrations/use-economic-integration';
import { useIcalIntegration } from './integrations/use-ical-integration';
import { usePaypalIntegration } from './integrations/use-paypal-integration';
import { useQuickpayIntegration } from './integrations/use-quickpay-integration';
import { useStripeIntegration } from './integrations/use-stripe-integration';
import { useTruestoryIntegration } from './integrations/use-truestory-integration';

export type IntegrationObject = {
  id: string;
  title: string;
  icon: string;
  description: string;
  extendedDescription: string;
  status: string;
  openForRequest?: boolean;
  isRequested?: boolean;
  hidden?: boolean;
  warnings?: string[];
  buttonLabel: string | ((status: string) => string);
  guide?: string;
  onInstall?: (status: string) => () => Promise<void>;
  onUninstall?: () => Promise<void>;
};

export const useIntegrations = () => {
  const icalIntegration = useIcalIntegration();
  const truestoryIntegration = useTruestoryIntegration();

  const otherIntegrations = [
    useEconomicIntegration(),
    usePaypalIntegration(),
    useStripeIntegration(),
    useQuickpayIntegration(),
  ];

  const { activeIntegrations, availableIntegrations } =
    otherIntegrations.reduce<{
      activeIntegrations: IntegrationObject[];
      availableIntegrations: IntegrationObject[];
    }>(
      (acc, integration) => {
        if (integration) {
          integration.status === 'connected'
            ? acc.activeIntegrations.push(integration)
            : acc.availableIntegrations.unshift(integration);
        }
        return acc;
      },
      {
        activeIntegrations: [],
        availableIntegrations: [icalIntegration, truestoryIntegration],
      }
    );

  // Sort activeIntegrations alphabetically by title
  activeIntegrations.sort((a, b) => a.title.localeCompare(b.title));
  // Sort availableIntegrations alphabetically by title
  availableIntegrations.sort((a, b) => a.title.localeCompare(b.title));

  return {
    availableIntegrations,
    activeIntegrations,
  };
};
