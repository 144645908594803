import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IntercomProps,
  useIntercom as useIntercomInternal,
} from 'react-use-intercom';

import { useAuth } from '../Hooks/useAuth';
import { useExperience } from '../Hooks/useExperience';
import { useOnBoarding } from '../Hooks/useOnBoarding';
import { useProfile } from '../Hooks/useProfile';
import {
  intercomPopopClosed,
  intercomPopopOpened,
} from '../tracking/intercom/intercomEvents';
import { isProduction } from '../Utils/environment';

export const useIntercom = () => {
  const { boot, shutdown, update, isOpen } = useIntercomInternal();
  const { isAuthenticated } = useAuth();

  const [isBooted, setIsBooted] = useState(false);

  const intercomProps = useIntercomProps();

  // Boot Intercom and set user properties
  useEffect(() => {
    if (!isProduction) {
      return;
    }

    if (!isAuthenticated) {
      setIsBooted(false);
      shutdown();
    }

    if (!intercomProps) {
      return;
    }

    if (isBooted) {
      update(intercomProps);
      return;
    }

    boot(intercomProps);
    setIsBooted(true);
  }, [boot, intercomProps, isAuthenticated, isBooted, shutdown, update]);

  // Track in Amplitude when the messenger is opened and closed
  const previousMessengerState = useRef<boolean>(isOpen);
  useEffect(() => {
    if (isOpen && previousMessengerState.current === false) {
      intercomPopopOpened();
    } else if (!isOpen && previousMessengerState.current === true) {
      intercomPopopClosed();
    }

    previousMessengerState.current = isOpen;
  }, [isOpen]);
};

function useIntercomProps(): IntercomProps | null {
  const { i18n } = useTranslation();
  const { me, company, userinfo } = useProfile();
  const { experiences } = useExperience();
  const { onBoarding } = useOnBoarding();

  const intercomProps = useMemo(() => {
    if (!me.data || !company.data || !userinfo.data) {
      return null;
    }

    const numberOfExperiences = experiences.data?.length ?? null;

    // Payment attributes
    const stripeIsConnected = Boolean(
      onBoarding.data?.items.find(
        (item) =>
          item.key === 'payment' &&
          item.steps.some(
            (x) => x.key === 'setup' && x.metadata?.status === 'connected'
          )
      )
    );

    const paypalIsConnected =
      onBoarding.data?.response['payment-paypal']?.find(
        (k) => k.key === 'setup'
      )?.metadata?.status === 'connected';

    const quickpayIsConnected =
      onBoarding.data?.response['payment-quickpay']?.find(
        (k) => k.key === 'setup'
      )?.metadata?.status === 'connected';

    // Company
    const companyHasCover = !!company.data.pictures?.cover?.url;
    const companyCvr = company.data.cvrNr;
    const companyVatRegistered =
      company.data.vatCompliance?.vatRegistrations?.length > 0 ?? false;
    const companyEmail = company.data.companyEmail;
    const companyPhone = company.data.companyPhone;
    const companyZipCode = company.data.location?.zipCode;
    const companyCity = company.data.location?.city;
    const companyAddress = company.data.location?.address;
    const companyCountry = company.data.location?.country;
    const companyCurrency = company.data.defaultCurrency;
    const companyOnboardingCompleted = !!company.data.onboardingCompleted;

    const hasCreatedFirstEvent = !!onBoarding.data?.items.find(
      ({ key }) => key === 'event'
    )?.completed;

    // We accept a company has a description if any of the languages have content
    const companyHasDescription = Object.values(
      company.data.description ?? {}
    ).some((description) => !!description);

    // Terms
    const terms = onBoarding.data?.response.terms ?? [];
    const hasTermsOfTrade =
      (terms.find(({ key }) => key === 'general')?.progress ?? 0) >= 100;
    const hasCancellationPolicy =
      (terms.find(({ key }) => key === 'cancellation')?.progress ?? 0) >= 100;
    const hasPrivacyPolicy =
      (terms.find(({ key }) => key === 'privacy')?.progress ?? 0) >= 100;

    // Properties as defined by: https://developers.intercom.com/installing-intercom/web/attributes-objects/
    // Note that in the future, we should probably utilize the `companies`-property instead of the `company`-property,
    // as a single user can actually be a part of multiple companies.
    const props: IntercomProps = {
      userId: me.data.userId,
      name: me.data.name,
      email: me.data.email,
      createdAt: me.data.createdAt,
      phone: me.data.phone,
      company: {
        companyId: company.data.id,
        name: company.data.name,
        website: company.data.website,
        customAttributes: {
          'Has terms of trade': hasTermsOfTrade,
          'Has cancellation policy': hasCancellationPolicy,
          'Has privacy policy': hasPrivacyPolicy,
          'Has created first event': hasCreatedFirstEvent,
          'Number of experiences': numberOfExperiences,
          'Showing upcoming events in the storefront':
            !!company.data.features?.showUpcomingEventsInStorefront?.enabled,
          'Stripe is connected': stripeIsConnected,
          'PayPal is connected': paypalIsConnected,
          'Quickpay is connected': quickpayIsConnected,
          'Company has cover': companyHasCover,
          'Company CVR': companyCvr,
          'Company email': companyEmail,
          'Company phone': companyPhone,
          'Company zip code': companyZipCode,
          'Company city': companyCity,
          'Company address': companyAddress,
          'Company country': companyCountry,
          'Company is VAT registered': companyVatRegistered,
          'Company currency': companyCurrency,
          'Company has description': companyHasDescription,
          'Company has completed onboarding': companyOnboardingCompleted,
        },
      },
      actionColor: '#212121',
      customAttributes: {
        backofficeLanguage: i18n.resolvedLanguage,
      },
    };

    return props;
  }, [
    company.data,
    experiences.data?.length,
    i18n.resolvedLanguage,
    me.data,
    onBoarding.data?.items,
    onBoarding.data?.response,
    userinfo.data,
  ]);

  return intercomProps;
}
