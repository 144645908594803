import { ReactNode, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { t } from '../../i18n/config';

export const AuthPage = ({
  children,
  title,
}: {
  children: ReactNode;
  title?: string;
}) => {
  useEffect(() => {
    document.title = title ? `${t(title)} - Understory` : 'Understory';
  }, [title]);

  const form = useForm({ reValidateMode: 'onChange' });

  return <FormProvider {...form}>{children}</FormProvider>;
};
