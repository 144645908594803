import { lightTheme, Text } from '@holdbar-com/pixel';
import { Event } from '@holdbar-com/utils-types';
import { format, isSameDay } from 'date-fns';

import useResponsive from '../../../../Hooks/layout/useResponsive';

export const EventTime = ({
  startDateTime,
  endDateTime,
  isCancelled,
}: Pick<Event, 'startDateTime' | 'endDateTime'> & {
  isCancelled?: boolean;
}) => {
  const { isMd } = useResponsive();

  const startDate = new Date(startDateTime);
  const endDate = endDateTime ? new Date(endDateTime) : null;

  const style = {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textDecorationLine: isCancelled ? 'line-through' : undefined,
    color: isCancelled ? lightTheme.palette.neutral.n300 : undefined,
  };

  if (!endDate || !isSameDay(startDate, endDate)) {
    return (
      <Text style={style}>
        <Text variant="medium" color="inherit" fontSize="xsmall">
          {format(startDate, 'PP')}
        </Text>{' '}
        <Text
          fontSize="xsmall"
          color="inherit"
          variant={isMd ? 'medium' : 'normal'}
        >
          {format(startDate, 'HH:mm')}
        </Text>
      </Text>
    );
  }

  return (
    <Text style={style}>
      <Text fontSize="xsmall" variant="medium" color="inherit">
        {format(startDate, 'PP')}
      </Text>{' '}
      <Text
        fontSize="xsmall"
        color="inherit"
        variant={isMd ? 'medium' : 'normal'}
      >
        {`${format(startDate, 'HH:mm')} - ${format(endDate, 'HH:mm')}`}
      </Text>
    </Text>
  );
};
