import { useQuery } from '@tanstack/react-query';

import * as api from '../Api';

export const useReceipt = (id?: string) => {
  const receiptQueryKey = ['receipt', id];

  const receipt = useQuery({
    queryKey: receiptQueryKey,

    queryFn: ({ queryKey }) => {
      const [, receiptId] = queryKey as [string, string];

      return api.getReceipt(receiptId);
    },

    enabled: !!id,
  });

  return {
    receipt,
  };
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type VoucherTransaction = {
  id: string;
  amountUsed: number;
  amountLeft: number;
  code: string;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type PaymentTransaction = {
  id: string;
  gateway: string;
  amountPaid: number;
  method: string;
  brand: string;
  expMonth?: number;
  expYear?: number;
  last4?: string;
  country?: string;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type BookingReceiptMeta = {
  location: string;
  booking: {
    id: string;
    startDateTime: string;
    endDateTime: string;
  };
  eventId: string;
  experienceId: string;
  accounting?: {
    customerReceiptUrl?: string;
    guestRefundUrl?: string;
    /**
     * @deprecated use `platformFeeUrl` instead
     */
    holdbarFeeUrl?: string;
    platformFeeUrl?: string;
  };
  customDataInputs?: unknown;
  paymentMeta?: unknown;
  marketingConsentGiven?: boolean;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type VoucherReceiptMeta = {
  voucherId: string;
  sellingVoucherId: string;
  voucherType: string;
  code: string;
  originalAmount: number;
  expiresAt: string;
  paymentMeta?: unknown;
  marketingConsentGiven?: boolean;
};

export interface ReceiptLineItem {
  experienceId: string;
  variantId: string | null;
  name: string;
  quantity: number;
  price: number;
  vatRate: number;
  vatAmount: number;
  unitPrice: number;
}

export interface DiscountTransaction {
  code: string;
  id: string;
  percentage: boolean;
  rate: number;
  totalDiscountedAmount: number;
}
