import { Experiment, Variant, Variants } from '@amplitude/experiment-js-client';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { DefaultConfiguration } from '../Ampli';

const createExperimentContext = (apiKey?: string) => ({
  Context: createContext<{ flags: Variants | null; ready: boolean }>({
    flags: null,
    ready: false,
  }),
  apiKey,
});

/** All of our experiment contexts */
const ExperimentContexts = {
  ForcedQuickOnboardingExperiment: createExperimentContext(
    process.env.REACT_APP_AMPLITUDE_MOBILE_PLG_API_KEY
  ),
};

type ExperimentName = keyof typeof ExperimentContexts;

const ENABLED = true;

/**
 * The ExperimentProvider gives it's children access to the experiment flags
 * @param experiment {ExperimentName} The experiment to provide flags for
 */
export const ExperimentProvider = ({
  children,
  experiment,
}: {
  children: ReactNode;
  experiment: ExperimentName;
}) => {
  const [flags, setFlags] = useState<Variants | null>(null);
  const [ready, setReady] = useState(false);
  const { Context, apiKey } = ExperimentContexts[experiment];

  useEffect(() => {
    if (!apiKey || !ENABLED) return;

    const client = Experiment.initializeWithAmplitudeAnalytics(apiKey, {
      ...DefaultConfiguration,
      fetchTimeoutMillis: 3000,
    });

    client
      .start()
      .then(() => {
        setFlags(client.all());
      })
      .finally(() => {
        setReady(true);
      });
  }, [apiKey]);

  return (
    <Context.Provider value={{ flags, ready }}>{children}</Context.Provider>
  );
};

/**
 * @param experiment {Experiments} The experiment to get the variant for
 * @param options {Object} Specify which flags to get variants for and an optional fallback, in case the flag fails to load.
 * @returns {Variant} The variant for the requested flag
 */
export function useExperimentVariant(
  experiment: ExperimentName,
  options: {
    variant: string;
    fallback?: Variant;
  }
): Variant | undefined {
  const { Context } = ExperimentContexts[experiment];
  const { ready, flags } = useContext(Context);

  if (!ready) {
    return undefined;
  }

  if (!flags || !(options.variant in flags)) {
    return options.fallback;
  }

  const foundVariant = flags[options.variant];
  return foundVariant;
}
