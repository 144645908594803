import { Text } from '@holdbar-com/pixel';
import { EconomicConfiguration } from '@holdbar-com/utils-types';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useEconomicAccounts } from '../../data/use-economic-accounts';

type Props<Path extends string> =
  GetFieldType<Required<EconomicConfiguration['accounts']>, Path> extends number
    ? {
        label: string;
        path: Path;
        defaultValue?: number;
      }
    : never;

export const EconomicAccountAutocomplete = <T extends string>({
  path,
  label,
  defaultValue,
}: Props<T>) => {
  const { isSm } = useResponsive();
  const { control } = useFormContext();

  const { isLoading, data: accounts } = useEconomicAccounts();

  if (isLoading || !accounts) {
    return <Skeleton variant="rounded" width={300} height={55} />;
  }

  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          onChange={(_, value) => {
            field.onChange(value?.accountNumber ?? null);
          }}
          value={
            accounts?.find(
              (account) => account.accountNumber === field.value
            ) ?? null
          }
          options={accounts || []}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              label={label}
              error={!!error}
              InputProps={{
                ...params.InputProps,
                style: { height: 48 },
              }}
            />
          )}
          getOptionLabel={(option) => `${option.accountNumber} ${option.name}`}
          getOptionKey={(option) => option.accountNumber}
          renderOption={({ ...props }, option) => (
            <Box component="li" {...props}>
              <Stack>
                <Text>{option.name}</Text>
                <Typography variant={'body2'}>
                  {option.accountNumber}
                </Typography>
              </Stack>
            </Box>
          )}
          sx={{ width: isSm ? '100%' : 300 }}
        />
      )}
      control={control}
      name={`accounts.${path}`}
      defaultValue={defaultValue as any}
    />
  );
};

export type GetFieldType<Obj, Path> =
  Path extends `${infer Left}.${infer Right}`
    ? Left extends keyof Obj
      ?
          | GetFieldType<Exclude<Obj[Left], undefined>, Right>
          | Extract<Obj[Left], undefined>
      : undefined
    : Path extends keyof Obj
      ? Obj[Path]
      : undefined;
