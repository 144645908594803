import { FormProvider, useForm } from 'react-hook-form';

import { TermsForm } from './ui/TermsForm';

export const SettingsTermsSection = () => {
  const form = useForm<Record<string, any>>();

  return (
    <FormProvider {...form}>
      <TermsForm />
    </FormProvider>
  );
};
