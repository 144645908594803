import { toISODateTime } from '@holdbar-com/utils-date';
import { addHours } from 'date-fns';
import { useMemo } from 'react';

import { nextInterval } from '../ui/time/ui/time_form/event_upsert_time_form';
import { createRecurringNotSelected } from '../ui/time/ui/time_form/use_time_form_recurring';
import { EventType } from './types';

export const createEvent = (
  values?: Partial<EventType>
): Readonly<EventType> => {
  return {
    id: values?.id ?? '',
    parentId: values?.parentId ?? '',
    step: values?.step ?? 'details',
    visibility: values?.visibility ?? 'public',
    variant: values?.variant ?? 'create',
    experienceId: values?.experienceId ?? '',
    status: values?.status ?? 'active',
    bookings: values?.bookings ?? undefined,
    intervals: values?.intervals ?? {
      selectedOptionKey: 'no',
    },
    recurring: values?.recurring ?? createRecurringNotSelected(),
    dates: values?.dates ?? {
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
    },
    languages: values?.languages ?? [],
    startDateTime:
      values?.startDateTime ?? toISODateTime(nextInterval(new Date(), 60)),
    endDateTime:
      values?.endDateTime ??
      toISODateTime(nextInterval(addHours(new Date(), 1), 60)),
    endDateTimeDisabled:
      values?.variant === 'create' ? false : !values?.endDateTime,
    startEndTimeDiff: values?.startEndTimeDiff ?? 60,
    seatCount: values?.seatCount ?? undefined,
    assignedGuides: values?.assignedGuides ?? [],
    addresses: values?.addresses ?? {
      value: '',
      selectedOptionKey: 'custom',
    },
    booking: values?.booking ?? {
      guests: {},
      name: '',
      email: '',
      phone: '',
      internalNotes: '',
      language: undefined,
      paymentMethod: 'paymentLink',
    },
    locationId: values?.locationId ?? '',
  };
};

export const useEventInitial = (values?: Partial<EventType>): EventType =>
  useMemo(() => createEvent(values), [values]);
