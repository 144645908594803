import { Stack } from '@mui/material';

import { TokenPayload } from '../../../../../Api/Profiles';
import { CompanyFormAbout } from './CompanyFormAbout';
import { CompanyFormActions } from './CompanyFormActions';
import { CompanyFormAddress } from './CompanyFormAddress';
import { CompanyFormCurrency } from './CompanyFormCurrency';
import { CompanyFormDetails } from './CompanyFormDetails';
import { CompanyFormDomainWidget } from './CompanyFormDomainWidget';
import { CompanyFormPictures } from './CompanyFormPictures';
import CompanyFormVatRegistration from './VatRegistration/CompanyFormVatRegistration';

type CompanyFormProps = {
  userinfo?: TokenPayload;
};

export const CompanyForm = ({ userinfo }: CompanyFormProps) => {
  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
      >
        <Stack gap={3}>
          <CompanyFormPictures userinfo={userinfo} />
          <CompanyFormDetails />
          <CompanyFormAddress />
        </Stack>
        <CompanyFormDomainWidget />
      </Stack>
      <CompanyFormCurrency />
      <CompanyFormAbout />
      <CompanyFormVatRegistration />
      <CompanyFormActions />
    </>
  );
};
