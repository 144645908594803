import { Controller } from 'react-hook-form';

import { LanguageChips } from '../../../../../Components/LanguageChips/LanguageChips';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { SyiSection } from '../../../../../Pages/SyiPage/SyiSection';

export const ProfileFormLanguages = () => {
  const { t } = useTranslate('settings.profile');

  return (
    <SyiSection title={t('languages')}>
      <Controller
        name={'languages'}
        render={({ field }) => (
          <LanguageChips
            onChange={field.onChange}
            shouldTranslate={false}
            languages={field.value}
            multiple={true}
          />
        )}
      />
    </SyiSection>
  );
};
