import { useMemo } from 'react';

import { useOnBoarding } from '../../../Hooks/useOnBoarding';
import { useTranslate } from '../../../Hooks/useTranslate';

export const useActivePaymentGateways = () => {
  const { onBoarding } = useOnBoarding();

  const { t } = useTranslate('settings.integrations.payments');

  return useMemo(() => {
    const activePaymentItems = Object.keys(onBoarding.data?.response ?? {})
      .filter((key) => key.startsWith('payment'))
      .map((key) => {
        const id = key === 'payment' ? 'stripe' : key.replace('payment-', '');
        return {
          id,
          label: t(`${id}.title`),
        };
      });

    return {
      isLoading: onBoarding.isLoading,
      isError: onBoarding.isError,
      data: activePaymentItems,
    };
  }, [onBoarding.data?.response, onBoarding.isError, onBoarding.isLoading, t]);
};
