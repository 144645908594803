import { GiftCard } from '@holdbar-com/utils-types';

import { TBoughtVoucher, TVoucher } from '../Hooks/useVoucher';
import { apiServices } from './apiServices';
import api, { IShallowSuccessResponse } from './index';

export const getVouchers = async () => {
  if (typeof apiServices.vouchers === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_VOUCHERS_URL is not defined'
    );
  }

  const { data } = await api.get<TVoucher[]>(`${apiServices.vouchers}/company`);

  return data;
};

export const getSoldVouchers = async () => {
  if (typeof apiServices.vouchers === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_VOUCHERS_URL is not defined'
    );
  }

  const { data } = await api.get<TBoughtVoucher[]>(
    `${apiServices.vouchers}/company/sold`
  );

  return data;
};

export const getGiftCard = async (giftCardId: string) => {
  if (typeof apiServices.vouchers === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_VOUCHERS_URL is not defined'
    );
  }

  if (!giftCardId) throw new Error('Missing giftCardId');

  const { data } = await api.get<GiftCard>(
    `${apiServices.vouchers}/bought/${giftCardId}`
  );

  return data;
};

export const adjustGiftCardValue = async (
  giftCardId: string,
  payload: { amountChangeCents: number; reason: string }
) => {
  if (typeof apiServices.vouchers === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_VOUCHERS_URL is not defined'
    );
  }

  if (!giftCardId) throw new Error('Missing giftCardId');

  const { data } = await api.post<GiftCard>(
    `${apiServices.vouchers}/adjust/${giftCardId}`,
    payload
  );

  return data;
};

export const deleteVoucher = async (id: string) => {
  if (typeof apiServices.vouchers === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_VOUCHERS_URL is not defined'
    );
  }

  const { data } = await api.delete<IShallowSuccessResponse>(
    `${apiServices.vouchers}/company/${id}`
  );

  return data;
};

export const cancelGiftCard = async (id: string) => {
  if (typeof apiServices.vouchers === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_VOUCHERS_URL is not defined'
    );
  }

  const { data } = await api.post<IShallowSuccessResponse>(
    `${apiServices.vouchers}/cancel/${id}`
  );

  return data;
};
