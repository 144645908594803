import { useCallback } from 'react';

import { ampli } from '../../../../Ampli';
import FeatureItem from '../../../../Components/FeatureItem/FeatureItem';

const FEATURE_ID = 'showUpcomingEventsInStorefront';

export const ShowUpcomingEventsInStorefrontFeature = () => {
  const trackToggling = useCallback((enabled: boolean) => {
    if (enabled) {
      ampli.upcomingEventsToggledOn({
        feature_enabled: 'showUpcomingEventsInStorefront',
      });
    } else {
      ampli.upcomingEventsToggledOff();
    }
  }, []);

  return <FeatureItem feature={FEATURE_ID} onToggle={trackToggling} />;
};
