import { DoneRounded } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { TFunction } from 'i18next';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useMemo, useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import { CustomChip } from '../../Components/CustomChip/CustomChip';
import { DateController } from '../../Components/DateController/DateController';
import {
  OptionGroup,
  OptionGroupSelectedSection,
} from '../../Components/OptionGroup/OptionGroup';
import { Localized, useExperience } from '../../Hooks/useExperience';
import { useProfile } from '../../Hooks/useProfile';
import { useTranslate } from '../../Hooks/useTranslate';
import { ErrorLabel } from '../../Pages/SyiPage/ErrorLabel';
import { SyiSection } from '../../Pages/SyiPage/SyiSection';
import { useErrors } from '../../Pages/SyiPage/useErrors';

const _options = {
  scope: [
    {
      key: 'total',
      label: 'for hele din mor',
    },
    {
      key: 'unit',
      label: 'Pr gæst',
    },
  ],
  limitTimesRedeemed: [
    {
      key: false,
      label: 'Uendeligt mange gange',
    },
    {
      key: true,
      label: 'Definer',
    },
  ],
  expiry: [
    {
      key: false,
      label: 'Gyldig til jeg stopper den',
    },
    {
      key: true,
      label: 'Definer tidsperiode',
    },
  ],
  applyForAll: [
    {
      key: true,
      label: 'Ja, for alle mine oplevelser',
    },
    {
      key: false,
      label: 'Nej, lad mig vælge specifikke oplevelser',
    },
  ],
};

const getLocalized = (value: Localized | undefined, language: string) => {
  if (value === undefined) return '';

  return value[language as keyof Localized] ?? Object.values(value)?.[0] ?? '';
};

const disabledSx = { opacity: 0.5 };

const ExperienceTableRow = ({
  row,
  index,
  name,
}: {
  row: OverrideRow;
  index: number;
  name: string;
}) => {
  const { t } = useTranslate('discounts.create.details');

  const { setValue, register } = useFormContext();

  const { clearError, getError } = useErrors();

  const enabled = useWatch({ name: `${name}.enabled` });

  const enable = () => {
    setValue(`${name}.id`, row.id);
    setValue(`${name}.type`, 'experience');
    setValue(`${name}.enabled`, true);
  };

  const handleChange = (key: string) => (e: unknown, value: boolean) => {
    clearError?.('overrides');
    clearError?.('overrides[0]');
    switch (key) {
      case 'enabled':
        if (value) {
          enable();
          setValue(`${name}.percentage`, false, { shouldDirty: true });
        } else {
          setValue(name, null, { shouldDirty: true });
        }
        break;
      case 'useAmount':
        if (value) {
          setValue(`${name}.percentage`, false, { shouldDirty: true });
          enable();
        } else {
          setValue(`${name}.percentage`, true, { shouldDirty: true });
        }
        break;
      case 'usePercentage':
        if (value) {
          setValue(`${name}.percentage`, true, { shouldDirty: true });
          enable();
        } else {
          setValue(`${name}.percentage`, false, { shouldDirty: true });
        }
        break;
    }
  };

  const { onChange, ...input } = register(`${name}.rate`, {
    valueAsNumber: true,
  });

  return (
    <TableRow
      key={row.headline}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <Box
          component={'img'}
          width={48}
          height={48}
          borderRadius={1}
          src={row.picture}
          sx={{
            objectFit: 'cover',
            ...(!enabled && disabledSx),
          }}
        />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{ fontSize: '1em', ...(!enabled && disabledSx) }}
      >
        {row.headline}
      </TableCell>
      <TableCell align="right">
        <Checkbox onChange={handleChange('enabled')} checked={row.enabled} />
      </TableCell>
      <TableCell align="left">
        <Checkbox
          onChange={handleChange('useAmount')}
          checked={row.enabled && !row.percentage}
        />
      </TableCell>
      <TableCell align="right">
        <Checkbox
          onChange={handleChange('usePercentage')}
          checked={row.percentage}
        />
      </TableCell>
      <TableCell align="left">
        <TextField
          size={'small'}
          sx={{ maxWidth: 140, ...(!enabled && disabledSx) }}
          label={
            row.percentage
              ? t('applyForAll.options.true.percentage')
              : t('applyForAll.options.true.amount')
          }
          type={'number'}
          error={Boolean(getError(`overrides[${index}].rate`))}
          inputProps={{
            min: 0,
          }}
          defaultValue={row.rate}
          onChange={(e) => {
            clearError(`overrides[${index}].rate`);
            return onChange(e);
          }}
          {...input}
        />
      </TableCell>
    </TableRow>
  );
};

type Override = {
  id: string;
  type: 'experience' | 'voucher';
  enabled?: boolean;
  rate?: number;
  percentage?: boolean;
};

type OverrideRow = {
  id: string;
  enabled: boolean;
  rate: number | undefined;
  percentage: boolean;
  headline: string;
  picture: string | undefined;
};

const ExperienceTable = () => {
  const { t, i18n } = useTranslate('discounts.create.details');

  const overrides = useWatch({ name: 'overrides' });

  const { setValue } = useFormContext();

  const {
    experiences: { data: experiences = [], isLoading },
  } = useExperience(undefined, { type: 'owned' });

  const [hasLoadedInitially, setHasLoadedInitially] = useState(false);

  const _overrides = useMemo(() => {
    return experiences.map((el) => {
      const headline = getLocalized(el.headline, i18n.language);
      const picture = el.media?.primaryImage?.url;
      const override = (overrides as Override[])?.find((o) => o?.id === el?.id);
      return {
        id: el.id as string,
        enabled: override?.enabled ?? false,
        rate: override?.rate,
        percentage: override?.percentage ?? false,
        headline,
        picture,
        override,
      };
    });
  }, [experiences, overrides]);

  useEffect(() => {
    if (_overrides.length > 0 && !hasLoadedInitially) {
      setValue(
        'overrides',
        _overrides.map((el) => el.override)
      );
      setHasLoadedInitially(true);
    }
  }, [_overrides]);

  const hasExperiences = _overrides?.length > 0;

  return (
    <Table sx={{ minWidth: 650, minHeight: 100, position: 'relative' }}>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 10,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.9)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={'1em'} />
          <Typography ml={1}>
            {t('loadingExperiences', 'utils.generic')}
          </Typography>
        </Box>
      )}

      <TableHead sx={{ textTransform: 'uppercase' }}>
        <TableRow
          sx={{ '& th': { borderTop: 0, color: '#44444F', fontWeight: 600 } }}
        >
          <TableCell sx={{ minWidth: 52 }} />
          <TableCell>{t('tableExperience')}</TableCell>
          <TableCell align="left">{t('tableIncluded')}</TableCell>
          <TableCell align="right">{t('tableAmount')}</TableCell>
          <TableCell align="left">{t('tablePercentage')}</TableCell>
          <TableCell align="left">{t('tableDiscount')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {hasLoadedInitially &&
          _overrides.map((row, i) => (
            <ExperienceTableRow
              row={row}
              index={i}
              name={`overrides.${i}`}
              key={row.id}
            />
          ))}
        {!hasExperiences && !isLoading && (
          <TableCell
            colSpan={6}
            sx={{
              textAlign: 'center',
              borderBottom: 'none',
              whiteSpace: 'pre-wrap',
            }}
          >
            {t('noExperiences')}
          </TableCell>
        )}
      </TableBody>
    </Table>
  );
};

const translateOptions = (t: TFunction) => {
  return Object.fromEntries(
    Object.entries(_options).map(([key, opts]) => {
      return [
        key,
        opts.map((o) => {
          return { ...o, label: t(`${key}.options.${o.key}.label`) };
        }),
      ];
    })
  );
};

export const DiscountSyiSectionDetails = () => {
  const { t } = useTranslate('discounts.create.details');

  const { defaultCurrency } = useProfile();

  const {
    register,
    setValue,
    formState: { touchedFields },
  } = useFormContext();

  const existing = !isEmpty(touchedFields);

  useController({ name: 'enabled', defaultValue: true });

  const { field: startDateTime } = useController({
    name: 'expiry.startDateTime',
  });
  const { field: endDateTime } = useController({ name: 'expiry.endDateTime' });
  const { field: percentage } = useController({
    name: 'percentage',
    defaultValue: true,
  });

  const { clearError, getError } = useErrors();

  const overrides = useWatch({ name: 'overrides' });
  const limits = useWatch({ name: 'limits' });
  const expiry = useWatch({ name: 'expiry' });

  const rate = useWatch({ name: 'rate' });
  const applyForAll = useWatch({ name: '_applyForAll' });

  useEffect(() => {
    if (existing) {
      setValue('_applyForAll', !overrides);
      setValue('_shouldExpire', Boolean(expiry?.startDateTime));
      setValue('_limitTimesRedeemed', Boolean(limits?.timesRedeemed));
    }
  }, []);

  const handleChangeApplyForAll = (enableForAll: boolean) => {
    if (enableForAll) {
      setValue('enabled', true);
      setValue('overrides', null);
      setValue('rate', null);
    } else {
      setValue('enabled', false);
      setValue('overrides', []);
      setValue('rate', 0);
    }
    clearError?.('_applyForAll');
  };

  const handleChangeExpiry = (shouldExpire: boolean) => {
    if (!shouldExpire) {
      startDateTime.onChange(undefined);
      endDateTime.onChange(undefined);
      setValue('expiry', null);
    }
    clearError?.('_shouldExpire');
  };

  const handleChangeLimit = (shouldLimit: boolean) => {
    if (!shouldLimit) {
      setValue('limits', null);
    }
    clearError?.('_limitTimesRedeemed');
  };

  const handleChangeRate = (newValue: 'percentage' | 'amount') => () => {
    if (newValue === 'percentage') {
      percentage.onChange(true);
      if (typeof rate === 'number' && rate > 100) {
        setValue('rate', 100);
      }
    } else {
      percentage.onChange(false);
    }
  };

  const isPercentageRate = percentage.value === true;

  const options = translateOptions(t);

  const { onChange, ...codeInput } = register('code', { required: true });
  const { onChange: onChangeRate, ...rateInput } = register('rate', {
    valueAsNumber: true,
    required: true,
  });

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>{t('title')}</Typography>
      </Stack>

      <Stack mt={2} divider={<Divider />} spacing={4}>
        <TextField
          label={t('code.inputLabel')}
          helperText={t('code.helperText')}
          sx={{ minWidth: 320, maxWidth: 320 }}
          {...codeInput}
          error={Boolean(getError('code'))}
          onChange={(e) => {
            clearError?.('code');
            return onChange(e);
          }}
        />

        <SyiSection
          title={t('applyForAll.title')}
          error={getError('_applyForAll')}
        >
          <OptionGroup
            name={'_applyForAll'}
            options={options.applyForAll}
            onChangeValue={handleChangeApplyForAll}
          >
            <OptionGroupSelectedSection optionKey={false} mt={2}>
              <ErrorLabel
                mb={1}
                label={getError('overrides') ?? getError('overrides[0]')}
              />
              <ExperienceTable />
            </OptionGroupSelectedSection>
          </OptionGroup>
        </SyiSection>

        {applyForAll === true && (
          <SyiSection title={t('applyForAll.options.true.title')}>
            <Grid container gap={1} mb={3}>
              <Grid
                item
                component={CustomChip}
                selected={!isPercentageRate}
                onClick={handleChangeRate('amount')}
                deleteIcon={<DoneRounded />}
                label={t('applyForAll.options.true.amount')}
                sx={{
                  height: 38,
                  fontSize: '0.88em',
                  fontWeight: '600',
                  borderRadius: 1,
                }}
              />
              <Grid
                item
                component={CustomChip}
                onClick={handleChangeRate('percentage')}
                selected={isPercentageRate}
                deleteIcon={<DoneRounded />}
                label={t('applyForAll.options.true.percentage')}
                sx={{
                  height: 38,
                  fontSize: '0.88em',
                  fontWeight: '600',
                  borderRadius: 1,
                }}
              />
            </Grid>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isPercentageRate ? '%' : defaultCurrency.toUpperCase()}
                  </InputAdornment>
                ),
              }}
              label={
                isPercentageRate
                  ? t('applyForAll.options.true.percentageRateInputLabel')
                  : t('applyForAll.options.true.amountRateInputLabel')
              }
              type={'number'}
              sx={{ minWidth: 320 }}
              inputProps={{
                min: 0,
                ...(isPercentageRate && {
                  max: 100,
                }),
              }}
              {...rateInput}
              error={Boolean(getError('rate'))}
              onChange={(e) => {
                clearError?.('rate');
                return onChangeRate(e);
              }}
            />
          </SyiSection>
        )}

        <SyiSection title={t('scope.title')} error={getError('scope')}>
          <OptionGroup
            name={'scope'}
            options={options.scope}
            onChangeValue={() => clearError?.('scope')}
          />
        </SyiSection>

        <SyiSection title={t('expiry.title')} error={getError('_shouldExpire')}>
          <OptionGroup
            name={'_shouldExpire'}
            options={options.expiry}
            onChangeValue={handleChangeExpiry}
          >
            <OptionGroupSelectedSection optionKey={true} mt={2} mb={2}>
              <Stack spacing={3}>
                <DateController
                  startDateTime={startDateTime}
                  endDateTime={endDateTime}
                  hideTo={true}
                  name={'startDateTime'}
                  label={t('startDate', 'utils.generic')}
                />

                <DateController
                  label={t('endDate', 'utils.generic')}
                  hideFrom={true}
                  startDateTime={endDateTime}
                  endDateTime={endDateTime}
                  name={'endDateTime'}
                />
              </Stack>
            </OptionGroupSelectedSection>
          </OptionGroup>
        </SyiSection>

        <SyiSection
          title={t('limitTimesRedeemed.title')}
          error={getError('_limitTimesRedeemed')}
        >
          <OptionGroup
            name={'_limitTimesRedeemed'}
            options={options.limitTimesRedeemed}
            onChangeValue={handleChangeLimit}
          >
            <OptionGroupSelectedSection optionKey={true} mt={2} mb={2}>
              <TextField
                label={t('limitTimesRedeemed.options.true.inputLabel')}
                type={'number'}
                inputProps={{
                  min: 0,
                }}
                {...register('limits.timesRedeemed', { valueAsNumber: true })}
                sx={{ minWidth: 320 }}
              />
            </OptionGroupSelectedSection>
          </OptionGroup>
        </SyiSection>
      </Stack>
    </>
  );
};
