import styled from '@emotion/styled';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { Skeleton } from '@mui/material';
import { FC } from 'react';

import { useExperience } from '../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../Hooks/useLocalizedStringFormatter';

type Props = {
  experienceId: string;
};

export const SuggestionsExperienceName: FC<Props> = ({ experienceId }) => {
  const {
    experience: { data },
  } = useExperience(experienceId);

  const localize = useLocalizedStringFormatter();

  const isDataAvailable = data && Object.keys(data).length > 0;
  if (!isDataAvailable) {
    return <Skeleton variant="text" width={125} />;
  }

  return (
    <Text fontSize="medium" variant="medium">
      <StyledLink href={`/experience/${experienceId}`}>
        {localize(data?.headline)}
      </StyledLink>
    </Text>
  );
};

const StyledLink = styled.a`
  color: ${lightTheme.palette.neutral.n500};

  &:hover {
    text-decoration: underline;
    color: ${lightTheme.palette.contrast.black};
  }
`;
