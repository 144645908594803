import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../../../../../../../../Hooks/useTranslate';
import { NotificationsResendModalFormType } from './use_notifications_resend_modal_form';

type NotificationsResendModalFormAddressProps = {
  disallowChangeRecipient?: boolean;
};

export const NotificationsResendModalFormAddress = ({
  disallowChangeRecipient,
}: NotificationsResendModalFormAddressProps) => {
  const { t } = useTranslate('utils.generic');

  const {
    control,
    formState: { errors },
  } = useFormContext<Pick<NotificationsResendModalFormType, 'address'>>();

  return (
    <Controller
      name="address"
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          label={t('email')}
          variant="outlined"
          disabled={disallowChangeRecipient}
          error={!!errors.address}
          helperText={errors.address?.message}
        />
      )}
    />
  );
};
