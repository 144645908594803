import { DraggableSyntheticListeners } from '@dnd-kit/core';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import {
  Box,
  BoxProps,
  CircularProgress,
  IconButton,
  Paper,
} from '@mui/material';
import React, { CSSProperties } from 'react';

import { useLazyImage } from '../../Hooks/useLazyImage';
import { AspectRatioBox } from '../AspectRatioBox/AspectRatioBox';

export interface IPictureProps extends Omit<BoxProps, 'ref'> {
  source: string;
  onDelete?: () => void;
  large?: boolean;
  style?: CSSProperties;
  listeners?: DraggableSyntheticListeners;
  aspectRatio?: number;
  attributes?: {
    role: string;
    tabIndex: number;
    'aria-pressed': boolean | undefined;
    'aria-roledescription': string;
    'aria-describedby': string;
  };
}

export const Picture = React.forwardRef<HTMLDivElement, IPictureProps>(
  (
    {
      source: originalSource,
      onDelete,
      large = false,
      listeners,
      attributes,
      aspectRatio = 4 / 3,
      ...rest
    },
    ref
  ) => {
    const { source, state } = useLazyImage({ source: originalSource });

    return (
      <Box
        component={Paper}
        ref={ref}
        display={'flex'}
        alignItems={'center'}
        sx={{
          position: 'relative',
          padding: 0,
          overflow: 'hidden',
          boxShadow: '0 0 4px 0 rgba(0,0,0,0.2)',
        }}
        {...rest}
      >
        <AspectRatioBox
          aspectRatio={aspectRatio}
          sx={{
            display: 'block',
            backgroundColor: 'common.black',
            backgroundImage: `url("${source}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            width: '100%',
            borderRadius: '5px',
          }}
        />

        {state === 'loading' && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress
              variant="indeterminate"
              color="secondary"
              size={large ? 80 : 50}
              thickness={large ? 5 : 4.6}
            />
          </Box>
        )}

        {state !== 'loading' && (
          <>
            {!!onDelete && (
              <IconButton
                onClick={onDelete}
                sx={{
                  backgroundColor: 'action.active',
                  color: 'common.white',
                  position: 'absolute',
                  top: (theme) => theme.spacing(1),
                  right: (theme) => theme.spacing(1),
                }}
                size="small"
                className="delete"
              >
                <DeleteIcon />
              </IconButton>
            )}

            {!!listeners && (
              <IconButton
                sx={{
                  backgroundColor: 'action.active',
                  color: 'common.white',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate3d(-50%, -50%, 0)',
                  touchAction: 'none',
                }}
                {...attributes}
                {...listeners}
                size="large"
                className="move"
              >
                <OpenWithIcon />
              </IconButton>
            )}
          </>
        )}
      </Box>
    );
  }
);

Picture.displayName = 'Picture';
