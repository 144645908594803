import { Grid } from '@mui/material';
import { motion } from 'framer-motion';

import { CategoryTotals } from '../../../../Api/Analytics';
import { useDashboardPageContext } from '../../use-dashboard-page-context';
import { DashboardGraphsLoaderCard } from './dashboard-graphs-loader-card/dashboard-graphs-loader-card';
import { DashboardGraphsLoaderError } from './dashboard-graphs-loader-error';
import { DashboardGraphsLoaderFooter } from './dashboard-graphs-loader-footer';

export const DashboardGraphsLoader = () => {
  const { categoryTotals, isError } = useDashboardPageContext();
  const categories = Object.entries(categoryTotals ?? {});

  if (isError) return <DashboardGraphsLoaderError />;

  return (
    <>
      <Grid container spacing={2} columns={12}>
        {categories.map((category, index) => (
          <Grid item key={category[0]} xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, y: index !== 0 ? 20 : 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ delay: index * 0.2, duration: 0.5 }}
              style={{ height: '100%' }}
            >
              <DashboardGraphsLoaderCard
                categoryKey={category[0] as keyof CategoryTotals}
                metrics={category[1]}
              />
            </motion.div>
          </Grid>
        ))}
      </Grid>
      <DashboardGraphsLoaderFooter />
    </>
  );
};
