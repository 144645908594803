import NiceModal from '@ebay/nice-modal-react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { ampli } from '../Ampli';
import * as api from '../Api';
import { states } from '../Components/CustomCard/CustomCard';
import { SearchDialog } from '../Modals/search/SearchDialog';

export const useSearch = () => {
  const [query, setQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<TBookingResult[]>([]);

  const SearchQueryKey = ['search', query];

  const { data, isFetching } = useQuery({
    queryKey: SearchQueryKey,

    queryFn: async () => {
      return await api.getSearchResults(query);
    },
  });

  useEffect(() => {
    if (data?.items) {
      setSearchResults(data.items);

      if (query.trim()) {
        ampli.searchSearched({
          query: query.trim(),
          results: data.items.length,
        });
      }
    }
  }, [data]);

  const openSearch = () => {
    NiceModal.show(SearchDialog);
  };

  return {
    query,
    setQuery,
    openSearch,
    searchResults,
    isFetching,
  };
};

export type TBookingResult = {
  id: string;
  experienceName: string;
  startDateTime: string;
  customer: {
    name: string;
    email: string;
  };
  status: keyof typeof states;
  receiptId?: string;
  endDateTime?: string;
};
