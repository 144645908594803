import { useFormContext } from 'react-hook-form';

import { TranslatableGroup } from '../../../../Components/TranslatableGroup/translatable-group';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { ICancelEventForm, ICancellationNote } from '../../domain/types';

export const CancelEventTranslatableGroupField = ({
  selectedLanguages,
  prevCancellationNote,
  isBookingsLoading,
  mappedNotesForLanguages,
}: {
  selectedLanguages: {
    key: string;
    label: string;
    icon: string;
  }[];
  prevCancellationNote?: ICancellationNote[];
  isBookingsLoading: boolean;
  mappedNotesForLanguages: (string | undefined)[];
}) => {
  const { t } = useTranslate('dialogs.cancelEvent');

  const {
    formState: { errors },
    reset,
  } = useFormContext<ICancelEventForm>();
  const noteError = errors?.cancelNote?.message ? t('noteError') : undefined;

  const resetErrors = () => {
    if (!noteError) return;
    reset(
      {},
      {
        keepValues: true,
        keepErrors: false,
      }
    );
  };

  return (
    <TranslatableGroup
      id={'cancelNote'}
      langs={selectedLanguages}
      values={mappedNotesForLanguages}
      title={
        prevCancellationNote ? t('noteTitleWithNote') : t('noteTitleNoNote')
      }
      input={{
        type: 'input',
        placeholder: prevCancellationNote
          ? t('noNoteGivenPlaceholder')
          : t('notePlaceholder'),
        maxLength: 300,
        props: {
          multiline: true,
          rows: 4,
        },
      }}
      error={noteError}
      clearError={resetErrors}
      disabled={isBookingsLoading || Boolean(prevCancellationNote)}
      disableTranslation={mappedNotesForLanguages.length > 0}
    />
  );
};
