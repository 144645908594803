import { useQuery } from '@tanstack/react-query';

import * as api from '../../Api';
import { transformEvents } from '../../Utils/eventHelpers';

export const getEventQueryKey = (id: string) => ['events', id];

export const useGetEvent = (id: string) => {
  const EventQueryKey = getEventQueryKey(id);

  const event = useQuery({
    queryKey: EventQueryKey,

    queryFn: async () => {
      const fetched = await api.getEvent(id);
      return transformEvents([fetched])[0];
    },
    enabled: Boolean(id),
  });

  return { event };
};
