import { FC } from 'react';

import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { PageBreadcrumbItem } from '../page-breadcrumb-item';

export const PageBreadcrumbBreadCrumbsBookingsList: FC = () => {
  const { t } = useTranslate('utils.primaryNavigation');

  return <PageBreadcrumbItem title={t('bookings')} href="/bookings" />;
};
