import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { createContext, useContext } from 'react';
import { useState } from 'react';

import {
  trackOverviewIntervalSelected,
  trackOverviewNextPeriodButtonClicked,
  trackOverviewPreviousPeriodButtonClicked,
} from '../../../../../tracking/overview/interactions';
import { TimePeriod } from '../../../types';
import { useDashboardPageContext } from '../../../use-dashboard-page-context';
import { getDateRanges } from './helpers';

type DashboardGraphsFilterPeriodState = {
  interval: TimePeriod | 'period';
  currentInterval: TimePeriod;
  handleUpdateInterval: (newInterval: TimePeriod) => void;
  handlePeriodChange: (direction: 'previous' | 'next') => void;
};

export const DashboardGraphsFilterPeriodContext =
  createContext<DashboardGraphsFilterPeriodState | null>(null);

export const useDashboardGraphsFilterPeriodContext = () => {
  const context = useContext(DashboardGraphsFilterPeriodContext);
  if (!context) {
    throw new Error(
      'useDashboardGraphsFilterPeriodContext must be used within a DashboardGraphsFilterPeriodContextProvider'
    );
  }
  return context;
};

interface DashboardGraphsFilterPeriodContextProviderProps {
  interval: TimePeriod | 'period';
  setInterval: (newInterval: TimePeriod | 'period') => void;
  children: React.ReactNode;
}

export const DashboardGraphsFilterPeriodContextProvider = ({
  interval,
  setInterval,
  children,
}: DashboardGraphsFilterPeriodContextProviderProps) => {
  const { fromDate, toDate, setFromDate, setToDate } =
    useDashboardPageContext();

  const [currentInterval, setCurrentInterval] =
    useState<TimePeriod>('thisWeek');

  const handleUpdateInterval = (newInterval: TimePeriod) => {
    setInterval(newInterval);
    setCurrentInterval(newInterval);
    if (newInterval === 'custom') return;

    const [fromDate, toDate] = getDateRanges(new Date())[newInterval]();
    setFromDate(fromDate);
    setToDate(toDate);
    trackOverviewIntervalSelected(newInterval);
  };

  const handlePeriodChange = (direction: 'previous' | 'next') => {
    setInterval('period');

    const dateAdjustment = direction === 'previous' ? -1 : 1;

    const newDateRange = (() => {
      switch (currentInterval) {
        case 'today':
          return [
            addDays(fromDate, dateAdjustment),
            addDays(toDate, dateAdjustment),
          ];
        case 'thisWeek':
          return [
            addWeeks(fromDate, dateAdjustment),
            endOfWeek(addWeeks(toDate, dateAdjustment), { weekStartsOn: 1 }),
          ];
        case 'thisMonth':
          return [
            addMonths(fromDate, dateAdjustment),
            endOfMonth(addMonths(toDate, dateAdjustment)),
          ];
        case 'lastMonth':
          return [
            startOfMonth(addMonths(fromDate, dateAdjustment)),
            endOfMonth(addMonths(fromDate, dateAdjustment)),
          ];
        case 'year':
          return [
            startOfYear(addYears(fromDate, dateAdjustment)),
            endOfYear(addYears(toDate, dateAdjustment)),
          ];
        default:
          return [fromDate, toDate];
      }
    })();

    setFromDate(newDateRange[0]);
    setToDate(newDateRange[1]);

    if (direction === 'next') {
      trackOverviewNextPeriodButtonClicked();
    } else {
      trackOverviewPreviousPeriodButtonClicked();
    }
  };

  return (
    <DashboardGraphsFilterPeriodContext.Provider
      value={{
        interval,
        currentInterval,
        handleUpdateInterval,
        handlePeriodChange,
      }}
    >
      {children}
    </DashboardGraphsFilterPeriodContext.Provider>
  );
};
