import { queryClient } from '../../../../../../../..';
import * as api from '../../../../../../../../Api';
import { AuditLog } from '../../../../../../../../Api/Audits';
import { Recipient } from './types';

export const getNotificationEmail = async (notificationId: string) => {
  const notificationEmail = await api.getNotificationLog(notificationId);
  return notificationEmail.item?.processingStages
    ?.toReversed()
    .find(
      (stage: any) =>
        stage.stageName === 'Holdbar.Notifications.DeliveryFunction.Email'
    ).processingEvents[1].eventData.NotificationContent;
};

export const resendNotification = async (
  notificationId: string,
  bookingId: string,
  bookingRecipient: Recipient,
  newRecipient?: Recipient
) => {
  await api.resendNotification(notificationId, bookingRecipient, newRecipient);
  queryClient.invalidateQueries({
    queryKey: [
      'audits',
      {
        object: 'Booking',
        objectId: bookingId,
      },
    ],
  });
};

const customerNotificationTypes = [
  'BookingConfirmation',
  'BookingMoved',
  'BookingCancelled',
  'BookingReminder',
  'MessageParticipant',
  'EventCancelledParticipant',
];

export const isCustomerNotification = (auditLog: AuditLog) => {
  return (
    auditLog.type === 'Notification' &&
    auditLog.metadata.notificationType &&
    customerNotificationTypes.includes(auditLog.metadata.notificationType)
  );
};

export const getDaysSinceConfirmationSent = (audits: AuditLog[]) => {
  const confirmationSent = audits
    .filter((log) => isCustomerNotification(log))
    .find(
      (log) =>
        log.metadata.notificationType === 'BookingConfirmation' &&
        (log.metadata.notificationStatus === 'success' ||
          log.metadata.notificationStatus === 'resent')
    );

  if (confirmationSent) {
    return Math.floor(
      (new Date().getTime() - new Date(confirmationSent.createdAt).getTime()) /
        (1000 * 60 * 60 * 24)
    );
  }
};
