import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useCallback } from 'react';

import { queryClient } from '../..';
import * as api from '../../Api';
import { Suggestion, SuggestionDecision } from '../../Api/Suggestion';

const suggestionsQueryKey = ['suggestions'];

type UseSuggestionsReturn = UseQueryResult<Suggestion[]>;

export const useSuggestions = (): UseSuggestionsReturn => {
  return useQuery({
    queryKey: suggestionsQueryKey,
    queryFn: () => api.getSuggestions(),
  });
};

export const useSuggestionDecision = (id: string) => {
  const mutation = useMutation({
    mutationFn: (payload: {
      decision: SuggestionDecision;
      suggestionId: string;
    }) => api.suggestionDecision(payload.suggestionId, payload.decision),

    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: suggestionsQueryKey });

      queryClient.setQueryData<Suggestion[]>(suggestionsQueryKey, (prev) => {
        return (
          prev?.filter(
            (suggestion) => suggestion.suggestionId !== payload.suggestionId
          ) ?? []
        );
      });
    },

    onSettled: async () => {
      queryClient.invalidateQueries({ queryKey: suggestionsQueryKey });
    },
  });

  const acceptSuggestion = useCallback(async () => {
    return mutation.mutateAsync({ decision: 'accept', suggestionId: id });
  }, [id, mutation]);

  const ignoreSuggestion = useCallback(async () => {
    return mutation.mutateAsync({ decision: 'ignore', suggestionId: id });
  }, [id, mutation]);

  return {
    acceptSuggestion,
    ignoreSuggestion,
  };
};
