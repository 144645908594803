import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Text } from '@holdbar-com/pixel';
import { CloseRounded } from '@mui/icons-material';
import { Box, CircularProgress, Dialog, Stack } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';

import useResponsive from '../Hooks/layout/useResponsive';
import { useTranslate } from '../Hooks/useTranslate';

export const StripeDialog = NiceModal.create(
  ({
    onClick,
  }: {
    onClick: (accountStatus: 'new' | 'existing' | undefined) => Promise<void>;
  }) => {
    const { t } = useTranslate('dialogs.initStripe');
    const { isSm } = useResponsive();

    const [loadingNew, setLoadingNew] = useState(false);
    const [loadingExisting, setLoadingExisting] = useState(false);

    const modal = useModal();

    const handleClose = () => {
      modal.reject('StripeDialog');
      modal.hide();
    };

    const handleClickNewAccount = async () => {
      setLoadingNew(true);
      try {
        await onClick('new');
      } catch (error) {
        toast.error(t('errorTitle', 'dialogs.errorDialog'));
        throw error;
      } finally {
        setLoadingNew(false);
      }
    };

    const handleClickExistingAccount = async () => {
      setLoadingExisting(true);
      try {
        await onClick('existing');
      } catch (error) {
        toast.error(t('errorTitle', 'dialogs.errorDialog'));
        throw error;
      } finally {
        setLoadingExisting(false);
      }
    };

    return (
      <Dialog
        open={modal.visible}
        onClose={handleClose}
        PaperProps={{ sx: { maxWidth: 800 } }}
        fullScreen={isSm}
      >
        <Stack gap={3} p={4}>
          <Stack gap={6}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Text fontSize={'xlarge'} variant="medium">
                {t('subtitle')}
              </Text>
              <CloseRounded onClick={handleClose} sx={{ cursor: 'pointer' }} />
            </Stack>
            <Stack direction={'row'} gap={4}>
              <Text style={{ whiteSpace: 'pre-wrap' }}>{t('description')}</Text>
              {!isSm && (
                <Box
                  component={'img'}
                  src={'/graphics/onboarding-widget-illustration-4.svg'}
                  height={'100%'}
                />
              )}
            </Stack>
            <Stack
              gap={2}
              direction={isSm ? 'column' : 'row'}
              alignSelf={isSm ? 'auto' : 'flex-end'}
            >
              <Button
                variant="secondary"
                size="medium"
                disabled={loadingNew || loadingExisting}
                onClick={handleClickExistingAccount}
              >
                {loadingExisting ? (
                  <CircularProgress color={'inherit'} size={20} sx={{ m: 0 }} />
                ) : (
                  t('actions.secondary')
                )}
              </Button>
              <Button
                variant="primary"
                size="medium"
                disabled={loadingNew || loadingExisting}
                onClick={handleClickNewAccount}
              >
                {loadingNew ? (
                  <CircularProgress color={'inherit'} size={20} sx={{ m: 0 }} />
                ) : (
                  t('actions.primary')
                )}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
    );
  }
);
