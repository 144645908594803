import NiceModal from '@ebay/nice-modal-react';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { ExperiencesGetWaitlistResponse } from '@holdbar-com/utils-types';
import { Skeleton } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { WaitlistDialog } from './waitlist-dialog';

export const WaitlistAction = ({
  waitlist,
}: {
  waitlist?: UseQueryResult<ExperiencesGetWaitlistResponse>;
}) => {
  const { t } = useTranslate('experience.details.value');

  const handleOpenWaitlist = () => {
    NiceModal.show(WaitlistDialog, {
      waitlist,
    });
  };

  if (waitlist?.isLoading) {
    return <Skeleton variant="text" width={80} />;
  }

  if (!waitlist?.data?.items?.length) {
    return null;
  }

  return (
    <Text
      fontSize="xsmall"
      style={{
        cursor: 'pointer',
        textDecoration: 'underline',
        color: lightTheme.palette.action.a200,
      }}
      onClick={handleOpenWaitlist}
    >
      {t('waitlist', {
        count: waitlist.data.items.length,
      })}
    </Text>
  );
};
