import { lightTheme } from '@holdbar-com/pixel';
import { Box, Divider, Skeleton, Stack } from '@mui/material';

import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { useDashboardPageContext } from '../../../../use-dashboard-page-context';
import { PERCENTAGE_METRIC_KEYS } from '../dashboard-graphs-loader-card';
import { DashboardGraphsLoaderCardSeriesGraph } from './dashboard-graphs-loader-card-series-graph';

type DashboardGraphsLoaderCardSeriesProps = {
  selectedMetric: {
    key: string;
    value: number;
  } | null;
  graphSeriesToRender: {
    keys: string[];
    values: {
      value: number;
      rest: { key: string; value: number }[];
    }[];
  };
};

export const DashboardGraphsLoaderCardSeries = ({
  selectedMetric,
  graphSeriesToRender,
}: DashboardGraphsLoaderCardSeriesProps) => {
  const { t } = useTranslate('dashboard');
  const { isLoadingMetrics } = useDashboardPageContext();

  return (
    <Stack gap={2}>
      <Divider sx={{ color: lightTheme.palette.neutral.n100 }} />
      <Box sx={{ height: { xs: '220px', md: '260px' } }}>
        {!isLoadingMetrics && selectedMetric ? (
          <DashboardGraphsLoaderCardSeriesGraph
            name={t(`statistics.cards.${selectedMetric.key}`)}
            data={graphSeriesToRender}
            isPercentage={PERCENTAGE_METRIC_KEYS.includes(selectedMetric.key)}
          />
        ) : (
          <Skeleton variant="rounded" height={'100%'} />
        )}
      </Box>
    </Stack>
  );
};
