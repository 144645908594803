import NiceModal, { useModal } from '@ebay/nice-modal-react';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  GridProps,
  InputAdornment,
  Skeleton,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ampli } from '../Ampli';
import * as api from '../Api';
import { generateExperienceKeywords } from '../Api/Generation';
import { ProgressButton } from '../Components/ProgressButton/ProgressButton';
import { useTranslate } from '../Hooks/useTranslate';
import { Header } from './Header';

interface IGenerationDialogProps {
  onClose?: () => void;
  language: string;
  experienceId: string;
  experienceName: string;
  instantReturn: boolean;
}

export const GenerationDialog = NiceModal.create(
  ({
    onClose,
    language,
    experienceId,
    experienceName,
    instantReturn,
  }: IGenerationDialogProps) => {
    const { t } = useTranslate('dialogs.generation');
    const modal = useModal();

    const [keyword, setKeyword] = useState<string>('');
    const [keywords, setKeywords] = useState<string[]>([]);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [loadingSuggestions, setLoadingSuggestions] =
      useState<boolean>(false);
    const [loadingSuggestionsFailed, setLoadingSuggestionsFailed] =
      useState<boolean>(false);

    useEffect(() => {
      setLoadingSuggestions(true);
      generateExperienceKeywords({
        experienceId,
        experienceName,
        language,
      })
        .then((value) => {
          setSuggestions(value.keywords);
        })
        .catch(() => {
          setLoadingSuggestionsFailed(true);
        })
        .finally(() => {
          setLoadingSuggestions(false);
        });
    }, [language]);

    const handleDeleteKeyword = (index: number, keyword: string) => () => {
      ampli.generateDescriptionRemoveKeyword({
        keyword,
      });
      setKeywords((kws) => kws.filter((kv, i) => i !== index));
    };

    const handleAddSuggestion = (index: number, keyword: string) => {
      // Track adding new keyword
      ampli.generateDescriptionAddKeyword({
        keyword: keyword,
        fromSuggestion: true,
      });

      // Add the keyword
      setKeywords((p) => Array.from(new Set([...p, keyword])));

      // Remove suggestion from suggestion list
      setSuggestions((kws) => kws.filter((kv, i) => i !== index));
    };

    const handleClose = () => {
      modal.hide().then(() => modal.remove());
      onClose?.();
    };

    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code !== 'Enter') {
        return;
      }

      const t = e.target as HTMLInputElement;
      const value = t.value.trim();
      if (value) {
        handleAddKeyword();
      }
    };

    const handleAddKeyword = () => {
      ampli.generateDescriptionAddKeyword({
        keyword,
        fromSuggestion: false,
      });
      setKeywords((p) => Array.from(new Set([...p, keyword])));
      setKeyword('');
    };

    const handleSubmit = async () => {
      ampli.generateDescriptionGenerate({
        experienceId,
        experienceName,
        numberOfKeywords: keywords.length,
      });
      const payload = {
        language,
        keywords,
        experienceId,
        experienceName,
      };
      try {
        if (instantReturn) {
          const getCompletion = api
            .generateExperience(payload)
            .then((value) => value.completion);
          modal.resolve(getCompletion);
        } else {
          const { completion } = await api.generateExperience(payload);
          modal.resolve(completion);
        }
        handleClose();
      } catch (err) {
        ampli.generateDescriptionFailed({
          experienceId,
          experienceName,
        });
        const errorMessage = (err as Error)?.message || t('toast.error');
        toast.error(errorMessage);
        throw err;
      }
    };

    return (
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={modal.visible}
        onClose={handleClose}
      >
        <Box pl={4}>
          <Header title={t('title')} onClose={handleClose} />
          <Stack pt={4} pb={4} pr={4}>
            <Typography variant={'h5'} mb={3}>
              {t('subtitle')}
            </Typography>
            <Keywords
              mb={3}
              keywords={keywords}
              deleteIcon={<ClearIcon />}
              onDelete={handleDeleteKeyword}
            />
            <TextField
              name={'keyword'}
              label={t('inputLabel')}
              helperText={t('inputHelperText')}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={handleEnter}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant={'outlined'}
                      aria-label="add keyword"
                      onClick={handleAddKeyword}
                    >
                      {t('actions.addKeyword')}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant={'h5'} mt={3} mb={3}>
              {t('suggestions')}
            </Typography>
            {!loadingSuggestions ? (
              <>
                {loadingSuggestionsFailed ? (
                  <Typography>{t('toast.error')}</Typography>
                ) : (
                  <Grid container gap={1}>
                    {suggestions.map((keyword, index) => (
                      <Button
                        key={index}
                        sx={{ mr: 1, padding: '1px 16px', borderRadius: 100 }}
                        variant={'outlined'}
                        color={'secondary'}
                        size="medium"
                        onClick={() => handleAddSuggestion(index, keyword)}
                      >
                        {keyword}
                      </Button>
                    ))}
                  </Grid>
                )}
              </>
            ) : (
              <>
                <Stack spacing={2}>
                  <Skeleton width={360} />
                  <Skeleton width={405} />
                  <Skeleton width={260} />
                </Stack>
              </>
            )}
            <ProgressButton
              size={'large'}
              sx={{ mt: 3, alignSelf: 'flex-end' }}
              disabled={keywords.length === 0}
              variant={'contained'}
              label={t('actions.primary')}
              onClick={handleSubmit}
            />
          </Stack>
        </Box>
      </Dialog>
    );
  }
);

const Keywords = ({
  keywords,
  onDelete,
  ...props
}: GridProps & {
  deleteIcon?: any | undefined;
  keywords: string[];
  onDelete: (index: number, keyword: string) => () => void;
}) => {
  const Styled = styled(Chip)(() => ({
    ['& .MuiChip-deleteIcon']: {
      color: 'rgba(0,0,0,0.5)',
      fontSize: '12px',
    },
  }));

  return keywords.length > 0 ? (
    <Grid container gap={1} {...props}>
      {keywords.map((keyword, i) => (
        <Grid
          key={`keyword-${i}`}
          item
          component={Styled}
          label={keyword}
          onDelete={onDelete(i, keyword)}
          deleteIcon={props.deleteIcon}
          size={'small'}
        />
      ))}
    </Grid>
  ) : null;
};
