import styled from '@emotion/styled';
import { Button } from '@holdbar-com/pixel';
import { MoreVertOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Popover } from '@radix-ui/themes';
import { ReactNode } from 'react';

import { useAuth } from '../../Hooks/useAuth';
import { theme } from '../../Utils/theme';
import { StyledPopoverContent } from '../Popover/radix_popover_styles';
import ContextMenuItem, { ContextMenuItemProps } from './context-menu-item';

type ContextMenuProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  options: ContextMenuItemProps[];
  type?: 'button' | 'icon';
  element?: ReactNode;
};

export const ContextMenu = ({
  open = false,
  onOpenChange,
  element,
  type = 'icon',
  options,
}: ContextMenuProps) => {
  const { canAccess } = useAuth();
  const canAccessContextMenu = options.some((option) =>
    (option.scopes ?? []).every(canAccess)
  );

  if (!canAccessContextMenu) return null;

  return (
    <Popover.Root open={open} onOpenChange={onOpenChange}>
      <Popover.Trigger
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onOpenChange(!open);
        }}
      >
        {element ? (
          element
        ) : type === 'icon' ? (
          <ContextButton variant="text" size="medium">
            <MoreVertOutlined fontSize="small" />
          </ContextButton>
        ) : (
          <StyledIconButton variant="secondary" size="medium">
            <MoreVertOutlined
              fontSize="small"
              sx={{
                display: 'flex',
              }}
            />
          </StyledIconButton>
        )}
      </Popover.Trigger>
      <StyledPopoverContent sideOffset={5} align="end">
        <Stack gap={1} width="100%" py={1}>
          {options.map(({ onClick, ...props }, i) => {
            return (
              <ContextMenuItem
                key={i}
                {...props}
                onClick={() => {
                  onOpenChange(false);
                  onClick();
                }}
              />
            );
          })}
        </Stack>
      </StyledPopoverContent>
    </Popover.Root>
  );
};

const ContextButton = styled(Button)({
  padding: 0,
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  transition: 'opacity 0.1s ease-in-out',
  '&:hover': {
    opacity: 0.75,
  },
  '& > span': {
    display: 'flex',
  },
});

const StyledIconButton = styled(Button)`
  width: 40px;
  height: 40px;
  padding: unset;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 32px;
    height: 32px;
  }
`;
