import { capitalize } from '@mui/material';

import { ampli } from '../../../../Ampli';
import { TBooking } from '../../../../Hooks/useBookings';
import { IExperience, Variant } from '../../../../Hooks/useExperience';
import { EventSourceType } from '../../../../tracking/events/eventProperties';
import {
  renderGuestTypes,
  TEventWithTitle,
} from '../../../../Utils/eventHelpers';

export const usePrintParticipants = (
  source: EventSourceType,
  event?: TEventWithTitle
) => {
  const handlePrintParticipants = (
    bookings: TBooking[],
    experience: IExperience,
    callback?: () => void
  ) => {
    ampli.eventButtonClicked({
      action: 'printParticipants',
      location: source,
    });

    if (bookings) {
      import('xlsx').then(({ utils, writeFileXLSX }) => {
        const ws = utils.json_to_sheet(
          prepareSheet(bookings, experience?.price?.variants)
        );
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Data');
        writeFileXLSX(wb, `ParticipantList_${event?.id}.xlsx`);
      });
    }

    callback?.();
  };

  const isDisabled = event?.slots?.booked === 0;

  return {
    handlePrintParticipants,
    isDisabled,
  };
};

const prepareSheet = (bookings: TBooking[], variants?: Variant[]) => {
  return bookings
    .filter((x) => x.status !== 'cancelled' && x.status !== 'moved')
    .reduce<TBooking[]>((acc, el) => {
      return [
        ...acc,
        ...Object.entries(el.items ?? {})
          .filter(([id]) => id.includes('variant'))
          .reduce<any[]>((all, [, count], index) => {
            return [
              ...all,
              ...Array.from(Array(count).keys()).map((__, i) => {
                return {
                  Time: el.startDateTime,
                  Name: el.customer.name,
                  Phone: el.customer.phone,
                  Email: el.customer.email,
                  Status: capitalize(el.status),
                  Reference: el.id,
                  Variants:
                    index === 0 && i === 0
                      ? renderGuestTypes(
                          el,
                          'variant',
                          undefined,
                          variants
                        ).join(', ')
                      : '-',
                  Addons:
                    index === 0 && i === 0
                      ? renderGuestTypes(el, 'addon', undefined, variants).join(
                          ', '
                        )
                      : '-',
                  Note: el.internalNote,
                  Source: capitalize(el.source ?? 'checkout'),
                  ...(el.customDataInputs
                    ? el.customDataInputs.reduce((acc, i) => {
                        const sorted = i.inputs
                          .filter((input) => input)
                          .sort((a, b) => a?.name.localeCompare(b?.name));

                        return {
                          ...acc,
                          ...sorted.reduce((acc, i) => {
                            return {
                              ...acc,
                              [i.name]: i.value,
                            };
                          }, {}),
                        };
                      }, {})
                    : {}),
                };
              }),
            ];
          }, []),
      ];
    }, []);
};
