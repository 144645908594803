import NiceModal from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { Alert, Box, Card, CircularProgress, Grid, Stack } from '@mui/material';
import { useState } from 'react';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { IntegrationObject } from '../../domain/use-integrations';
import { AvailableIntegrationDialog } from './available-integration-dialog';

export const AvailableIntegrationsListItem = ({
  title,
  description,
  extendedDescription,
  icon,
  status,
  buttonLabel,
  openForRequest,
  isRequested,
  guide,
  warnings = [],
  onInstall,
  onUninstall,
}: IntegrationObject) => {
  const { t } = useTranslate('utils.generic');
  const [isLoading, setIsLoading] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const onAddIntegration = async () => {
    setIsLoading(true);
    await onInstall?.(status)();
    setIsLoading(false);
  };

  const handleClick = async () => {
    if (status !== 'connect') {
      onAddIntegration();
      return;
    }

    NiceModal.show(AvailableIntegrationDialog, {
      integration: {
        icon,
        title,
        description,
        extendedDescription,
        openForRequest,
        isRequested,
        buttonLabel:
          typeof buttonLabel === 'function' ? buttonLabel(status) : buttonLabel,
        guide,
      },
      onAddIntegration,
    });
  };

  const handleDisconnect = async () => {
    setIsDisconnecting(true);
    await onUninstall?.();
    setIsDisconnecting(false);
  };

  return (
    <Grid
      item
      component={Card}
      minWidth={260}
      maxWidth={260}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'space-between'}
      textAlign={'center'}
      gap={1.5}
      sx={{ backgroundColor: lightTheme.palette.contrast.white }}
    >
      <Stack gap={2} alignItems={'center'}>
        <Box
          sx={{
            minWidth: 80,
            minHeight: 80,
            width: 80,
            backgroundSize: 'cover',
            borderRadius: 200,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url("${icon}")`,
          }}
        />
        <Stack gap={0.5}>
          <Text variant="medium">{title}</Text>
          <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
            {description}
          </Text>
        </Stack>
      </Stack>
      <Stack maxWidth={'100%'}>
        {warnings.length > 0 &&
          warnings.map((el, i) => (
            <Alert
              key={i}
              severity={'warning'}
              sx={{
                textAlign: 'left',
                overflowWrap: 'break-word',
              }}
            >
              {el}
            </Alert>
          ))}
      </Stack>
      <Stack width={'100%'} gap={1}>
        <Button
          variant={'primary'}
          size="medium"
          disabled={isLoading || isDisconnecting}
          leftIcon={
            isLoading && <CircularProgress color={'inherit'} size={'1em'} />
          }
          onClick={handleClick}
        >
          {status !== 'connect'
            ? typeof buttonLabel === 'function'
              ? buttonLabel(status)
              : buttonLabel
            : t('getStarted')}
        </Button>
        {onUninstall && status !== 'connect' ? (
          <Button
            variant={'secondary'}
            size="medium"
            disabled={isLoading || isDisconnecting}
            leftIcon={
              isDisconnecting && (
                <CircularProgress color={'inherit'} size={'1em'} />
              )
            }
            onClick={handleDisconnect}
          >
            {t('disconnectLabel', 'settings.integrations.economic')}
          </Button>
        ) : null}
      </Stack>
    </Grid>
  );
};
