import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { Location } from '@holdbar-com/utils-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { queryClient } from '../../../..';
import { updateExperience } from '../../../../Api';
import {
  QueryKeys as GetLocationQueryKeys,
  useDeleteLocation,
} from '../../../../Hooks/data/useLocations';
import { useGetEvents } from '../../../../Hooks/events/useGetEvents';
import { IExperience } from '../../../../Hooks/useExperience';
import { getLocalizedString } from '../../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { TagDialogWrapper } from '../../tag-management/dialogs/tag-dialog-wrapper';

export const DeleteLocationDialog = NiceModal.create(
  ({
    location,
    experiences,
    locations,
  }: {
    location: Location;
    experiences: IExperience[];
    locations: Location[];
  }) => {
    const { t } = useTranslate('location.dialog.delete');

    const modal = useModal();

    const handleClose = useCallback(() => {
      modal.resolve();
      modal.remove();
    }, [modal]);

    return (
      <TagDialogWrapper
        open={modal.visible}
        handleClose={handleClose}
        title={t('title')}
      >
        <DeleteLocationDialogContent
          location={location}
          experiences={experiences}
          locations={locations}
          handleClose={handleClose}
        />
      </TagDialogWrapper>
    );
  }
);

const DeleteLocationDialogContent = ({
  location,
  experiences,
  locations,
  handleClose,
}: {
  location: Location;
  experiences: IExperience[];
  locations: Location[];
  handleClose: () => void;
}) => {
  const { t, i18n } = useTranslate('location.dialog.delete');

  const {
    events: {
      data: events,
      isPending: isFetchingEvents,
      isError: isEventsError,
    },
  } = useGetEvents({
    locationIds: location.locationId,
    states: 'isUpcoming',
  });

  const [experiencesWithUpcomingEvents, setExperiencesWithUpcomingEvents] =
    useState<string[] | undefined>();

  useEffect(() => {
    if (!events) return;

    setExperiencesWithUpcomingEvents(
      Array.from(new Set(events?.map(({ experienceId }) => experienceId)))
    );
  }, [events]);

  if (isFetchingEvents || !experiencesWithUpcomingEvents) {
    return (
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        <Text>{t('checkingStatus')}</Text>
        <CircularProgress size="12px" />
      </Stack>
    );
  }

  if (isEventsError) {
    return (
      <Text color={lightTheme.palette.error.e300}>{t('eventsError')}</Text>
    );
  }

  if (experiencesWithUpcomingEvents && experiencesWithUpcomingEvents.length) {
    return (
      <>
        <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
          {t('unableToDelete.description')}
        </Text>
        <Stack>
          <Text fontSize="small" variant="medium">
            {t('unableToDelete.list.title')}
          </Text>
          <Stack>
            {experiencesWithUpcomingEvents.map((experienceId) => {
              const experience = experiences.find(
                (experience) => experience.id === experienceId
              );
              return (
                <StyledLink
                  key={experienceId}
                  to={`/experience/${experienceId}`}
                  onClick={handleClose}
                >
                  <Text fontSize="small" color="inherit">
                    {getLocalizedString(experience?.headline, i18n.language)}
                  </Text>
                </StyledLink>
              );
            })}
          </Stack>
        </Stack>
        <Button
          type="button"
          variant="secondary"
          size="large"
          onClick={handleClose}
        >
          {t('cancel', 'location.dialog.action')}
        </Button>
      </>
    );
  }

  return (
    <DeleteLocationForm
      location={location}
      experiences={experiences}
      locations={locations}
      handleClose={handleClose}
    />
  );
};

const DeleteLocationForm = ({
  location,
  experiences,
  locations,
  handleClose,
}: {
  location: Location;
  experiences: IExperience[];
  locations: Location[];
  handleClose: () => void;
}) => {
  const { t } = useTranslate('location.dialog.delete');

  const deleteLocation = useDeleteLocation(location.locationId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [replacementLocationIds, setReplacementLocationIds] = useState<
    Record<IExperience['id'], Location['locationId']>
  >({});
  const [openAccordionId, setOpenAccordionId] = useState<string | undefined>();

  const { experiencesThatRequireUpdate, experiencesThatUseLocationCount } =
    useMemo(() => {
      const experiencesWithThisLocation = [];

      for (const experience of experiences) {
        const { locationIds } = experience;
        // Skip experience if location to be deleted is not added to experience
        if (!locationIds || !locationIds.includes(location.locationId)) {
          continue;
        }

        experiencesWithThisLocation.push(experience);
      }

      return {
        experiencesThatRequireUpdate: experiencesWithThisLocation,
        experiencesThatUseLocationCount: experiencesWithThisLocation.length,
      };
    }, [experiences, location.locationId]);

  // Remove current location from options
  const locationOptions = useMemo(
    () => locations.filter((x) => x.locationId !== location.locationId),
    [location.locationId, locations]
  );

  const hasSelectedLocationForAllExperiences = useMemo(
    () =>
      Object.keys(replacementLocationIds).length ===
      experiencesThatUseLocationCount,
    [experiencesThatUseLocationCount, replacementLocationIds]
  );

  const handleSubmit = useCallback(async () => {
    if (
      experiencesThatUseLocationCount > 0 &&
      !hasSelectedLocationForAllExperiences
    ) {
      return;
    }
    setIsSubmitting(true);

    try {
      if (experiencesThatRequireUpdate.length) {
        await Promise.all(
          experiencesThatRequireUpdate.map((experience) => {
            const newLocationIds =
              experience.locationIds?.filter(
                (id) => id !== location.locationId
              ) ?? [];

            const replacementId = replacementLocationIds[experience.id];

            if (replacementId && replacementId !== 'none') {
              newLocationIds.push(replacementId);
            }

            if (newLocationIds.length === 0) {
              throw new Error('Experience must have at least 1 location');
            }

            return updateExperience(experience.id, {
              ...experience,
              locationIds: newLocationIds,
            });
          })
        );
      }

      await queryClient.invalidateQueries({ queryKey: ['experiences'] });
      await queryClient.invalidateQueries({
        queryKey: [GetLocationQueryKeys.locations],
      });

      await deleteLocation.mutateAsync().then(() => {
        handleClose();
      });
    } catch (error) {
      toast.error(t('generic', 'utils.errors'));
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }, [
    deleteLocation,
    experiencesThatRequireUpdate,
    experiencesThatUseLocationCount,
    handleClose,
    hasSelectedLocationForAllExperiences,
    location.locationId,
    replacementLocationIds,
    t,
  ]);

  const shouldChooseReplacement = !!experiencesThatUseLocationCount;
  const hasReplacementsAvailable = !!locationOptions.length;

  const isSubmitDisabled =
    shouldChooseReplacement &&
    (!hasSelectedLocationForAllExperiences || !hasReplacementsAvailable);

  return (
    <>
      <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
        {shouldChooseReplacement
          ? t('deleteInUseConfirmation', {
              amount: experiencesThatUseLocationCount,
            })
          : t('deleteConfirmation')}
      </Text>
      {shouldChooseReplacement && (
        <Stack sx={{ gap: 2 }}>
          <Stack>
            <Text variant="medium">{t('replaceLocation.label')}</Text>
            <Text fontSize="small">{t('replaceLocation.description')}</Text>
          </Stack>
          {hasReplacementsAvailable ? (
            <Stack divider={<Divider />}>
              {experiencesThatRequireUpdate.map((experience) => (
                <ChooseNewLocationAccordion
                  key={experience.id}
                  selectedLocation={
                    replacementLocationIds[experience.id] ?? null
                  }
                  experience={experience}
                  locationOptions={locationOptions}
                  open={openAccordionId === experience.id}
                  onOpenChange={setOpenAccordionId}
                  onSelect={(selectedLocation) =>
                    setReplacementLocationIds((prev) => ({
                      ...prev,
                      [experience.id]: selectedLocation,
                    }))
                  }
                  canSelectNoReplacement={
                    !experience.locationIds?.every(
                      (id) => id === location.locationId
                    )
                  }
                />
              ))}
            </Stack>
          ) : (
            <Text
              fontSize="small"
              variant="medium"
              color={lightTheme.palette.error.e300}
            >
              {t('replaceLocation.noLocations')}
            </Text>
          )}
        </Stack>
      )}
      <Stack
        sx={{
          flexDirection: { xs: 'column-reverse', sm: 'row' },
          gap: { xs: 1, sm: 2 },
          '& > button': {
            flexGrow: 1,
            flexBasis: { md: 0 },
          },
        }}
      >
        <Button
          type="button"
          variant="secondary"
          size="large"
          onClick={handleClose}
        >
          {t('cancel', 'location.dialog.action')}
        </Button>
        <Button
          type="submit"
          variant="danger"
          size="large"
          disabled={isSubmitDisabled}
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          {t('delete', 'location.dialog.action')}
        </Button>
      </Stack>
    </>
  );
};

const StyledLink = styled(Link)({
  color: lightTheme.palette.action.a200,
  transition: '0.1s ease-in-out',
  ':hover': {
    color: lightTheme.palette.action.a300,
  },
});

const ChooseNewLocationAccordion = ({
  selectedLocation,
  experience,
  locationOptions,
  open,
  onOpenChange,
  onSelect,
  canSelectNoReplacement,
}: {
  selectedLocation: string | null;
  experience: IExperience;
  locationOptions: Location[];
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<string | undefined>>;
  onSelect: (selectedLocation: string) => void;
  canSelectNoReplacement: boolean;
}) => {
  const { t, i18n } = useTranslate('location.dialog.delete');

  const selectedOptionLabel = selectedLocation
    ? selectedLocation === 'none'
      ? t('replaceLocation.noLocation')
      : locationOptions.find(
          (location) => location.locationId === selectedLocation
        )?.locationName ?? selectedLocation
    : t('replaceLocation.selectLocation');

  // Remove locations that have already been added to the experience
  const filteredLocationOptions = locationOptions.filter(
    ({ locationId }) => !experience.locationIds?.includes(locationId)
  );

  return (
    <StyledAccordion
      expanded={open}
      onChange={(_, open) => onOpenChange(open ? experience.id : undefined)}
      disableGutters
    >
      <StyledAccordionSummary>
        <Text fontSize="small">
          {getLocalizedString(experience.headline, i18n.language)}
        </Text>
        <Text fontSize="small">{selectedOptionLabel}</Text>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <FormControl>
          <FormLabel>{t('selectNewLocation')}</FormLabel>
          <RadioGroup
            name={experience.id}
            value={selectedLocation}
            onChange={(_, newValue) => {
              onSelect(newValue);
              onOpenChange(undefined);
            }}
          >
            {canSelectNoReplacement && (
              <LocationRadio
                value="none"
                label={t('replaceLocation.noLocation')}
              />
            )}
            {filteredLocationOptions.map(({ locationId, locationName }) => (
              <LocationRadio
                key={locationId}
                value={locationId}
                label={locationName}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

const LocationRadio = ({ value, label }: { value: string; label: string }) => {
  return (
    <FormControlLabel
      value={value}
      label={<Text fontSize="small">{label}</Text>}
      control={
        <Radio size="small" sx={{ padding: 0.75, paddingLeft: '9px' }} />
      }
    />
  );
};

const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  backgroundColor: 'unset',
});

const StyledAccordionSummary = styled(AccordionSummary)({
  padding: '0px',
  '.MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '0px',
  paddingBottom: 8,
});
