import { createContext, Reducer, useContext, useReducer } from 'react';

import {
  ActionType,
  ActionTypeEnum,
  BookingFlowSectionContextType,
  ExperiencePayloadType,
  FlowTypeEnum,
} from './types';

type InitialBookingFlowSectionState = {
  selectedFlowType: FlowTypeEnum;
  selectedExperience: ExperiencePayloadType;
  selectedLanguage: string;
  selectedTags?: string[];
};

const initialBookingFlowSectionState: InitialBookingFlowSectionState = {
  selectedFlowType: FlowTypeEnum.Storefront,
  selectedExperience: 'all',
  selectedLanguage: '',
};

const reducer = (
  state: InitialBookingFlowSectionState,
  action: ActionType
): typeof initialBookingFlowSectionState => {
  switch (action.type) {
    case ActionTypeEnum.SetFlowType:
      return {
        ...state,
        selectedFlowType: action.payload as FlowTypeEnum,
      };
    case ActionTypeEnum.SetExperience:
      if (typeof action.payload === 'string' && action.payload !== 'all')
        return state;
      return {
        ...state,
        selectedExperience: action.payload,
      };
    case ActionTypeEnum.SetLanguage:
      if (typeof action.payload !== 'string') return state;
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    case ActionTypeEnum.SetTags: {
      if (!Array.isArray(action.payload)) {
        return state;
      }

      return {
        ...state,
        selectedTags: action.payload,
      };
    }
    default:
      return state;
  }
};

export const BookingFlowSectionContext =
  createContext<BookingFlowSectionContextType | null>(null);

export const useBookingFlowSectionContext = () => {
  const context = useContext(BookingFlowSectionContext);
  if (!context) {
    throw new Error(
      'useBookingSectionContext must be used within a BookingSectionContextProvider'
    );
  }
  return context;
};

interface BookingFlowSectionContextProviderProps {
  children: React.ReactNode;
}

export const BookingFlowSectionContextProvider = ({
  children,
}: BookingFlowSectionContextProviderProps) => {
  const [state, dispatch] = useReducer<
    Reducer<typeof initialBookingFlowSectionState, ActionType>
  >(reducer, initialBookingFlowSectionState);

  return (
    <BookingFlowSectionContext.Provider value={{ ...state, dispatch }}>
      {children}
    </BookingFlowSectionContext.Provider>
  );
};
