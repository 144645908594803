import { lightTheme, Text } from '@holdbar-com/pixel';
import { Skeleton, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';

import { InfoBox } from '../../Components/InfoBox/InfoBox';
import { useTranslate } from '../../Hooks/useTranslate';
import { useQuickpayIntegration } from '../../Sections/SettingsSections/Integrations/domain/integrations/use-quickpay-integration';
import { StyledTable } from '../events/event_details/shared';
import { QuickpayIntegrationConfigure } from './quickpay-integration-configure';

export const QuickpayIntegrationLoader: FC = () => {
  const { t } = useTranslate('settings.integrations.payments.quickpay');

  const integration = useQuickpayIntegration();
  if (!integration) {
    return <Skeleton variant="rounded" height={200} />;
  }

  if (integration.status !== 'connected') {
    return <QuickpayIntegrationConfigure />;
  }

  return (
    <Stack gap={3} width={'100%'}>
      <InfoBox title={'Details'}>
        <StyledTable>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" sx={{ display: 'block' }}>
                <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
                  Quickpay ID
                </Text>
              </TableCell>
              <TableCell>{integration.onboarding?.metadata?.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" sx={{ display: 'block' }}>
                <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
                  {t('name')}
                </Text>
              </TableCell>
              <TableCell>
                {integration.onboarding?.metadata?.shopName}
              </TableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      </InfoBox>
    </Stack>
  );
};
