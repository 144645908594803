import { Button } from '@holdbar-com/pixel';
import { CircularProgress, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { IcalDialogStep } from '../../../ical-dialog-step-loader';
import { IcalFormValues } from '../use-ical-dialog-create-step';

type IcalDialogCreateStepFormActionsProps = {
  loading: boolean;
  hasLoadedInitially: boolean;
  setStep: React.Dispatch<React.SetStateAction<IcalDialogStep>>;
};

export const IcalDialogCreateStepFormActions = ({
  loading,
  hasLoadedInitially,
  setStep,
}: IcalDialogCreateStepFormActionsProps) => {
  const { t } = useTranslate('dialogs.ical.steps.create');

  const { reset } = useFormContext<IcalFormValues>();

  const handleCancelEdit = () => {
    reset();
    setStep(IcalDialogStep.done);
  };

  return (
    <Stack
      gap={2}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'flex-end'}
    >
      {hasLoadedInitially && (
        <Button
          variant={'secondary'}
          size={'medium'}
          disabled={loading}
          onClick={handleCancelEdit}
        >
          {t('undo', 'buttons')}
        </Button>
      )}
      <Button
        type={'submit'}
        variant={'primary'}
        size={'medium'}
        disabled={loading}
        style={{ minWidth: '30%' }}
      >
        {loading ? (
          <CircularProgress color={'inherit'} size={20} sx={{ m: 0 }} />
        ) : (
          t('continue', 'buttons')
        )}
      </Button>
    </Stack>
  );
};
