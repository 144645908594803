import { captureException } from '@sentry/react';
import randomBytes from 'randombytes';
import { ComponentPropsWithoutRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppShell } from '../Components/AppShell/AppShell';
import { LoadingFullscreen } from '../Components/Loading/LoadingFullscreen';
import { useExperienceEvents } from '../Hooks/events/useExperienceEvents';
import { useExperience } from '../Hooks/useExperience';
import { useFireOnce } from '../Hooks/useFireOnce';
import { useProfile } from '../Hooks/useProfile';
import {
  trackExperienceCreated,
  trackExperienceEditFlowSaved,
  trackExperienceEditFlowStarted,
} from '../tracking/experiences/flow/step-events';
import { ExperienceDetailsPage } from './ExperienceDetailsPage';
import { experienceSchema, experienceSyiSteps } from './SyiPage/config';
import { SyiPage } from './SyiPage/SyiPage';

const defaultValues = {
  seats: {
    type: 'single',
  },
  price: {
    hasVariants: true,
  },
  cutoffTimeSeconds: 0,
  infoForGuests: {
    selectedOptionKey: 'no',
  },
  customData: {
    selectedOptionKey: 'no',
  },
  notifications: {
    selectedOptionKey: 'emailAndText',
  },
  visibility: 'public',
};

export const ExperiencePage = ({
  children,
}: ComponentPropsWithoutRef<'main'>) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const {
    me: { data: me },
    company: { data: company, isLoading: isLoadingCompany },
  } = useProfile();

  const {
    params: { type },
  } = useMatch(':key/:id/:type/*') ?? { params: {} };
  const isCreateFlow = id === 'create' || location.pathname.includes('/create');

  const { state } = useLocation();
  const navigate = useNavigate();
  const fireOnce = useFireOnce();

  const _id = useMemo(
    () => (!isCreateFlow && id ? id : randomBytes(16).toString('hex')),
    [id, isCreateFlow]
  );

  const { experience, updateExperience, deleteExperience } = useExperience(
    _id!
  );

  const {
    eventsForExperience: { data: firstUpcomingEvent },
  } = useExperienceEvents(
    _id,
    { state: 'future', status: 'statusIsActive' },
    1,
    1
  );

  useEffect(() => {
    if (type === 'edit') {
      fireOnce(() => {
        trackExperienceEditFlowStarted(_id);
      });
    }
  }, [_id, type, fireOnce]);

  const _steps = useMemo(() => {
    return experienceSyiSteps.map((el) => {
      return {
        ...el,
        label: t(`experience.create.progress.${el.key}`),
      };
    });
  }, [t]);

  if (!isCreateFlow && type !== 'edit') {
    return (
      <AppShell hideContainerPadding>
        <ExperienceDetailsPage experienceId={_id}>
          {children}
        </ExperienceDetailsPage>
      </AppShell>
    );
  }

  if (isLoadingCompany)
    return <LoadingFullscreen isLoading={isLoadingCompany} />;

  const onSubmit = async (
    experience: any,
    _dirtyFields?: { [k: string]: boolean },
    stayOnPage: boolean = false
  ) => {
    setIsLoading(true);
    await updateExperience
      .mutateAsync(experience)
      .then(() => {
        if (!stayOnPage) {
          navigate(`/experience/${_id}`, { replace: true });
        }

        toast.success(
          isCreateFlow
            ? t('experience.create.toast.creatingSuccess')
            : t('experience.edit.toast.editingSuccess')
        );

        if (isCreateFlow) {
          trackExperienceCreated(me?.id);
        } else {
          trackExperienceEditFlowSaved(
            experience.id,
            _dirtyFields,
            firstUpcomingEvent
          );
        }
      })
      .catch((error) => {
        captureException(error, (scope) => {
          scope.setTransactionName(
            `Failed to ${isCreateFlow ? 'create' : 'edit'} experience`
          );
          return scope;
        });

        toast.error(t('experience.create.toast.creatingError'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <SyiPage
        modelType={'experience'}
        steps={_steps}
        returnUrl={location?.state?.returnUrl ?? '/experiences'}
        title={
          isCreateFlow
            ? `${t('utils.generic.new')} ${t('utils.generic.experience')}`
            : `${t('utils.generic.edit')} ${t('utils.generic.experience')}`
        }
        deleteItem={deleteExperience}
        updateItem={updateExperience}
        item={experience}
        schema={experienceSchema(company)}
        state={state}
        onSubmit={onSubmit}
        defaultValues={{
          status: 'draft',
          ...(isCreateFlow
            ? {
                ...defaultValues,
                languages: company?.languages,
              }
            : {}),
        }}
        id={_id}
        isCreateFlow={isCreateFlow}
        isLoading={isLoading}
      >
        {children}
      </SyiPage>
    </>
  );
};
