import { apiServices } from './apiServices';
import api from './index';

type ExperiencePayload = {
  experienceId?: string;
  experienceName?: string;
  language: string;
};

type ExperienceDescriptionPayload = {
  keywords: string[];
} & ExperiencePayload;

type ExperienceResponse = {
  experience: string;
  keywords: string[];
  language: string;
  completion: string;
};

export const generateExperience = async (
  payload: ExperienceDescriptionPayload
) => {
  if (typeof apiServices.generation === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_GENERATION_URL is not defined'
    );
  }

  const { data } = await api.post<ExperienceResponse>(
    `${apiServices.generation}/experience`,
    { ...payload }
  );

  return data;
};

export const generateExperienceKeywords = async (
  payload: ExperiencePayload
) => {
  if (typeof apiServices.generation === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_GENERATION_URL is not defined'
    );
  }

  const { data } = await api.post<ExperienceResponse>(
    `${apiServices.generation}/experience-keywords`,
    { ...payload }
  );

  return data;
};
