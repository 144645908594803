import { Button, Text } from '@holdbar-com/pixel';
import { MenuItem, Stack, TextField } from '@mui/material';
import countries from 'iso-3166-1';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { ILocationView } from '../../../../types/api/location';

export const LocationForm = ({
  onSubmit,
  defaultValues,
  handleCancel,
  isSubmitting,
  isEdit = false,
  defaultCountry = 'DK',
}: {
  onSubmit: (data: ILocationView) => void;
  defaultValues?: ILocationView;
  handleCancel: () => void;
  isSubmitting: boolean;
  isEdit?: boolean;
  defaultCountry?: string;
}) => {
  const { t, i18n } = useTranslate('location.dialog.form');

  const formMethods = useForm<ILocationView>({
    defaultValues: {
      locationId: defaultValues?.locationId,
      locationName: defaultValues?.locationName,
      address: {
        line1: defaultValues?.address.line1,
        line2: defaultValues?.address.line2,
        city: defaultValues?.address.city,
        state: defaultValues?.address.state,
        postalCode: defaultValues?.address.postalCode,
        country: defaultValues?.address.country,
      },
      revision: defaultValues?.revision,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const countryNames = useMemo(
    () =>
      new Intl.DisplayNames(i18n.language, {
        type: 'region',
      }),
    [i18n.language]
  );

  const countryOptions = useMemo(
    () => getCountryOptions(i18n.language, countryNames),
    [i18n.language, countryNames]
  );

  return (
    <FormProvider {...formMethods}>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ flexGrow: 1, justifyContent: 'space-between', gap: 4 }}
      >
        <Stack sx={{ gap: 3 }}>
          <TextField
            {...register('locationName', {
              required: {
                value: true,
                message: t('locationName.error.required'),
              },
              maxLength: {
                value: 20,
                message: t('locationName.error.maxLength', { amount: 20 }),
              },
            })}
            required
            label={t('locationName.label')}
            placeholder={t('locationName.placeholder')}
            InputLabelProps={{ shrink: true }}
            error={!!errors.locationName?.message}
            helperText={errors.locationName?.message}
          />
          <TextField
            {...register('address.line1')}
            label={t('address.line1.label')}
            InputLabelProps={{ shrink: true }}
          />
          <Stack sx={{ flexDirection: 'row', gap: 2 }}>
            <TextField
              {...register('address.postalCode')}
              fullWidth
              label={t('address.postalCode.label')}
              InputLabelProps={{ shrink: true }}
              sx={{ maxWidth: '35%' }}
            />
            <TextField
              {...register('address.city')}
              fullWidth
              label={t('address.city.label')}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
          <Stack sx={{ flexDirection: 'row', gap: 2 }}>
            <TextField
              {...register('address.state')}
              fullWidth
              label={t('address.state.label')}
              InputLabelProps={{ shrink: true }}
              sx={{ maxWidth: '35%' }}
            />
            <TextField
              select
              {...register('address.country', {
                required: {
                  value: true,
                  message: t('address.country.error.required'),
                },
              })}
              defaultValue={defaultCountry}
              label={t('address.country.label')}
              InputLabelProps={{ shrink: true }}
              fullWidth
              error={!!errors.address?.country?.message}
              helperText={errors.address?.country?.message}
            >
              {countryOptions.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  <Text fontSize="small">{country.name}</Text>
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Stack>
        <Stack
          sx={{
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            gap: { xs: 1, sm: 2 },
            '& > button': {
              flexGrow: 1,
              flexBasis: { md: 0 },
            },
          }}
        >
          <Button
            type="button"
            variant="secondary"
            size="large"
            onClick={handleCancel}
          >
            {t('cancel', 'location.dialog.action')}
          </Button>
          <Button
            type="submit"
            variant="primary"
            size="large"
            loading={isSubmitting}
          >
            {t(isEdit ? 'save' : 'create', 'location.dialog.action')}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};

function getCountryOptions(
  locale: string,
  names: Intl.DisplayNames
): Array<CountryOption> {
  // Returns a sorted list of the country names in the current language
  return countries
    .all()
    .map(
      (country: { alpha2: string }): CountryOption => ({
        code: country.alpha2,
        name: names.of(country.alpha2) ?? '',
      })
    )
    .sort((a: CountryOption, b: CountryOption) =>
      a.name.localeCompare(b.name, locale)
    );
}

type CountryOption = {
  code: string;
  name: string;
};
