import { Stack } from '@mui/material';

import { ProfileFormAbout } from './ProfileFormAbout';
import { ProfileFormActions } from './ProfileFormActions';
import { ProfileFormDetails } from './ProfileFormDetails';
import { ProfileFormLanguages } from './ProfileFormLanguages';
import { ProfileFormPicture } from './ProfileFormPicture';

export const ProfileForm = () => {
  return (
    <Stack gap={3} component={'form'}>
      <ProfileFormPicture />
      <ProfileFormDetails />
      <ProfileFormLanguages />
      <ProfileFormAbout />
      <ProfileFormActions />
    </Stack>
  );
};
