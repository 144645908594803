import { lightTheme } from '@holdbar-com/pixel';
import { CircularProgress, Slide, Stack } from '@mui/material';
import { FC, useState } from 'react';

import { useBookings } from '../../../Hooks/useBookings';
import { useReceipt } from '../../../Hooks/useReceipt';
import { trackBookingCheckInCompleted } from '../../../tracking/bookings/details';
import { deserializeQr } from '../../../Utils/holdbar-qr-deserializer';
import { TicketCheckingIn } from './ticket-checking-in';
import { TicketCheckinSuccess } from './ticket-checking-success';
import { TicketDetails } from './ticket-details';
import { TicketError } from './ticket-error';

const SUCCESS_MESSAGE_DURATION_MS = 500;

type Props = {
  containerRef: React.RefObject<HTMLDivElement | null>;
  qrCodeValue: string | null;
  onClose: () => void;
};

export const TicketPopover: FC<Props> = ({
  containerRef,
  qrCodeValue,
  onClose,
}) => {
  const qr = deserializeQr(qrCodeValue ?? '');
  const { booking, checkInBooking } = useBookings(undefined, qr?.bookingId);
  const { receipt } = useReceipt(qr?.bookingId);
  const [checkInLoading, setCheckInLoading] = useState(false);
  const [isCheckedIn, setIsCheckedIn] = useState(false);

  async function handleCheckIn() {
    if (!booking?.data?.id) {
      return;
    }

    setCheckInLoading(true);
    await checkInBooking.mutateAsync({
      id: booking?.data?.id,
      method: 'backoffice-qr-scan',
    });
    setCheckInLoading(false);
    setIsCheckedIn(true);

    trackBookingCheckInCompleted('qr_code', booking.data, receipt.data);

    setTimeout(() => {
      onClose();
      setIsCheckedIn(false);
    }, SUCCESS_MESSAGE_DURATION_MS);
  }

  if (booking.isLoading) {
    return <CircularProgress sx={{ color: lightTheme.palette.neutral.n100 }} />;
  }

  return (
    <Slide in={!!qrCodeValue} container={containerRef.current} direction="up">
      <Stack
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          p: 3,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'stretch',
          minHeight: '60vh',
        }}
      >
        <>
          {isCheckedIn && <TicketCheckinSuccess />}
          {checkInLoading && <TicketCheckingIn />}
          {qr === null && (
            <TicketError error="invalid-format" onClose={onClose} />
          )}
          {booking.error && <TicketError error="load-fail" onClose={onClose} />}
          {booking.data && !isCheckedIn && !checkInLoading ? (
            <TicketDetails
              booking={booking.data}
              isCheckInLoading={checkInLoading}
              onClose={onClose}
              onCheckIn={() => handleCheckIn()}
            />
          ) : null}
        </>
      </Stack>
    </Slide>
  );
};
