import {
  EventStates,
  EventStatus,
  ExperienceStatus,
} from '@holdbar-com/utils-types';

export const getEventStatus = (
  status: EventStatus,
  states: EventStates,
  experienceStatus: ExperienceStatus
) => {
  if (states.statusIsCancelled || status === 'cancelled') return 'cancelled';
  if (states.statusIsInactive || experienceStatus === 'inactive')
    return 'inactive';
  if (states.isCompleted) return 'done';
  if (states.statusIsActive) return 'active';
  if (status === 'draft') return 'draft';
  return status === 'active' ? 'active' : 'inactive';
};
