import { useMemo } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';

type Option<T> = {
  selectedOptionKey: boolean;
  value: T;
};

export type IcalFormValues = {
  onlyEventsWithBookings: Option<never>;
  filterGuides: Option<string[]>;
  language: Option<string | null>;
};

export const icalCreateDefaultValues = {
  onlyEventsWithBookings: {
    selectedOptionKey: false,
  },
  filterGuides: {
    selectedOptionKey: false,
    value: [],
  },
  language: {
    selectedOptionKey: false,
    value: null,
  },
};

export const useIcalDialogCreateStep = () => {
  const { t } = useTranslate('dialogs.ical.steps.create');

  const onlyEventsWithBookingsOptions = useMemo(
    () => [
      {
        key: true,
        label: t('eventsWithBookings.true'),
      },
      {
        key: false,
        label: t('eventsWithBookings.false'),
      },
    ],
    [t]
  );

  const filterGuidesOptions = useMemo(
    () => [
      {
        key: false,
        label: t('filterGuides.false'),
      },
      {
        key: true,
        label: t('filterGuides.true'),
      },
    ],
    [t]
  );

  const languageOptions = useMemo(
    () => [
      {
        key: false,
        label: t('language.false'),
      },
      {
        key: true,
        label: t('language.true'),
      },
    ],
    [t]
  );

  return {
    onlyEventsWithBookingsOptions,
    filterGuidesOptions,
    languageOptions,
  };
};
