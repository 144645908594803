import { Dispatch, SetStateAction } from 'react';

import { OverviewPageViewedProperties } from '../../Ampli';
import { CategoryTotals, MetricsSeries } from '../../Api/Analytics';

export const timePeriods = [
  'today',
  'thisWeek',
  'thisMonth',
  'lastMonth',
  'year',
  'custom',
] as const;
export type TimePeriod = (typeof timePeriods)[number];

export type DashboardPageState = {
  isLoadingMetrics: boolean;
  isError: boolean;
  graphSeries?: MetricsSeries;
  categoryTotals?: CategoryTotals;
  metadata:
    | {
        dataUpdateTimeUtc: string;
        nextDataUpdateTimeUtc: string;
      }
    | undefined;
  fromDate: Date;
  toDate: Date;
  experienceFilter: string[];
  overviewTrackingData: OverviewPageViewedProperties | undefined;
  setFromDate: Dispatch<SetStateAction<Date>>;
  setToDate: Dispatch<SetStateAction<Date>>;
  setExperienceFilter: Dispatch<SetStateAction<string[]>>;
  setOverviewTrackingData: Dispatch<
    SetStateAction<OverviewPageViewedProperties | undefined>
  >;
};
