import { differenceInDays } from 'date-fns';

import { countVariants } from '../../../features/bookings/booking_details/ui/booking_details_summary/domain/use_booking_details_summary';
import { EventType } from '../../../features/events/event_upsert/domain/types';
import { getFormattedDate } from '../../helpers';

export const eventProperties = (event: EventType) => {
  const {
    id: event_id,
    visibility,
    assignedGuides,
    seatCount,
    experienceId: experience_id,
    languages: language_list,
    addresses: location,
    startDateTime,
    endDateTime,
  } = event;

  const event_scheduled_date = getFormattedDate(new Date(startDateTime));

  const days_until_event_starts = differenceInDays(
    new Date(startDateTime),
    new Date()
  );

  const days_until_event_closes = endDateTime
    ? differenceInDays(new Date(endDateTime), new Date())
    : undefined;

  const is_recurring =
    event.recurring.selectedOptionKey === 'yes'
      ? event.recurring.frequency.value
      : undefined;

  const number_of_times =
    event.intervals.selectedOptionKey === 'yes'
      ? event.intervals.value.length + 1
      : 1;

  const number_of_guests = event.booking?.name
    ? countVariants(event.booking.guests)
    : undefined;

  return {
    event_capacity: seatCount?.value ?? 0,
    event_id,
    experience_id,
    is_private: visibility === 'private',
    language_list,
    location: location?.value ?? '',
    number_of_guides: assignedGuides.length,
    days_until_event_starts,
    days_until_event_closes,
    event_scheduled_date,
    is_recurring,
    number_of_times,
    language: event.booking?.language,
    number_of_guests,
    flow_type: event.variant,
  };
};
