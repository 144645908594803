import { lightTheme, Text } from '@holdbar-com/pixel';
import { Box, Stack, styled } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useTranslate } from '../../../Hooks/useTranslate';
import {
  CompanyPicker,
  CompanyPickerProps,
} from '../../../Pages/Auth/CompanyPicker';
import { useAuthStore } from '../../../Store/useAuthStore';
import { DialogWrapper } from './dialog-wrapper';

export type Account = {
  id: string;
  name: string;
  logo?: string;
};

export type CompanyPickerFormValues = {
  org: string;
};

export function ChooseAccountDialog({
  companies,
  onSubmit,
  invitedEmail,
  currentUserEmail,
  invitationToken,
}: {
  companies: Account[];
  onSubmit: CompanyPickerProps['onSubmit'];
  invitedEmail: string;
  currentUserEmail: string;
  invitationToken: string;
}) {
  const form = useForm<CompanyPickerFormValues>();
  const { t } = useTranslate('connect.chooseAccount');
  const { clearAuth } = useAuthStore();
  const mismatchingEmailAddresses =
    invitedEmail.toLowerCase() !== currentUserEmail.toLowerCase();

  return (
    <DialogWrapper open={true} title={t('title')} fullWidth>
      <Stack gap={4}>
        <Text variant="normal" fontSize="medium">
          {t('description')}
        </Text>
        <FormProvider {...form}>
          <CompanyPicker onSubmit={onSubmit} companies={companies} />
          {mismatchingEmailAddresses && (
            <Box
              sx={{
                background: lightTheme.palette.neutral.n50,
                borderRadius: 1,
                px: 2,
                py: 1.5,
              }}
            >
              <Text variant="normal" fontSize="small">
                <Trans
                  i18nKey={'connect.chooseAccount.mismatchingEmailsMessage'}
                  values={{
                    invitedEmail,
                    currentUserEmail,
                    invitationToken,
                  }}
                  components={[
                    <Text variant="medium" fontSize="small" key="invitedEmail">
                      {invitedEmail}
                    </Text>,
                    <Text
                      variant="medium"
                      fontSize="small"
                      key="currentUserEmail"
                    >
                      {currentUserEmail}
                    </Text>,
                    <StyledRouterLink
                      key="login-link"
                      onClick={(e) => {
                        e.preventDefault();
                        clearAuth();

                        e.target.dispatchEvent(
                          new MouseEvent('click', e.nativeEvent)
                        );
                      }}
                      to={`/login?returnUrl=/settings/connect/invitation/${invitationToken}`}
                    ></StyledRouterLink>,
                  ]}
                ></Trans>
              </Text>
            </Box>
          )}
        </FormProvider>
      </Stack>
    </DialogWrapper>
  );
}

const StyledRouterLink = styled(Link)({
  color: lightTheme.palette.action.a300,
  textDecoration: 'underline',
});
