import { Box, BoxProps, Card, Grow, lighten, styled } from '@mui/material';

const StyledItem = styled(Box)({
  borderRadius: 16,
  zIndex: 10,
});

export const OnboardingWidgetItemContainer = ({
  children,
  shouldFocus = false,
  itemCount = 3,
  ...props
}: BoxProps & { shouldFocus?: boolean; itemCount?: number }) => {
  return (
    <Box position={'relative'} mb={2} {...props}>
      <StyledItem
        component={Card}
        p={0}
        position={'relative'}
        boxShadow={'0 4px 16px 0 rgba(0,0,0,0.07)'}
        display={'flex'}
        minHeight={240}
      >
        {children}
      </StyledItem>
      <Box sx={{ transform: 'translateY(0px)' }}>
        {Array.from(Array(itemCount > 0 ? itemCount : 0).keys()).map(
          (el, i) => (
            <Grow in={!shouldFocus} key={`grow-item-${i}`}>
              <Box
                component={Card}
                boxShadow={'0 4px 16px 0 rgba(0,0,0,0.07)'}
                width={`calc(100% - ${(i + 1) * 32}px)`}
                height={4}
                borderRadius={2}
                zIndex={itemCount - i}
                position={'absolute'}
                bottom={-(6 * (i + 1))}
                left={16 * (i + 1)}
                sx={{
                  background: `white linear-gradient(90deg, ${lighten(
                    'rgba(0,0,0,0.07)',
                    0.5
                  )} 0%, ${lighten('rgba(0,0,0,0.07)', 0.1)} 100%)`,
                }}
              />
            </Grow>
          )
        )}
      </Box>
    </Box>
  );
};
