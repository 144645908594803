import NiceModal from '@ebay/nice-modal-react';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { ArrowBackRounded } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { View } from '../../Components/View';
import { useEconomicConfiguration } from '../../features/economic/data/use-economic-configuration';
import { EconomicIntegrationLoader } from '../../features/economic/economic-integration-loader';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useTranslate } from '../../Hooks/useTranslate';
import { ConfirmDialog } from '../../Sections/SettingsSections/Integrations/ui/shared/confirm-dialog';

export const EconomicConfigurationPage = () => {
  const { t } = useTranslate('settings.integrations.economic.configuration');
  const { isMd } = useResponsive();
  const navigate = useNavigate();

  const { configuration } = useEconomicConfiguration();

  const [configurationIsDirty, setConfigurationIsDirty] = useState(false);

  const handleNavigateBack = () => {
    if (configuration.data === null) {
      navigate('/settings/integrations');
      return;
    }

    navigate('/settings/integrations/economic');
  };

  const handleClickBack = (e?: MouseEvent) => {
    e?.preventDefault();

    if (configurationIsDirty) {
      NiceModal.show(ConfirmDialog, {
        title: t('title', 'settings.integrations.cancelDialog'),
        description: t('description', 'settings.integrations.cancelDialog'),
        cta: t('cta', 'settings.integrations.cancelDialog'),
        onConfirm: handleNavigateBack,
      });
    } else {
      handleNavigateBack();
    }
  };

  return (
    <View back={handleNavigateBack} style={{ paddingBottom: 48 }}>
      <Stack gap={2} direction={'row'} width={'100%'} mb={2}>
        {!isMd && (
          <StyledBackArrowRounded onClick={(e) => handleClickBack(e)} />
        )}
        <Stack alignItems={'center'} mt={isMd ? 0 : 1}>
          <Text fontSize="large" variant="medium">
            {t('title')}
          </Text>
        </Stack>
      </Stack>
      <EconomicIntegrationLoader
        setConfigurationIsDirty={setConfigurationIsDirty}
      />
    </View>
  );
};

const StyledBackArrowRounded = styled(ArrowBackRounded)({
  height: '33px',
  width: '33px',
  padding: '4px',
  cursor: 'pointer',
  borderRadius: '50%',
  marginTop: '3.5px',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n200,
  },
});
