import styled from '@emotion/styled';
import { Button, lightTheme, LinkButton, Text } from '@holdbar-com/pixel';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useTranslate } from '../../../Hooks/useTranslate';
import { trackSignUpConsentChanged } from '../../../tracking/signup/trackSignUpConsentChanged';
import { trackSignUpEmailFieldOpened } from '../../../tracking/signup/trackSignUpEmailFieldOpened';
import { trackSignUpLogInClicked } from '../../../tracking/signup/trackSignUpLogInClicked';
import { trackSignUpPasswordFieldOpened } from '../../../tracking/signup/trackSignUpPasswordFieldOpened';
import { trackSignUpTermsClicked } from '../../../tracking/signup/trackSignUpTermsClicked';
import { emailRegex } from '../../../Utils/helpers';

type SignupFormInputs = {
  email: string;
  password: string;
  hasAcceptedTerms: boolean;
};

export const SignupForm = ({
  isSubmitting,
  prefilledEmail,
  disableEmailField,
  setIsSubmitting,
  callback,
}: {
  isSubmitting: boolean;
  prefilledEmail?: string;
  disableEmailField?: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  callback: (password?: string, email?: string) => Promise<void>;
}) => {
  const { t } = useTranslate('signup.form');

  const [showPassword, setShowPassword] = useState(false);

  const formMethods = useForm<SignupFormInputs>({
    defaultValues: {
      email: prefilledEmail,
    },
  });

  const {
    handleSubmit,
    register,
    formState: {
      errors: {
        email: emailError,
        password: passwordError,
        hasAcceptedTerms: termsError,
      },
    },
    setError,
    watch,
  } = formMethods;

  const onSubmit = async ({ email, password }: SignupFormInputs) => {
    try {
      setIsSubmitting(true);
      await callback(password, email);
    } catch (error) {
      const { message } = error as { message?: string };
      if (message === 'USER_ALREADY_EXISTS') {
        setError('email', { message: t('validation.emailAlreadyUsed') });
      } else {
        console.error('An error occured', error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const watchedTerms = watch('hasAcceptedTerms');

  useEffect(() => {
    trackSignUpConsentChanged(watchedTerms);
  }, [watchedTerms]);

  return (
    <FormProvider {...formMethods}>
      <Stack
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        gap={16}
        flexGrow={1}
      >
        <Stack gap={1}>
          <Stack gap={3}>
            <TextField
              {...register('email', {
                required: {
                  value: true,
                  message: t('validation.emailRequired'),
                },
                pattern: {
                  value: emailRegex,
                  message: t('validation.emailInvalid'),
                },
              })}
              type="email"
              error={!!emailError}
              helperText={emailError?.message}
              label={t('label.email')}
              placeholder={t('placeholder.email')}
              InputLabelProps={{ shrink: true }}
              onFocus={trackSignUpEmailFieldOpened}
              disabled={isSubmitting || disableEmailField}
            />
            <TextField
              {...register('password', {
                required: {
                  value: true,
                  message: t('validation.passwordRequired'),
                },
              })}
              type={showPassword ? 'text' : 'password'}
              error={!!passwordError}
              helperText={passwordError?.message}
              label={t('label.password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword ? 'Hide password' : 'Show password'
                      }
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onFocus={trackSignUpPasswordFieldOpened}
              disabled={isSubmitting}
            />
          </Stack>
          <Stack>
            <FormControlLabel
              {...register('hasAcceptedTerms', {
                required: {
                  value: true,
                  message: t('validation.termsRequired'),
                },
              })}
              label={
                <Text fontSize="small">
                  {t('label.acceptTerms')}{' '}
                  <StyledLink
                    variant="text"
                    size="small"
                    href="https://understory.io/terms"
                    target="_blank"
                    onClick={trackSignUpTermsClicked}
                  >
                    {t('label.termsAndConditions')}
                  </StyledLink>
                </Text>
              }
              control={
                <Checkbox
                  disabled={isSubmitting}
                  sx={{
                    color: termsError ? lightTheme.palette.error.e300 : 'unset',
                  }}
                />
              }
              disabled={isSubmitting}
            />
            {termsError && (
              <Text
                fontSize="xsmall"
                color={lightTheme.palette.error.e300}
                style={{ marginTop: '-4px' }}
              >
                {termsError.message}
              </Text>
            )}
          </Stack>
        </Stack>
        <Stack marginTop="auto" gap={2}>
          <Button
            type="submit"
            variant="primary"
            size="large"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={20} /> : t('action.signup')}
          </Button>
          {!prefilledEmail && (
            <Text textAlign="center" variant="medium" fontSize="small">
              {t('existingAccount')}{' '}
              <StyledLink
                size="small"
                variant="text"
                href="/login"
                onClick={trackSignUpLogInClicked}
              >
                {t('action.login')}
              </StyledLink>
            </Text>
          )}
        </Stack>
      </Stack>
    </FormProvider>
  );
};

const StyledLink = styled(LinkButton)({
  padding: 0,
  height: 'unset',
  color: lightTheme.palette.action.a300,
  fontSize: '14px',
});
