import { Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useResponsive from '../../../../../../Hooks/layout/useResponsive';
import { BookingDetailsSummaryBooking } from './booking/BookingDetailsSummaryBooking';
import { BookingDetailsSummaryMobileDivider } from './BookingDetailsSummaryMobileDivider';
import { BookingDetailsSummaryCustomer } from './customer/BookingDetailsSummaryCustomer';
import { BookingDetailsSummaryNotes } from './notes/BookingDetailsSummaryNotes';
import { BookingDetailsSummaryNotifications } from './notifications/BookingDetailsSummaryNotifications';
import { BookingDetailsSummaryPayment } from './payment/BookingDetailsSummaryPayment';

export const BookingDetailsSummaryMobile = () => {
  const { isSm } = useResponsive();
  const { featureNotificationsLog } = useFlags();

  return (
    <Stack gap={isSm ? 4 : 2} divider={<BookingDetailsSummaryMobileDivider />}>
      <BookingDetailsSummaryBooking />
      <BookingDetailsSummaryPayment />
      <BookingDetailsSummaryCustomer />
      <BookingDetailsSummaryNotes />
      {featureNotificationsLog && <BookingDetailsSummaryNotifications />}
    </Stack>
  );
};
