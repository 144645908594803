import { useMemo } from 'react';
import * as yup from 'yup';

import { useTranslate } from '../../../Hooks/useTranslate';

export const useVatFormValidation = () => {
  const { t } = useTranslate('');

  return useMemo(
    () =>
      yup.object().shape({
        vatCompliance: yup.object().shape({
          isVatRegistered: yup
            .boolean()
            .default(true)
            .oneOf([true], t('required', 'utils.errors'))
            .required(t('required', 'utils.errors')),
          vatRegistration: yup.object().shape({
            vatNumber: yup.string().required(t('required', 'utils.errors')),
            country: yup.string().required(t('required', 'utils.errors')),
            defaultVatCategory: yup
              .string()
              .required(t('required', 'utils.errors')),
          }),
        }),
      }),
    [t]
  );
};

export type VatFormData = yup.InferType<
  ReturnType<typeof useVatFormValidation>
>;
