import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { Experience, ExperienceSortOption } from '@holdbar-com/utils-types';
import { SortRounded } from '@mui/icons-material';
import { MenuItem, Select, Skeleton, Stack } from '@mui/material';
import { Popover } from '@radix-ui/themes';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { EmptyScreen } from '../Components/EmptyScreen/EmptyScreen';
import { StyledPopoverContent } from '../Components/Popover/radix_popover_styles';
import {
  DesktopCard,
  ExperienceItem,
  MobileCard,
} from '../features/experiences/overview/experience-item';
import useResponsive from '../Hooks/layout/useResponsive';
import { useExperience } from '../Hooks/useExperience';
import { useFireOnce } from '../Hooks/useFireOnce';
import { useProfile } from '../Hooks/useProfile';
import { useTranslate } from '../Hooks/useTranslate';
import { trackEventFlowOpened } from '../tracking/events/flow/trackEventFlowOpened';
import { trackExperiencesPageViewed } from '../tracking/experiences/page/trackExperiencesPageViewed';
import { CrudPage } from './CrudPage';
import { experienceSyiSteps } from './SyiPage/config';

const EXPERIENCE_PREFERENCES_KEY = 'experiences';

// Alphabetical order is used as fallback in the backend so we that as the default setting
export const DEFAULT_EXPERIENCE_SORTING: ExperienceSortOption = 'alphanumeric';

export const ExperiencesPage = () => {
  const { t, i18n } = useTranslate('experience');
  const { isMd } = useResponsive();
  const { updatePreferences, me, company } = useProfile();
  const [sortBy, setSortBy] = useState<ExperienceSortOption>(
    DEFAULT_EXPERIENCE_SORTING
  );

  const navigate = useNavigate();
  const fireOnce = useFireOnce();

  const {
    experiences: { data: experiences, isLoading },
  } = useExperience(undefined, {
    // If sortOrder is selected, we use the company setting instead
    sortExperiencesBy:
      sortBy === 'sortOrder'
        ? company.data?.sortExperiencesBy ?? DEFAULT_EXPERIENCE_SORTING
        : sortBy,
    defaultLanguage: company.data?.defaultLanguage,
    language: i18n.language,
  });

  const [hasAppliedPreference, setHasAppliedPreference] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (hasAppliedPreference) return;
    if (!me.data) return;
    setSortBy(
      (prev) => me.data?.metadata?.preferences?.experiences?.sortBy ?? prev
    );
    setHasAppliedPreference(true);
  }, [hasAppliedPreference, me.data]);

  useEffect(() => {
    if (!experiences) return;

    fireOnce(() => trackExperiencesPageViewed(experiences, me.data?.id));
  }, [experiences, me.data, fireOnce]);

  const handleChange = useCallback(
    (newValue: ExperienceSortOption) => {
      setSortBy(newValue);
      updatePreferences(
        EXPERIENCE_PREFERENCES_KEY,
        (previous: Record<string, string> | undefined) => {
          return {
            ...previous,
            sortBy: newValue,
          };
        }
      );
    },
    [updatePreferences]
  );

  const experiencesCount = Number(experiences?.length);

  const sortOptions = [
    { key: 'alphanumeric' },
    { key: 'createdAtDesc' },
    { key: 'createdAtAsc' },
    { key: 'sortOrder' },
  ];

  return (
    <CrudPage
      baseScope="experience"
      createButtonLabel={t('buttonLabel')}
      createEventButtonLabel={t('createEventButtonLabel')}
      title={t('title')}
      titleProps={isMd ? { fontSize: 'large', variant: 'medium' } : undefined}
      description={
        isMd ? t('description', { count: experiencesCount }) : undefined
      }
      handleCreate={() =>
        navigate(`/experience/create/${experienceSyiSteps[0].key}`, {
          replace: true,
        })
      }
      handleCreateEvent={
        experiencesCount
          ? () => {
              navigate('/event/create', {
                replace: true,
                state: { returnUrl: '/experiences' },
              });
              trackEventFlowOpened('/experiences', 'create');
            }
          : undefined
      }
      contextMenu={
        <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <Popover.Trigger>
            <StyledIconButton
              variant="secondary"
              size="medium"
              onClick={() => {}}
            >
              <SortRounded
                fontSize="small"
                sx={{
                  color: 'black',
                  display: 'flex',
                }}
              />
            </StyledIconButton>
          </Popover.Trigger>
          <StyledPopoverContent sideOffset={5} align="end">
            <Stack gap={1} width="100%" py={1}>
              {sortOptions.map(({ key }) => {
                return (
                  <StyledContextMenuItem
                    key={key}
                    size="medium"
                    variant="text"
                    onClick={() => {
                      handleChange(key as ExperienceSortOption);
                      setIsPopoverOpen(false);
                    }}
                    selected={sortBy === key}
                  >
                    <Text fontSize="small">{t(`sort.${key}`)}</Text>
                  </StyledContextMenuItem>
                );
              })}
            </Stack>
          </StyledPopoverContent>
        </Popover.Root>
      }
    >
      <Stack gap={2}>
        {!isMd && (
          <Select
            size="small"
            labelId="sortBy"
            sx={{
              minWidth: 220,
              height: 40,
              alignSelf: 'flex-start',
            }}
            MenuProps={{
              autoFocus: false,
            }}
            value={sortBy}
            renderValue={(value) => (
              <Text fontSize="small">
                <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
                  {t('sort.label')}:
                </Text>{' '}
                {t(`sort.${value}`)}
              </Text>
            )}
            onChange={(e) =>
              handleChange(e.target.value as ExperienceSortOption)
            }
          >
            {sortOptions.map(({ key }) => (
              <MenuItem key={key} value={key}>
                {t(`sort.${key}`)}
              </MenuItem>
            ))}
          </Select>
        )}
        {!experiences || isLoading ? (
          <ExperienceListLoading />
        ) : (
          <ExperienceList experiences={experiences} />
        )}
      </Stack>
    </CrudPage>
  );
};

const ExperienceList = ({ experiences }: { experiences: Experience[] }) => {
  const { isMd } = useResponsive();

  const { t } = useTranslate('experience');

  if (!experiences?.length)
    return (
      <EmptyScreen
        imageSrc="/empty-state-nature.svg"
        title={t('emptyState.title')}
        description={t('emptyState.description')}
      />
    );

  const ContainerComponent = isMd ? ExperienceGrid : StyledStack;

  return (
    <ContainerComponent>
      {experiences.map((experience) => (
        <ExperienceItem key={experience.id} experience={experience} />
      ))}
    </ContainerComponent>
  );
};

const ExperienceListLoading = () => {
  const { isMd } = useResponsive();

  if (isMd) {
    return (
      <ExperienceGrid>
        {Array(10)
          .fill(undefined)
          .map((_, index) => (
            <MobileCard key={index}>
              <Skeleton height={152} width={108} />
              <Stack padding={2} paddingBottom={5} flexGrow={1}>
                <Skeleton
                  height={17}
                  width={50}
                  sx={{ alignSelf: 'flex-end', marginBottom: '4px' }}
                />
                <Skeleton height={21} width={100} />
                <Skeleton height={21} width={100} />
                <Skeleton height={21} width={100} />
              </Stack>
            </MobileCard>
          ))}
      </ExperienceGrid>
    );
  }

  return (
    <Stack gap={1}>
      {Array(10)
        .fill(undefined)
        .map((_, index) => (
          <DesktopCard key={index}>
            <Skeleton width={80} height={67} />
            <Skeleton
              height={19.2}
              width={100}
              sx={{ gridColumn: 'span 3', justifySelf: 'flex-start' }}
            />
            <Skeleton height={19.2} width={100} sx={{ gridColumn: 'span 2' }} />
            <Skeleton height={19.2} width={100} sx={{ gridColumn: 'span 2' }} />
            <Skeleton height={19.2} width={100} sx={{ gridColumn: 'span 2' }} />
            <Skeleton
              height={19.2}
              width={100}
              sx={{ gridColumn: 'span 2', justifySelf: 'flex-end' }}
            />
          </DesktopCard>
        ))}
    </Stack>
  );
};

const ExperienceGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fit, minmax(300px, 1fr))`,
  gap: '16px',
});

const StyledStack = styled(Stack)({
  gap: '8px',
});

const StyledIconButton = styled(Button)({
  padding: 8,
  aspectRatio: 1,
});

const StyledContextMenuItem = styled(Button)(
  ({ selected }: { selected: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 8,
    padding: 8,
    borderRadius: 0,
    minWidth: 200,
    ':hover': { backgroundColor: lightTheme.palette.neutral.n50 },
    backgroundColor: selected ? lightTheme.palette.neutral.n100 : 'unset',
  })
);
