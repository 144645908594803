import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Box, Stack, styled } from '@mui/material';
import { useRef, useState } from 'react';
import Slider from 'react-slick';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useGrowthOpportunities } from '../../domain/use-growth-opportunities';
import { GrowthOpportunitiesSliderButtons } from './growth-opportunities-slider-buttons';
import { GrowthOpportunitiesSliderItem } from './growth-opportunities-slider-item';

export const GrowthOpportunitiesSlider = () => {
  const { opportunities } = useGrowthOpportunities();
  const [centerIndex, setCenterIndex] = useState(1);
  const [autoplay, setAutoplay] = useState(true);
  const sliderRef = useRef<Slider>(null);
  const { isMd, isLg } = useResponsive();

  const settings = {
    draggable: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    pauseOnFocus: true,
    nextArrow: false,
    prevArrow: false,
    beforeChange: (current: number, next: number) => {
      if (isMd) return;
      const newCenterIndex = (next + Math.floor(3 / 2)) % opportunities.length;
      setCenterIndex(newCenterIndex);
    },
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Stack alignItems={'flex-end'} onClick={() => setAutoplay(false)}>
      {!isMd && <GrowthOpportunitiesSliderButtons sliderRef={sliderRef} />}
      <StyledBox isMd={isMd} isLg={isLg}>
        <Slider ref={sliderRef} {...settings}>
          {opportunities.map((opportunity, index) => {
            return (
              <GrowthOpportunitiesSliderItem
                key={opportunity.id}
                opportunity={opportunity}
                secondary={!isMd && index !== centerIndex}
              />
            );
          })}
        </Slider>
        {isMd && <GrowthOpportunitiesSliderButtons sliderRef={sliderRef} />}
      </StyledBox>
    </Stack>
  );
};

const StyledBox = styled(Box)<{ isMd: boolean; isLg: boolean }>(
  ({ isMd, isLg }) => ({
    minHeight: 440,
    margin: '0 auto',
    width: isLg ? '100%' : 'calc(100vw - 360px)',
    maxWidth: isLg ? 1200 : 1000,

    '& .slick-track, & .slick-slide': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& .slick-slide': {
      width: isMd ? '100%' : 'calc(100% / 11) !important',
      height: 'auto',
      padding: '32px 0',
      boxSizing: 'border-box',
    },

    '& .slick-list': {
      width: '100%',
      margin: '-32px auto',
    },

    '& .slick-arrow': {
      display: 'none !important',
    },
  })
);
