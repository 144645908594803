import { Button, Text } from '@holdbar-com/pixel';
import { CircularProgress, Stack, TextField } from '@mui/material';
import { SetStateAction, useEffect, useMemo } from 'react';

import { ampli } from '../../../../Ampli';
import { CopyLink } from '../../../../Components/CopyLink/CopyLink';
import { useCalendarFeed } from '../../../../Hooks/useCalendarFeed';
import { useFireOnce } from '../../../../Hooks/useFireOnce';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { IcalDialogStep } from '../../ical-dialog-step-loader';

const getUrl = (accessKey?: string) => {
  if (!accessKey) {
    return undefined;
  }
  return `${process.env.REACT_APP_API_CALENDAR_FEEDS_URL ?? ''}/${accessKey}`;
};

type IcalDialogDoneStepProps = {
  setStep: React.Dispatch<SetStateAction<IcalDialogStep>>;
};

export const IcalDialogDoneStep = ({ setStep }: IcalDialogDoneStepProps) => {
  const { t } = useTranslate('dialogs.ical.steps.done');
  const fireOnce = useFireOnce();

  const {
    me: { data: me },
  } = useProfile();

  const { accessKey } = useCalendarFeed();

  const icalLink = useMemo(() => getUrl(accessKey), [accessKey]);

  useEffect(() => {
    if (icalLink) {
      fireOnce(() => {
        ampli.integrationsCalendarSyncConnectFlowSuccessfullyCompleted();
        ampli.identify(me?.id, {
          'Integrations Calendar Sync Connect Flow Successfully Completed':
            true,
        });
      });
    }
  }, [icalLink, fireOnce, me?.id]);

  if (!icalLink) {
    return <IcalDialogGeneratingLink />;
  }

  return (
    <Stack gap={4}>
      <Text variant="medium" fontSize="large">
        {t('title')}
      </Text>

      <TextField
        helperText={t('helperText')}
        label={'iCal link'}
        required
        fullWidth
        multiline
        disabled={true}
        InputProps={{
          endAdornment: icalLink && (
            <CopyLink href={icalLink} label="" size="xlarge" />
          ),
        }}
        value={icalLink ?? ''}
      />

      <Button
        variant={'secondary'}
        size={'medium'}
        onClick={() => setStep(IcalDialogStep.create)}
        style={{ alignSelf: 'flex-end' }}
      >
        {t('edit', 'utils.generic')}
      </Button>
    </Stack>
  );
};

const IcalDialogGeneratingLink = () => {
  const { t } = useTranslate('dialogs.ical.steps.done');

  return (
    <Stack
      gap={2}
      direction={'row'}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        height: 150,
      }}
    >
      <Text variant="medium" fontSize="large">
        {t('generatingLink')}
      </Text>
      <CircularProgress size={20} />
    </Stack>
  );
};
