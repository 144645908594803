import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useIntegrationRequestsStore } from '../../../../../Store/useIntegrationRequestsStore';
import { useIntegrationRequest } from '../use-integration-request';
import { IntegrationObject } from '../use-integrations';

export const useTruestoryIntegration = (): IntegrationObject => {
  const { t } = useTranslate('settings.integrations');

  const id = 'truestory';

  const isRequested = useIntegrationRequestsStore
    .getState()
    .isRequestInStore(id);

  const truestoryIntegration = {
    id,
    title: t('marketplaces.truestory.title'),
    icon: '/integrations/truestory.png',
    description: t('marketplaces.truestory.description'),
    extendedDescription: t('marketplaces.truestory.extendedDescription'),
    status: 'connect',
    buttonLabel: t('request.requestIntegration'),
    openForRequest: true,
    isRequested,
    onInstall: () => async () => {
      await handleIntegrationRequest();
    },
  };

  const { handleIntegrationRequest } =
    useIntegrationRequest(truestoryIntegration);

  return truestoryIntegration;
};
