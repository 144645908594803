import { useQuery } from '@tanstack/react-query';

import { queryClient } from '../..';
import * as api from '../../Api';
import { EventOptions } from '../../Api/Event';
import { TEvent, transformEvents } from '../../Utils/eventHelpers';

const EVENTS_QUERY_KEY = 'events';

export const getEventsQueryKey = (options?: EventOptions) =>
  options
    ? [
        EVENTS_QUERY_KEY,
        {
          from: options?.from?.toISOString(),
          to: options?.to?.toISOString(),
          locationIds: options?.locationIds,
          states: options?.states,
        },
      ]
    : [EVENTS_QUERY_KEY];

export const useGetEvents = (options?: EventOptions) => {
  const eventsQueryKey = getEventsQueryKey(options);

  const events = useQuery<TEvent[]>({
    queryKey: eventsQueryKey,
    queryFn: fetchEvents(options),
  });

  return { events };
};

export const usePrefetchEvents = (options?: EventOptions) => {
  const EventsQueryKey = getEventsQueryKey(options);

  queryClient.prefetchQuery({
    queryKey: EventsQueryKey,
    queryFn: fetchEvents(options),
  });
};

const fetchEvents = (options?: EventOptions) => async () => {
  const events = await api.getEvents(options);
  const transformed = transformEvents(events);
  for (const el of transformed) {
    queryClient.setQueryData<TEvent>(['events', el.id], el);
  }

  return transformed;
};
