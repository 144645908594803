import { differenceInCalendarDays } from 'date-fns';

import { ampli } from '../../Ampli';
import { OverviewPageViewedProperties } from '../../Ampli';
import { CategoryTotals } from '../../Api/Analytics';

export const trackOverviewPageViewed = (
  categoryTotals: CategoryTotals,
  currency: string,
  fromDate: Date,
  toDate: Date,
  userId?: string
) => {
  const properties = pageViewEventProperties(
    categoryTotals,
    currency,
    fromDate,
    toDate
  );
  const userProperties = transformPageViewToUserProperties(properties);

  if (userId) ampli.identify(userId, userProperties);
  ampli.overviewPageViewed(properties);

  return properties;
};

export const pageViewEventProperties = (
  categoryTotals: CategoryTotals,
  currency: string,
  fromDate: Date,
  toDate: Date
) => {
  const daysToStart = differenceInCalendarDays(fromDate, new Date());
  const daysToEnd = differenceInCalendarDays(toDate, new Date());

  const days_from_current_date = Math.min(
    Math.abs(daysToStart),
    Math.abs(daysToEnd)
  );

  return {
    currency,
    days_from_current_date,
    total_gift_cards_sold: categoryTotals.giftCards?.vouchersSold ?? 0,
    total_number_customers: categoryTotals.orders?.orders ?? 0,
    total_number_guests: categoryTotals.participants?.guests ?? 0,
    total_sales: categoryTotals.sales?.totalSales ?? 0,
    total_views_experiences: categoryTotals.orders?.pageViews ?? 0,
  };
};

export const transformPageViewToUserProperties = (
  properties: OverviewPageViewedProperties
) => ({
  total_number_guests: properties.total_number_guests,
  total_sales: properties.total_sales,
});

export const startDateEventProperties = (
  new_start_date: Date,
  current_end_date: Date
) => {
  const days_from_current_date = differenceInCalendarDays(
    new_start_date,
    new Date()
  );

  const days_range = differenceInCalendarDays(current_end_date, new_start_date);

  return { days_from_current_date, days_range };
};

export const endDateEventProperties = (
  current_start_date: Date,
  new_end_date: Date
) => {
  const days_from_current_date = differenceInCalendarDays(
    new_end_date,
    new Date()
  );

  const days_range = differenceInCalendarDays(new_end_date, current_start_date);

  return { days_from_current_date, days_range };
};
