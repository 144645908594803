import { useCallback } from 'react';

import { ampli } from '../../../../Ampli';
import { DaysInput } from '../../../../Components/DaysInput/DaysInput';
import FeatureItem from '../../../../Components/FeatureItem/FeatureItem';
import FeatureInput from '../inputs/FeatureInput';

export const AllowGuestsMoveBookingFeature = () => {
  const trackToggling = useCallback((enabled: boolean) => {
    if (enabled) {
      ampli.moveBookingToggledOn({ feature_enabled: 'allowGuestsMoveBooking' });
    } else {
      ampli.moveBookingToggledOff();
    }
  }, []);

  return (
    <FeatureItem feature="allowGuestsMoveBooking" onToggle={trackToggling}>
      <FeatureInput name="untilDays" as={DaysInput} required />
    </FeatureItem>
  );
};
