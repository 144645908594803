import { Text } from '@holdbar-com/pixel';
import { Box, Skeleton, Step, StepButton, Stepper } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useEventUpsert } from '../domain/event_upsert_context';
import { EventStepType, getEventSteps } from '../domain/types';

type EventUpsertStepperProps = {
  step: EventStepType;
};

export const EventUpsertStepper = ({ step }: EventUpsertStepperProps) => {
  const { t, ready } = useTranslation();
  const { isMd } = useResponsive();
  const { currentEvent, updateEventStep } = useEventUpsert();

  const steps = getEventSteps(
    currentEvent.visibility === 'public' || currentEvent.variant === 'edit'
  );
  const currentStepIndex = steps.indexOf(step);

  return (
    <Box height="100%" display={isMd ? 'none' : 'block'}>
      <Stepper activeStep={currentStepIndex} orientation="vertical">
        {steps.map((stepItem, index) => (
          <Step
            key={stepItem}
            onClick={() => {
              if (index <= currentStepIndex) {
                updateEventStep(stepItem);
              }
            }}
          >
            <StepButton>
              {!ready ? (
                <Skeleton width={100} height={20} />
              ) : (
                <Text
                  fontSize={isMd || step !== stepItem ? 'xsmall' : 'small'}
                  variant={step === stepItem ? 'medium' : 'normal'}
                  style={{ opacity: step === stepItem ? '100%' : '40%' }}
                >
                  {getTranslation(stepItem, t)}
                </Text>
              )}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

const getTranslation = (key: EventStepType, t: TFunction) => {
  return t(`eventUpsert.stepper.${key}`);
};
