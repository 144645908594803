import { Button, lightTheme, LinkButton, Text } from '@holdbar-com/pixel';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import randomBytes from 'randombytes';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  Form,
  redirect,
  useHref,
  useLinkClickHandler,
  useNavigate,
  useNavigation,
  useRouteLoaderData,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import { initiatePartialRefund } from '../../../../Api/Receipt';
import { cancelGiftCard, getGiftCard } from '../../../../Api/Voucher';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useFireOnce } from '../../../../Hooks/useFireOnce';
import { t } from '../../../../i18n/config';
import {
  giftCardDisableFlowCompleted,
  giftCardDisableFlowStarted,
} from '../../../../tracking/giftCards/giftCardEvents';
import routes from '../../../../Utils/routes';
import { DialogWrapper } from '../../../connect/components/dialog-wrapper';
import { LoaderData as GiftCardDetailsLoaderData } from '../gift-card-details-page';

export default function DeactivateGiftCardDialog() {
  const { giftCard } = useRouteLoaderData(
    'gift-card-details'
  ) as GiftCardDetailsLoaderData;

  const { t } = useTranslation();

  // Intentionally throwing error so the error element is shown
  if (giftCard.status === 'cancelled')
    throw new Error(t('giftCard.deactivate.error.isCancelled'));

  const { state } = useNavigation();
  const isSubmitting = state === 'submitting';

  const { isSm } = useResponsive();

  const closeHref = useHref(routes.giftCard.details(giftCard.id).index);
  const closeLinkClick = useLinkClickHandler(closeHref);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(closeHref);
  };

  const fireOnce = useFireOnce();
  useEffect(() => {
    fireOnce(giftCardDisableFlowStarted);
  }, [fireOnce]);

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('giftCard.deactivate.title')}
    >
      <Text color={lightTheme.palette.neutral.n400}>
        {t('giftCard.deactivate.description')}
      </Text>
      <Stack
        component={Form}
        method="post"
        noValidate
        sx={{ gap: 4, marginTop: 4 }}
      >
        {giftCard.receiptId && (
          <FormControl>
            <FormLabel>
              <Text fontSize="small" variant="medium">
                {t('giftCard.deactivate.optionLabel')}
              </Text>
            </FormLabel>
            <RadioGroup name="option" defaultValue="skipRefund">
              <FormControlLabel
                value="skipRefund"
                label={t('giftCard.deactivate.skipRefund')}
                control={<Radio />}
              />
              <FormControlLabel
                value="shouldRefund"
                label={t('giftCard.deactivate.shouldRefund')}
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
        )}
        <Stack
          sx={{
            gap: 2,
            flexDirection: { xs: 'column-reverse', md: 'row' },
          }}
        >
          <LinkButton
            href={closeHref}
            onClick={closeLinkClick}
            variant="secondary"
            size="large"
            fullWidth
            style={{
              flexShrink: 'unset',
            }}
            disabled={isSubmitting}
          >
            {t('giftCard.deactivate.cancelLabel')}
          </LinkButton>
          <Button
            type="submit"
            variant="danger"
            size="large"
            loading={isSubmitting}
            fullWidth
            style={{
              flexShrink: 'unset',
            }}
          >
            {t('giftCard.deactivate.submitLabel')}
          </Button>
        </Stack>
      </Stack>
    </DialogWrapper>
  );
}

export async function action({ params, request }: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('giftCard.deactivate.toast.loading'), {
    toastId: loadingToastId,
  });

  const id = params.id;

  if (!id) {
    throw new Response('Invalid id', { status: 404 });
  }

  try {
    const giftCard = await getGiftCard(id);
    if (!giftCard) throw new Error('Gift card not found');

    const formData = await request.formData();
    const option = formData.get('option');

    const shouldRefund = option === 'shouldRefund';

    // Handle refund, if selected
    if (shouldRefund) {
      try {
        if (!giftCard.receiptId) throw new Error('No order id on gift card');
        await initiatePartialRefund(
          giftCard.receiptId,
          giftCard.amountLeftCents
        );
      } catch (error) {
        toast.dismiss(loadingToastId);
        toast.error(t('giftCard.deactivate.toast.refundFailed'), {
          delay: 500,
        });
        // Aborting rest of the flow on failure
        return null;
      }
    }

    await cancelGiftCard(id);

    toast.dismiss(loadingToastId);
    toast.success(t('giftCard.deactivate.toast.success'), {
      autoClose: 5000,
    });

    // Intentionally not awaiting the event. Optimistic tracking.
    giftCardDisableFlowCompleted({
      isRefunded: shouldRefund,
    });

    return redirect(routes.giftCard.details(id).index);
  } catch (error) {
    toast.dismiss(loadingToastId);
    toast.error(t('giftCard.deactivate.toast.error'), { delay: 500 });
    return null;
  }
}
