import styled from '@emotion/styled';
import { lightTheme } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import useResponsive from '../../../Hooks/layout/useResponsive';
import { useApplySearchParams } from '../../../Hooks/useApplySearchParams';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { SignupType } from '../../../Hooks/useProfile';
import { trackSignUpViewed } from '../../../tracking/signup/trackSignUpViewed';
import { SignupCard } from './signup-card';
import { SignUpHero } from './signup-hero';

export const SignupView = () => {
  const { isMd } = useResponsive();
  const fireOnce = useFireOnce();

  const [searchParams] = useSearchParams();

  useApplySearchParams();

  useEffect(() => {
    const type =
      searchParams.has('preview') || searchParams.get('source') === 'generic' // signed up from AI but chose generic storefront
        ? SignupType.AISignup
        : SignupType.SelfSignup;

    fireOnce(() => trackSignUpViewed(type));
  }, [fireOnce, searchParams]);

  if (isMd) return <SignupCard />;

  return (
    <Grid>
      <SignUpHero />
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: lightTheme.palette.contrast.white }}
      >
        <SignupCard />
      </Stack>
    </Grid>
  );
};

const Grid = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  height: '100vh',
});
