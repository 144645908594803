import create from 'zustand';
import { persist } from 'zustand/middleware';

interface GrowthOpportunityRequestStore {
  growthOpportunityRequests: string[];
  addGrowthOpportunityRequest: (value: string) => void;
  isRequestInStore: (value: string) => boolean;
}

export const useGrowthOpportunityRequestStore = create(
  persist<GrowthOpportunityRequestStore>(
    (set, get) => ({
      growthOpportunityRequests: [],
      addGrowthOpportunityRequest: (value) =>
        set((state) => ({
          growthOpportunityRequests: [
            ...state.growthOpportunityRequests,
            value,
          ],
        })),
      isRequestInStore: (value: string) =>
        get().growthOpportunityRequests.includes(value),
    }),
    {
      name: 'holdbar-admin',
      getStorage: () => sessionStorage,
    }
  )
);
