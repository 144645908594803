import './styles.css';

import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS,
} from '@lexical/markdown';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ErrorBoundaryType } from '@lexical/react/shared/useDecorators';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { Box } from '@mui/material';
import { BoxProps } from '@radix-ui/themes/dist/cjs/components/box';
import { $getRoot, EditorState, LexicalEditor } from 'lexical';
import { useEffect } from 'react';

import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { theme } from './theme';

const editorConfig = {
  namespace: 'rich-editor',

  // The editor theme
  theme,
  // Handling of errors during update
  onError(error: any) {
    console.log(error);
  },

  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

const UpdatePlugin = ({ value }: { value: string }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(
      () => {
        const currentHtmlString = $convertToMarkdownString(TRANSFORMERS);

        if (currentHtmlString !== value) {
          $convertFromMarkdownString(value, TRANSFORMERS);
        }
      },
      {
        tag: 'collaboration',
      }
    );
  }, [value]);

  return null;
};

export const RichEditor = ({
  placeholder,
  value,
  onChange,
  ...props
}: Omit<BoxProps, 'onChange'> & {
  placeholder?: string;
  value: string;
  onChange: any;
}) => {
  const handleChange = (state: EditorState, editor: LexicalEditor) => {
    editor.update(() => {
      if ($getRoot().getTextContent()) {
        const markdown = $convertToMarkdownString(TRANSFORMERS);
        const a = markdown.replaceAll('\n\n', '');
        const b = value.replaceAll('\n\n', '');
        if (a !== b) {
          onChange(markdown);
        }
      } else {
        onChange('');
      }
    });
  };

  return (
    <LexicalComposer
      initialConfig={{
        ...editorConfig,
      }}
    >
      <Box className="editor-container" {...props}>
        <ToolbarPlugin />
        <div className="editor-inner">
          <UpdatePlugin value={value} />
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={
              <div className="editor-placeholder">{placeholder}</div>
            }
            ErrorBoundary={undefined as unknown as ErrorBoundaryType}
          />
          <OnChangePlugin onChange={handleChange} />
          <HistoryPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
        </div>
      </Box>
    </LexicalComposer>
  );
};
