import { Skeleton } from '@mui/material';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { useActivePaymentGateways } from '../../data/use-active-payment-gateways';
import { EconomicAccountAutocomplete } from './economic-account-autocomplete';
import { EconomicConfigurationAccountsByExperience } from './economic-configuration-accounts-by-experience';
import { EconomicConfigurationAccountsSection } from './economic-configuration-accounts-section';

export const EconomicConfigurationAccounts = () => {
  const { t } = useTranslate('settings.integrations.economic.configuration');

  const activePaymentGateways = useActivePaymentGateways();

  return (
    <>
      <EconomicConfigurationAccountsSection
        title={t('sales.title')}
        description={t('sales.description')}
        appendedNode={<EconomicConfigurationAccountsByExperience />}
      >
        <EconomicAccountAutocomplete
          label={t('accounts.salesWithVat')}
          path="salesWithVat"
        />
        <EconomicAccountAutocomplete
          label={t('accounts.salesWithoutVat')}
          path="salesWithoutVat"
        />
      </EconomicConfigurationAccountsSection>

      <EconomicConfigurationAccountsSection
        title={t('costs.title')}
        description={t('costs.description')}
      >
        <EconomicAccountAutocomplete
          label={t('accounts.holdbarFee')}
          path="feeHoldbar"
        />
        {activePaymentGateways.data?.map(({ id, label }) => (
          <EconomicAccountAutocomplete
            key={id}
            label={t(`accounts.${id}Fee`, {
              gateway: label,
            })}
            path={`gateways.${id}.fee`}
          />
        )) || <Skeleton width={300} height={48} />}
      </EconomicConfigurationAccountsSection>

      <EconomicConfigurationAccountsSection
        title={t('liabilities.title')}
        description={t('liabilities.description')}
      >
        <EconomicAccountAutocomplete
          label={t('accounts.bookings')}
          path="liabilityGuest"
        />
        <EconomicAccountAutocomplete
          label={t('accounts.giftCards')}
          path={'liabilityGiftCard'}
        />
      </EconomicConfigurationAccountsSection>

      <EconomicConfigurationAccountsSection
        title={t('receivables.title')}
        description={t('receivables.description')}
      >
        {activePaymentGateways.data?.map(({ id, label }) => (
          <EconomicAccountAutocomplete
            key={id}
            label={t(`accounts.gatewayAssets`, {
              gateway: label,
            })}
            path={`gateways.${id}.asset`}
          />
        )) || <Skeleton width={300} height={48} />}
      </EconomicConfigurationAccountsSection>
    </>
  );
};
