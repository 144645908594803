import { useQuery } from '@tanstack/react-query';

import * as api from '../Api';

export const useCountryVatRates = () => {
  const CountryVatRatesQueryKey = ['countryVatRates'];

  const countryVatRates = useQuery({
    queryKey: CountryVatRatesQueryKey,

    queryFn: async () => {
      return await api.getCountryVatRates();
    },
  });

  return countryVatRates;
};
