import { Stack } from '@mui/material';
import { addMonths, parseISO } from 'date-fns';

import { DateInput } from '../../../../../Components/inputs/date-input';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import {
  trackOverviewEndDateOpened,
  trackOverviewEndDateSelected,
  trackOverviewStartDateOpened,
  trackOverviewStartDateSelected,
} from '../../../../../tracking/overview/interactions';
import { useDashboardPageContext } from '../../../use-dashboard-page-context';

export const DashboardGraphsFilterPeriodCustom = () => {
  const { t } = useTranslate('utils.generic');

  const maxDate = addMonths(new Date(), 3).toISOString();

  const { isLoadingMetrics, toDate, fromDate, setFromDate, setToDate } =
    useDashboardPageContext();

  const onChange = (from: Date, to: Date) => {
    setFromDate(from);
    setToDate(to);
  };

  const handleChange = (key: 'from' | 'to') => (value: Date) => {
    const isFrom = key === 'from';

    const [newFrom, newTo] = isFrom ? [value, toDate] : [fromDate, value];

    if ((isFrom && value > toDate) || (!isFrom && value < fromDate)) {
      setFromDate(value);
      setToDate(value);
      onChange(value, value);
    } else {
      if (isFrom) setFromDate(value);
      else setToDate(value);

      onChange(newFrom, newTo);
    }
  };

  return (
    <Stack
      gap={1}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={'center'}
    >
      <DateInput
        label={t('from')}
        value={fromDate}
        onChange={(date: string) => {
          const parsedDate = parseISO(date);
          handleChange('from')(parsedDate);
          trackOverviewStartDateSelected(parsedDate, toDate);
        }}
        onClick={trackOverviewStartDateOpened}
        minDate="2022-01-01"
        maxDate={maxDate}
        disabled={isLoadingMetrics}
        size="small"
        sx={{ maxWidth: 160 }}
      />
      <DateInput
        label={t('to')}
        value={toDate}
        onChange={(date: string) => {
          const parsedDate = parseISO(date);
          handleChange('to')(parsedDate);
          trackOverviewEndDateSelected(fromDate, parsedDate);
        }}
        onClick={trackOverviewEndDateOpened}
        minDate={fromDate}
        maxDate={maxDate}
        disabled={isLoadingMetrics}
        size="small"
        sx={{ maxWidth: 160 }}
      />
    </Stack>
  );
};
