import { differenceInCalendarDays } from 'date-fns';

import { ampli } from '../../Ampli';
import { getDiscountStatus, TDiscount } from '../../Hooks/useDiscount';

// Get Started tab
export const trackGrowthBusinessReviewRequestCompleted = (userId?: string) => {
  ampli.growthBusinessReviewRequestCompleted();
  ampli.identify(userId, {
    'Growth Requested': true,
  });
};

export const trackGrowthCampaignManagementRequestStarted = (
  userId?: string
) => {
  ampli.growthCampaignManagementRequestStarted();
  ampli.identify(userId, {
    'Growth Campaign Mngmt Started': true,
  });
};

export const trackGrowthCampaignManagementRequestCompleted = (
  userId?: string
) => {
  ampli.growthCampaignManagementRequestCompleted();
  ampli.identify(userId, {
    'Growth Campaign Mngmt Requested': true,
  });
};

export const trackGrowthEmailMarketingRequestStarted = (userId?: string) => {
  ampli.growthEmailMarketingRequestStarted();
  ampli.identify(userId, {
    'Growth Email Marketing Started': true,
  });
};

export const trackGrowthEmailMarketingRequestCompleted = (userId?: string) => {
  ampli.growthEmailMarketingRequestCompleted();
  ampli.identify(userId, {
    'Growth Email Marketing Requested': true,
  });
};

export const trackGrowthIntegrationsRequestStarted = (userId?: string) => {
  ampli.growthIntegrationsRequestStarted();
  ampli.identify(userId, {
    'Growth Integration Started': true,
  });
};

export const trackGrowthIntegrationsRequestCompleted = (userId?: string) => {
  ampli.growthIntegrationsRequestCompleted();
  ampli.identify(userId, {
    'Growth Integration Requested': true,
  });
};

export const trackGrowthConnectRequestStarted = (userId?: string) => {
  ampli.growthConnectRequestStarted();
  ampli.identify(userId, {
    'Growth Connect Started': true,
  });
};

export const trackGrowthConnectRequestCompleted = (userId?: string) => {
  ampli.growthConnectRequestCompleted();
  ampli.identify(userId, {
    'Growth Connect Requested': true,
  });
};

// Discount Codes tab
export const trackGrowthDiscountCodesViewed = (
  discounts: TDiscount[],
  userId?: string
) => {
  const {
    num_discount_codes,
    num_active_discount_codes,
    total_perc_value,
    discount_perc_count,
  } = discounts.reduce(
    (acc, discount) => {
      acc.num_discount_codes++;
      if (getDiscountStatus(discount) === 'active')
        acc.num_active_discount_codes++;
      if (discount.percentage && !discount.overrides) {
        acc.total_perc_value += discount.rate;
        acc.discount_perc_count++;
      }
      return acc;
    },
    {
      num_discount_codes: 0,
      num_active_discount_codes: 0,
      total_perc_value: 0,
      discount_perc_count: 0,
    }
  );

  const avg_perc_value_discount_codes =
    discount_perc_count > 0
      ? Number((total_perc_value / discount_perc_count).toFixed(2))
      : 0;

  ampli.growthDiscountCodesViewed({
    num_discount_codes,
    num_active_discount_codes,
    avg_perc_value_discount_codes,
  });
  ampli.identify(userId, {
    '# Active Discount Codes': num_active_discount_codes,
    'AVG % Value Discount Codes': avg_perc_value_discount_codes,
  });
};

export const trackGrowthDiscountCodeCreationStarted = () =>
  ampli.growthDiscountCodeCreationStarted();

export const trackGrowthDiscountCodeCreationCompleted = (
  discount: TDiscount
) => {
  const daysToExpiry = discount.expiry?.endDateTime
    ? differenceInCalendarDays(
        new Date(discount.expiry.endDateTime),
        new Date()
      ).toString()
    : 'infinite';

  const hasOverrides = discount.overrides && discount.overrides.length > 0;

  const properties = {
    discount_target_exp: !hasOverrides
      ? 'all experiences'
      : 'specific experiences',
    discount_value: !hasOverrides
      ? discount.percentage
        ? 'percentage'
        : 'amount'
      : 'specific experiences',
    discount_rate: !hasOverrides
      ? discount.rate.toString()
      : 'specific experiences',
    discount_target_guest:
      discount.scope === 'total' ? 'entire order' : 'per guest',
    discount_validity: daysToExpiry,
    discount_repeatability:
      discount.limits?.timesRedeemed?.toString() ?? 'infinite',
  };

  ampli.growthDiscountCodeCreationCompleted(properties);
};

// Email Marketing tab
export const trackGrowthEmailsPermissionsDownloaded = (userId?: string) => {
  ampli.growthEmailsPermissionsDownloaded();
  ampli.identify(userId, {
    'E-Mail Permissions List Downloaded': true,
  });
};
