import { lightTheme, Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';

import { useTranslate } from '../../../../Hooks/useTranslate';

export const DashboardGraphsLoaderError = () => {
  const { t } = useTranslate('dashboard');

  return (
    <Stack gap={1}>
      <Text fontSize={'large'}>{t('statistics.error.title')}</Text>
      <Text fontSize={'small'} color={lightTheme.palette.neutral.n400}>
        {t('statistics.error.description')}
      </Text>
    </Stack>
  );
};
