import { MenuItem, Skeleton, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { ampli } from '../../../../../../Ampli';
import { useCountryVatRates } from '../../../../../../Hooks/useCountryVatRates';
import { useFireOnce } from '../../../../../../Hooks/useFireOnce';
import { useProfile } from '../../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { StyledTextField } from '../../../../shared/StyledTextField';

type CompanyFormVatRegistrationCountryProps = {
  fixedWidth?: boolean;
};

export const CompanyFormVatRegistrationCountry = ({
  fixedWidth,
}: CompanyFormVatRegistrationCountryProps) => {
  const { t } = useTranslate('');
  const fireOnce = useFireOnce();

  const { data: countryVatRates } = useCountryVatRates();
  const {
    company: { data: company },
  } = useProfile();

  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const shouldDisableCountry =
    !!company?.vatCompliance?.vatRegistrations.length;

  const Field = fixedWidth ? StyledTextField : TextField;

  if (!countryVatRates)
    return (
      <Skeleton
        variant="rectangular"
        width={fixedWidth ? 320 : '100%'}
        height={56}
      />
    );

  return (
    <Controller
      name={'vatCompliance.vatRegistration.country'}
      defaultValue={''}
      render={({ field }) => (
        <Field
          {...field}
          onChange={(e) => {
            field.onChange(e);
            setValue('vatCompliance.vatRegistration.vatNumber', '');
            setValue('vatCompliance.vatRegistration.defaultVatCategory', '');
          }}
          onFocus={() => fireOnce(() => ampli.vatCountrySelectorStarted())}
          error={!!(errors.vatCompliance as any)?.vatRegistration?.country}
          helperText={
            (errors.vatCompliance as any)?.vatRegistration?.country?.message ||
            ''
          }
          label={t('country', 'utils.generic')}
          select
          required
          disabled={shouldDisableCountry}
        >
          {Object.keys(countryVatRates).map((country) => (
            <MenuItem key={country} value={country}>
              {t(country, 'countryCodes')}
            </MenuItem>
          ))}
        </Field>
      )}
    />
  );
};
