import { Receipt } from '@holdbar-com/utils-types';
import { Stack } from '@mui/material';

import { formatMoney } from '../../../../../../../../Components/VariantSelect/VariantSelect';
import { useTranslate } from '../../../../../../../../Hooks/useTranslate';
import { getActiveRefunds } from '../../../../../../../../Utils/orders';
import { ListItem } from './shared';

type Props = {
  order: Receipt;
};

export const BookingOrderSummary = ({ order }: Props) => {
  const { t } = useTranslate('dialogs.booking');

  const moneyFormatter = formatMoney(t, true);

  const nativeCurrency = order.financials.currency;

  const totalRefundedAmountCents = getActiveRefunds(order.refunds).reduce(
    (prev, curr) => prev + curr.amountRefundedCents,
    0
  );

  const isDiscounted = !!order.financials.totalDiscountCents;
  const discountCode = (order.metadata?.discount as { code?: string })?.code;

  return (
    <Stack gap={2}>
      {isDiscounted && (
        <>
          <ListItem
            label={t('subtotal')}
            value={moneyFormatter({
              nativeCurrency,
              value:
                (order.financials.grandTotalCents +
                  order.financials.totalDiscountCents) /
                100,
            })}
          />
          <ListItem
            label={`${t('discount')}${discountCode && ` (${discountCode})`}`}
            value={moneyFormatter({
              nativeCurrency,
              value: -order.financials.totalDiscountCents / 100,
            })}
          />
        </>
      )}
      <ListItem
        label={t('totalAmount')}
        value={moneyFormatter({
          nativeCurrency,
          value: order.financials.grandTotalCents / 100,
        })}
      />
      <ListItem
        label={t('totalPaid')}
        value={moneyFormatter({
          nativeCurrency,
          value:
            order.transactions.reduce(
              (prev, curr) => prev + curr.totalAmountChargedCents,
              0
            ) / 100,
        })}
      />
      {totalRefundedAmountCents > 0 && (
        <ListItem
          label={t('refunded')}
          value={moneyFormatter({
            nativeCurrency,
            value: -(totalRefundedAmountCents / 100),
          })}
        />
      )}
      <ListItem
        label={t('outstandingBalance')}
        value={moneyFormatter({
          nativeCurrency,
          value: order.amountDueCents / 100,
        })}
      />
    </Stack>
  );
};
