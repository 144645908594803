import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Text } from '@holdbar-com/pixel';
import { CloseRounded } from '@mui/icons-material';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import { Box, Dialog, IconButton, Stack } from '@mui/material';
import { useState } from 'react';

import useResponsive from '../../Hooks/layout/useResponsive';
import { useTranslate } from '../../Hooks/useTranslate';
import {
  trackQuickSetupCloseDialogContinueClicked,
  trackQuickSetupCloseDialogExitClicked,
  trackQuickSetupCloseDialogOpened,
} from '../../tracking/quick-setup/global-events';
import { OnboardingContextProvider, Step } from './onboarding-context';
import { OnboardingFlow } from './onboarding-flow-form';

export const OnboardingDialog = NiceModal.create(
  ({ showCloseButton = true }: { showCloseButton?: boolean }) => {
    const { isMd } = useResponsive();
    const [shouldWarnBeforeClosing, setShouldWarnBeforeClosing] =
      useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [currentStepKey, setCurrentStepKey] = useState<Step>('start');

    const modal = useModal();

    const handleClose = () => {
      if (shouldWarnBeforeClosing) {
        setShowWarning(true);
        trackQuickSetupCloseDialogOpened(currentStepKey);
      } else {
        closeSetupFlow();
      }
    };

    const closeSetupFlow = () => {
      modal.resolve();
      modal.remove();
    };

    return (
      <OnboardingContextProvider
        setShouldWarnBeforeClosing={setShouldWarnBeforeClosing}
        setCurrentStepKey={setCurrentStepKey}
        closeSetupFlow={closeSetupFlow}
      >
        <Dialog
          open={modal.visible}
          onClose={handleClose}
          fullWidth
          maxWidth="lg"
          fullScreen={isMd}
        >
          {showCloseButton && (
            <IconButton
              sx={{
                padding: 0,
                position: 'absolute',
                top: '32px',
                right: { xs: 'unset', md: '32px' },
                left: { xs: '16px', md: 'unset' },
                zIndex: 1,
              }}
              onClick={() => handleClose()}
            >
              <ArrowBackRounded
                sx={{
                  display: { md: 'none' },
                  fontSize: { xs: '24px', md: '32px' },
                }}
              />
              <CloseRounded
                sx={{
                  display: { xs: 'none', md: 'unset' },
                  fontSize: { xs: '24px', md: '32px' },
                }}
              />
            </IconButton>
          )}
          <Box height="100%" overflow="auto">
            <OnboardingFlow />
          </Box>
          {showWarning && (
            <CloseDialogWarning
              open={showWarning}
              closeSetup={() => {
                setShowWarning(false);
                closeSetupFlow();
                trackQuickSetupCloseDialogExitClicked();
              }}
              continueSetup={() => {
                setShowWarning(false);
                trackQuickSetupCloseDialogContinueClicked();
              }}
            />
          )}
        </Dialog>
      </OnboardingContextProvider>
    );
  }
);

const CloseDialogWarning = ({
  open,
  closeSetup,
  continueSetup,
}: {
  open: boolean;
  closeSetup: () => void;
  continueSetup: () => void;
}) => {
  const { t } = useTranslate('onboarding.dialog.warning');

  return (
    <Dialog open={open} onClose={continueSetup} maxWidth="sm" fullWidth>
      <Stack sx={{ background: 'white' }} padding={4}>
        <Text variant="medium">{t('title')}</Text>
        <Text>{t('description')}</Text>
        <Stack
          sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
          marginTop={2}
          gap={1}
          justifyContent="flex-end"
        >
          <Button
            variant="secondary"
            size="medium"
            type="button"
            onClick={closeSetup}
          >
            {t('confirmExit')}
          </Button>
          <Button
            variant="primary"
            size="medium"
            type="button"
            onClick={continueSetup}
          >
            {t('cancelExit')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
