import NiceModal from '@ebay/nice-modal-react';
import { Button, Text } from '@holdbar-com/pixel';
import { AddRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';

import { ampli } from '../../Ampli';
import { IInvitation } from '../../Api/Invitation';
import { UsersList } from '../../Components/UsersList/UsersList';
import { useInvitations } from '../../Hooks/data/useInvitations';
import { useTranslate } from '../../Hooks/useTranslate';
import { InviteUsersDialog } from '../../Modals/InviteUsersDialog';

export const SettingsUsersSection = () => {
  const { t } = useTranslate('settings.team');
  const { invitations, invite } = useInvitations();

  const handleClickInvite = async () => {
    ampli.inviteNewUserFlowStarted();
    const usersToBeAdded =
      await NiceModal.show<IInvitation[]>(InviteUsersDialog);

    toast.loading(
      t('loading', 'toast.inviteUser', { count: usersToBeAdded.length }),
      {
        toastId: 'invite-users-loading',
      }
    );

    const responses = await Promise.all(
      usersToBeAdded.map(async (i) => {
        try {
          await invite(i);
          return {
            error: false,
            email: i.claims.email,
          };
        } catch (err) {
          return {
            error: true,
            message: (err as Error).message,
            email: i.claims.email,
          };
        }
      })
    ).finally(() => {
      toast.dismiss('invite-users-loading');
      invitations.refetch();
    });

    if (responses.some((x) => x.error)) {
      const failed = responses.filter((x) => x.error);
      const succeeded = responses.filter((x) => !x.error);
      failed.map((x) => {
        if (!x.message) {
          toast.error(
            t('failed', 'toast.inviteUser', {
              count: failed.length,
              users: failed.map((x) => `${x.email}: ${x.message}`).join('\n'),
            }),
            {
              toastId: 'invite-users-failed',
              autoClose: 5000,
            }
          );
          return;
        }

        toast.error(
          t(x.message, 'toast.inviteUser.status', {
            email: x.email,
          }),
          {
            toastId: 'invite-users-failed-' + x.message,
            autoClose: 5000,
          }
        );
      });
      succeeded.map((x) => {
        toast.success(
          t('SUCCESS', 'toast.inviteUser.status', {
            email: x.email,
          }),
          {
            toastId: 'invite-users-success-' + x.email,
            autoClose: 5000,
          }
        );
      });
    } else {
      toast.success(
        t('success', 'toast.inviteUser', { count: responses.length }),
        {
          toastId: 'invite-users-success',
          autoClose: 5000,
        }
      );
      ampli.inviteNewUserFlowCompleted();
    }
  };

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={2}
      >
        <Text fontSize={'large'} variant={'medium'}>
          {t('title')}
        </Text>
        <Button
          size="small"
          variant="primary"
          leftIcon={<AddRounded fontSize="small" />}
          onClick={handleClickInvite}
        >
          {t('buttonLabel')}
        </Button>
      </Box>
      <UsersList />
    </>
  );
};
