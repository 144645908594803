import { Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useExperience } from '../../../../Hooks/useExperience';
import { EconomicConfigurationAddonsAccount } from './addons/economic-configuration-addons-account';

export const EconomicConfigurationExperienceAccounts: FC = () => {
  const { experiences } = useExperience();
  const { isSm } = useResponsive();

  if (experiences.isLoading || !experiences.data) {
    return <Skeleton variant="rounded" width={300} height={55} />;
  }

  return (
    <Stack
      gridColumn={isSm ? 1 : 2}
      gap={4}
      pl={4}
      flexDirection="column"
      flexWrap="wrap"
    >
      {experiences.data.map((experience) => (
        <EconomicConfigurationAddonsAccount
          key={experience.id}
          experience={experience}
        />
      ))}
    </Stack>
  );
};
