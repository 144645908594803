import NiceModal from '@ebay/nice-modal-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo } from 'react';

import { ampli } from '../../../../../Ampli';
import { disconnectPayment, initPaymentSetup } from '../../../../../Api/Api';
import { useOnBoarding } from '../../../../../Hooks/useOnBoarding';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { ConfirmDialog } from '../../../../../Modals/ConfirmDialog';
import { PaypalDialog } from '../../../../../Modals/PaypalDialog';
import { IntegrationObject } from '../use-integrations';

export const usePaypalIntegration = (): IntegrationObject | null => {
  const { t } = useTranslate('settings.integrations');
  const flags = useFlags();
  const { onBoarding } = useOnBoarding();

  const paymentPaypalStatus = useMemo(() => {
    const payPal = onBoarding.data?.response['payment-paypal'];
    return payPal?.find((el) => el.key === 'setup');
  }, [onBoarding.data]);

  const handlePaypal = useCallback(
    (status: string) => async () => {
      if (status === 'connect') {
        NiceModal.show(PaypalDialog, {
          onClick: async (accountStatus: 'new' | 'existing' | undefined) => {
            const { url } = await initPaymentSetup('paypal', accountStatus);
            ampli.integrationsPayPalConnectFlowStarted();
            window.open(url, '_blank');
          },
        });
      } else {
        const { url } = await initPaymentSetup('paypal', undefined);
        window.open(url, '_blank');
      }
    },
    []
  );

  const handleDisconnectPaypal = useCallback(async () => {
    NiceModal.show(ConfirmDialog, {
      title: t('payments.paypal.disconnect.title'),
      confirmLabel: t('payments.paypal.disconnect.confirmLabel'),
      description: t('payments.paypal.disconnect.description'),
    }).then(async () => {
      await disconnectPayment('paypal');
      window.location.reload();
    });
  }, [t]);

  if (!flags?.featurePaypalIntegration) return null;

  return {
    id: 'paypal',
    icon: '/integrations/paypal.png',
    title: t('payments.paypal.title'),
    description: t('payments.paypal.description'),
    extendedDescription: t('payments.paypal.extendedDescription'),
    warnings: (paymentPaypalStatus?.metadata?.requirements ?? []).map((el) =>
      t(`payments.paypal.requirements.${el}`)
    ),
    buttonLabel: (status: string) => t(`payments.status.${status}`),
    status: paymentPaypalStatus?.metadata?.status ?? 'connect',
    onInstall: handlePaypal,
    onUninstall: handleDisconnectPaypal,
  };
};
