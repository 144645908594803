import { lightTheme, Text } from '@holdbar-com/pixel';
import { Skeleton, Stack } from '@mui/material';
import { motion } from 'framer-motion';

import { useProfile } from '../../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { useDashboardPageContext } from '../../../../use-dashboard-page-context';
import {
  CURRENCY_METRIC_KEYS,
  PERCENTAGE_METRIC_KEYS,
} from '../dashboard-graphs-loader-card';

type DashboardGraphsLoaderCardMetricsSelectedProps = {
  selectedMetric: {
    key: string;
    value: number;
  } | null;
};

export const DashboardGraphsLoaderCardMetricsSelected = ({
  selectedMetric,
}: DashboardGraphsLoaderCardMetricsSelectedProps) => {
  const { t, i18n } = useTranslate('dashboard');

  const { defaultCurrency } = useProfile();
  const { isLoadingMetrics } = useDashboardPageContext();

  return (
    <Stack gap={2}>
      <motion.div
        key={selectedMetric?.value}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={animationVariants}
        transition={{ duration: 0.3 }}
      >
        <Text fontSize={'small'} color={lightTheme.palette.neutral.n400}>
          {!isLoadingMetrics && selectedMetric ? (
            t(`statistics.cards.${selectedMetric.key}`)
          ) : (
            <Skeleton variant="text" width={100} />
          )}
        </Text>
      </motion.div>
      <Stack gap={1} direction={'row'} alignItems={'flex-end'}>
        <motion.div
          key={selectedMetric?.value}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={animationVariants}
          transition={{ duration: 0.3 }}
        >
          <Text fontSize="h3" style={{ lineHeight: 0.95 }}>
            {!isLoadingMetrics && selectedMetric ? (
              PERCENTAGE_METRIC_KEYS.includes(selectedMetric.key) ? (
                selectedMetric.value?.toLocaleString(i18n.language, {
                  style: 'percent',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              ) : (
                selectedMetric.value?.toLocaleString(i18n.language)
              )
            ) : (
              <Skeleton variant="rounded" width={160} height={38} />
            )}
          </Text>
        </motion.div>

        {!isLoadingMetrics &&
          selectedMetric &&
          CURRENCY_METRIC_KEYS.includes(selectedMetric.key) && (
            <Text color={lightTheme.palette.neutral.n300}>
              {defaultCurrency}
            </Text>
          )}
      </Stack>
    </Stack>
  );
};

const animationVariants = {
  initial: { opacity: 0, scale: 0.95 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 },
};
