import { lightTheme } from '@holdbar-com/pixel';
import { Box, Divider, Skeleton, Stack, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

export const ListContainer = ({ children }: PropsWithChildren) => {
  return <StyledStack divider={<Divider />}>{children}</StyledStack>;
};

const StyledStack = styled(Stack)({
  borderRadius: 8,
  backgroundColor: lightTheme.palette.contrast.white,
  borderColor: lightTheme.palette.neutral.n100,
  borderWidth: 1,
  borderStyle: 'solid',
});

export const LoadingEventItem = () => {
  return (
    <Box
      padding={1}
      borderRadius={1}
      sx={{ backgroundColor: lightTheme.palette.neutral.n100 }}
    >
      <Skeleton height={21} width={250} />
    </Box>
  );
};
