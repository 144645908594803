import { Stack } from '@mui/material';
import { motion } from 'framer-motion';

import { GrowthOpportunityList } from './growth-opportunities-list/growth-opportunities-list';
import { GrowthOpportunitiesSlider } from './growth-opportunities-slider/growth-opportunities-slider';
import { RequestReviewBanner } from './request-review-banner';

export const GrowthOpportunitiesSection = () => {
  return (
    <Stack gap={6}>
      <motion.div {...growAnimation}>
        <RequestReviewBanner />
      </motion.div>
      <motion.div {...slideUpAnimation}>
        <GrowthOpportunitiesSlider />
      </motion.div>
      <GrowthOpportunityList />
    </Stack>
  );
};

const slideUpAnimation = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5, ease: 'easeOut', delay: 0.1 },
};

const growAnimation = {
  initial: { opacity: 0, scale: 0.95 },
  animate: { opacity: 1, scale: 1 },
  transition: { duration: 0.5 },
};
