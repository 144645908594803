import { Receipt } from '@holdbar-com/utils-types';

import { apiServices } from './apiServices';
import api from './index';

export const getReceipt = async (id: string) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await api.get<Receipt>(
    `${apiServices.internalApi}/v1/orders/${id}`
  );

  return data;
};

export const initiatePartialRefund = async (
  orderId: string,
  refundAmountCents: number
) => {
  await api.post(
    `${apiServices.internalApi}/v1/orders/${orderId}/partial-refunds`,
    {
      amountCents: refundAmountCents,
    }
  );
};

export type OrderRefund = {
  transactionId: string;
  amountCents: number;
};

export const refundOrder = async (id: string, refunds: OrderRefund[]) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await api.post<Receipt>(
    `${apiServices.internalApi}/v1/orders/${id}/refunds`,
    {
      refunds,
    },
    {
      // HTTP 400 often just means no transactions or fully refunded. Both of which are not _errors_ per say.
      ignoredStatusCodesInReporting: [400],
    }
  );

  return data;
};
