import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ExperienceTag } from '@holdbar-com/utils-types';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { useExperienceTags } from '../../../../Hooks/useExperienceTags';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { trackStorefrontTagManagementNewTagAdded } from '../../../../tracking/storefront/tags-events';
import { TagDialogWrapper } from './tag-dialog-wrapper';
import { ExperienceTagForm } from './tag-form';

export const CreateTagDialog = NiceModal.create(() => {
  const { t } = useTranslate('tags.dialog.create');
  const { createTag } = useExperienceTags();
  const [isCreating, setIsCreating] = useState(false);

  const handleSubmit = (data: ExperienceTag['name']) => {
    setIsCreating(true);
    createTag
      .mutateAsync(data)
      .then(() => {
        handleClose();
        trackStorefrontTagManagementNewTagAdded();
      })
      .catch((error) => {
        toast.error(t('generic', 'utils.errors'));
        console.error(error);
      })
      .finally(() => setIsCreating(false));
  };

  const modal = useModal();

  const handleClose = () => {
    modal.resolve();
    modal.remove();
  };

  return (
    <TagDialogWrapper
      open={modal.visible}
      handleClose={handleClose}
      title={t('title')}
      description={t('description')}
    >
      <ExperienceTagForm
        onSubmit={handleSubmit}
        handleCancel={handleClose}
        isSubmitting={isCreating}
      />
    </TagDialogWrapper>
  );
});
