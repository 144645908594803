import { useQuery } from '@tanstack/react-query';

import * as api from '../Api';

export const useAudits = (
  object: string,
  objectId: string,
  page?: number,
  limit?: number
) => {
  const AuditsQueryKey = ['audits', { object, objectId }, page];

  const audits = useQuery({
    queryKey: AuditsQueryKey,

    queryFn: async () => {
      const audits = await api.getAudits(object, objectId, page, limit);
      return audits;
    },

    enabled: !!objectId,
  });

  return { audits };
};
