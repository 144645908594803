import { Link, Stack, styled } from '@mui/material';

import { LanguageSelect } from '../../../../Components/LanguageSelect/LanguageSelect';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { SyiSection } from '../../../../Pages/SyiPage/SyiSection';
import {
  trackProfileLanguageSelected,
  trackProfileLanguageSettingsOpened,
} from '../../../../tracking/settings/settings-events';

export const SettingsProfileSectionAdvanced = () => {
  const { t } = useTranslate('settings.profile');
  const {
    me: { data: me },
  } = useProfile();

  return (
    <SyiSection title={t('advancedSettings.title')}>
      <Stack gap={1} alignItems={'flex-start'}>
        <LanguageSelect
          onChange={(key) => trackProfileLanguageSelected(key, me?.id)}
          renderComp={(onClick) => (
            <StyledLink
              fontSize={'0.88em'}
              onClick={(e) => {
                onClick?.(e);
                trackProfileLanguageSettingsOpened();
              }}
            >
              {t('advancedSettings.changeLanguage')}
            </StyledLink>
          )}
        />
        <StyledLink href="javascript:CookieConsent.renew()" fontSize={'0.88em'}>
          {t('advancedSettings.cookiePreferences')}
        </StyledLink>
      </Stack>
    </SyiSection>
  );
};

const StyledLink = styled(Link)({
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});
