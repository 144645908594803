import { matchIsValidTel } from 'mui-tel-input';
import { useMemo } from 'react';
import * as yup from 'yup';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { localizedSchema } from '../../../../Pages/SyiPage/config';

export const useProfileFormValidation = () => {
  const { t } = useTranslate('');

  return useMemo(
    () =>
      yup.object().shape({
        name: yup.string(),
        email: yup
          .string()
          .email(t('invalidEmail', 'auth.errors'))
          .required(t('required', 'utils.errors')),
        phone: yup
          .string()
          .test(
            'mustMatchCountry',
            t('incorrectPhone', 'utils.errors'),
            function (value) {
              if (value) {
                return matchIsValidTel(value);
              }

              return true;
            }
          ),
        languages: yup.array().of(yup.string()),
        description: localizedSchema(),
        pictures: yup
          .object()
          .shape({
            profile: yup.object().nullable(),
          })
          .nullable(),
      }),
    [t]
  );
};

export type ProfileFormData = yup.InferType<
  ReturnType<typeof useProfileFormValidation>
>;
