import { Box, Card, Stack, styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { LanguageSelect } from '../../Components/LanguageSelect/LanguageSelect';
import { Logo } from '../../Components/logo';

type Props = PropsWithChildren;

export const PreviewLayoutDesktop: FC<Props> = ({ children }) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        position="fixed"
        p={3}
      >
        <Box component={'a'} href="https://www.understory.io/" color="white">
          <Logo appearance="white" />
        </Box>
        <LanguageSelect />
      </Stack>
      <StyledSignupDesktop>
        <StyledCard>{children}</StyledCard>
      </StyledSignupDesktop>
    </>
  );
};

const StyledSignupDesktop = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '100%',
  margin: '0 auto',
  background: 'rgba(33, 33, 33, 1)',
});

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  flexGrow: '0',
  maxWidth: '432px',
  minHeight: 430,
  width: '100%',
  padding: `${theme.spacing(9)} ${theme.spacing(7)}`,
  borderRadius: '16px',
  background: 'white',
  boxShadow: '0 0 16px 0 rgba(0,0,0,0.18)',
}));
