import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';

import { CloseButton } from '../Components/CloseButton/CloseButton';
import { useTranslate } from '../Hooks/useTranslate';

interface InfoDialogProps {
  onClose?: () => void;
  info: {
    title: string;
    content: string;
  }[];
  buttons?: {
    label: string;
    onClick: () => void;
  }[];
}

export const InfoDialog = NiceModal.create<InfoDialogProps>(
  ({ onClose, info, buttons }) => {
    const { t } = useTranslate('utils.generic');

    const modal = useModal();

    const handleClose = () => {
      modal.reject('InfoDialog');
      modal.hide();
      onClose?.();
    };

    const handleOk = () => {
      modal.resolve();
      modal.hide();
    };

    return (
      <Dialog open={modal.visible} onClose={handleClose}>
        <CloseButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: 24, right: 24 }}
        />
        <Box p={8} textAlign={'center'}>
          {info.map((item, index) => (
            <Stack key={index} spacing={2} mt={2} mb={4}>
              <Typography variant={'h4'}>{item.title}</Typography>
              <Typography display={'inline-flex'} alignItems={'center'}>
                {item.content}
              </Typography>
            </Stack>
          ))}
          <Stack direction={'row'} justifyContent={'center'} mt={4} spacing={2}>
            {buttons ? (
              buttons.map((button, index) => {
                function onClick() {
                  handleOk();
                  button.onClick();
                }

                return (
                  <Button
                    key={index}
                    onClick={onClick}
                    size={'large'}
                    sx={{ whiteSpace: 'nowrap' }}
                    variant={'contained'}
                  >
                    {button.label}
                  </Button>
                );
              })
            ) : (
              <Button
                onClick={handleOk}
                size={'large'}
                sx={{ whiteSpace: 'nowrap' }}
                variant={'contained'}
              >
                {t('ok')}
              </Button>
            )}
          </Stack>
        </Box>
      </Dialog>
    );
  }
);
