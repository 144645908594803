export const apiServices = {
  accounts: process.env.REACT_APP_API_ACCOUNTS_URL,
  'auth-google': process.env.REACT_APP_API_AUTH_GOOGLE,
  'auth-signin': process.env.REACT_APP_API_AUTH_SIGNIN,
  'api-baseurl': process.env.REACT_APP_API_BASE_URL,
  bookings: process.env.REACT_APP_API_BOOKINGS_URL,
  'calendar-feeds': process.env.REACT_APP_API_CALENDAR_FEEDS_URL,
  discounts: process.env.REACT_APP_API_DISCOUNTS_URL,
  domains: process.env.REACT_APP_API_DOMAINS_URL,
  economic: process.env.REACT_APP_API_ECONOMIC_URL,
  events: process.env.REACT_APP_API_EVENTS_URL,
  experiences: process.env.REACT_APP_API_EXPERIENCES_URL,
  generation: process.env.REACT_APP_API_GENERATION_URL,
  locations: process.env.REACT_APP_API_LOCATIONS_URL,
  marketing: process.env.REACT_APP_API_MARKETING_URL,
  notifications: process.env.REACT_APP_API_NOTIFICATIONS_URL,
  onboarding: process.env.REACT_APP_API_ONBOARDING_URL,
  payment: process.env.REACT_APP_API_PAYMENT_URL,
  platform: process.env.REACT_APP_API_PLATFORM_URL,
  profiles: process.env.REACT_APP_API_PROFILES_URL,
  terms: process.env.REACT_APP_API_TERMS_URL,
  tracking: process.env.REACT_APP_API_TRACKING_URL,
  translate: process.env.REACT_APP_API_TRANSLATE_URL,
  'update-password': process.env.REACT_APP_API_UPDATE,
  upload: process.env.REACT_APP_API_UPLOAD_URL,
  vouchers: process.env.REACT_APP_API_VOUCHERS_URL,
  internalApi: process.env.REACT_APP_INTERNAL_API_GATEWAY,
};

export function findApiServiceName(hostName: string) {
  const service = Object.entries(apiServices).find(([, hostConfig]) =>
    hostConfig?.includes(hostName)
  );
  return service ? service[0] : null;
}
