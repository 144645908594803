import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { IntercomContextValues } from 'react-use-intercom';
import { ObjectSchema } from 'yup';

import { ampli } from '../Ampli';
import * as api from '../Api';
import { OnBoardingData, OnBoardingResult } from '../Api/OnBoarding';
import { useAuthStore } from '../Store/useAuthStore';

type Step = {
  key: string;
  onClick?: (
    trackEvent: IntercomContextValues['trackEvent'],
    startTour: IntercomContextValues['startTour']
  ) => void;
  progress: number;
  metadata?: {
    status?: string;
    existingAccount?: boolean;
  };
  depends?: string[];
  subjectId?: string;
};

export type OnboardingItem = {
  key: string;
  steps: Step[];
  completed?: boolean;
};

export const INTERCOM_SETUP_TOURS = {
  experience: 546237,
  event: 546601,
  implementation: 546605,
};

const handleSetupExperience = (
  trackEvent: IntercomContextValues['trackEvent'],
  startTour: IntercomContextValues['startTour']
) => {
  trackEvent('onboarding-setup-experience');
  startTour(INTERCOM_SETUP_TOURS.experience);
  ampli.onboardingListExperienceClicked();
};

const handleSetupEvent = (
  trackEvent: IntercomContextValues['trackEvent'],
  startTour: IntercomContextValues['startTour']
) => {
  trackEvent('onboarding-setup-event');
  startTour(INTERCOM_SETUP_TOURS.event);
  ampli.onboardingListEventClicked();
};

const handleSetupPayment = (
  trackEvent: IntercomContextValues['trackEvent']
) => {
  trackEvent('onboarding-setup-payment');
  ampli.onboardingListPaymentClicked();
};

const handleSetupImplementation = (
  trackEvent: IntercomContextValues['trackEvent'],
  startTour: IntercomContextValues['startTour']
) => {
  trackEvent('onboarding-setup-implementation');
  startTour(INTERCOM_SETUP_TOURS.implementation);
  ampli.onboardingListImplementationClicked();
};

export const defaultItems: OnboardingItem[] = [
  {
    key: 'experience',
    steps: [
      {
        key: 'create',
        onClick: handleSetupExperience,
        progress: 0,
      },
    ],
  },
  {
    key: 'event',
    steps: [
      {
        key: 'create',
        onClick: handleSetupEvent,
        progress: 0,
        depends: ['experience'],
      },
    ],
  },
  {
    key: 'payment',
    steps: [
      {
        key: 'setup',
        onClick: handleSetupPayment,
        progress: 0,
      },
    ],
  },
  {
    key: 'implementation',
    steps: [
      {
        key: 'setup',
        onClick: handleSetupImplementation,
        progress: 0,
      },
    ],
  },
];

export const useOnBoarding = () => {
  const queryClient = useQueryClient();
  const { auth } = useAuthStore();

  const QueryKey = ['onBoarding'];

  const onBoarding = useQuery({
    queryKey: QueryKey,

    queryFn: async () => {
      const saved = await api.getOnBoarding();
      const data = {
        items: defaultItems
          .map((el) => {
            const savedElement = saved[el.key];
            return savedElement
              ? {
                  ...el,
                  steps:
                    el.steps?.map((step) => {
                      const {
                        progress = 0,
                        metadata,
                        subjectId,
                      } = savedElement.find((f) => f.key === step.key) ?? {};

                      return {
                        ...step,
                        subjectId,
                        progress,
                        ...(metadata && { metadata }),
                      };
                    }) ?? [],
                }
              : el;
          })
          .map((m) => {
            return {
              ...m,
              completed: !m.steps?.some((st) => st.progress !== 100),
            };
          }),
        onboarding: defaultItems.reduce((data, item) => {
          data[item.key] =
            saved[item.key] ??
            (item.steps.map((step) => ({
              key: step.key,
              progress: 0,
            })) as OnBoardingData[]);
          return data;
        }, {} as OnBoardingResult),
        response: saved,
      };
      return data;
    },

    enabled: Boolean(auth),
    retry: false,
    refetchOnWindowFocus: 'always',
  });

  const missingSteps = useMemo(() => {
    if (onBoarding.data?.items) {
      return onBoarding.data.items
        .filter((f) => !f.completed)
        .map(({ key }) => ({ key }));
    }
    return defaultItems.map(({ key }) => ({ key }));
  }, [onBoarding]);

  const updateOnBoardingStep = useMutation({
    mutationFn: ({
      type,
      payload,
    }: {
      type: string;
      payload: { key: string; progress: number };
    }) => {
      const existingOnboardingData = queryClient.getQueryData<{
        items: OnboardingItem[];
        onboarding: OnBoardingResult;
      }>(QueryKey);
      if (
        existingOnboardingData?.items?.some(
          (item) =>
            item.key === type &&
            item.steps.some(
              (step) => step.key === payload.key && step.progress !== 100
            )
        )
      ) {
        return api.updateOnBoardingStep(type, payload);
      }
      return Promise.resolve();
    },

    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(QueryKey, context.previous);
      }
    },

    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: QueryKey,
      });
    },
  });

  const updateStep = async (
    payload: { id?: string },
    schema: ObjectSchema<any>,
    type: string,
    stepKey: string
  ) => {
    const errorCount = await schema
      .validate(payload, { abortEarly: false, strict: true })
      .then(() => 0)
      .catch((err) => {
        console.log(err.errors);
        return err.errors?.length ?? 0;
      });
    const fieldsCount = Object.keys(schema.fields).length;
    updateOnBoardingStep.mutate({
      type,
      payload: {
        key: stepKey,
        progress:
          errorCount > 0
            ? Math.round(((fieldsCount - errorCount) / fieldsCount) * 100)
            : 100,
        ...(payload.id && {
          subjectId: payload.id,
        }),
      },
    });
  };

  return {
    onBoarding,
    updateStep,
    updateOnBoardingStep,
    missingSteps,
  };
};

export const isStepComplete = (step: string, items?: OnboardingItem[]) =>
  items?.find((item) => item.key === step)?.completed;
