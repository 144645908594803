import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import { ButtonBase } from '@mui/material';
import { forwardRef, PropsWithChildren, useState } from 'react';

import { ContextMenu } from '../context-menu/context-menu';
import { ContextMenuItemProps } from '../context-menu/context-menu-item';
import { Badge, BadgeProps } from './badge';

type OptionsBadgeProps = BadgeProps &
  PropsWithChildren & { options: ContextMenuItemProps[] };

export const OptionsBadge = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ children, options, ...props }: OptionsBadgeProps, _ref) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <ContextMenu
        open={isOpen}
        onOpenChange={setIsOpen}
        element={
          <ButtonBase
            disableRipple
            sx={{
              ':focus-visible': {
                outline: 1,
              },
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Badge {...props}>
              {children}
              {isOpen ? (
                <KeyboardArrowUpRounded fontSize="small" />
              ) : (
                <KeyboardArrowDownRounded fontSize="small" />
              )}
            </Badge>
          </ButtonBase>
        }
        options={options}
      />
    );
  }
);

OptionsBadge.displayName = 'OptionsBadge';
