import { SearchRounded } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { useRef } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';
import {
  giftCardSearchClicked,
  giftCardSearchInputted,
} from '../../../../tracking/giftCards/giftCardEvents';

export const GiftCardsSearchFormInput = ({
  query,
  setQuery,
}: {
  query: string;
  setQuery: (query: string) => void;
}) => {
  const { t } = useTranslate('utils.generic');
  const debounceRef = useRef<NodeJS.Timeout>();

  return (
    <TextField
      InputProps={{ endAdornment: <SearchRounded /> }}
      size={'small'}
      placeholder={t('search')}
      value={query}
      onFocus={() => giftCardSearchClicked()}
      onChange={(e) => {
        setQuery(e.target.value);

        clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(() => {
          giftCardSearchInputted();
        }, 1000);
      }}
    />
  );
};
