import { Text } from '@holdbar-com/pixel';
import { Box, Stack } from '@mui/material';

import { Logo } from '../../../Components/logo';
import { useTranslate } from '../../../Hooks/useTranslate';

export const SignUpHero = () => {
  const { t } = useTranslate('signup');

  return (
    <Stack
      sx={{
        p: 4,
        justifyContent: 'space-between',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <Box alignSelf="flex-start">
        <Logo />
      </Box>
      <Stack
        sx={{
          maxWidth: '600px',
          alignItems: 'center',
          gap: 8,
        }}
      >
        <Stack
          sx={{
            gap: 4,
            textAlign: 'center',
          }}
        >
          <Text fontSize="h3">{t('header')}</Text>
          <Text>{t('subtitle')}</Text>
        </Stack>
        <img
          src="/graphics/signup.png"
          alt="Sign up"
          style={{
            maxWidth: '468px',
            width: '100%',
            padding: '0 1rem',
          }}
        />
      </Stack>
      <Stack />
    </Stack>
  );
};
