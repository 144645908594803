import { Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { motion } from 'framer-motion';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useGrowthOpportunities } from '../../domain/use-growth-opportunities';
import { GrowthOpportunityListItem } from './growth-opportunity-list-item';

const flyUpAnimation = {
  initial: { opacity: 0, y: 20 },
  whileInView: { opacity: 1, y: 0 },
  transition: { duration: 0.5, ease: 'easeOut' },
  viewport: { once: true, margin: '-40px' },
};

export const GrowthOpportunityList = () => {
  const { t } = useTranslate('opportunities.list');
  const { opportunities } = useGrowthOpportunities();

  return (
    <Stack gap={3}>
      <Text fontSize="large" variant="medium">
        {t('title')}
      </Text>
      <Stack gap={2}>
        {opportunities.map((opportunity) => (
          <motion.div key={opportunity.id} {...flyUpAnimation}>
            <GrowthOpportunityListItem
              key={opportunity.id}
              opportunity={opportunity}
            />
          </motion.div>
        ))}
      </Stack>
    </Stack>
  );
};
