import { Text } from '@holdbar-com/pixel';
import { CloseRounded } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { FC } from 'react';

import useResponsive from '../../Hooks/layout/useResponsive';

type DialogHeaderProps = {
  title: string;
  handleClose: (shouldRemove?: boolean) => void;
};

export const DialogHeader: FC<DialogHeaderProps> = ({ title, handleClose }) => {
  const { isSm } = useResponsive();

  return (
    <Stack
      padding={isSm ? 2 : 4}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Title title={title} />
      <CloseButton handleClose={handleClose} />
    </Stack>
  );
};

const Title: FC<Pick<DialogHeaderProps, 'title'>> = ({ title }) => {
  return (
    <Text variant="medium" fontSize="xlarge">
      {title}
    </Text>
  );
};

const CloseButton: FC<Pick<DialogHeaderProps, 'handleClose'>> = ({
  handleClose,
}) => {
  return (
    <IconButton sx={{ padding: 0 }} onClick={() => handleClose(true)}>
      <CloseRounded sx={{ fontSize: { xs: '24px', md: '32px' } }} />
    </IconButton>
  );
};
