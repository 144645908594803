import { Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useOnboardingWidgetContext } from '../use-onboarding-widget-context';
import { OnboardingWidgetItemActions } from './onboarding-widget-item-actions';
import { OnboardingWidgetItemAnimation } from './onboarding-widget-item-animation';
import { OnboardingWidgetItemContainer } from './onboarding-widget-item-container';

export const OnboardingWidgetItem = () => {
  const { t } = useTranslate('onboardingSetup');

  const { items, step } = useOnboardingWidgetContext();

  return (
    <OnboardingWidgetItemContainer
      flexGrow={1}
      maxWidth={800}
      minWidth={320}
      itemCount={(items.length === 0 ? 1 : items.length) - (step + 1)}
    >
      <OnboardingWidgetItemAnimation>
        <Stack gap={2}>
          <Text fontSize="xlarge" variant="medium">
            {t(`${items[step]?.key}.title`)}
          </Text>
          <Text>{t(`${items[step]?.key}.description`)}</Text>
        </Stack>
        <OnboardingWidgetItemActions />
      </OnboardingWidgetItemAnimation>
    </OnboardingWidgetItemContainer>
  );
};
