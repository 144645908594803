import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { AutoAwesome, CloseRounded, TextIncrease } from '@mui/icons-material';
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
} from '@mui/material';
import { toast } from 'react-toastify';

import { ExperiencePracticalInfoSuggestion } from '../../../Api/Suggestion';
import { useSuggestionDecision } from '../../../Hooks/data/useSuggestions';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useExperience } from '../../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../Hooks/useTranslate';
import { Markdown } from '../markdown';
import { SuggestionsExperienceName } from '../suggestions-experience-name';

type Props = {
  suggestion: ExperiencePracticalInfoSuggestion;
};

export const SuggestionExperiencePracticalInfoModal = NiceModal.create<Props>(
  ({ suggestion }: Props) => {
    const { t } = useTranslate('suggestions');

    const modal = useModal();
    const { isSm } = useResponsive();

    const localize = useLocalizedStringFormatter();

    const {
      experience: { data },
      updateExperience,
    } = useExperience(suggestion.entityId);

    const { ignoreSuggestion, acceptSuggestion } = useSuggestionDecision(
      suggestion.suggestionId
    );

    const handleClose = () => {
      modal.hide();
      modal.remove();
    };

    const handleCallToAction = async () => {
      if (!data || Object.keys(data).length === 0) {
        return;
      }

      toast.promise(
        async () => {
          handleClose();

          await updateExperience.mutateAsync({
            ...data,
            ...suggestion.after,
          });

          await acceptSuggestion();
        },
        {
          pending: t('applying'),
          success: t('applyingDone'),
          error: t('applyingFailed'),
        }
      );
    };

    return (
      <Dialog
        fullWidth
        maxWidth={'md'}
        fullScreen={isSm}
        open={modal.visible}
        onClose={handleClose}
        sx={{ minHeight: '300px' }}
        PaperProps={{
          sx: {
            minHeight: '300px',
            padding: 3,
            paddingTop: 5,
          },
        }}
      >
        <IconButton
          sx={{
            top: 0,
            position: 'absolute',
            right: 0,
            color: lightTheme.palette.contrast.black,
          }}
          onClick={handleClose}
        >
          <CloseRounded sx={{ fontSize: { xs: '24px', md: '32px' } }} />
        </IconButton>
        <DialogContent sx={{ padding: 0 }}>
          <Stack gap={2}>
            <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
              <TextIncrease
                sx={{
                  color: lightTheme.palette.action.a300,
                  width: 32,
                  height: 32,
                }}
              />
              <Stack>
                <Text
                  fontSize="xsmall"
                  style={{
                    lineHeight: 1,
                  }}
                >
                  {t('experienceDescription.title')}
                </Text>
                <SuggestionsExperienceName experienceId={suggestion.entityId} />
              </Stack>

              <Chip
                variant="filled"
                label={
                  <Text variant="medium" fontSize="small">
                    Beta
                  </Text>
                }
                size="small"
              />
            </Stack>
            <Text fontSize="small">
              {t('experienceDescription.description')}
            </Text>
            <StyledGrid>
              <Stack gap={1}>
                <Text variant="medium">
                  {t('experienceDescription.currentDescription')}
                </Text>
                <PreviewBox>
                  <Markdown>
                    {localize(suggestion.before.practicalInfo) +
                      '\n\n' +
                      localize(suggestion.before.whatsIncluded)}
                  </Markdown>
                </PreviewBox>
              </Stack>
              <Stack gap={1}>
                <Text variant="medium">
                  {t('experienceDescription.suggestedDescription')}
                </Text>
                <PreviewBox>
                  <Markdown>
                    {localize(suggestion.after.practicalInfo) +
                      '\n\n' +
                      localize(suggestion.after.whatsIncluded)}
                  </Markdown>
                </PreviewBox>
              </Stack>
            </StyledGrid>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ paddingTop: 3 }}>
          <OutlinedButton
            size="medium"
            variant="text"
            onClick={() => {
              ignoreSuggestion();
              handleClose();
            }}
          >
            {t('ignore')}
          </OutlinedButton>
          <Button
            size="medium"
            variant="primary"
            onClick={() => handleCallToAction()}
            rightIcon={<AutoAwesome width={16} />}
          >
            {t('apply')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

const PreviewBox = styled.div`
  border: 1px solid ${lightTheme.palette.neutral.n100};
  border-radius: 8px;
  padding: 8px;
`;

const OutlinedButton = styled(Button)`
  border: 1px solid ${lightTheme.palette.neutral.n200};
  border-radius: 8px;
`;
