import axiosStatic from 'axios';

import { apiServices } from './apiServices';
import api from './index';

export type TTerms = {
  [type: string]: { versions: { [lang: string]: { content: string } } };
};

export const getTerms = async () => {
  if (typeof apiServices.terms === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_TERMS_URL is not defined'
    );
  }

  try {
    const { data } = await api.get<TTerms>(`${apiServices.terms}/company`);
    return data;
  } catch (error) {
    if (axiosStatic.isAxiosError(error)) {
      if (error.response && error.response.status === 404) {
        return null;
      }
    }

    throw error;
  }
};

export const updateTerms = async (
  type: string,
  payload: { [k: string]: unknown }
) => {
  if (typeof apiServices.terms === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_TERMS_URL is not defined'
    );
  }

  if (!payload) {
    throw new Error('Missing payload');
  }

  const { data } = await api.put(
    `${apiServices.terms}/company/${type}`,
    payload
  );

  return data;
};

export const createFromTemplate = async <T>(
  type: string,
  language: string,
  variables: { [k: string]: string }
) => {
  if (typeof apiServices.terms === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_TERMS_URL is not defined'
    );
  }

  if (!type) {
    throw new Error('Missing type');
  }

  const { data } = await api.post<T>(`${apiServices.terms}/template/${type}`, {
    language,
    variables,
  });

  return data;
};
